// -------------------------------------------------------------------------------------------------
//  Avatar.js
//  - - - - - - - - - -
//  Відображення подвійної аватарки юзера.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/UI/icons/Icon';
import styles from './Avatar.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Avatar extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    title: Types.string,                  // title string
    badgeText: Types.string,              // текстова мітка на аватарі
    largeClassName: Types.string,         // large image class name
    smallClassName: Types.string,         // small image class name
    largePreviewUrl: Types.string,        // bigger preview url
    smallPreviewUrl: Types.string,        // smaller preview url
    presenceStatus: Types.bool,           // presence status
    hasSecondIcon: Types.bool,            // does avatar have second icon?
    defaultIcon: Types.string,            // default icon
  }

  static defaultProps = {
    title: '',
    presenceStatus: false,
    hasSecondIcon: false,                 // по-замовчуванню другу аватарку НЕ показуємо !!!
    defaultIcon: "logo-icon-grayscale",
  }

  render() {
    const {
      className,
      title,
      badgeText,
      largeClassName,
      smallClassName,
      largePreviewUrl,
      smallPreviewUrl,
      presenceStatus,
      hasSecondIcon,
      defaultIcon} = this.props;
    return (
      <div className={classnames(styles.Avatar, className)}>
        <Icon symbolName={defaultIcon} className={styles.defaultIcon} />
        {largePreviewUrl &&
          <div
            className={classnames(styles.largeImage, largeClassName)}
            style={{backgroundImage: `url(${largePreviewUrl})`}}
            title={title}
          />
        }
        {hasSecondIcon && smallPreviewUrl && smallPreviewUrl !== largePreviewUrl &&
          <div
            className={classnames(styles.smallImage, smallClassName)}
            style={{backgroundImage: `url(${smallPreviewUrl})`}}
            title={title}
          />
        }
        {presenceStatus && <span className={classnames(styles.presenceStatus, styles.online)} /> }
        {badgeText && <span className={styles.badgeText}>{badgeText}</span> }
      </div>
    );
  }
}
