// -------------------------------------------------------------------------------------------------
//  hr.js
//  - - - - - - - - - -
//  a) {`***` | `---` | `* * *` | `-----------`}[SPACE] ---> <hr>
//  b) ...[SPACE]{`***` | `---` | `* * *` | `-----------`}[SPACE] ---> ...' ―'
//
//  Notes:
//  - працює ТІЛЬКИ для параграфу, глибиною менше 3 та якщо попередній блок не <hr>;
//  - в усіх інших випадках послідовність замінюємо на DASH;
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import {blockTypes} from 'core/richTypes';

export default function autoreplaceHorizontal(node, matched, change) {
  // - - - a:[hr]
  if (
    matched.index === 0
    && change.value.document.getDepth(node.key) < 3
    && change.value.document.getFurthestAncestor(node.key).type === blockTypes.PARAGRAPH
    && change.value.document.getPreviousBlock(node.key).type !== blockTypes.HR
  ) {
    change.removeNodeByKey(node.key);
    change.insertBlock({
      type: blockTypes.HR,
      isVoid: true
    });
    return change.collapseToStartOfNextBlock();
  }

  // - - - b:[dash]
  const matchedLength = matched[0].length;
  const addedText = matched.index === 0 ? '―' : ' ―';

  change.deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );
  return change.insertTextByKey(node.key, matched.index, addedText);
}
