// -------------------------------------------------------------------------------------------------
//  MyMessage.js
//  - - - - - - - - - -
//  Повідомлення в чат від користувача.
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {is} from 'immutable';
import dateFormat from 'dateformat';
import classnames from 'classnames';
import {t} from 'ttag';
import {MARKDOWN_CSS, SELECTABLE_CSS} from 'core/commonTypes';
import {
  UCTX_CHAT, SCTX_CHAT,
  TEXT_MESSAGE,
  MEDIA_MESSAGE,
  GRADE_MESSAGE,
  REPLY_MESSAGE,
  WIPED_MESSAGE,
  SYSTEM_MESSAGE,
  MISSED_CALL_MESSAGE,
  REJECTED_CALL_MESSAGE,
  FINISHED_CALL_MESSAGE,
  CLOSED_MESSAGE} from 'core/communicationTypes';
import {deleteChatMessage} from 'actions/ChatActions';
import MessageCommands from 'components/Chats/MessageCommands';
import {confirmAction} from 'components/UI/ConfirmAction';
import Grade, {getGradeTitle} from 'components/UI/Grade';
import Icon from 'components/UI/icons/Icon';
import {mediaViewer} from 'components/MediaViewer';
import {togglePopupMenu} from 'components/UI/PopupMenu';
import {humanDateShort} from 'utils/humanFormats';
import styles from './MyMessage.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - MyMessage';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class MyMessage extends React.Component {
  static propTypes = {
    message: Types.object,                // повідомлення
    chatType: Types.string,               // тип чату
    createdAt: Types.string,              // час створення повідомлення
    handleSetFocus: Types.func,           // функція установки фокуса компоненти OneChat
  }

  state = {
    isProcessing: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.createdAt !== nextProps.createdAt
      || this.state.isProcessing !== nextState.isProcessing
      || !is(this.props.message, nextProps.message);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  handleTogglePopup = (e) => {
    e.preventDefault();
    const {message} = this.props;
    const {id:messageId} = message || {};
    togglePopupMenu(prefix + messageId);
  }

  // // Attn: 1) повідомлення може бути видалене протягом 10 хвилин !!!
  // isLast10MinutesMessage = () => {
  //   const {createdAt} = this.props;
  //   return ((new Date()).getTime() - (new Date(createdAt)).getTime()) / 1000 / 60 < 10; // sic!: 1)
  // }

  handleViewMedia = () => {
    trace(`MyMessage:handleViewMedia`);
    const {message, handleSetFocus} = this.props;
    const {type, previewXlUrl} = message || {};
    mediaViewer({
      type:type,
      previewUrl:previewXlUrl,
      handleSetFocus:handleSetFocus,
    });
  }

  handleConfirmDelete = () => {
    trace(`handleConfirmDelete`);
    const {message, handleSetFocus} = this.props;
    if (message) {
      const {id:messageId, chatId} = message || {};
      confirmAction({
        title: t`Delete Message`,
        description: t`Are you sure to permanently delete this Message?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { deleteChatMessage({chatId, messageId}); handleSetFocus && handleSetFocus(); }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  render() {
    const {chatType, message, createdAt} = this.props;
    const {isProcessing} = this.state;
    const {id:messageId, type, extraText, bodyHtml, previewMdUrl, isRead} = message || {};
    trace(`render`);
    return (
      <div
        className={classnames(styles.MyMessage, {
          [styles.isUctxChat]: chatType === UCTX_CHAT && type !== WIPED_MESSAGE,
          [styles.isSctxChat]: chatType === SCTX_CHAT && type !== WIPED_MESSAGE,
          [styles.gradeType]: type === GRADE_MESSAGE,
          [styles.wipedType]: type === WIPED_MESSAGE,
          [styles.systemType]: type === SYSTEM_MESSAGE,
          [styles.missedCallType]: type === MISSED_CALL_MESSAGE || type === REJECTED_CALL_MESSAGE,
          [styles.finishedCallType]: type === FINISHED_CALL_MESSAGE
        })}
        title={dateFormat(createdAt, t`mmm d, yyyy HH:MM`)}
      >
        {type === GRADE_MESSAGE && extraText &&
          <Grade className={styles.grade} iconClassName={styles.icon} gradeId={extraText} />
        }
        {type === WIPED_MESSAGE &&
          <F>
            <Icon symbolName="delete-2" className={styles.wipedIcon} />
            <div className={styles.wipedText}>{t`Message was deleted`}</div>
          </F>
        }
        {previewMdUrl &&
          <div className={classnames(styles.pictureHolder, {[styles.hasBody]: bodyHtml && type !== MISSED_CALL_MESSAGE})}>
            <img src={previewMdUrl} ref={e => this.pictureRef = e} onClick={this.handleViewMedia} />
          </div>
        }
        {bodyHtml && type !== MISSED_CALL_MESSAGE &&
          <div className={classnames(MARKDOWN_CSS, styles.bodyText)}>{bodyHtml}</div>
        }
        {type === MISSED_CALL_MESSAGE &&
          <Icon symbolName="phone-missed" className={styles.missedCallIcon} />
        }
        <div className={styles.statusPanel}>
          <div className={styles.dateTime}>{humanDateShort(createdAt)}</div>
          <Icon symbolName={isRead ? "done-all" : "done"} className={styles.readIcon} />
        </div>
        {(type === TEXT_MESSAGE || type === MEDIA_MESSAGE || type === REPLY_MESSAGE || type === GRADE_MESSAGE) &&
          <MessageCommands
            prefix={prefix}
            className={styles.messageCommandsButton}
            messageId={messageId}
            onDelete={this.handleConfirmDelete}
            onTogglePopup={this.handleTogglePopup}
          />
        }
      </div>
    );
  }
}
