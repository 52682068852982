// -------------------------------------------------------------------------------------------------
//  StapleStore.js
//  - - - - - - - - - -
//  Staples & MyStaples.
//
//  Comments:
//  - - - - -
//  - поля id, value, label потрібні для Select2 (геттери: value, label);
//  - при розміщенні обʼєктів в стор перетворюємо назви полів: snake_case --> camelCase;
//  - collections зберігаємо в сторі в текстовому вигляді в форматі {{ CO-LIST-STR }};
//  - для кожної колекції формуємо список ідентифікаторів стейплів, що входять в цю колекцію:
//    ['collectionStaplesOptions', collectionId, 'stapleIds'];
//  - функції get{...}Staples віддають обʼєкти тільки ДО курсора, щоб не було "пропусків" в фіді;
//  - embed-плеєри (напр: YouTube) вимагають незакодованого url;
//    а оскільки на сервері виконується ескейпінг, то при збереженні в стор
//    виконуємо зворотнє перетворення для символу '=';
//    https://www.youtube.com/playlist?list%3DPLMi4vCbIfdcvwwWQtus2Zld-G16Y34kn0 (backend)
//    https://www.youtube.com/playlist?list=PLMi4vCbIfdcvwwWQtus2Zld-G16Y34kn0 (YouTube)
//  - декілька видів поля 'position':
//    -   position: позиція стейплу у загальних фідах (беремо зі стейплу);
//    -   position2: позиція стейплу в колекції (присвоюється із stapleDatas при вході в режим
//        сортування колекції);
//    -   prevPosition: позиція стейплу в фіді до сортування (присвоюється в залежності від режиму
//        сортування із stapleDatas/Staple при вході в режим);
//  - для колекцій позиція стейплу в списку зберігається в екстра-даних (stapleDatas):
//    stapleFeeds: {
//      [collectionId]: {                         // дані фіду стейплів до колекції collectionId
//          areStaplesLoaded: false,              // чи завантажений фід повністю?
//          stapleCursor: 'zzzzzzzzzz',           // поточний курсор фіду
//          stapleDatas: [                        // екстра-дані стейплів із колекції collectionId
//              [stapleId]: {                     // дані до стейплу stapleId
//                  z2: 'alK9A6srElz',            // позиція стейплу в колекції
//                  zx: 125,                      // позиція X для masonry
//                  zy: 300,                      // позиція Y для masonry
//                  ...                           // ...інші дані про стейпл із колекції
//              }, ...]}, ...}
//
//  Attn:
//  - - - - -
//  a)  для показу новостворених стейплів в пустому фіді курсор ще не визначений,
//      тому він може бути дефолтним, а отже умова є: (cursor || cursor === DEFAULT_..._CURSOR);
//  b)  суфікс '...Feed' в назві функції показує, що функція повертає відразу повний набір
//      параметрів фіду (напр: {staples, stapleCursor, areStaplesLoaded});
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {List, Map, Set, OrderedSet, Record} from 'immutable';
import {parse as UriParse} from 'uri-js';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  DISCOVER_STAPLES_WERE_FETCHED_ACTION,
  FEATURED_STAPLES_WERE_FETCHED_ACTION,
  FOLLOWING_STAPLES_WERE_FETCHED_ACTION,
  SEARCHED_STAPLES_WERE_FETCHED_ACTION,
  CSUBSCRIPTION_STAPLES_WERE_FETCHED_ACTION,
  COLLECTION_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
  COLLECTION_STAPLES_WERE_FETCHED_ACTION,
  MY_STAPLES_WERE_FETCHED_ACTION,
  USER_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
  USER_STAPLES_WERE_FETCHED_ACTION,
  STAPLE_WAS_FETCHED_AS_GUEST_ACTION,
  STAPLE_WAS_FETCHED_ACTION,
  STAPLE_WAS_CREATED_ACTION,
  STAPLE_WAS_CLONED_ACTION,
  STAPLE_WAS_UPDATED_ACTION,
  STAPLE_WAS_BANNED_ACTION,
  STAPLES_WERE_DELETED_ACTION,
  STAPLE_WAS_SELECTED_ACTION,
  STAPLE_WAS_UNSELECTED_ACTION,
  STAPLES_WERE_UNSELECTED_ACTION,
  STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION,
  STAPLES_OF_COLLECTION_WERE_UNSELECTED_ACTION,
  STAPLE_UPDATED_NTF_WAS_RECEIVED_ACTION,
  COLLECTION_WAS_UPDATED_ACTION,
  COLLECTION_WAS_DELETED_ACTION,
  COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION,
  COLLECTION_WAS_FOLLOWED_ACTION,
  COLLECTION_WAS_UNFOLLOWED_ACTION,
  MY_CONTEXTS_WERE_FETCHED_ACTION,
  USER_CONTEXTS_WERE_FETCHED_ACTION,
  // MY_SCTX_CHATS_WERE_FETCHED_ACTION,
  STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION,
  SCTX_CHATS_WERE_CREATED_ACTION,
  CHAT_WAS_DELETED_ACTION,
  // CHAT_CONTEXT_WAS_UPDATED_ACTION,
  UNREAD_MESSAGES_WERE_FETCHED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  STAPLE_FLD,
  STAPLE_ID_FLD,
  STAPLEDATAS_FLD,
  COLLECTION_ID_FLD,
  ID_FLD,
  TYPE_FLD,
  OWNER_ID_FLD,
  AUTHOR_ID_FLD,
  NAME_FLD,
  BODY_FLD,
  COMMENTS_FLD,
  COLLECTIONS_FLD,
  ELEMENTS_FLD,
  ELEMENTS1_FLD,
  ELEMENTS2_FLD,
  EMBED_CODE_FLD,
  EMBED_TYPE_FLD,
  DOCUMENT_TYPE_FLD,
  DOCUMENT_XHASH2_FLD,
  DOCUMENT_EXT_FLD,
  ORIGIN_URL_FLD,
  PICTURE_SOURCE_URL_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  BLOB_FLD,
  BLOBS_FLD,
  DOMAIN_FLD,
  PUBLIC_COLLECTIONS_QTY_FLD,
  PRIVATE_COLLECTIONS_QTY_FLD,
  CREATED_AT_FLD,
  UPDATED_AT_FLD,
  HAS_PUBLIC_COLLECTIONS_FLD,
  HAS_PRIVATE_COLLECTIONS_FLD,
  IS_PUBLIC_FLD,
  IS_CLONABLE_FLD,
  POSITION_FLD,
  POSITION2_FLD,
  PREV_POSITION_FLD} from 'core/apiFields';
import {
  DEFAULT_TSN12_CURSOR,
  DEFAULT_POS10_CURSOR,
  PRIVATE_PRIVACY,
  PUBLIC_PRIVACY,
  MISC_PRIVACY,
  LS_SETTINGS} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {
  MY_CSUBSCRIPTION_STAPLES_FEED,
  DISCOVER_STAPLES_FEED,
  FEATURED_STAPLES_FEED,
  FOLLOWING_STAPLES_FEED,
  SEARCHED_STAPLES_FEED,
  COLLECTION_STAPLES_FEED,
  USER_STAPLES_FEED} from 'core/uiTypes';
import {fetchStaple} from 'actions/StapleActions';
import {toCoListStr} from 'components/UI/fields/SelectField';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {composePictureUrl} from 'utils/settingsTools';
import {domain} from 'settings/local.yaml';

const DISCOVER_STAPLES_PREFIX             = DISCOVER_STAPLES_FEED; // префікс ключа для discover-фіду стейплів
const FEATURED_STAPLES_PREFIX             = FEATURED_STAPLES_FEED; // префікс ключа для featured-фіду стейплів
const FOLLOWING_STAPLES_PREFIX            = FOLLOWING_STAPLES_FEED; // префікс ключа для following-фіду стейплів
const SEARCHED_STAPLES_PREFIX             = SEARCHED_STAPLES_FEED; // префікс ключа для search-фіду стейплів
const CSUBSCRIPTION_STAPLES_PREFIX        = MY_CSUBSCRIPTION_STAPLES_FEED; // префікс ключа для фіду стейплів підписок на курс-колекції
const COLL_STAPLES_PREFIX                 = COLLECTION_STAPLES_FEED; // префікс ключа для фіду стейплів колекції (напр: c19vDIwiC8g00)
const USER_STAPLES_PREFIX                 = USER_STAPLES_FEED; // префікс ключа для фіду стейплів юзера (напр: u19c4CJjfP900)

const isVerbose = DEBUG && true;
const prefix = '- - - StapleStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function parseDomain(originUrl = '') {
  if (originUrl) {
    const {host} = UriParse(originUrl);
    if (host) {
      return host;
    }
  }
  return domain;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MyElementRecord = Record({
  [ID_FLD]: '',                           // код елементу
  [TYPE_FLD]: '',                         // тип елементу ('a', 'b', 'e', 'i', 't', 'n', 'd', ...)
  [NAME_FLD]: '',                         // назва елементу
  [BODY_FLD]: '',                         // публічний текст від автора/author елементу (Conmark) (напр: умова задачі)
  [COMMENTS_FLD]: '',                     // непублічні коментарі від власника/owner стейплу (Conmark) (напр: відповідь до задачі)
  [EMBED_CODE_FLD]: '',                   // code of embedded object (http://www.youtube.com/embed/{code})
  [EMBED_TYPE_FLD]: '',                   // type of embedded object
  [ORIGIN_URL_FLD]: '',                   // url of origin webpage
  [PICTURE_SOURCE_URL_FLD]: '',           // md-size url (http://img.youtube.com/vi/{code}/mqdefault.jpg)
  [PICTURE_XHASH2_FLD]: '',               // хеш-код файлу превьюшки
  [PICTURE_EXT_FLD]: '',                  // розширення файлу превьюшки
  [DOCUMENT_TYPE_FLD]: '',                // тип документу (з 'api/DocumentAPI')
  [DOCUMENT_XHASH2_FLD]: '',              // хеш-код xhash2 документу
  [DOCUMENT_EXT_FLD]: '',                 // розширення файлу документу (pdf, xls, doc, ...)
  [UPDATED_AT_FLD]: '',                   // дата/час оновлення елементу
  // ...custom:
  [POSITION_FLD]: 0,                      // позиція в списку елементів стейплу
  [DOMAIN_FLD]: '',                       // домен з адреси originUrl
  [BLOB_FLD]: '',                         // завантажене в памʼять зображення
});

class MyElement extends MyElementRecord {
  get faviconUrl() {
    return `https://www.google.com/s2/favicons?domain=${this[DOMAIN_FLD]}`;
  }
  get previewSsUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 's0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get previewMdUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 'm0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get previewXlUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 'x0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get bodyHtml() {
    return toReact(this[BODY_FLD]);
  }
  get commentsHtml() {
    return toReact(this[COMMENTS_FLD]);
  }
}

// ToDo: може треба додати elementIndex ???
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MyStapleRecord = Record({
  id: '',                                 // код стейплу
  ownerId: '',                            // власник стейплу (юзер, що створив стейпл)
  authorId: '',                           // автор контенту (юзер, хто створив елемент[и])
  createdAt: '',                          // дата створення
  [ELEMENTS_FLD]: List(),                 // список елементів (обʼєднані публічна & приватна частини)
  [COLLECTIONS_FLD]: '[]',                // список колекцій в форматі {{ CO-LIST-STR }}
  [HAS_PUBLIC_COLLECTIONS_FLD]: true,     // sic!: для розрахунку privacy чужих стейплів (допускаємо існування публічних колекцій)
  [HAS_PRIVATE_COLLECTIONS_FLD]: true,    // sic!: для розрахунку privacy чужих стейплів (допускаємо існування приватних колекцій)
  [IS_PUBLIC_FLD]: true,                  // sic!: для розрахунку privacy чужих стейплів
  [IS_CLONABLE_FLD]:true,                 // флаг можливості клонування (присвоюється false для стейплів які або приватні, або не мають публічних колекцій)
  [POSITION_FLD]: '',                     // позиція стейплу у загальних фідах (присвоюється зі стейплу)
  // ...custom:
  [POSITION2_FLD]: '',                    // позиція стейплу в колекції (присвоюється із stapleDatas при вході в режим сортування колекції)
  [PREV_POSITION_FLD]: '',                // позиція стейплу в фіді до сортування (присвоюється в зал.від режиму сортування із stapleDatas/Staple при вході в режим)
  isSelected: false,                      // флаг відмітки контакту для множинної операції
  chatsCursor: DEFAULT_TSN12_CURSOR,      // наступний курсор для списку чатів цього стейплу
  areChatsLoaded: false,                  // чи завантажені усі чати цього стейплу в стор?
});

class MyStaple extends MyStapleRecord {
  get privacy() {
    if (this[IS_PUBLIC_FLD]) {
      if (this[HAS_PUBLIC_COLLECTIONS_FLD] === this[HAS_PRIVATE_COLLECTIONS_FLD]) { // sic!: T && T || F && F
        return MISC_PRIVACY;
      } else if (this[HAS_PUBLIC_COLLECTIONS_FLD] && !this[HAS_PRIVATE_COLLECTIONS_FLD]) {
        return PUBLIC_PRIVACY;
      }
    }
    return PRIVATE_PRIVACY;
  }
  get isPublic() {
    return this[IS_PUBLIC_FLD];
  }
  get isClonable() {
    return this[IS_CLONABLE_FLD];
  }
  get elementsQty() {
    return (this[ELEMENTS_FLD] && this[ELEMENTS_FLD].size) || 0;
  }
  get lstCollections() {
    return JSON.parse(this[COLLECTIONS_FLD]); // '[]' --> []
  }
  get defaultSortKey() {
    return this.id; // sic!: поки що загальні фіди сортуються по Staple.id !!!
  }
}

// Attn: 1) embed-плеєри (напр: YouTube) вимагають незакодованого url;
//          а оскільки на сервері виконується ескейпінг, то при збереженні в стор
//          виконуємо зворотнє перетворення для символу '=';
//          https://www.youtube.com/playlist?list%3DPLMi4vCbIfdcvwwWQtus2Zld-G16Y34kn0 (backend)
//          https://www.youtube.com/playlist?list=PLMi4vCbIfdcvwwWQtus2Zld-G16Y34kn0 (YouTube)
// Attn: 2) тимчасові blob-превьюшок передаються через staple-обʼєкт і потім присвоюються в елементи;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeStaple(state, patchObject) {
  const stapleId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['staples', stapleId], prevStaple => {
    const {
      [ID_FLD]:id,
      [OWNER_ID_FLD]:ownerId,
      [AUTHOR_ID_FLD]:authorId,
      [ELEMENTS1_FLD]:strElements1,
      [ELEMENTS2_FLD]:strElements2,
      [CREATED_AT_FLD]:createdAt,
      [COLLECTIONS_FLD]:tgxCollections,
      [PUBLIC_COLLECTIONS_QTY_FLD]:publicCollectionsQty,
      [PRIVATE_COLLECTIONS_QTY_FLD]:privateCollectionsQty,
      [IS_PUBLIC_FLD]:isPublic,
      [POSITION_FLD]:position,
      [POSITION2_FLD]:position2, // sic!: не зберігаємо бо беремо із stapleDatas, але дані є в {{ STAPLE }} + z2 тому так !!!
      [BLOBS_FLD]:pictureBlobUrls, // blob-превьюшки передаємо через обʼєкт стейплу !!!
      ...innerFormatStapleFields} = patchObject; // без camelcaseKeys (бо є поля з '_') !!!
    const formattedObject = camelcaseKeys(innerFormatStapleFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                     {formattedObject.id = stapleId;}
    if (ownerId !== undefined)                {formattedObject.ownerId = ownerId;}
    if (authorId !== undefined)               {formattedObject.authorId = authorId;}
    if (createdAt !== undefined)              {formattedObject.createdAt = createdAt;}
    if (position !== undefined)               {formattedObject[POSITION_FLD] = position;}
    if (tgxCollections !== undefined)         {formattedObject[COLLECTIONS_FLD] = toCoListStr(JSON.parse(tgxCollections));}
    if (isPublic !== undefined)               {formattedObject[IS_PUBLIC_FLD] = isPublic;}
    if (publicCollectionsQty !== undefined)   {formattedObject[HAS_PUBLIC_COLLECTIONS_FLD] = !!publicCollectionsQty;} // number --> bool
    if (privateCollectionsQty !== undefined)  {formattedObject[HAS_PRIVATE_COLLECTIONS_FLD] = !!privateCollectionsQty;} // number --> bool
    if (isPublic === false || publicCollectionsQty === 0) {formattedObject[IS_CLONABLE_FLD] = false;}
    // - - - elements:
    if (strElements1) {
      const lstElements1 = JSON.parse(strElements1) || [];
      const mapElements2 = strElements2 ? JSON.parse(strElements2) : {};
      formattedObject[ELEMENTS_FLD] = (lstElements1).reduce((acc, elem, index) => {
        const {[COMMENTS_FLD]:comments, [UPDATED_AT_FLD]:updatedAt} = mapElements2[elem[ID_FLD]] || {};
        if (comments !== undefined)           {elem[COMMENTS_FLD] = comments;}
        if (updatedAt !== undefined)          {elem[UPDATED_AT_FLD] = updatedAt;}
        if (pictureBlobUrls !== undefined)    {elem[BLOB_FLD] = pictureBlobUrls[index];} // ...якщо елемент має превьюшку і її щойно завантажили !!!
        elem[ORIGIN_URL_FLD]                = (elem[ORIGIN_URL_FLD] || '').replace(/%3D/g, '='); // sic!: a) & non-null value !!!
        elem[PICTURE_SOURCE_URL_FLD]        = elem[PICTURE_SOURCE_URL_FLD] || ''; // sic!: non-null value !!!
        elem[DOMAIN_FLD]                    = parseDomain(elem[ORIGIN_URL_FLD]);
        return acc.push((new MyElement()).merge(elem));
      }, List());
    }
    // - - -
    return (prevStaple || new MyStaple()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const StapleFeed = Record({
  stapleIds: Set(),                       // ідентифікатори стейплів, що входять в фід
  stapleDatas: Map(),                     // екстра-дані до стейплів колекції
  stapleCursor: '',                       // наступний курсор для даного фіду (sic!: '', бо ініціалізуємо в Actions/API)
  areStaplesLoaded: false,                // чи завантажені усі стейпли цього фіду в стор?
});

function mergeStapleFeed(state, key, patchObject) {
  return state.updateIn(['stapleFeeds', key], prevFeed => {
    return (prevFeed || new StapleFeed()).merge(patchObject);
  });
}

function resetStapleFeed(state, key) {
  return state.updateIn(['stapleFeeds', key], prevFeed => {
    return StapleFeed();
  });
}

// Attn: 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class StapleStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // sic!: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // ідентифікатор користувача
      staples: Map(),                     // завантажені в стор стейпли
      stapleFeeds: Map(),                 // дані для фідів стейплів (UserStaples, CollectionStaples, DiscoverStaples, ...)
      selectedStapleIds: Set(),           // ідентифікатори відмічених/відібраних користувачем стейплів
    });
  }

  // - - - predicates:

  // areDiscoverStaplesLoaded() {
  //   const result = !!this.getState().getIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX, 'areStaplesLoaded']);
  //   trace(`areDiscoverStaplesLoaded: loaded=${result}`);
  //   return result;
  // }

  // areFeaturedStaplesLoaded() {
  //   const result = !!this.getState().getIn(['stapleFeeds', FEATURED_STAPLES_PREFIX, 'areStaplesLoaded']);
  //   trace(`areFeaturedStaplesLoaded: loaded=${result}`);
  //   return result;
  // }

  areFollowingStaplesLoaded() {
    const result = !!this.getState().getIn(['stapleFeeds', FOLLOWING_STAPLES_PREFIX, 'areStaplesLoaded']);
    trace(`areFollowingStaplesLoaded: loaded=${result}`);
    return result;
  }

  areCollectionStaplesLoaded(collectionId) {
    const result = !!this.getState().getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId, 'areStaplesLoaded']);
    trace(`areCollectionStaplesLoaded: loaded=${result}`);
    return result;
  }

  areMyStaplesLoaded() {
    const myId = this.getState().get('myId');
    const result = !!this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + myId, 'areStaplesLoaded']);
    trace(`areMyStaplesLoaded: loaded=${result}`);
    return result;
  }

  areUserStaplesLoaded(userId) {
    const result = !!this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + userId, 'areStaplesLoaded']);
    trace(`areUserStaplesLoaded: loaded=${result}`);
    return result;
  }

  isStapleLoaded(id) {
    const result = !!this.getState().getIn(['staples', id]);
    trace(`isStapleLoaded: chatId=${id}, loaded=${result}`);
    return result;
  }

  areStapleChatsLoaded(id) {
    const result = this.getState().getIn(['staples', id, 'areChatsLoaded']);
    trace(`areStapleChatsLoaded: stapleId=${id}, loaded=${result}`);
    return result;
  }

  hasSelectedStaples() {
    const result = this.getState().get('selectedStapleIds').size > 0;
    trace(`hasSelectedStaples: ${result}`);
    return result;
  }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getStaple(id) {
    return this.getState().getIn(['staples', id]);
  }

  getMyStaples() { // ToDo: повертати {staples, stapleCursor, areStaplesLoaded} !!!
    trace(`getMyStaples`);
    const myId = this.getState().get('myId');
    const cursor = this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + myId, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
    return cursor || cursor === DEFAULT_TSN12_CURSOR ? // sic!: a)
      this.getState().get('staples')
        .filter(staple => staple.ownerId === myId && staple.id >= cursor)
        .toList()
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; }) :
      OrderedSet();
  }

  getUserStaples(userId) { // ToDo: повертати {staples, stapleCursor, areStaplesLoaded} !!!
    trace(`getUserStaples`);
    const cursor = this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + userId, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
    return cursor || cursor === DEFAULT_TSN12_CURSOR ? // sic!: a)
      this.getState().get('staples')
        .filter(staple => staple.ownerId === userId && staple.id >= cursor)
        .toList()
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; }) :
      OrderedSet();
  }

  getCSubscriptionStaplesFeed(collectionId) {
    trace(`getCSubscriptionStaplesFeed`);
    const {
      stapleDatas,
      stapleCursor = DEFAULT_POS10_CURSOR,
      areStaplesLoaded} = this.getState().getIn(['stapleFeeds', CSUBSCRIPTION_STAPLES_PREFIX + collectionId]) || StapleFeed();
    const staples = (stapleCursor || stapleCursor === DEFAULT_POS10_CURSOR) && stapleDatas ? // sic!: a)
      stapleDatas
        .reduce((acc, scData, stapleId) => {
          const position2 = scData.get(POSITION2_FLD);
          return stapleCursor === DEFAULT_POS10_CURSOR || position2 >= stapleCursor ?
            acc.push({[POSITION2_FLD]:position2, [STAPLE_FLD]:this.getStaple(stapleId)}) :
            acc;
        }, List())
        .sort((a, b) => { return a[POSITION2_FLD] > b[POSITION2_FLD] ? -1 : 1; })
        .map(zsData => zsData[STAPLE_FLD]) :
      List();
    return {staples, stapleCursor, areStaplesLoaded};
  }

  getCollectionStaples(collectionId) { // ToDo: повертати {staples, stapleCursor, areStaplesLoaded} !!!
    trace(`getCollectionStaples`);
    const {
      stapleCursor:cursor = DEFAULT_POS10_CURSOR,
      stapleDatas} = this.getState().getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || StapleFeed();
    return (cursor || cursor === DEFAULT_POS10_CURSOR) && stapleDatas ? // sic!: a)
      stapleDatas
        .reduce((acc, scData, stapleId) => {
          const position2 = scData.get(POSITION2_FLD);
          return cursor === DEFAULT_POS10_CURSOR || position2 >= cursor ?
            acc.push({[POSITION2_FLD]:position2, [STAPLE_FLD]:this.getStaple(stapleId)}) :
            acc;
        }, List())
        .sort((a, b) => { return a[POSITION2_FLD] > b[POSITION2_FLD] ? -1 : 1; })
        .map(zsData => zsData[STAPLE_FLD]) :
      List();
  }

  getCollectionStaplesWithDatas(collectionId) {
    trace(`getCollectionStaples`);
    const {
      stapleCursor:cursor = DEFAULT_POS10_CURSOR,
      stapleDatas} = this.getState().getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || StapleFeed();
    return (cursor || cursor === DEFAULT_POS10_CURSOR) && stapleDatas ? // sic!: a)
      stapleDatas
        .reduce((acc, scData, stapleId) => {
          const position2 = scData.get(POSITION2_FLD);
          const staple = (this.getStaple(stapleId) || new MyStaple()).merge({[POSITION2_FLD]: position2, [PREV_POSITION_FLD]: position2});
          return cursor === DEFAULT_POS10_CURSOR || position2 >= cursor ?
            acc.push({[POSITION2_FLD]:position2, [STAPLE_FLD]:staple}) :
            acc;
        }, List())
        .sort((a, b) => { return a[POSITION2_FLD] > b[POSITION2_FLD] ? -1 : 1; })
        .map(zsData => zsData[STAPLE_FLD]) :
      List();
  }

  getDiscoverStaplesFeed() {
    trace(`getDiscoverStaplesFeed`);
    const {
      stapleIds,
      stapleCursor = DEFAULT_TSN12_CURSOR,
      areStaplesLoaded} = this.getState().getIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX]) || {};
    const staples =
      this.getSomeStaples(stapleIds)
        .filter(staple => staple.id >= stapleCursor)
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
    return {staples, stapleCursor, areStaplesLoaded};
  }

  getFeaturedStaplesFeed() {
    trace(`getFeaturedStaplesFeed`);
    const {
      stapleIds,
      stapleCursor = DEFAULT_TSN12_CURSOR,
      areStaplesLoaded} = this.getState().getIn(['stapleFeeds', FEATURED_STAPLES_PREFIX]) || {};
    const staples =
      this.getSomeStaples(stapleIds)
        .filter(staple => staple.id >= stapleCursor)
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
    return {staples, stapleCursor, areStaplesLoaded};
  }

  getFollowingStaples() { // ToDo: повертати {staples, stapleCursor, areStaplesLoaded} !!!
    trace(`getFollowingStaples`);
    const stapleIds = this.getState().getIn(['stapleFeeds', FOLLOWING_STAPLES_PREFIX, 'stapleIds']);
    const cursor = this.getState().getIn(['stapleFeeds', FOLLOWING_STAPLES_PREFIX, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
    return this.getSomeStaples(stapleIds)
      .filter(staple => staple.id >= cursor)
      .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
  }

  getSelectedStaples() {
    trace(`getSelectedStaples`);
    return this.getState().get('selectedStapleIds').map(id => this.getStaple(id))
      .toList()
      .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
  }

  getSomeStaples(ids = []) { // ToDo: HIGHLOAD: отримувати по списку ідентифікаторів одним запитом (API: FETCH_..._CMD) !!!
    trace(`getSomeStaples`);
    return ids.reduce((acc, id) => {
      const staple = this.getStaple(id);
      if (!staple) {
        fetchStaple(id); // xtra-fetch: Staple
        return acc;
      }
      return acc.add(staple);
    }, OrderedSet()); // sic!: дефолтне сортування згідно порядку, як отримували з серверу
  }

  getSomeStaplesMap(ids = []) { // ToDo: HIGHLOAD: отримувати по списку ідентифікаторів одним запитом (API: FETCH_..._CMD) !!!
    trace(`getSomeStaplesMap`);
    return ids.reduce((acc, id) => {
      const staple = this.getStaple(id);
      if (!staple) {
        fetchStaple(id); // xtra-fetch: Staple
        return acc;
      }
      return acc.set(id, staple); // 'set' зберігає Record
    }, Map());
  }

  // getDiscoverStapleCursor() {
  //   trace(`getDiscoverStapleCursor`);
  //   return this.getState().getIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
  // }

  // getFeaturedStapleCursor() {
  //   trace(`getFeaturedStapleCursor`);
  //   return this.getState().getIn(['stapleFeeds', FEATURED_STAPLES_PREFIX, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
  // }

  getFollowingStapleCursor() {
    trace(`getFollowingStapleCursor`);
    return this.getState().getIn(['stapleFeeds', FOLLOWING_STAPLES_PREFIX, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getMyStapleCursor() {
    trace(`getMyStapleCursor`);
    const myId = this.getState().get('myId');
    return this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + myId, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getUserStapleCursor(userId) {
    trace(`getUserStapleCursor`);
    return this.getState().getIn(['stapleFeeds', USER_STAPLES_PREFIX + userId, 'stapleCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getCollectionStapleCursor(collectionId) {
    trace(`getCollectionStapleCursor`);
    return this.getState().getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId, 'stapleCursor']) || DEFAULT_POS10_CURSOR;
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    const {stapleIds:prevStapleIds} = nextState.getIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX]) || new StapleFeed();
    const stapleIds = staples.reduce((acc, staple) => acc.add(staple[ID_FLD]), prevStapleIds);
    return mergeStapleFeed(nextState, DISCOVER_STAPLES_PREFIX, {stapleCursor, areStaplesLoaded, stapleIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FEATURED_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    const {stapleIds:prevStapleIds} = nextState.getIn(['stapleFeeds', FEATURED_STAPLES_PREFIX]) || new StapleFeed();
    const stapleIds = staples.reduce((acc, staple) => acc.add(staple[ID_FLD]), prevStapleIds);
    return mergeStapleFeed(nextState, FEATURED_STAPLES_PREFIX, {stapleCursor, areStaplesLoaded, stapleIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FOLLOWING_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    const {stapleIds:prevStapleIds} = nextState.getIn(['stapleFeeds', FOLLOWING_STAPLES_PREFIX]) || new StapleFeed();
    const stapleIds = staples.reduce((acc, staple) => acc.add(staple[ID_FLD]), prevStapleIds);
    return mergeStapleFeed(nextState, FOLLOWING_STAPLES_PREFIX, {stapleCursor, areStaplesLoaded, stapleIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [SEARCHED_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {staples, stapleCursor, areStaplesLoaded} = payload;
  //   if (!staples) {
  //     return state;
  //   }
  //   const nextState = staples.reduce(mergeStaple, state);
  //   return mergeStapleFeed(nextState, SEARCHED_STAPLES_PREFIX, {stapleCursor, areStaplesLoaded});
  // }

  // 1) додаємо стейпл
  // 2) додаємо екстра-дані кожного зі стейплів до колекції + відмічаємо курсор та areStaplesLoaded
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CSUBSCRIPTION_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {collectionId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    // ...1)
    const nextState = staples.reduce(mergeStaple, state);
    // ...2)
    const {stapleDatas:prevStapleDatas} = nextState.getIn(['stapleFeeds', CSUBSCRIPTION_STAPLES_PREFIX + collectionId]) || new StapleFeed();
    const stapleDatas = staples.reduce((acc, staple) => {
      const {[ID_FLD]:stapleId, [POSITION2_FLD]:position2} = staple;
      return acc.merge({[stapleId]: {[POSITION2_FLD]: position2}});
    }, prevStapleDatas);
    return mergeStapleFeed(nextState, CSUBSCRIPTION_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas});
  }

  // 1) додаємо стейпл
  // 2) додаємо екстра-дані кожного зі стейплів до колекції + відмічаємо курсор та areStaplesLoaded
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {collectionId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    // ...1)
    const nextState = staples.reduce(mergeStaple, state);
    // ...2)
    const {stapleDatas:prevStapleDatas} = nextState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
    const stapleDatas = staples.reduce((acc, staple) => {
      const {[ID_FLD]:stapleId, [POSITION2_FLD]:position2} = staple;
      return acc.merge({[stapleId]: {[POSITION2_FLD]: position2}});
    }, prevStapleDatas);
    return mergeStapleFeed(nextState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas});
  }

  // 1) додаємо стейпл
  // 2) додаємо екстра-дані кожного зі стейплів до колекції + відмічаємо курсор та areStaplesLoaded
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_STAPLES_WERE_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {collectionId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    // ...1)
    const nextState = staples.reduce(mergeStaple, state);
    // ...2)
    const {stapleDatas:prevStapleDatas} = nextState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
    const stapleDatas = staples.reduce((acc, staple) => {
      const {[ID_FLD]:stapleId, [POSITION2_FLD]:position2} = staple;
      return acc.merge({[stapleId]: {[POSITION2_FLD]: position2}});
    }, prevStapleDatas);
    return mergeStapleFeed(nextState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {myId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    return mergeStapleFeed(nextState, USER_STAPLES_PREFIX + myId, {stapleCursor, areStaplesLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    return mergeStapleFeed(nextState, USER_STAPLES_PREFIX + userId, {stapleCursor, areStaplesLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_STAPLES_WERE_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {userId, staples, stapleCursor, areStaplesLoaded} = payload;
    if (!staples) {
      return state;
    }
    const nextState = staples.reduce(mergeStaple, state);
    return mergeStapleFeed(nextState, USER_STAPLES_PREFIX + userId, {stapleCursor, areStaplesLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {staple} = payload;
    return staple ?
      mergeStaple(state, staple) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {staple} = payload;
    return staple ?
      mergeStaple(state, staple) :
      state;
  }

  // 1) додаємо стейпл
  // 2) оновлюємо курсор для користувача (див. Attn: 2)
  // 3) додаємо екстра-дані нового стейплу до усіх колекцій в які він включений
  //
  // Attn: відразу після реєстрації, щоб показати новостворений стейпл, потрібно ініціалізувати
  //       курсор stapleCursor для поточного користувача; інакше новостворений стейпл
  //       не попадає для показу в фіді, бо його id < DEFAULT_TSN12_CURSOR;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {staple, stapleDatas} = payload;
    if (!staple) {
      return state;
    }
    // ...1)
    const nextState = mergeStaple(state, staple);
    // ...2)
    const {[ID_FLD]:stapleId, [OWNER_ID_FLD]:ownerId} = staple;
    const nextState2 = this.getUserStapleCursor(ownerId) === DEFAULT_TSN12_CURSOR ?
      mergeStapleFeed(nextState, USER_STAPLES_PREFIX + ownerId, {stapleCursor: stapleId, areStaplesLoaded: false}) :
      nextState;
    // ...3)
    return stapleDatas.reduce((accState, stapleData) => {
      const {[STAPLE_ID_FLD]:stapleId, [COLLECTION_ID_FLD]:collectionId, [POSITION2_FLD]:position2} = stapleData;
      const {stapleCursor, areStaplesLoaded, stapleDatas:prevStapleDatas} = accState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
      const nextStapleDatas = prevStapleDatas.merge({[stapleId]: {[POSITION2_FLD]: position2}});
      return mergeStapleFeed(accState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas:nextStapleDatas});
    }, nextState2);
  }

  // 1) додаємо клонований стейпл
  // 2) додаємо екстра-дані клонованого стейплу до усіх колекцій в які він включений
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CLONED_ACTION] = (state, {payload}) => {
    const {staple, stapleDatas} = payload;
    if (!staple) {
      return state;
    }
    // ...1)
    const nextState = mergeStaple(state, staple);
    // ...2)
    return stapleDatas.reduce((accState, stapleData) => {
      const {[STAPLE_ID_FLD]:stapleId, [COLLECTION_ID_FLD]:collectionId, [POSITION2_FLD]:position2} = stapleData;
      const {stapleCursor, areStaplesLoaded, stapleDatas:prevStapleDatas} = accState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
      const nextStapleDatas = prevStapleDatas.merge({[stapleId]: {[POSITION2_FLD]: position2}});
      return mergeStapleFeed(accState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas:nextStapleDatas});
    }, nextState);
  }

  // 1) видаляємо екстра-дані стейплу із колекцій з яких він вилучений
  // 2) оновлюємо стейпл
  // 3) додаємо НОВІ екстра-дані стейплу до усіх колекцій в які він включений
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {staple, stapleDatas, removedCollectionIds} = payload;
    const {[ID_FLD]:stapleId} = staple || {};
    if (!stapleId) {
      return state;
    }
    // ...1)
    const nextState1 = removedCollectionIds.reduce((accState, collectionId) => {
      const prevFeed = accState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
      const nextFeed = prevFeed.setIn(['stapleDatas'], prevFeed.getIn(['stapleDatas']).delete(stapleId));
      return accState.setIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId], nextFeed);
    }, state);
    // ...2)
    const nextState2 = mergeStaple(nextState1, staple);
    // ...3)
    return stapleDatas.reduce((accState, stapleData) => {
      const {[STAPLE_ID_FLD]:stapleId, [COLLECTION_ID_FLD]:collectionId, [POSITION2_FLD]:position2} = stapleData;
      const {stapleCursor, areStaplesLoaded, stapleDatas:prevStapleDatas} = accState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
      const nextStapleDatas = prevStapleDatas.merge({[stapleId]: {[POSITION2_FLD]: position2}});
      return mergeStapleFeed(accState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas:nextStapleDatas});
    }, nextState2);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION] = (state, {payload}) => {
    const {[STAPLEDATAS_FLD]:stapleDatas} = payload;
    return stapleDatas.reduce((accState, stapleData) => {
      const {[STAPLE_ID_FLD]:stapleId, [COLLECTION_ID_FLD]:collectionId, [POSITION2_FLD]:position2} = stapleData;
      const {stapleCursor, areStaplesLoaded, stapleDatas:prevStapleDatas} = accState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
      const nextStapleDatas = prevStapleDatas.merge({[stapleId]: {[POSITION2_FLD]: position2}});
      return mergeStapleFeed(accState, COLL_STAPLES_PREFIX + collectionId, {stapleCursor, areStaplesLoaded, stapleDatas:nextStapleDatas});
    }, state);
  }

  // Attn: 1) оновлюємо дату створення щоб відобразити превьюшку !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_UPDATED_NTF_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {[ID_FLD]:stapleId} = payload;
    return mergeStaple(state, {[ID_FLD]:stapleId, [CREATED_AT_FLD]: new Date().toISOString()}); // 1)
  }

  // ToDo: розіслати оновлений стейпл усім учасникам чату, де він є контекстом !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [CHAT_CONTEXT_WAS_UPDATED_ACTION] = (state, {payload}) => {
  //   const {staple} = payload;
  //   return state;
  // }

  // 1) видаляємо ідентифікатор із фіду DISCOVER
  // 2) видаляємо стейпл
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_BANNED_ACTION] = (state, {payload}) => {
    const {stapleId} = payload;
    if (!stapleId) {
      return state;
    }
    // ...1)
    const prevFeed = state.getIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX]) || new StapleFeed();
    const nextFeed = prevFeed.setIn(['stapleIds'], prevFeed.getIn(['stapleIds']).delete(stapleId));
    const nextState = state.setIn(['stapleFeeds', DISCOVER_STAPLES_PREFIX], nextFeed);
    // ...2)
    return nextState.deleteIn(['staples', stapleId]);
  }

  // 1) видаляємо ідентифікатори видалених стейплів із списку відмічених
  // 2) видаляємо екстра-дані видалених стейплів із усіх колекцій куди вони були включені
  // 3) видаляємо стейпли
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_WERE_DELETED_ACTION] = (state, {payload}) => {
    const {deletedIds, affectedCollectionIds} = payload;
    return deletedIds && deletedIds.length > 0 ?
      deletedIds.reduce((accState, stapleId) => {
        // ...1)
        const nextState1 = accState.set('selectedStapleIds', accState.get('selectedStapleIds').delete(stapleId));
        // ...2)
        const nextState2 = affectedCollectionIds.reduce((accLocalState, collectionId) => {
          const prevFeed = accLocalState.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]) || new StapleFeed();
          const nextFeed = prevFeed.setIn(['stapleDatas'], prevFeed.getIn(['stapleDatas']).delete(stapleId));
          return accLocalState.setIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId], nextFeed);
        }, nextState1);
        // ...3)
        return nextState2.deleteIn(['staples', stapleId]);
      }, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_SELECTED_ACTION] = (state, {payload}) => {
    const {stapleId} = payload;
    return stapleId ?
      state.set('selectedStapleIds', state.get('selectedStapleIds').add(stapleId))
        .setIn(['staples', stapleId, 'isSelected'], true) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_UNSELECTED_ACTION] = (state, {payload}) => {
    const {stapleId} = payload;
    return stapleId ?
      state.set('selectedStapleIds', state.get('selectedStapleIds').delete(stapleId))
        .setIn(['staples', stapleId, 'isSelected'], false) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_WERE_UNSELECTED_ACTION] = (state, {payload}) => {
    return state.get('selectedStapleIds').reduce((accState, stapleId) => {
      return accState
        .set('selectedStapleIds', accState.get('selectedStapleIds').delete(stapleId))
        .setIn(['staples', stapleId, 'isSelected'], false);
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    if (!collectionId) return state;
    return state.get('staples').reduce((accState, staple) => {
      const {id:stapleId, lstCollections} = staple;
      if (lstCollections) {
        const pos = lstCollections.findIndex(coll => coll[ID_FLD] === collectionId);
        if (pos >= 0) {
          return accState
            .set('selectedStapleIds', accState.get('selectedStapleIds').add(stapleId))
            .setIn(['staples', stapleId, 'isSelected'], true);
        }
      }
      return accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_OF_COLLECTION_WERE_UNSELECTED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    if (!collectionId) return state;
    return state.get('staples').reduce((accState, staple) => {
      const {id:stapleId, lstCollections} = staple;
      if (lstCollections) {
        const pos = lstCollections.findIndex(coll => coll[ID_FLD] === collectionId);
        if (pos >= 0) {
          return accState
            .set('selectedStapleIds', accState.get('selectedStapleIds').delete(stapleId))
            .setIn(['staples', stapleId, 'isSelected'], false);
        }
      }
      return accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_CONTEXTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples} = payload;
    return staples ?
      staples.reduce(mergeStaple, state) :
      state
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_CONTEXTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples} = payload;
    return staples ?
      staples.reduce(mergeStaple, state) :
      state
  }

  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [MY_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {staples} = payload;
  //   return staples ?
  //     staples.reduce(mergeStaple, state) :
  //     state
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {stapleId, areStapleChatsLoaded} = payload;
    return areStapleChatsLoaded ?
      mergeStaple(state, {[ID_FLD]: stapleId, areChatsLoaded: true}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SCTX_CHATS_WERE_CREATED_ACTION] = (state, {payload}) => {
    const {stapleIds} = payload;
    return stapleIds.reduce((accState, stapleId) => {
      return accState
        .set('selectedStapleIds', accState.get('selectedStapleIds').delete(stapleId))
        .setIn(['staples', stapleId, 'isSelected'], false);
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {ctxId, ctxType} = payload;
    const myId = this.getState().get('myId');
    if (myId && ctxId && ctxType === STAPLE_CTX) {
      const {id:stapleId, ownerId} = this.getState().getIn(['staples', ctxId]);
      if (stapleId && ownerId && ownerId !== myId) {
        return state.deleteIn(['staples', stapleId]);
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [UNREAD_MESSAGES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples} = payload;
    return staples ?
      staples.reduce(mergeStaple, state) :
      state
  }

  // 1) при зміні 'name' колекції --> змінюються назви колекцій в lstCollections
  // 2) при зміні IS_PUBLIC_FLD колекції --> змінюються приватність в lstCollections та значення HAS_PRIVATE_COLLECTIONS_FLD, HAS_PUBLIC_COLLECTIONS_FLD
  // 3) назви полів переводимо із формату {{ COLLECTION }} --> {{ local }}
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {prevCollection, collection} = payload;
    if (!collection) {
      return state;
    }
    const {name:prevName = '', [IS_PUBLIC_FLD]: prevIsPublic} = prevCollection;
    const {[ID_FLD]:collectionId, [NAME_FLD]:name, [IS_PUBLIC_FLD]:isPublic} = collection;
    const isNameChanged = prevName !== name;
    const isPrivacyChanged = prevIsPublic !== isPublic;
    // ...1),2)
    return state.get('staples').reduce((accState, staple) => {
      const {id:stapleId, lstCollections} = staple;
      if (lstCollections) {
        const pos = lstCollections.findIndex(coll => coll[ID_FLD] === collectionId);
        if (pos >= 0) {
          lstCollections.splice(pos, 1, Object.assign(lstCollections[pos], {[NAME_FLD]:name, [IS_PUBLIC_FLD]:isPublic}));
          const nextState = accState
            .setIn(['staples', stapleId, COLLECTIONS_FLD], JSON.stringify(
              isNameChanged ?
                lstCollections.sort((a, b) => { return ((a[TYPE_FLD] + a[NAME_FLD]) < (b[TYPE_FLD] + b[NAME_FLD])) ? -1 : 1; }) : // attn: sorting collections by type + name !!!
                lstCollections
            ));
          return isPrivacyChanged ?
            nextState
              .setIn(['staples', stapleId, HAS_PRIVATE_COLLECTIONS_FLD], lstCollections.findIndex(coll => coll.p === false) >= 0)
              .setIn(['staples', stapleId, HAS_PUBLIC_COLLECTIONS_FLD], lstCollections.findIndex(coll => coll.p === true) >= 0) :
            nextState;
        }
      }
      return accState;
    }, state);
  }

  // 1) видаляємо інфу про колекцію з поля COLLECTIONS_FLD для усіх стейплів з видаленої колекції
  // 2) видаляємо екстра-дані стейплів для видаленої колекції
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    if (!collectionId) {
      return state;
    }
    // ...1)
    const collStapleDatas = state.getIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId, 'stapleDatas']);
    const affectedStapleIds = collStapleDatas ? collStapleDatas.keySeq() : [];
    const nextState = affectedStapleIds.reduce((accState, stapleId) => {
      const lstCollections = JSON.parse(accState.getIn(['staples', stapleId, COLLECTIONS_FLD]) || '[]');
      const pos = lstCollections.findIndex(coll => coll.i === collectionId);
      if (pos >= 0) {
        lstCollections.splice(pos, 1);
      }
      return accState
        .setIn(['staples', stapleId, COLLECTIONS_FLD], JSON.stringify(lstCollections || []))
        .setIn(['staples', stapleId, HAS_PRIVATE_COLLECTIONS_FLD], lstCollections.findIndex(coll => coll.p === false) >= 0)
        .setIn(['staples', stapleId, HAS_PUBLIC_COLLECTIONS_FLD], lstCollections.findIndex(coll => coll.p === true) >= 0);
    }, state);
    // ...2)
    return nextState.deleteIn(['stapleFeeds', COLL_STAPLES_PREFIX + collectionId]);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_FOLLOWED_ACTION] = (state, {payload}) => {
    return resetStapleFeed(state, FOLLOWING_STAPLES_PREFIX);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_UNFOLLOWED_ACTION] = (state, {payload}) => {
    return resetStapleFeed(state, FOLLOWING_STAPLES_PREFIX);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      state.mergeIn(['myId'], account[ID_FLD]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS()); // ToDo: DEV/TEST !!!
      return nextState;
    }
    return state;
  }
}

export default new StapleStore(Dispatcher);
