// -------------------------------------------------------------------------------------------------
//  UrlSelectField.js
//  - - - - - - - - - -
//  Поле для введення/відображення списку веб-посилань.
//  Обгортка для компоненти react-select.
//
//  Формат списку елементів {{ UR-SELECT2 }}:
//  [
//    {
//      id: '1637068005',                   // id
//      value: '1https://site',             // (type + label)[MAX_URL_WIDTH]
//      label: 'https://site.com',          // label = форматований текст веб-посилання
//      type: '1',                          // type = тип веб-посилання
//      position: '0',                      // position = порядок сортування
//      __isNew__}                          // is an option just created by user?
//    ...
//  ]
//
//  Формат зберігання списку в БД {{ UR-TAGS }}:
//  {
//    '1637068005' {                        // id
//        l: 'https://site.com',            // label = форматований текст веб-посилання
//        t: '1',                           // type = тип веб-посилання
//        z: '0'                            // position = порядок сортування
//    },
//    ...
//  }
//
//  {{ UR-LIST-STR }}:
//  "[
//    {
//      i: "1637068005"                     // id
//      l: "https://site.com",              // label = текст веб-посилання
//      t: "1",                             // type = тип веб-посилання
//      z: "0"                              // position = порядок сортування
//    },
//    ...
//  ]"
//
//  Attn:
//  - - - - -
//  - значення ідентифікатора містить поле 'id' (напр: '1637068005');
//  - ідентифікатор = (+(new Date()) + '').substr(0,10);
//  - поле 'value' = type + (label).substr(24);
//  - поки що на бекенді подробиці лише зберігаються в полі all_urls і ніяких інших дій немає;
//  - classNamePrefix required to prevent library warning;
//
//  Replaceable components:
//  - - - - - - - - - - - -
//  - Control
//  - IndicatorsContainer
//  - IndicatorSeparator
//  - DropdownIndicator
//  - LoadingIndicator
//  - ClearIndicator
//  - Group
//  - GroupHeading
//  - Input (*)
//  - Menu
//  - MenuList
//  - LoadingMessage
//  - NoOptionsMessage
//  - MultiValue
//  - MultiValueContainer
//  - MultiValueLabel
//  - MultiValueRemove
//  - Option
//  - Placeholder
//  - SelectContainer
//  - SingleValue
//  - ValueContainer
//
//  Docs:
//  - - - - -
//  https://react-select.com/props#creatable-props
//  https://github.com/JedWatson/react-select
// -------------------------------------------------------------------------------------------------
// FixMe: не викликається onChange для нових записів !!!
// ToDo: передивитись чи потрібні ідентифікатори з timestamp (може просто q-ty + 1) ???

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import Select from 'react-select';
import {ID_FLD, LABEL_FLD, TYPE_FLD, POSITION_FLD} from 'core/apiFields';
import UrlField, {decodeUrlType} from 'components/UI/fields/UrlField';
import Icon from 'components/UI/icons/Icon';
import styles from './UrlSelectField.scss';

export const MAX_URL_WIDTH                = 96;     // к-сть символів в url

// =================================================================================================
//  (FMT) Urls Format Converters
// =================================================================================================

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts urls from UR-SELECT2 into UR-TAGS format.
//
//  {{ UR-SELECT2 }}:
//  [
//    {
//      id: '1637068005',                   // id
//      value: '1https://site',             // (type + label)[MAX_URL_WIDTH]
//      label: 'https://site.com',          // label = форматований текст веб-посилання
//      type: '1',                          // type = тип веб-посилання
//      position: '0',                      // position = порядок сортування
//      __isNew__}                          // is an option just created by user?
//    ...
//  ]
//
//  {{ UR-TAGS }}:
//  {
//    '1637068005' {                        // id
//        l: 'https://site.com',            // label = форматований текст веб-посилання
//        t: '1',                           // type = тип веб-посилання
//        z: '0'                            // position = порядок сортування
//    },
//    ...
//  }
//
export function toUrTagsPlus(urSelect2) {
  if (urSelect2 && urSelect2.length > 0) {
    return urSelect2.reduce((acc, current) => {
      const {id, value, label, type, position, __isNew__} = current;
      return Object.assign(acc, {
        [id]: { // sic!: без '+'; просто timestamp !!!
          [LABEL_FLD]: label,
          [TYPE_FLD]: type,
          [POSITION_FLD]: position}});
    }, {});
  } else {
    return {};
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts urls from UR-TAGS-PLUS into UR-LIST-STR format.
//
//  Attn:
//  - - - - -
//  - для phones потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - UR-LIST-STR це стрінгіфікований UR-LIST-PLUS
//
//  {{ UR-TAGS }}:
//  {
//    '1637068005' {                        // id
//        l: 'https://site.com',            // label = форматований текст веб-посилання
//        t: '1',                           // type = тип веб-посилання
//        z: '0'                            // position = порядок сортування
//    },
//    ...
//  }
//
//  {{ UR-LIST-STR }}:
//  "[
//    {
//      i: "1637068005"                     // id
//      l: "https://site.com",              // label = текст веб-посилання
//      t: "1",                             // type = тип веб-посилання
//      z: "0"                              // position = порядок сортування
//    },
//    ...
//  ]"
//
export function toUrListStr(urTagsPlus) {
  if (urTagsPlus && Object.keys(urTagsPlus).length > 0) {
    let urLstPlus = [];
    for (let i in urTagsPlus) {
      const {
        [LABEL_FLD]:label,
        [TYPE_FLD]:type,
        [POSITION_FLD]:position} = urTagsPlus[i];
      if (label) {
        urLstPlus = urLstPlus.concat([{
          [ID_FLD]: i,
          [LABEL_FLD]: label,
          [TYPE_FLD]: type,
          [POSITION_FLD]: position,
        }]);
      }
    }
    return JSON.stringify(
      urLstPlus.sort((a, b) => { return (a.t + a.l) < (b.t + b.l) ? -1 : 1; })
    );
  }
  return '[]';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts urls from UR-LIST-PLUS into UR-SELECT2 format.
//
//  {{ UR-LIST-STR }}:
//  "[
//    {
//      i: "1637068005"                     // id
//      l: "https://site.com",              // label = текст веб-посилання
//      t: "1",                             // type = тип веб-посилання
//      z: "0"                              // position = порядок сортування
//    },
//    ...
//  ]"
//
//  {{ UR-SELECT2 }}:
//  [
//    {
//      id: '1637068005',                   // id
//      value: '1https://site',             // (type + label)[24]
//      label: 'https://site.com',          // label = форматований текст веб-посилання
//      type: '1',                          // type = тип веб-посилання
//      position: '0',                      // position = порядок сортування
//      __isNew__}                          // is an option just created by user?
//    ...
//  ]
//
export function toUrSelect2(urLstPlus) {
  if (urLstPlus && urLstPlus.length > 0) {
    return urLstPlus.reduce((acc, current) => {
      const {
        [ID_FLD]:id,
        [LABEL_FLD]:label,
        [TYPE_FLD]:type,
        [POSITION_FLD]:position} = current;
      return acc.concat([{
        id: id,
        value: type + ('' + label).substr(0, MAX_URL_WIDTH),
        label: label,
        type: type,
        position: position
      }]);
    }, []);
  } else {
    return [];
  }
}

// (*) attn: в css-стилях відключив input: {display: none} !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customControl = (props) => {
  const {
    innerProps,
    isFocused,
    children,
    // hasValue,
    // isDisabled,
    // isMulti,
    // isRtl,
  } = props;
  const {...otherInnerProps} = innerProps || {};
  return (
    <div className={classnames(styles.control, {
      [styles.isFocused]: isFocused,
    })} {...otherInnerProps}>
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customIndicatorSeparator = ({innerProps}) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customClearIndicator = ({innerProps}) => {
  return (
    <div className={styles.clearIndicator} {...innerProps}>
      <Icon symbolName="cross" className={styles.icon} />
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customDropdownIndicator = (props) => {
  return null; // нам не потрібен dropdown зі списком телефонів
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenu = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenuList = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customOption = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMultiValue = (props) => {
  const {
    data:url,
    isFocused,
    isDisabled,
    removeProps,
    // children,
    // components,
    // hasValue,
    // isMulti,
    // isRtl,
  } = props;
  const {id, value, label, type} = url;
  return (
    <div className={classnames(styles.multiValue, {
      [styles.isFocused]: isFocused,
      [styles.isDisabled]: isDisabled,
    })}>
      <div className={styles.typeText}>{decodeUrlType(type) + ':'}</div>
      <a href={label} rel="nofollow noopener noreferrer" target="_blank">{label}</a>
      <div className={styles.removeButton}
           {...removeProps}>
        <Icon symbolName="cross" className={styles.icon} />
      </div>
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class UrlSelectField extends React.Component {
  static propTypes = {
    name: Types.string,                   // name of the HTML Input (optional - without this, no input will be rendered)
    value: Types.oneOfType([Types.object, Types.array]), // the current value; reflected by the selected option
    delimiter: Types.string,              // delimiter used to join multiple values into a single HTML Input value
    placeholder: Types.string,            // placeholder text for the select value
    label: Types.string,                  // label text (shown above field)
    error: Types.string,                  // error message
    choices: Types.array,                 // список можливих варіантів подробиць
    isAutoFocused: Types.bool,            // focus the control when it is mounted
    isBackspaceRemovable: Types.bool,     // can backspace remove current value?
    isClearable: Types.bool,              // is the select value clearable (by BACKSPACE)?
    isDisabled: Types.bool,               // is the select disabled?
    isInsertable: Types.bool,             // can insert new values?
    isRequired: Types.bool,               // is field required?
    isSearchable: Types.bool,             // is search functionality enabled?
    isMenuCloseOnSelect: Types.bool,      // close the select menu when the user selects an option
    isMenuOpenOnFocus: Types.bool,        // alows control of whether the menu is opened when the Select is focused
    isMenuOpenOnClick: Types.bool,        // allows control of whether the menu is opened when the Select is clicked
    isMulti: Types.bool,                  // is multiple selection enabled?
    isRtl: Types.bool,                    // is the select direction right-to-left?
    onBlur: Types.func,                   // callback on blur events
    onFocus: Types.func,                  // callback on focus events
    onChange: Types.func,                 // callback on change events
    onInputChange: Types.func,            // callback on change events on the input
    onKeyDown: Types.func,                // callback on key down events
    onMenuOpen: Types.func,               // callback on menu opening events
    onMenuClose: Types.func,              // callback on menu closing events
    onMenuScrollToTop: Types.func,        // fired when the user scrolls to the top of the menu
    onMenuScrollToBottom: Types.func,     // fired when the user scrolls to the bottom of the menu
    onNoOptions: Types.func,              // text to display when there are no options
  }

  static defaultProps = {
    delimiter: '·',
    placeholder: '',
    label: '',
    error: '',
    isAutoFocused: false,
    isBackspaceRemovable: false,
    isClearable: false,
    isDisabled: false,
    isInsertable: false,
    isRequired: false,
    isSearchable: true,
    isMenuCloseOnSelect: true,
    isMenuOpenOnFocus: true,
    isMenuOpenOnClick: true,
    isMulti: false,
    isRtl: false,
    onBlur: undefined,
    onFocus: undefined,
    onInputChange: undefined,
    onKeyDown: undefined,
    onMenuOpen: undefined,
    onMenuClose: undefined,
    onMenuScrollToTop: undefined,
    onMenuScrollToBottom: undefined,
    onNoOptions: () => t`Enter urls below to add new one...`,
  }

  // state = {
  // }

  // - - - - - - - - - - - - - - - - - - -
  //  Кастомна фільтрація елементів списку.
  //  Пошук дублів проводиться по полям label & value.
  //
  //  [searchCmd] + searchStr = pattern
  //
  //  Attn:
  //  - - - - -
  //  - дефолтна фільтрація шукає значення в label & value.
  // - - - - - - - - - - - - - - - - - - -
  handleFilter = (item, pattern) => {
    // show all items for empty search string
    if (!item || !item.label || !pattern) {
      return true;
    }
    const {label = '', value = '', data} = item;
    if (data) {
      const {__isNew__} = data;
      // show all new & new candidates items
      if (__isNew__) {
        return true;
      }
    }
    // show items containing search string in label only
    return (
      label.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
      || value.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
    );
  }

  handleChange = (items) => {
    const {onChange} = this.props;
    onChange && onChange(items);
  }

  handleAddNumber = ({urType, urLabel}) => {
    const {value:items} = this.props;
    if (urType && urLabel) {
      // 1) пошук на дублювання в списку вже створених елементів;
      let isDoubled = false;
      items.map(elem => {
        if (elem.type === urType && elem.label === urLabel) {
          isDoubled = true;
        }
      });
      // 2) додаємо новий елемент {{ UR-SELECT2 }}
      if (!isDoubled && urType.length > 0 && urLabel.length > 0) {
        items.push(Object.assign({
          id: (+(new Date()) + '').substr(0, 10),
          value: urType + ('' + urLabel).substr(0, MAX_URL_WIDTH),
          label: urLabel,
          type: urType,
          position: items.length + 1,
          __isNew__: true,
        }));
        items.sort((a, b) => { return (a.type + a.label) < (b.type + b.label) ? -1 : 1; });
      }
    }
    // 3) перемальовка компоненти
    this.setState({
      error: ''
    });
  }

  render() {
    const {
      name,
      value,
      delimiter,
      placeholder,
      label,
      error,
      choices,
      isAutoFocused,
      isBackspaceRemovable,
      isClearable,
      isDisabled,
      isInsertable,
      isRequired,
      isSearchable,
      isMenuCloseOnSelect,
      isMenuOpenOnFocus,
      isMenuOpenOnClick,
      isMulti,
      isRtl,
      onBlur,
      onFocus,
      onInputChange,
      onKeyDown,
      onMenuOpen,
      onMenuClose,
      onMenuScrollToTop,
      onMenuScrollToBottom,
      onNoOptions} = this.props;
    return (
      <div className={classnames(styles.UrlSelectField, {[styles.isError]: error})}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <Select
          className={styles.select}
          classNamePrefix="urs2"
          name={name}
          value={value}
          options={[]}
          delimiter={delimiter}
          placeholder={placeholder}
          components={{
            Control: customControl,
            IndicatorSeparator: customIndicatorSeparator,
            ClearIndicator: customClearIndicator,
            DropdownIndicator: customDropdownIndicator,
            Menu: customMenu,
            MenuList: customMenuList,
            Option: customOption,
            MultiValue: customMultiValue,
          }}
          autoFocus={isAutoFocused}
          backspaceRemovesValue={isBackspaceRemovable}
          closeMenuOnSelect={isMenuCloseOnSelect}
          openMenuOnFocus={isMenuOpenOnFocus}
          openMenuOnClick={isMenuOpenOnClick}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isRtl={isRtl}
          filterOption={this.handleFilter}
          noOptionsMessage={onNoOptions}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={this.handleChange}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onMenuScrollToTop={onMenuScrollToTop}
          onMenuScrollToBottom={onMenuScrollToBottom}
        />
        <UrlField
          error={''}
          placeholder={isDisabled ? '' : t`Enter urls here...`}
          choices={choices}
          isRequired={false}
          isDisabled={false}
          onAddButton={this.handleAddNumber}
        />
        <div className={styles.addButton}></div>
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
