// -------------------------------------------------------------------------------------------------
//  MyFSRUsersContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки запитів на дружбу до/від користувача.
//
//  Attn:
//  - - - - -
//  - запит контактів fetchMyContacts() обовʼязково робити ДО запиту юзерів fetchMyFriends() !!!
//    Причина: Якщо першим виконується запит юзерів, то в процесі обробки інформація про
//    привʼязаних юзерів заноситься тільки в ІСНУЮЧІ контакти - нові контакти НЕ створюються,
//    немає куди записати і інформація про контакт втрачається (friendshipStatus, ...).
//    Тоді ж як при обробці списку контактів в сторі UserStore створюються юзери для кожного
//    отриманого контакту і заповнюються поля про контакт (contactId, contactName, ...).
// -------------------------------------------------------------------------------------------------
// ToDo: перевіряти чи пройшло завантаження з серверу (щоб не показувати "коробку з мухою") !!!

import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {GROUP_TAB, SELECTION_TAB} from 'core/uiTypes';
import {setAllContactsUnselected} from 'actions/ContactActions';
import {setAllStaplesUnselected} from 'actions/StapleActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyFSRUsers = Loader.create(() => import('./MyFSRUsers'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyFSRUsersContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyFSRUsersContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const user = myId ? UserStore.getUser(myId) : null;
    const fsrUsers = UserStore.getFSRequestUsers();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const unreadCountsByChat = ChatStore.getUnreadCountsByChat();
    const areFriendsLoaded = UserStore.areMyFriendsLoaded();
    const hasAnySelected = myId && user && user.id === myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, user, fsrUsers, fsrCount, groupsQty, contactsQty,
      unreadCountsByChat, areFriendsLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.areFriendsLoaded !== nextState.areFriendsLoaded
      || this.state.unreadCountsByChat.size !== nextState.unreadCountsByChat.size
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.fsrUsers, nextState.fsrUsers)
      || !is(this.state.unreadCountsByChat, nextState.unreadCountsByChat)
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    this.setSidebar();
  }

  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    this.setSidebar();
  }

  // CTX:
  setSidebar = async () => {
    trace(`setSidebar`);
    const {isLoggedIn, myId, user, hasAnySelected} = this.state;
    if (isLoggedIn && user && user.id === myId) {
      await substituteSidebarTabMode(!hasAnySelected ? GROUP_TAB : SELECTION_TAB);
      await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      await substituteSidebarChat({chatId: ''});
    }
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  render() {
    trace(`render`);
    const {
      isLoggedIn,
      myId,
      user,
      fsrUsers,
      fsrCount,
      groupsQty,
      contactsQty,
      unreadCountsByChat,
      areFriendsLoaded,
      hasAnySelected} = this.state;
    return (
      <MyFSRUsers
        isLoggedIn={isLoggedIn}
        myId={myId}
        user={user}
        fsrUsers={fsrUsers}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        unreadCountsByChat={unreadCountsByChat}
        areFriendsLoaded={areFriendsLoaded}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
      />
    );
  }
}

export default Container.create(MyFSRUsersContainer, {pure: false});
