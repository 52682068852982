// -------------------------------------------------------------------------------------------------
//  RootSidebar.js
//  - - - - - - - - - -
//  Inspired by React Sidebar 2.3
//  https://github.com/balloob/react-sidebar
//
//  Для компоненту існують два набори props: "initialProps" та "extraProps".
//  Параметри "extraProps" задаються через компоненту <PageSidebar .../> та мають приорітет
//  над початковими параметрами. При заданні props через <PageSidebar .../> нові параметри додаємо
//  до списку propsMap, а при видаленні відновлюємо попередній набір параметрів.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {withRouter} from 'react-router'; // ToDo: CHECK !!!
import {OrderedMap, is} from 'immutable';
import PubSub from 'utils/PubSub';
import {scrollWrapperToTop} from 'application/LayerSwitch';
import FirstSidebar from 'components/Sidebars/FirstSidebar';
import TextingPanel from 'components/UI/panels/TextingPanel';
import ConnectionStatus from 'components/UI/ConnectionStatus';
import GlobalNavigation from 'components/UI/GlobalNavigation';
import styles from './RootSidebar.scss';

const CANCEL_DISTANCE_ON_SCROLL = 20;

const isVerbose = DEBUG && true;
const prefix = '- - - RootSidebar';

const propsMap = new OrderedMap().asMutable();
const pubsub = new PubSub();

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function setProps(key, nextProps) {
  propsMap.set(key, nextProps);
  pubsub.publish(propsMap.last());
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function removeProps(key) {
  propsMap.remove(key);
  pubsub.publish(propsMap.last() || {});
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class RootSidebar extends React.Component {
  static setProps = setProps;
  static removeProps = removeProps;

  static propTypes = {
    children: Types.node.isRequired,      // main content to render
    isLoggedIn: Types.bool,               // is current user logged in?
    isOpen: Types.bool,                   // true if sidebar should be opened
    isDocked: Types.bool,                 // true if sidebar should be docked
    isMobile: Types.bool,                 // is an app running in mobile layout?
    isPulledRight: Types.bool,            // place the sidebar on the right
    enableTransitions: Types.bool,        // true if transitions should be enabled
    enableTouch: Types.bool,              // true if touch gestures are enabled
    touchHandleWidth: Types.number,       // max distance from the edge we can start touching
    dragToggleDistance: Types.number,     // distance we have to drag the sidebar to toggle open state
    onToggleSidebar: Types.func,          // callback called when the overlay is clicked
  }

  static defaultProps = {
    isOpen: false,
    isDocked: false,
    isPulledRight: false,
    enableTransitions: true,
    enableTouch: true,
    touchHandleWidth: 20,
    dragToggleDistance: 30,
    onToggleSidebar: () => {},
  }

  state = {
    sidebarWidth: 0,                      // detected width of the sidebar in pixels
    isTouchSupported: false,              // if touch is supported by the browser
    touchIdentifier: null,                // keep track of touching params
    touchStartX: null,                    //
    touchStartY: null,                    //
    touchCurrentX: null,                  //
    touchCurrentY: null,                  //
    extraProps: {},                       // додаткові props, що задаються через PageSidebar
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.sidebarWidth !== nextState.sidebarWidth
      || this.props.isOpen !== nextProps.isOpen
      || this.props.isDocked !== nextProps.isDocked
      || this.props.isMobile !== nextProps.isMobile
      || this.props.location.pathname !== nextProps.location.pathname
      || !is(this.state.extraProps, nextState.extraProps);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    pubsub.subscribe((extraProps) => this.setState({extraProps }));
    this.setState({
      isTouchSupported: typeof window === 'object' && 'ontouchstart' in window,
    });
    this.saveSidebarWidth();
  }

  componentDidUpdate() {
    // filter out the updates when we're touching
    if (!this.isTouching()) {
      this.saveSidebarWidth();
    }
  }

  onOverlayClicked = () => {
    if (this.props.isOpen) {
      this.props.onToggleSidebar(false);
    }
  }

  onTouchStart = (e) => {
    // filter out if a user starts swiping with a second finger
    if (!this.isTouching()) {
      const touch = e.targetTouches[0];
      this.setState({
        touchIdentifier: touch.identifier,
        touchStartX: touch.clientX,
        touchStartY: touch.clientY,
        touchCurrentX: touch.clientX,
        touchCurrentY: touch.clientY,
      });
    }
  }

  onTouchMove = (e) => {
    if (this.isTouching()) {
      for (let ind = 0; ind < e.targetTouches.length; ind++) {
        // we only care about the finger that we are tracking
        if (e.targetTouches[ind].identifier === this.state.touchIdentifier) {
          this.setState({
            touchCurrentX: e.targetTouches[ind].clientX,
            touchCurrentY: e.targetTouches[ind].clientY,
          });
          break;
        }
      }
    }
  }

  onTouchEnd = () => {
    if (this.isTouching()) {
      // trigger a change to open if sidebar has been dragged beyond dragToggleDistance
      const {isOpen, dragToggleDistance, onToggleSidebar} = this.props;
      const {sidebarWidth, touchStartX, touchCurrentX} = this.state;
      const touchWidth = this.touchSidebarWidth();
      if (isOpen && touchWidth < this.state.sidebarWidth - dragToggleDistance ||
        !isOpen && touchWidth > dragToggleDistance) {
        onToggleSidebar(!isOpen);
      }
      this.setState({
        touchIdentifier: null,
        touchStartX: null,
        touchStartY: null,
        touchCurrentX: null,
        touchCurrentY: null,
      });
    }
  }

  // This logic helps us prevents the user from sliding the sidebar horizontally
  // while scrolling the sidebar vertically. When a scroll event comes in, we're
  // cancelling the ongoing gesture if it did not move horizontally much.
  onScroll = () => {
    if (this.isTouching() && this.isLessThanCancelDistance()) {
      this.setState({
        touchIdentifier: null,
        touchStartX: null,
        touchStartY: null,
        touchCurrentX: null,
        touchCurrentY: null,
      });
    }
  }

  // True if the on going gesture X distance is less than the cancel distance
  isLessThanCancelDistance = () => {
    return this.props.isPulledRight ?
      Math.abs(this.state.touchCurrentX - this.state.touchStartX) < CANCEL_DISTANCE_ON_SCROLL :
      Math.abs(this.state.touchStartX - this.state.touchCurrentX) < CANCEL_DISTANCE_ON_SCROLL;
  }

  isTouching = () => {
    return this.state.touchIdentifier !== null;
  }

  saveSidebarWidth = () => {
    const width = this.sidebarRef ? this.sidebarRef.offsetWidth : 0;
    if (width !== this.state.sidebarWidth) {
      this.setState({sidebarWidth: width});
    }
  }

  saveSidebarRef = (node) => {
    this.sidebarRef = node;
  }

  // calculate the sidebarWidth based on current touch info
  touchSidebarWidth = () => {
    const {isPulledRight, isOpen} = this.props;
    const {sidebarWidth, touchStartX, touchCurrentX} = this.state;
    // if the sidebar is open and start point of drag is inside the sidebar
    // we will only drag the distance they moved their finger
    // otherwise we will move the sidebar to be below the finger.
    if (isPulledRight) {
      if (isOpen && window.innerWidth - touchStartX < sidebarWidth) {
        return touchCurrentX > touchStartX ?
          sidebarWidth + touchStartX - touchCurrentX :
          sidebarWidth;
      }
      return Math.min(window.innerWidth - touchCurrentX, sidebarWidth);
    }
    if (isOpen && touchStartX < sidebarWidth) {
      return touchCurrentX > touchStartX ?
        sidebarWidth :
        sidebarWidth - touchStartX + touchCurrentX;
    }
    return Math.min(touchCurrentX, sidebarWidth);
  }

  handleHideSidebar = () => {
    const {isMobile, onToggleSidebar} = this.props;
    if (isMobile) {
      scrollWrapperToTop && scrollWrapperToTop();
      onToggleSidebar && onToggleSidebar();
    }
  }

  render() {
    const {
      isLoggedIn,
      isDocked,
      isOpen,
      isMobile,
      isPulledRight,
      enableTransitions,
      enableTouch,
      touchHandleWidth,
      children,
      location} = this.props;
    const {sidebarWidth, isTouchSupported, extraProps} = this.state;
    const {pathname} = location;
    const rootProps = {}; // {role: "toolbar"};
    const sidebarStyle = {};
    const overlayStyle = {};
    const workspaceStyle = {};
    const useTouch = isTouchSupported && enableTouch;
    const isTouching = this.isTouching();
    let dragHandle;
    // ...sidebarStyle right/left
    if (isPulledRight) {
      sidebarStyle.right = 0;
      sidebarStyle.transform = 'translateX(100%)';
      sidebarStyle.WebkitTransform = 'translateX(100%)';
    } else {
      sidebarStyle.left = 0;
      sidebarStyle.transform = 'translateX(-100%)';
      sidebarStyle.WebkitTransform = 'translateX(-100%)';
    }
    if (isTouching) {
      const percentage = this.touchSidebarWidth() / sidebarWidth;
      // slide open to what we dragged
      if (isPulledRight) {
        sidebarStyle.transform = `translateX(${(1 - percentage) * 100}%)`;
        sidebarStyle.WebkitTransform = `translateX(${(1 - percentage) * 100}%)`;
      } else {
        sidebarStyle.transform = `translateX(-${(1 - percentage) * 100}%)`;
        sidebarStyle.WebkitTransform = `translateX(-${(1 - percentage) * 100}%)`;
      }
      // fade overlay to match distance of drag
      overlayStyle.opacity = percentage;
      overlayStyle.visibility = 'visible';
    } else if (isDocked) {
      // show sidebar
      if (sidebarWidth !== 0) {
        sidebarStyle.transform = `translateX(0%)`;
        sidebarStyle.WebkitTransform = `translateX(0%)`;
      }
      // make space on the left/right side of the content for the sidebar
      isPulledRight ?
        workspaceStyle.right = `${sidebarWidth}px` :
        workspaceStyle.left = `${sidebarWidth}px`;
    } else if (isOpen) {
      // slide open sidebar
      sidebarStyle.transform = `translateX(0%)`;
      sidebarStyle.WebkitTransform = `translateX(0%)`;
      // show overlay
      overlayStyle.opacity = 1;
      overlayStyle.visibility = 'visible';
    }
    // no transitions for touching & when disabled by the prop
    if (isTouching || !enableTransitions) {
      sidebarStyle.transition = 'none';
      sidebarStyle.WebkitTransition = 'none';
      if (isTouching) {
        overlayStyle.transition = 'none';
      }
    }
    if (useTouch) {
      if (isOpen) {
        rootProps.onTouchStart = this.onTouchStart;
        rootProps.onTouchMove = this.onTouchMove;
        rootProps.onTouchEnd = this.onTouchEnd;
        rootProps.onTouchCancel = this.onTouchEnd;
        rootProps.onScroll = this.onScroll;
      } else { // create dragHandle element
        const dragHandleStyle = {};
        dragHandleStyle.width = touchHandleWidth;
        isPulledRight ?
          dragHandleStyle.right = 0 :
          dragHandleStyle.left = 0;
        dragHandle = (
          <div className={styles.dragHandle} style={dragHandleStyle}
               onTouchStart={this.onTouchStart}
               onTouchCancel={this.onTouchEnd}
               onTouchMove={this.onTouchMove}
               onTouchEnd={this.onTouchEnd}
          />
        );
      }
    }
    trace(`render`);
    return (
      <main className={styles.RootSidebar} {...rootProps}>
        {isLoggedIn &&
          <div className={styles.FirstSidebarHolder} style={sidebarStyle} ref={this.saveSidebarRef}>
            <FirstSidebar
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
              pathname={pathname}
              onHideSidebar={this.handleHideSidebar}
              {...extraProps}
            />
          </div>
        }
        <div className={styles.Overlay} style={overlayStyle}
             role="presentation"
             tabIndex="0"
             onClick={this.onOverlayClicked}
        />
        <div className={styles.Workspace} style={workspaceStyle}>
          {dragHandle}
          {children}
          <GlobalNavigation />
          {isLoggedIn &&
            <ConnectionStatus />
          }
          {/* - - - entry point: main area - - - */}
          <TextingPanel />
        </div>
      </main>
    );
  }
}

export default withRouter(RootSidebar);
