// -------------------------------------------------------------------------------------------------
//  PhoneSelectField.js
//  - - - - - - - - - -
//  Поле для введення/відображення списку телефонів.
//  Обгортка для компоненти react-select.
//
//  Формат списку елементів {{ PN-SELECT2 }}:
//  [
//    {id: 'alK9A6srElz01', value: '380675552233', label: '+380 (67) 555 22 33', country: 'ua', type: 'm', pos: '0', __isNew__: true},
//    ...
//  ]
//
//  Формат зберігання списку телефонів в БД {{ PN-TAGS-PLUS }}:
//  [
//    {i: 'alK9A6srElz01' {l: '+380 (67) 501 00 87', y: 'ua', t: 'm', z: '0'}},
//    ...
//  ]
//
//  Attn:
//  - - - - -
//  - значення ідентифікатора містить поле 'id' (напр: 'alK9A6srElz01');
//  - поле 'value' містить телефонний номер у форматі E164 _БЕЗ_ префіксу '+' (напр: '380675551122');
//  - наявність символу '+' в полі 'value' свідчить про те, що цей телефон був доданий до списку;
//  - classNamePrefix required to prevent library warning;
//
//  Replaceable components:
//  - - - - - - - - - - - -
//  - Control
//  - IndicatorsContainer
//  - IndicatorSeparator
//  - DropdownIndicator
//  - LoadingIndicator
//  - ClearIndicator
//  - Group
//  - GroupHeading
//  - Input (*)
//  - Menu
//  - MenuList
//  - LoadingMessage
//  - NoOptionsMessage
//  - MultiValue
//  - MultiValueContainer
//  - MultiValueLabel
//  - MultiValueRemove
//  - Option
//  - Placeholder
//  - SelectContainer
//  - SingleValue
//  - ValueContainer
//
//  Docs:
//  - - - - -
//  https://www.npmjs.com/package/awesome-phonenumber
//  https://react-select.com/props#creatable-props
//  https://github.com/JedWatson/react-select
// -------------------------------------------------------------------------------------------------
// FixMe: не викликається onChange !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import Select from 'react-select';
import {ID_FLD, LABEL_FLD, COUNTRY_FLD, TYPE_FLD, POSITION_FLD} from 'core/apiFields';
import PhoneField from 'components/UI/fields/PhoneField';
import Icon from 'components/UI/icons/Icon';
import styles from './PhoneSelectField.scss';

// =================================================================================================
//  (FMT) Phone Format Converters
// =================================================================================================

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts phones from PN-SELECT2 into PN-TAGS-PLUS format.
//
//  {{ PN-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id || '+'
//      value: '380675552233',              // phone in E164 format (w/o '+')
//      label: '+380 (67) 555 22 33',       // formatted phone
//      country: 'ua',                      // country code
//      type: 'm',                          // phone type
//      position: '0',                      // position
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
//  {{ PN-TAGS-PLUS }}:
//  {
//    "id1": {                              // id
//        l: "+380 (67) 555 22 33",         // sic!: 1) = форматований телефон (з дужками)
//        y: "ua",                          // country code (ISO 3166-2)
//        t: "m",                           // phone type
//        z: "0",                           // position
//      },
//    ...
//    "+": {                                // '+' свідчить, що потрібно створити НОВИЙ телефонний номер
//        l: "+380 (67) 555 22 33",         // sic!: 1) = форматований телефон (з дужками)
//        y: "ua",                          // country code (ISO 3166-2)
//        t: "m",                           // phone type
//        z: "0",                           // position
//      },
//    ...
//  }
//
//  1) у полі 'l' зберігаємо форматований номер саме з дужками, бо бібліотека
//       awesome-phonenumber не додає дужки у форматований вивід;
//
export function toPnTagsPlus(pnSelect2) {
  if (pnSelect2 && pnSelect2.length > 0) {
    return pnSelect2.reduce((acc, current) => {
      const {id, value, label, country, type, position, __isNew__} = current;
      return Object.assign(acc, {
        [__isNew__ ? '+' + value : id]: {
          [LABEL_FLD]: label,
          [COUNTRY_FLD]: country,
          [TYPE_FLD]: type,
          [POSITION_FLD]: position
        }
      });
    }, {});
  } else {
    return {};
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts phones from PN-TAGS-PLUS into PN-LIST-STR format.
//
//  Attn:
//  - - - - -
//  - для phones потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - PN-LIST-STR це стрінгіфікований PN-LIST-PLUS
//
//  {{ PN-TAGS-PLUS }}:
//  {
//    "id1": {                              // id
//        l: "+380 (67) 555 22 33",         // sic!: 1) = форматований телефон (з дужками)
//        y: "ua",                          // country code (ISO 3166-2)
//        t: "m",                           // phone type
//        z: "0",                           // position
//      },
//    ...
//  }
//
//  {{ PN-LIST-STR }}:
//  "[
//    {
//      i: "alK9A6srElz01",                 // id || '+' to create new phone
//      l: "+380 (67) 555 22 33",           // sic!: 1) = форматований телефон (з дужками)
//      y: "ua",                            // country code (ISO 3166-2)
//      t: "m",                             // phone type
//      z: "0",                             // position
//    },
//    ...
//  ]"
//
export function toPnListStr(pnTagsPlus) {
  if (pnTagsPlus && Object.keys(pnTagsPlus).length > 0) {
    let pnLstPlus = [];
    for (let i in pnTagsPlus) {
      const {
        [LABEL_FLD]:label,
        [COUNTRY_FLD]:country,
        [TYPE_FLD]:type,
        [POSITION_FLD]:position} = pnTagsPlus[i];
      if (label) {
        pnLstPlus = pnLstPlus.concat([{
          [ID_FLD]: i,
          [LABEL_FLD]: label,
          [COUNTRY_FLD]: country,
          [TYPE_FLD]: type,
          [POSITION_FLD]: position,
        }]);
      }
    }
    return JSON.stringify(
      pnLstPlus.sort((a, b) => { return a.i < b.i ? -1 : 1; })
    );
  }
  return '[]';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts phones from PN-LIST-PLUS into PN-SELECT2 format.
//
//  {{ PN-LIST-STR }}:
//  [
//    {
//      i: "alK9A6srElz01",                 // id || '+' to create new phone
//      l: "+380 (67) 555 22 33",           // sic!: 1) = форматований телефон (з дужками)
//      y: "ua",                            // country code (ISO 3166-2)
//      t: "m",                             // phone type
//      z: "0",                             // position
//    },
//    ...
//  ]
//
//  {{ PN-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id || '+'
//      value: '380675552233',              // phone in E164 format (w/o '+')
//      label: '+380 (67) 555 22 33',       // formatted phone
//      country: 'ua',                      // country code
//      type: 'm',                          // phone type
//      position: '0',                      // position
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
//
export function toPnSelect2(pnLstPlus) {
  if (pnLstPlus && pnLstPlus.length > 0) {
    return pnLstPlus.reduce((acc, current) => {
      const {
        [ID_FLD]:id,
        [LABEL_FLD]:label,
        [COUNTRY_FLD]:country,
        [TYPE_FLD]:type,
        [POSITION_FLD]:position} = current;
      return acc.concat([{
        id: id,
        value: ('' + label).replace(/\D/g, ''),
        label: label,
        country: country,
        type: type,
        position: position
      }]);
    }, []).sort((a, b) => { return a.position < b.position ? -1 : 1; }); // сортуємо список телефонів !!!
  } else {
    return [];
  }
}

// (*) attn: в css-стилях відключив input: {display: none} !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customControl = (props) => {
  const {
    innerProps,
    isFocused,
    children,
    // hasValue,
    // isDisabled,
    // isMulti,
    // isRtl,
  } = props;
  const {...otherInnerProps} = innerProps || {};
  return (
    <div className={classnames(styles.control, {
      [styles.isFocused]: isFocused,
    })} {...otherInnerProps}>
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customIndicatorSeparator = ({innerProps}) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customClearIndicator = ({innerProps}) => {
  return (
    <div className={styles.clearIndicator} {...innerProps}>
      <Icon symbolName="cross" className={styles.icon} />
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customDropdownIndicator = (props) => {
  return null; // нам не потрібен dropdown зі списком телефонів
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenu = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenuList = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customOption = (props) => {
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMultiValue = (props) => {
  const {
    data:phone,
    isFocused,
    isDisabled,
    removeProps,
    // children,
    // components,
    // hasValue,
    // isMulti,
    // isRtl,
  } = props;
  const {id, value, label, country, type} = phone;
  return (
    <div className={classnames(styles.multiValue, {
      [styles.isFocused]: isFocused,
      [styles.isDisabled]: isDisabled,
    })}>
      <div className={classnames(styles.flag, {[country]: !!country})}></div>
      <div className={styles.labelText}>{label}</div>
      <div className={styles.removeButton}
           {...removeProps}>
        <Icon symbolName="cross" className={styles.icon} />
      </div>
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class PhoneSelectField extends React.Component {
  static propTypes = {
    name: Types.string,                   // name of the HTML Input (optional - without this, no input will be rendered)
    country: Types.oneOfType([Types.string, Types.number]), // країна по-замовчуванню (для відображення тел.коду країни)
    value: Types.oneOfType([Types.object, Types.array]), // the current value; reflected by the selected option
    delimiter: Types.string,              // delimiter used to join multiple values into a single HTML Input value
    placeholder: Types.string,            // placeholder text for the select value
    label: Types.string,                  // label text (shown above field)
    error: Types.string,                  // error message
    isAutoFocused: Types.bool,            // focus the control when it is mounted
    isBackspaceRemovable: Types.bool,     // can backspace remove current value?
    isClearable: Types.bool,              // is the select value clearable (by BACKSPACE)?
    isDisabled: Types.bool,               // is the select disabled?
    isInsertable: Types.bool,             // can insert new values?
    isRequired: Types.bool,               // is field required?
    isSearchable: Types.bool,             // is search functionality enabled?
    isMenuCloseOnSelect: Types.bool,      // close the select menu when the user selects an option
    isMenuOpenOnFocus: Types.bool,        // alows control of whether the menu is opened when the Select is focused
    isMenuOpenOnClick: Types.bool,        // allows control of whether the menu is opened when the Select is clicked
    isMulti: Types.bool,                  // is multiple selection enabled?
    isRtl: Types.bool,                    // is the select direction right-to-left?
    onBlur: Types.func,                   // callback on blur events
    onFocus: Types.func,                  // callback on focus events
    onChange: Types.func,                 // callback on change events
    onInputChange: Types.func,            // callback on change events on the input
    onKeyDown: Types.func,                // callback on key down events
    onMenuOpen: Types.func,               // callback on menu opening events
    onMenuClose: Types.func,              // callback on menu closing events
    onMenuScrollToTop: Types.func,        // fired when the user scrolls to the top of the menu
    onMenuScrollToBottom: Types.func,     // fired when the user scrolls to the bottom of the menu
    onNoOptions: Types.func,              // text to display when there are no options
  }

  static defaultProps = {
    delimiter: '·',
    placeholder: '',
    label: '',
    error: '',
    isAutoFocused: false,
    isBackspaceRemovable: false,
    isClearable: false,
    isDisabled: false,
    isInsertable: false,
    isRequired: false,
    isSearchable: true,
    isMenuCloseOnSelect: true,
    isMenuOpenOnFocus: true,
    isMenuOpenOnClick: true,
    isMulti: false,
    isRtl: false,
    onBlur: undefined,
    onFocus: undefined,
    onChange: undefined,
    onInputChange: undefined,
    onKeyDown: undefined,
    onMenuOpen: undefined,
    onMenuClose: undefined,
    onMenuScrollToTop: undefined,
    onMenuScrollToBottom: undefined,
    onNoOptions: () => t`Enter phone number below to add new one...`,
  }

  // state = {
  // }

  // - - - - - - - - - - - - - - - - - - -
  //  Кастомна фільтрація елементів списку.
  //  Пошук дублів проводиться по полям label & value.
  //
  //  [searchCmd] + searchStr = pattern
  //
  //  Attn:
  //  - - - - -
  //  - дефолтна фільтрація шукає значення в label & value.
  // - - - - - - - - - - - - - - - - - - -
  handleFilter = (item, pattern) => {
    // show all items for empty search string
    if (!item || !item.label || !pattern) {
      return true;
    }
    const {label = '', value = '', data} = item;
    if (data) {
      const {__isNew__} = data;
      // show all new & new candidates items
      if (__isNew__) {
        return true;
      }
    }
    // show items containing search string in label only
    return (
      label.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
      || value.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
    );
  }

  handleChange = (items) => {
    const {onChange} = this.props;
    onChange && onChange(items);
  }

  // handleChangeNumber = (formattedNumber, countryData, e) => {
  //   console.log(formattedNumber, countryData);
  // }

  handleAddNumber = ({pnNumber, pnFormattedNumber, pnCountryCode, pnType}) => {
    const {value:items} = this.props;

    if (pnNumber && pnFormattedNumber && pnCountryCode && pnType) {
      // 1) пошук на дублювання в списку вже створених елементів;
      let isDoubled = false;
      items.map(elem => {
        if (elem.label === pnFormattedNumber) {
          isDoubled = true;
        }
      });
      // 2) додаємо новий елемент {{ PN-SELECT2 }}
      if (!isDoubled && pnFormattedNumber.length > 0 && pnFormattedNumber.length > 0) {
        items.push(Object.assign({
          id: '+',
          value: pnNumber,
          label: pnFormattedNumber,
          country: pnCountryCode,
          type: pnType,
          position: items.length + 1,
          __isNew__: true,
        }));
      }
    }
    // 3) перемальовка компоненти
    this.setState({
      error: ''
    });
  }

  render() {
    const {
      name,
      value,
      delimiter,
      placeholder,
      error,
      label,
      isAutoFocused,
      isBackspaceRemovable,
      isClearable,
      isDisabled,
      isInsertable,
      isRequired,
      isSearchable,
      isMenuCloseOnSelect,
      isMenuOpenOnFocus,
      isMenuOpenOnClick,
      isMulti,
      isRtl,
      onBlur,
      onFocus,
      onInputChange,
      onKeyDown,
      onMenuOpen,
      onMenuClose,
      onMenuScrollToTop,
      onMenuScrollToBottom,
      onNoOptions} = this.props;
    return (
      <div className={classnames(styles.PhoneSelectField, { [styles.isError]: error })}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <Select
          className={styles.select}
          classNamePrefix="pns2"
          name={name}
          value={value}
          options={[]}
          delimiter={delimiter}
          placeholder={isDisabled ? '' : placeholder}
          components={{
            Control: customControl,
            IndicatorSeparator: customIndicatorSeparator,
            ClearIndicator: customClearIndicator,
            DropdownIndicator: customDropdownIndicator,
            Menu: customMenu,
            MenuList: customMenuList,
            Option: customOption,
            MultiValue: customMultiValue,
          }}
          autoFocus={isAutoFocused}
          backspaceRemovesValue={isBackspaceRemovable}
          closeMenuOnSelect={isMenuCloseOnSelect}
          openMenuOnFocus={isMenuOpenOnFocus}
          openMenuOnClick={isMenuOpenOnClick}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isRtl={isRtl}
          filterOption={this.handleFilter}
          noOptionsMessage={onNoOptions}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={this.handleChange}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onMenuScrollToTop={onMenuScrollToTop}
          onMenuScrollToBottom={onMenuScrollToBottom}
        />
        <PhoneField
          country={'ua'}
          value={''}
          error={''}
          isDisabled={false}
          hasDropdown={true}
          hasSearchBox={true}
          enableTerritories={false}
          preferredCountries={['ua', 'ca', 'pl', 'de', 'us']}
          excludeCountries={['ru']}
          // onChange={this.handleChangeNumber}
          onAddButton={this.handleAddNumber}
        />
        <div className={styles.addButton}></div>
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
