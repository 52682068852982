// -------------------------------------------------------------------------------------------------
//  rehype-link-attrs.js
//  - - - - - - - - - - - - - -
//  Додає атрибути rel="nofollow noopener noreferrer" та target="_blank" до елементів типу 'a' в hast-дереві.
//
//  Result:
//  - - - -
//  {
//    "type":"element",
//    "tagName":"a",
//    "properties":{
//      "href":"https://learn.javascript.ru/constructor-new",
//      "rel":"nofollow noopener noreferrer",
//      "target":"_blank"
//    },
//    "children":[{
//      "type":"text",
//      "value":"https://learn.javascript.ru/constructor-new"
//    }]
//  }
//
// -------------------------------------------------------------------------------------------------
import visit from 'unist-util-visit';
import {hastTypes} from 'core/hastTypes';

export default function setLinkAttrs() {
  return transform;
}

function transform(tree) {
  visit(tree, 'element', visitor);
}

function visitor(node) {
  const {tagName, properties} = node;

  if (tagName === hastTypes.LINK) {
    properties.rel = 'nofollow noopener noreferrer';
    properties.target = '_blank';
  }
}
