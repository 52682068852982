// -------------------------------------------------------------------------------------------------
//  bitmaskTypes.js
//  - - - - - - - - - -
//  Some types for bits & bitmasks.
//
//  Manual about bit operations:
//  https://medium.com/@parkerjmed/practical-bit-manipulation-in-javascript-bfd9ef6d6c30
// -------------------------------------------------------------------------------------------------

export const DEFAULT_BITMASK = 1;               // B'000 0000 0000 0001'::integer

export const BIT01 = 0b000000000000001;         // 1
export const BIT02 = 0b000000000000010;         // 2
export const BIT03 = 0b000000000000100;         // 4
export const BIT04 = 0b000000000001000;         // 8
export const BIT05 = 0b000000000010000;         // 16
export const BIT06 = 0b000000000100000;         // 32
export const BIT07 = 0b000000001000000;         // 64
export const BIT08 = 0b000000010000000;         // 128
export const BIT09 = 0b000000100000000;         // 256
export const BIT10 = 0b000001000000000;         // 512
export const BIT11 = 0b000010000000000;         // 1024
export const BIT12 = 0b000100000000000;         // 2048
export const BIT13 = 0b001000000000000;         // 4096
export const BIT14 = 0b010000000000000;         // 8192
export const BIT15 = 0b100000000000000;         // 16384

export const NON01 = 0b111111111111110;         // 32766

export const b2d = x => parseInt(x,2)           // binary to decimal
export const d2b = x => x.toString(2)           // decimal to binary
