// -------------------------------------------------------------------------------------------------
//  ServerActions.js
//  - - - - - - - - - -
//  (WebRTC: Part 3/4)
//
//  Обробка усіх нотифікейшенів від сервера + колбек обробки помилок сокетів.
//  Для кожного rtc-нотифікейшена емітує відповідний сигнал.
//  Модуль містить call actions, що будуть імпортовані разом з on/off під псевдонімом 'signaling';
//
//  Attn:
//  - - - - -
//  - щоб не навантажувати стори для rtc-нотифікейшенів емітуються сигнали (pub/sub);
//  - payload для частини нотифікейшенів обробляємо БЕЗ перетворення у внутрішній формат (див. 1));
// -------------------------------------------------------------------------------------------------
// FixMe: проблема отримання одного нотифікейшена по декілька разів (з кожного connection) !!!
// FixMe: створити список з UUID для "гасіння" дублів, які прилетіли з різних connection !!!

import EventEmitter from 'eventemitter3';
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {applyAccountTypeChanges} from 'core/accountTypes';
import {
  PRESENCE_NTF_WAS_RECEIVED_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION,
  FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
  FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION,
  FRIENDSHIP_WAS_CANCELED_ACTION,
  CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION,
  STAPLE_UPDATED_NTF_WAS_RECEIVED_ACTION,
  COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION,
  CSUBSCRIPTION_WAS_CREATED_ACTION,
  CHAT_WAS_CREATED_ACTION,
  CHAT_WAS_UPDATED_ACTION,
  CHAT_WAS_CLOSED_ACTION,
  CHAT_WAS_DELETED_ACTION,
  CHAT_MESSAGE_WAS_RECEIVED_ACTION,
  CHAT_MESSAGE_WAS_UPDATED_ACTION,
  CHAT_MESSAGE_WAS_READ_ACTION,
  CHAT_MESSAGE_WAS_DELETED_ACTION,
  CHAT_CONTEXT_WAS_UPDATED_ACTION} from 'core/actionTypes';
import {
  ACCOUNT_TYPE_FLD,
  FRIENDSHIP_STATUS_FLD,
  STAPLE_FLD,
  CSUBSCRIPTION_FLD,
  CONTEXT_ID_FLD,
  CONTEXT_TYPE_FLD,
  CHAT_ID_FLD,
  CALL_ID_FLD,
  SIGNAL_DATA_FLD,
  MEMBER_ID_FLD,
  MESSAGE_FLD,
  MESSAGES_FLD,
  CHAT_FLD,
  USER_FLD,
  USER_ID_FLD,
  EVENT_TYPE_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS, PING, PONG,
  // - - -
  ACCOUNT_TYPE_CHANGED_NTF,
  PRESENCE_NTF,
  FRIENDSHIP_NTF,
  CONTACT_UPDATED_NTF,
  STAPLE_UPDATED_NTF,
  COLLECTION_STAPLEDATAS_UPDATED_NTF,
  CSUBSCRIPTION_CREATED_NTF,
  CHAT_CREATED_NTF,
  CHAT_UPDATED_NTF,
  CHAT_CLOSED_NTF,
  CHAT_DELETED_NTF,
  CHAT_MESSAGE_CREATED_NTF,
  CHAT_MESSAGE_UPDATED_NTF,
  CHAT_MESSAGE_READ_NTF,
  CHAT_MESSAGE_DELETED_NTF,
  CHAT_CONTEXT_UPDATED_NTF,
  CALL_REQUEST_NTF,
  CALL_REQUEST_CANCEL_NTF,
  CALL_JOIN_NTF,
  CALL_LEAVE_NTF,
  CALL_MEMBER_ADD_NTF,
  CALL_TERMINATE_NTF,
  CALL_SIGNAL_NTF,
  // - - -
  MY_CALLS_WERE_FETCHED_SIG,
  CALL_REQUESTED_SIG,
  CALL_REQUEST_CANCELED_SIG,
  CALL_JOINED_SIG,
  CALL_LEAVED_SIG,
  CALL_MEMBER_ADDED_SIG,
  CALL_TERMINATED_SIG,
  RTC_OFFER_SIG,
  RTC_ANSWER_SIG,
  RTC_ICE_CANDIDATE_SIG,
  SERVER_ERROR_SIG,
  SOCKET_ERROR_SIG} from 'core/apiTypes';
import {
  FS_REQUEST_SENT_STATUS,
  FS_REQUEST_RECEIVED_STATUS,
  FS_REQUEST_ACCEPTED_STATUS,
  FS_REQUEST_REJECTED_STATUS,
  FS_CANCELED_STATUS} from 'core/friendshipTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchMyCalls,
  apiRequestCall,
  apiJoinCall,
  apiLeaveCall,
  apiAddCallMember,
  apiSendSignal} from 'api/CallAPI';
import {
  wsockSubscribeOnEvents,
  wsockUnsubscribeFromEvents,
  wsockSubscribeOnErrors,
  wsockUnsubscribeFromErrors} from 'api/WebSocketAPI';
import AccountStore from 'stores/AccountStore';

const isVerbose = DEBUG && true;
const prefix = '- - - ServerActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

const emitter = new EventEmitter();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function subscribeOnServerEvents() {
  trace(`subscribeOnServerEvents`);
  wsockSubscribeOnEvents(handleNotification);
  wsockSubscribeOnErrors(handleError);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function unsubscribeFromServerEvents() {
  trace(`unsubscribeFromServerEvents`);
  wsockUnsubscribeFromEvents(handleNotification);
  wsockUnsubscribeFromErrors(handleError);
}

// 1) payload для частини нотифікейшенів обробляємо БЕЗ перетворення у внутрішній формат!!!
// 2) оновлення превьюшок:
//  - формування превьюшок на сервері потребує часу, тому потрібно зробити re-fetch превьюшок в браузері;
//  - оновлення робимо через зміну регістру поля '.ext' (спочатку в upperCase, потім через пару секунд в LowerCase);
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function handleNotification(event) {
  const {[NTF]:ntf, [PAYLOAD]:payload} = JSON.parse(event.data);
  if (ntf) {
    trace(`handleNotification: ${ntf}`);
  }
  switch(ntf) {
    // - - - account type:
    case ACCOUNT_TYPE_CHANGED_NTF:
      const myAccountType = AccountStore.getMyAccountType();
      const {[EVENT_TYPE_FLD]: eventType, [ACCOUNT_TYPE_FLD]:nextAccountType} = payload;
      if (myAccountType && nextAccountType && myAccountType !== nextAccountType) {
        applyAccountTypeChanges(eventType, nextAccountType);
      }
      break;
    // - - - presence:
    case PRESENCE_NTF:
      Dispatcher.dispatch({
        type: PRESENCE_NTF_WAS_RECEIVED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    // - - - friendship requests (FSR):
    case FRIENDSHIP_NTF:
      const {
        [USER_ID_FLD]:userId,
        [USER_FLD]:user,
        [CHAT_FLD]:chat,
        [MESSAGES_FLD]:messages,
        [FRIENDSHIP_STATUS_FLD]:friendshipStatus} = payload;
      switch(friendshipStatus) {
        case FS_REQUEST_SENT_STATUS:
          Dispatcher.dispatch({
            type: FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
            payload: {
              user: user,
            }
          });
          break;
        case FS_REQUEST_RECEIVED_STATUS:
          Dispatcher.dispatch({
            type: FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION,
            payload: {
              user: user,
            }
          });
          break;
        case FS_REQUEST_ACCEPTED_STATUS:
          Dispatcher.dispatch({
            type: FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION,
            payload: {
              user: user,
              chat: chat,
              messages: messages,
            }
          });
          break;
        case FS_REQUEST_REJECTED_STATUS:
          Dispatcher.dispatch({
            type: FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
            payload: {
              userId: userId,
            }
          });
          break;
        case FS_CANCELED_STATUS:
          Dispatcher.dispatch({
            type: FRIENDSHIP_WAS_CANCELED_ACTION,
            payload: {
              userId: userId,
            }
          });
          break;
      }
      break;
    // - - - contacts:
    case CONTACT_UPDATED_NTF:
      Dispatcher.dispatch({
        type: CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    // - - - staples:
    case STAPLE_UPDATED_NTF:
      Dispatcher.dispatch({
        type: STAPLE_UPDATED_NTF_WAS_RECEIVED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    case COLLECTION_STAPLEDATAS_UPDATED_NTF:
      Dispatcher.dispatch({
        type: COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    // - - - csubscriptions:
    case CSUBSCRIPTION_CREATED_NTF:
      const {[CSUBSCRIPTION_FLD]:csubscription} = payload;
      Dispatcher.dispatch({
        type: CSUBSCRIPTION_WAS_CREATED_ACTION,
        payload: {csubscription: csubscription},
      });
      break;
    // - - - chats:
    case CHAT_CREATED_NTF:
      const {[CHAT_FLD]:createdChat, [MESSAGES_FLD]:createdMessages} = payload;
      Dispatcher.dispatch({
        type: CHAT_WAS_CREATED_ACTION,
        payload: {chat: createdChat, messages: createdMessages}
      });
      break;
    case CHAT_UPDATED_NTF:
      const {[CHAT_FLD]:updatedChat, [MESSAGE_FLD]:statusMessage} = payload;
      Dispatcher.dispatch({
        type: CHAT_WAS_UPDATED_ACTION,
        payload: {chat: updatedChat, message: statusMessage}
      });
      break;
    case CHAT_CLOSED_NTF:
      const {[CHAT_FLD]:closedChat, [MESSAGE_FLD]:closedMessage} = payload;
      Dispatcher.dispatch({
        type: CHAT_WAS_CLOSED_ACTION,
        payload: {chat: closedChat, message: closedMessage}
      });
      break;
    case CHAT_DELETED_NTF:
      const {[CHAT_ID_FLD]:chatId, [CONTEXT_ID_FLD]:ctxId, [CONTEXT_TYPE_FLD]:ctxType} = payload;
      Dispatcher.dispatch({
        type: CHAT_WAS_DELETED_ACTION,
        payload: {chatId: chatId, ctxId: ctxId, ctxType: ctxType}
      });
      break;
    case CHAT_MESSAGE_CREATED_NTF:
      Dispatcher.dispatch({
        type: CHAT_MESSAGE_WAS_RECEIVED_ACTION,
        payload: payload, // sic!: 1)
      });
      // - - -
      // attn: 2) (оновлення превьюшок):
      //  - формування превьюшок на сервері потребує часу, тому потрібно зробити re-fetch превьюшок в браузері;
      //  - оновлення робимо через зміну регістру поля '.ext' (спочатку в upperCase, потім через пару секунд в LowerCase);
      setTimeout(() => {
        Dispatcher.dispatch({
          type: CHAT_MESSAGE_WAS_RECEIVED_ACTION,
          payload: Object.assign(payload, {isPictureReloadRequired: true}), // sic!: 2) = оновлення превьюшок
        });
      }, 2000); // 2сек затримки до перевантаження превьюшок !!!
      break;
    case CHAT_MESSAGE_UPDATED_NTF:
      Dispatcher.dispatch({
        type: CHAT_MESSAGE_WAS_UPDATED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    case CHAT_MESSAGE_READ_NTF:
      Dispatcher.dispatch({
        type: CHAT_MESSAGE_WAS_READ_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    case CHAT_MESSAGE_DELETED_NTF:
      Dispatcher.dispatch({
        type: CHAT_MESSAGE_WAS_DELETED_ACTION,
        payload: payload, // sic!: 1)
      });
      break;
    case CHAT_CONTEXT_UPDATED_NTF:
      const {[STAPLE_FLD]:staple} = payload;
      Dispatcher.dispatch({
        type: CHAT_CONTEXT_WAS_UPDATED_ACTION,
        payload: {staple: staple},
      });
      break;
    // - - - rtc/call (емітуємо сигнали для OneCall):
    case CALL_REQUEST_NTF:          emitter.emit(CALL_REQUESTED_SIG,        payload); break;
    case CALL_REQUEST_CANCEL_NTF:   emitter.emit(CALL_REQUEST_CANCELED_SIG, payload); break;
    case CALL_JOIN_NTF:             emitter.emit(CALL_JOINED_SIG,           payload); break;
    case CALL_LEAVE_NTF:            emitter.emit(CALL_LEAVED_SIG,           payload); break;
    case CALL_MEMBER_ADD_NTF:       emitter.emit(CALL_MEMBER_ADDED_SIG,     payload); break;
    case CALL_TERMINATE_NTF:        emitter.emit(CALL_TERMINATED_SIG,       payload); break;
    case CALL_SIGNAL_NTF:           handleSignal(payload); break;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function handleSignal(payload) {
  const {[CALL_ID_FLD]:callId, [MEMBER_ID_FLD]:memberId, [SIGNAL_DATA_FLD]:signalData} = payload;
  const {sigtype, offer, answer, ice_candidate:iceCandidate} = signalData || {};
  trace(`handleSignal: \nntf.payload=${JSON.stringify(payload, '', 2)}`);
  switch(sigtype) {
    case RTC_OFFER_SIG:             emitter.emit(RTC_OFFER_SIG,             {callId, memberId, offer}); break;
    case RTC_ANSWER_SIG:            emitter.emit(RTC_ANSWER_SIG,            {callId, memberId, answer}); break;
    case RTC_ICE_CANDIDATE_SIG:     emitter.emit(RTC_ICE_CANDIDATE_SIG,     {callId, memberId, iceCandidate}); break;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function handleError() {
  trace(`handleError: SOCKET_ERROR`);
  emitter.emit(SOCKET_ERROR_SIG, null);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const on = emitter.on.bind(emitter);
export const off = emitter.off.bind(emitter);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyCalls() {
  trace(`fetchMyCalls`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchMyCalls();
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchMyCalls: OK`);
        emitter.emit(MY_CALLS_WERE_FETCHED_SIG, payload); break; // sic!: результат --> OneCall !!!
        break;
      default:
        traceError(`fetchMyCalls: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function requestCall(opts) {
  trace(`requestCall`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiRequestCall(opts);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`requestCall: OK`);
        ReactGA.event({
          category: gaCategories.RTC,
          action: gaActions.RTC_CALL_REQUESTED,
        });
        return payload; // sic!: повертаємо в точку виклику !!!
      case 'no_available_members':
      case 'member_limit_exceeded':
      case 'forbidden':
        emitter.emit(SERVER_ERROR_SIG, status);
        break;
      default:
        traceError(`requestCall: status=${status}`);
        errors.formError = `server_error: status=${status}`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function joinCall(callId) {
  trace(`joinCall`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiJoinCall(callId);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`joinCall: OK`);
        return payload; // sic!: повертаємо в точку виклику !!!
        break;
      // case 'forbidden':
      // case 'invalid_formdata':
      default:
        traceError(`joinCall: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function leaveCall(callId) {
  trace(`leaveCall`);
  let status;
  let errors = {};
  try {
    const response = await apiLeaveCall(callId);
    ({[STATUS]:status = 'unknown_error', [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`leaveCall: OK`);
        break;
      // case 'not_found':
      default:
        traceError(`leaveCall: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function addCallMember(opts) {
  trace(`addCallMember`);
  // let status;
  // let payload;
  // let errors = {};
  // try {
  //   const response = await apiAddCallMember(opts);
  //   ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
  //   switch(status) {
  //     case 'ok':
  //       trace(`addCallMember: OK`);
  //       const {
  //         available_member_ids:availableMemberIds,
  //       } = payload;
  //
  //       // Dispatcher.dispatch({
  //       //   type: CALL_MEMBER_ADDED_ACTION,
  //       //   payload: {
  //       //     availableMemberIds: availableMemberIds,
  //       //   }
  //       // });
  //       break;
  //     // case 'forbidden':
  //     // case 'no_available_members':
  //     // case 'member_limit_exceeded':
  //     // case 'not_enough_coins':
  //     default:
  //       traceError(`addCallMember: status=${status}`);
  //       errors.formError = `server_error`;
  //       break;
  //   }
  // } catch(error) {
  //   console.error(`API error`, error);
  //   status = 'unknown_error';
  //   errors.formError = JSON.stringify(error);
  //   return {status, errors};
  // }
  // return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendSignal(opts) {
  trace(`sendSignal`);
  let status;
  let errors = {};
  try {
    const response = await apiSendSignal(opts);
    ({[STATUS]:status = 'unknown_error', [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendSignal: OK`);
        break;
      // case 'forbidden':
      default:
        traceError(`sendSignal: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default {
  on,
  off,
  fetchMyCalls,
  requestCall,
  joinCall,
  leaveCall,
  addCallMember,
  sendSignal,
}
