// -------------------------------------------------------------------------------------------------
//  OneCollectionContainer.js
//  - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  - форма має два параметри: collectionId, chatId (опціонально);
//  - якщо колекція не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в колекцію контекст сайдбару встановлюємо на закладку чату (CHAT_TAB);
//  - якщо другим параметром вказаний chatId, то встановлюємо цей чат;
//  - якщо у нас є виділені колекції, то контекст і чат встановлюємо, але активна закладка SELECTION_TAB;
//  - при виході встановлюємо на закладку колекцій (бо стираємо ctxId, ctxType, userId);
//
//  Attn: a)
//  - - - - -
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається яке API буде застосовано;
// -------------------------------------------------------------------------------------------------
// ToDo: OBSOLETE <-- чи потрібна ця форма  ???

import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
// import {OWNER_ID_FLD} from 'core/apiFields';
// import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
// import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchCollection, fetchCollectionAsGuest} from 'actions/CollectionActions';
import {fetchUsers, fetchUsersAsGuest} from 'actions/UserActions';
// import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
// import ChatStore from 'stores/ChatStore';
// import CrmStore from 'stores/CrmStore';
import CollectionStore from 'stores/CollectionStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const OneCollection = Loader.create(() => import('./OneCollection'));
const isVerbose = DEBUG && true;
const prefix = '- - - OneCollectionContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// let prevTabMode;                          // CTX:
// let prevCtxId;                            // CTX:
// let prevCtxType;                          // CTX:
// let prevCtxOwnerId;                       // CTX:
// let prevChatId;                           // CTX:

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class OneCollectionContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, UserStore];
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про колекцію?
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const {collectionId} = props.match.params;
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const collection = collectionId ? CollectionStore.getCollection(collectionId) : null;
    const {ownerId} = collection || {};
    const owner = ownerId ? UserStore.getUser(ownerId) : null;
    return {isLoggedIn, myId, owner, collection};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.props.location.pathname !== nextProps.location.pathname
      || !is(this.state.owner, nextState.owner)
      || !is(this.state.collection, nextState.collection);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {collectionId} = this.props.match.params;
    const {isLoggedIn} = this.state;
    this.setState({isServerRequested: false});
    if (collectionId && !CollectionStore.isCollectionLoaded(collectionId)) {
      const {ownerId} = isLoggedIn ?
        await fetchCollection(collectionId) :
        await fetchCollectionAsGuest(collectionId);
      if (ownerId) {
        if (!UserStore.isUserLoaded(ownerId)) {
          isLoggedIn ?
            await fetchUsers([ownerId]) :
            await fetchUsersAsGuest([ownerId]);
        }
      }
    }
    this.setState({isServerRequested: true});
  }

  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    const {isLoggedIn, collection} = this.state;
    const {ownerId} = collection || {};
    if (!isLoggedIn && ownerId && !UserStore.isUserLoaded(ownerId)) {
      await fetchUsersAsGuest([ownerId]);
    }
  }

  // async componentWillUnmount() {
  //   trace(`componentWillUnmount`);
  //   const {isLoggedIn} = this.state;
  //   // CTX: поки повертаємо попередній контекст, але це погано працює при переході з форми НЕ на попередній URL !!!
  //   if (isLoggedIn) {
  //     // await substituteSidebarTabMode(CollectionStore.hasSelectedCollections() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : COLLECTION_TAB);
  //     // await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
  //     // await substituteSidebarChat({chatId: ''});
  //     await substituteSidebarTabMode(prevTabMode);
  //     await substituteSidebarContext({ctxId: prevCtxId, ctxType: prevCtxType, ctxOwnerId: prevCtxOwnerId});
  //     await substituteSidebarChat({chatId: prevChatId});
  //   }
  // }

  render() {
    const {isServerRequested, isLoggedIn, myId, owner, collection} = this.state;
    if (isServerRequested && !collection) {
      return (
        <NotFoundPage />
      );
    }
    if (!collection) {
      return null;
    }
    trace(`render`);
    return (
      <OneCollection
        isLoggedIn={isLoggedIn}
        myId={myId}
        owner={owner}
        collection={collection}
      />
    );
  }
}

export default Container.create(OneCollectionContainer, {withProps: true, pure: false});
