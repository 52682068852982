// -------------------------------------------------------------------------------------------------
//  RichIcon.js
//  - - - - - - - - - -
//  Відображення іконки на RichToolbar.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/UI/icons/Icon';
import styles from './RichIcon.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const RichIcon = ({commandName, symbolName, symbolText, onClick, onChange,
                    isDisabled = false, isActive = false, isDisplayed = true}) => {
  if (!isDisplayed) {
    return null;
  }
  return (
    <div
      key={commandName}
      className={classnames(styles.RichIcon, {
        [styles.isActive]: isActive,
        [styles.isDisabled]: isDisabled,
      })}
      onMouseDown={ev => ev.preventDefault()}
      onClick={!isDisabled ? onClick : () => {}}
      onChange={!isDisabled ? onChange : () => {}}
      disabled={isDisabled}
    >
      {symbolName &&
        <Icon symbolName={symbolName} className={styles.icon} />
      }
      {symbolText &&
        <span>{symbolText}</span>
      }
    </div>
  );
};

RichIcon.propTypes = {
  commandName: Types.string.isRequired,   // the name of button's command
  symbolName: Types.string,               // the name of icon symbol from sprite
  symbolText: Types.any,                  // symbol text instead of symbolName
  isDisabled: Types.bool,                 // is button disabled?
  isActive: Types.bool,                   // is button active?
  onClick: Types.func,                    // on click callback
  onChange: Types.func,                   // on change callback
};

export default RichIcon;
