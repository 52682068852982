// -------------------------------------------------------------------------------------------------
//  GroupActions.js
//  - - - - - - - - - -
//  Екшени для роботи з групами користувача (MyGroup).
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' та робимо обробку catch-помилок;
//  - інформація про помилку міститься в status та errors обʼєкту response;
//  - в полі errors.formError повертаємо додаткову інформацію про помилки;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import {t} from 'ttag';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  CONTACT_WAS_UPDATED_ACTION,
  MY_GROUPS_WERE_FETCHED_ACTION,
  GROUP_WAS_FETCHED_ACTION,
  GROUP_WAS_CREATED_ACTION,
  GROUP_WAS_UPDATED_ACTION,
  GROUP_WAS_DELETED_ACTION} from 'core/actionTypes';
import {
  CONTACTS_FLD,
  GROUP_FLD,
  GROUP_ID_FLD,
  GROUPS_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchMyGroups,
  apiFetchGroup,
  apiCreateGroup,
  apiUpdateGroup,
  apiDeleteGroup,
  apiAddContactsToGroup,
  apiRemoveContactsFromGroup} from 'api/GroupAPI';
import {showSuccess} from 'components/UI/Informer';

const isVerbose = DEBUG && true;
const prefix = '- - - GroupActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let areMyGroupsFetched = false;           // чи викликали завантаження списку контактів?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyGroups(isForced = false) {
  trace(`fetchMyGroups`);

  const fetcher = async () => {
    let resp;
    if (!areMyGroupsFetched || isForced) {
      try {
        areMyGroupsFetched = true;
        resp = await apiFetchMyGroups();
      } catch(error) {
        areMyGroupsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[GROUPS_FLD]:groups} = payload;
        if (groups) {
          const areLoaded = true; // sic!: тому що для груп поки немає пагінації
          Dispatcher.dispatch({
            type: MY_GROUPS_WERE_FETCHED_ACTION,
            payload: {
              groups: groups,
              areGroupsLoaded: areLoaded,
            }
          });
        }
      } else {
        traceError(`fetchMyGroups: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchGroup(id) {
  trace(`fetchGroup: groupId=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchGroup(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_FLD]:group} = payload;
        if (group) {
          Dispatcher.dispatch({
            type: GROUP_WAS_FETCHED_ACTION,
            payload: {
              group: group,
            }
          });
        }
        break;
      default:
        traceError(`fetchGroup: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createGroup(nextGroup) {
  trace(`createGroup`);
  let status;
  let payload;
  let errors = {};
  let group; // attn: для переходу на url новоствореної групи !!!
  try {
    const response = await apiCreateGroup(nextGroup);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_FLD]:createdGroup} = payload;
        if (createdGroup) {
          group = createdGroup;
          Dispatcher.dispatch({
            type: GROUP_WAS_CREATED_ACTION,
            payload: {
              group: createdGroup,
            }
          });
          ReactGA.event({
            category: gaCategories.CRM,
            action: gaActions.GROUP_CREATED
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`createGroup: status=${status}`);
        break
      default:
        traceError(`createGroup: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors, group};
  }
  return {status, errors, group};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateGroup(group, patchObject) {
  trace(`updateGroup`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateGroup(group, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_FLD]:updatedGroup} = payload;
        if (updatedGroup) {
          Dispatcher.dispatch({
            type: GROUP_WAS_UPDATED_ACTION,
            payload: {
              prevGroup: group,
              group: updatedGroup,
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`updateGroup: status=${status}`);
        break
      default:
        traceError(`updateGroup: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteGroup(group) {
  trace(`deleteGroup`);
  let status;
  let payload;
  let errors = {};
  const {id} = group;
  try {
    const response = await apiDeleteGroup(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    // - - - - -
    // FixMe: 'forbidden' при видаленні вже видаленої групи !!!
    // видає 'forbidden' при спробі видалити групу, яка була видалена в іншій вкладці;
    // потрібно обробити ситуацію - напр. видалити цю групу із списку ???
    // - - - - -
    switch(status) {
      case 'ok':
        trace(`deleteGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_ID_FLD]:groupId} = payload;
        if (groupId) {
          Dispatcher.dispatch({
            type: GROUP_WAS_DELETED_ACTION,
            payload: {
              groupId: groupId,
            }
          });
          ReactGA.event({
            category: gaCategories.CRM,
            action: gaActions.GROUP_DELETED
          });
        }
        break;
      default:
        traceError(`deleteGroup: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function addContactsToGroup(groupId, contactIds) {
  trace(`addContactsToGroup`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiAddContactsToGroup(groupId, contactIds);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`apiAddContactsToGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_ID_FLD]:updatedGroupId, [CONTACTS_FLD]:updatedContacts} = payload || [];
        if (updatedGroupId && updatedContacts && updatedContacts.length > 0) {
          showSuccess(t`Processing has started. Please wait while the operation completes.`, 1000);
          updatedContacts.map(contact => {
            Dispatcher.dispatch({
              type: CONTACT_WAS_UPDATED_ACTION,
              payload: {
                contact: contact,
                appendedGroupIds: [updatedGroupId],
                removedGroupIds: [],
              }
            });
          });
        }
        showSuccess(t`Processed ${contactIds.length} contacts.\nGroup was added to ${updatedContacts.length} contacts.`, 4000);
        break;
      default:
        traceError(`apiAddContactsToGroup: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function removeContactsFromGroup(groupId, contactIds) {
  trace(`removeContactsFromGroup`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiRemoveContactsFromGroup(groupId, contactIds);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`apiRemoveContactsFromGroup: OK, payload=${JSON.stringify(payload)}`);
        const {[GROUP_ID_FLD]:updatedGroupId, [CONTACTS_FLD]:updatedContacts} = payload || [];
        if (updatedGroupId && updatedContacts && updatedContacts.length > 0) {
          showSuccess(t`Processing has started. Please wait while the operation completes.`, 1000);
          updatedContacts.map(contact => {
            Dispatcher.dispatch({
              type: CONTACT_WAS_UPDATED_ACTION,
              payload: {
                contact: contact,
                appendedGroupIds: [],
                removedGroupIds: [updatedGroupId],
              }
            });
          });
        }
        showSuccess(t`Processed ${contactIds.length} contacts.\nGroup was removed from ${updatedContacts.length} contacts.`, 4000);
        break;
      default:
        traceError(`apiRemoveContactsFromGroup: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
