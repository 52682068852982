// -------------------------------------------------------------------------------------------------
//  OneContactContainer.js
//  - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  - якщо контакт не знайдено в сторі і на сервері --> сторінка /404;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {createDirectChat} from 'actions/ChatActions';
import {fetchMyContacts} from 'actions/ContactActions';
import {fetchUsers} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const OneContact = Loader.create(() => import('./OneContact'));
const isVerbose = DEBUG && true;
const prefix = '- - - OneContactContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class OneContactContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, CrmStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const contactId = props.match.params.contactId;
    const contact = contactId ? CrmStore.getMyContact(props.match.params.contactId) : null;
    const {userId} = contact || {};
    const user = userId ? UserStore.getUser(userId) : null;
    return {isLoggedIn, myId, contact, user};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || !is(this.state.contact, nextState.contact)
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  // ToDo: (1) замість GROUP_TAB --> вкладку з пояснення як "подружитись" !!!
  async componentDidMount() {
    trace(`componentDidMount`);
    if (!CrmStore.areMyContactsLoaded()) {
      fetchMyContacts();
    }
    const {userId} = this.state.contact || {};
    if (userId && !UserStore.isUserLoaded(userId)) {
      await fetchUsers([userId]);
    }
    // CTX:
    const {isLoggedIn, myId, user} = this.state;
    if (isLoggedIn) {
      if (user && user.id !== myId) {
        let directChatId = user.directChatId;
        if (!directChatId) {
          directChatId = await createDirectChat(userId, 'ui:one-contact');
        }
        substituteSidebarContext({ctxId: user.id, ctxType: USER_CTX, ctxOwnerId: user.id});
        if (directChatId) {
          substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : CHAT_TAB);
          substituteSidebarChat({chatId: directChatId});
        }
      } else {
        substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : GROUP_TAB); // ToDo: (1)
        substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
        substituteSidebarChat({chatId: ''});
      }
    }
  }

  // ToDo: (1) замість GROUP_TAB --> вкладку з пояснення як "подружитись" !!!
  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    const {userId} = this.state.contact || {};
    if (userId && !UserStore.isUserLoaded(userId)) {
      await fetchUsers([userId]);
    }
    // CTX:
    const {isLoggedIn, myId, user} = this.state;
    if (isLoggedIn) {
      if (user && user.id !== myId) {
        const directChatId = user.directChatId;
        if (directChatId) {
          substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : CHAT_TAB);
          substituteSidebarContext({ctxId: user.id, ctxType: USER_CTX, ctxOwnerId: user.id});
          substituteSidebarChat({chatId: directChatId});
        } else {
          substituteSidebarTabMode(GROUP_TAB); // ToDo: (1)
          substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
          substituteSidebarChat({chatId: ''});
        }
      } else {
        substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : GROUP_TAB); // ToDo: (1)
        substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
        substituteSidebarChat({chatId: ''});
      }
    }
  }

  componentWillUnmount() {
    trace(`componentWillUnmount`);
    const {isLoggedIn} = this.state;
    // CTX:
    if (isLoggedIn) {
      substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : GROUP_TAB);
      substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
    }
  }

  render() {
    const {isLoggedIn, myId, contact, user} = this.state;
    if (!contact) {
      return null;
    }
    trace(`render`);
    return (
      <OneContact
        isLoggedIn={isLoggedIn}
        myId={myId}
        contact={contact}
        user={user}
      />
    );
  }
}

export default Container.create(OneContactContainer, {withProps: true, pure: false});
