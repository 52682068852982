// -------------------------------------------------------------------------------------------------
//  Head.js
//  - - - - - - - - - -
//  Функції та компоненти для модифікації head.
//
//  Attn:
//  - - -
//  - тег 'canonical' додавати ЛИШЕ в форми, які відкриті для індексування !!!
//  - попри те, що Head перевантажує мета-теги, але обовʼязково треба додавати "дефолтні" значення
//    в файл 'index.htmx'; інакше Facebook/Viber & ... не встигають завантажити значення
//    тегів og:image/og:title/og:description...
// -------------------------------------------------------------------------------------------------
// ToDo: OBSOLETE ???

import React from 'react';
import Types from 'prop-types';
import {Helmet} from 'react-helmet';
import {t} from 'ttag';
import 'assets/favicons/favicons';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Head extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  };

  render() {
    const pathname = this.context.router?.history?.location?.pathname;
    return (
      <Helmet>
        <title>Contentle</title>
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content={TWITTER_PAGE} />
        <meta name="twitter:image" content={`https://contentle.com/assets/images/contentle-knowledge-library.jpeg`} />
        <meta name="twitter:description" content="Contentle is a versatile tool designed to curate a wide range of content types (articles, docs, videos, images, bookmarks, etc.) into a personal and organized knowledge library." />
        <meta property="og:site_name" content="Contentle" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://contentle.com/assets/images/contentle-knowledge-library.jpeg`} />
        <meta property="og:title" content={t`Contentle is a versatile tool designed to curate a wide range of content types into a personal and organized knowledge library.`} />
        <meta property="og:description" content="Contentle is a versatile tool designed to curate a wide range of content types (articles, docs, videos, images, bookmarks, etc.) into a personal and organized knowledge library." />
        <meta name="description" content="Contentle is a versatile tool designed to curate a wide range of content types (articles, docs, videos, images, bookmarks, etc.) into a personal and organized knowledge library." />
        <meta name="keywords" content="collaboration education messenger knowledge library for teachers students social curation context favorites" />
        <meta property="og:url" content={BASE_URL + pathname} />
      </Helmet>
    );
  }
}
