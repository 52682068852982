// -------------------------------------------------------------------------------------------------
//  AuthAPI.js
//  - - - - - - - - - -
//  Authentication (login/logout/registration) APIs.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
// -------------------------------------------------------------------------------------------------
import Cookies from 'js-cookie';
import {
  AUTH_LOGOUT_CMD,
  AUTH_REGISTRATION_UPDATE_CMD,
  // AUTH_APPEND_SOCIAL_LOGIN_CMD,
  // AUTH_REMOVE_SOCIAL_LOGIN_CMD
} from 'core/apiTypes';
import {httpFetch} from 'api/HttpAPI';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiLogin() {
  const authToken = Cookies.get('x-auth-token');
  if (authToken) {
    localStorage.setItem('authToken', authToken);
    Cookies.remove('x-auth-token');
  }
  return authToken;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiLogout() {
  const response = await httpFetch(AUTH_LOGOUT_CMD, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: "{}",
  });
  // token is deleted unconditionally
  localStorage.removeItem('authToken');
  return response;
}

// FixMe: помилки валідації даних (порожні значення) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiRegistrationUpdate(data) {
  return await httpFetch(AUTH_REGISTRATION_UPDATE_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify(data),
  });
}

// // ToDo: IMPLEMENT !!!
// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiAppendSocialLogin(provider) {
//   const Uri = (AUTH_APPEND_SOCIAL_LOGIN_CMD).replace('{provider}', provider);
//   return await httpFetch(Uri, {
//     method: 'GET',
//     headers: {
//       'accept-language': 'en',
//       'content-type': 'application/json',
//       // 'access-control-allow-origin': `http://localhost:8180, http://localhost:8181`,
//       // 'access-control-allow-methods': 'GET, POST, OPTIONS',
//       // 'access-control-allow-headers': 'content-type, access-control-allow-headers, authorization, x-requested-with, x-auth-token, x-data',
//       // 'access-control-allow-credentials': 'false'
//     },
//   });
// }

// // ToDo: IMPLEMENT !!!
// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiRemoveSocialLogin(provider) {
//   const Uri = (AUTH_REMOVE_SOCIAL_LOGIN_CMD).replace('{provider}', provider);
//   return await httpFetch(Uri, {
//     method: 'POST',
//     headers: {
//       'accept-language': 'en',
//       'content-type': 'application/json'
//     },
//     body: JSON.stringify(data),
//   });
// }
