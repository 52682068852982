// -------------------------------------------------------------------------------------------------
//  ComponentLoader.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Spinner from 'components/UI/Spinner';
import styles from './ComponentLoader.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ComponentLoader extends React.Component {
  state = {
    component: null,
  }

  static create(getComponent) {
    return class extends ComponentLoader {
      componentDidMount() {
        getComponent().then((module) => {
          this.setState({component: module.default || module});
        });
      }
    }
  }

  render() {
    const {component: Component} = this.state;
    return Component ?
      <Component {...this.props} /> :
      <Spinner className={styles.ComponentLoader} />;
  }
}
