// -------------------------------------------------------------------------------------------------
//  CreateMediaMessage.js
//  - - - - - - - - - -
//  Attn: 1) error-system: clear error message on any change;
//  Attn: 2) тип чату зберігається в полі chat_type mnesia-таблиці непрочитаних повідомлень #unread_message);
// -------------------------------------------------------------------------------------------------
// ToDo: впорядкувати formError, richBodyError: показувати помилки прямо у формі, а не у confirmAction() !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {
  BODY_FLD,
  PICTURES_FLD,
  PICTURE_ID_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD} from 'core/apiFields';
import {addBodyClass, removeBodyClass, NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import {createChatMessage} from 'actions/ChatActions';
import {uploadPicture} from 'actions/MediaActions';
import {optimizeImage, previewSettings, isValidImageType} from 'api/MediaAPI';
import {confirmAction} from 'components/UI/ConfirmAction';
import {getErrorTitle, getErrorDescription} from 'utils/errors';
import Icon from 'components/UI/icons/Icon';
import PictButton from 'components/UI/buttons/PictButton';
import RichField, {toRich, isRichEmpty, toConmark, cleanifyConmark, isConmarkEmpty} from 'components/UI/fields/RichField';
import MediaFilePicker from 'components/UI/MediaFilePicker';
import styles from './CreateMediaMessage.scss';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - OneChat';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function createMediaMessage({chatId, chatType, richBody, handleSetFocus = null}) {
  pubsub.publish({
    chatId: chatId,
    chatType: chatType,
    richBody: richBody,
    handleSetFocus: handleSetFocus,
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class CreateMediaMessage extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      chatId: null,                       // чат в який створюємо меседж
      chatType: null,                     // тип чату = attn: 2)
      richBody: toRich(''),               // текст повідомлення в форматі Conmark
      richBodyError: '',                  // помилка, що відноситься до поля 'richBody'
      formError: '',                      // помилка, що відноситься до форми
      handleSetFocus: null,               // функція встановлення фокусу на елементі OneChat
      // - - - media
      media: null,                        // оптимізоване зображення ілюстрації
      mediaFileSize: 0,                   // розмір оптимізованого зображення (binary)
      mediaPercent: 0,                    // відсоток завантаження зображення на сервер
      isMediaOptimized: false,            // чи було зображення оптимізоване?
      // - - -
      isProcessing: false,
      isDisplayed: false,
    }
    this.localEditorRef = React.createRef(); // посилання на редактор повідомлення
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({chatId, chatType, richBody, handleSetFocus}) => {
      this.setState({
        chatId,
        chatType,
        richBody,
        handleSetFocus,
        isProcessing: false,
        isDisplayed: true
      });
      PreventBodyScroll();
      addBodyClass('no-submodal-esc');
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleRemoveNoEscape = () => {
    removeBodyClass('no-submodal-esc');
  }

  // Attn: 2) якщо швидко зняти флаг, то спрацює закриття форми SubmodalLevel !!!
  handleClose = () => {
    this.setState({
      media: null,
      mediaFileSize: 0,
      mediaPercent: 0,
      isMediaOptimized: false,
      isProcessing: false,
      isDisplayed: false,
    });
    RestoreBodyScroll();
    const {handleSetFocus} = this.state;
    handleSetFocus && handleSetFocus();
    setTimeout(this.handleRemoveNoEscape, 500); // sic!: 2)
  }

  handleKeyDown = (event, change, editor) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
    if (event.key === 'Enter'
      && event.altKey === false
      && event.ctrlKey === false
      && event.metaKey === false
      && event.shiftKey === false)
    {
      trace(`handleKeyDown: ENTER`);
      this.handleSendMessage();
    }
  }

  handleLocalSetFocus = () => {
    this.localEditorRef
    && this.localEditorRef.current
    && this.localEditorRef.current.focus
    && this.localEditorRef.current.focus();
  }

  checkBody = (value) => {
    const {media} = this.state;
    const conmarkBody = toConmark(value);
    let error = '';
    if (isConmarkEmpty(conmarkBody) && !media) {
      error = `Message shouldn't be empty without an image.`
    }
    return error;
  }

  isFormValid = () => {
    trace(`isFormValid`);
    const {richBody} = this.state;
    const richBodyError = this.checkBody(richBody);
    this.setState({
      richBodyError: richBodyError,
      formError: '',
    });
    return richBodyError === '';
  }

  handleBodyChange = ({value}) => {
    trace(`handleBodyChange`);
    this.setState({
      richBody: value,
      richBodyError: '', // sic!: 1)
      formError: '', // sic!: 1)
    });
  }

  handleSelectedPictureFile = async (file) => {
    trace(`handleSelectedPictureFile`);
    if (!file) return;
    const {name:fileName, type:fileType, size:mediaFileSize} = file;
    if(!isValidImageType(fileType)) {
      confirmAction({
        title: t`Unsupported File Format`,
        description: t`You can upload *.jpg, *.jpeg, *.png files only`,
      });
      return;
    }
    // attn: mediaFileSize can be different when user creates photo/video several times in a row !!!
    if (!this.state.isMediaOptimized || mediaFileSize !== this.state.mediaFileSize) {
      const mediaType = fileType.split('/')[0];
      if(mediaType === 'image') {
        this.setState({isProcessing: true});
        const mediaFile = await optimizeImage(file, previewSettings('illustration', fileType));
        const mediaUrl = URL.createObjectURL(mediaFile);
        this.setState({
          media: {file:mediaFile, type:mediaType, url:mediaUrl},
          mediaFileSize: mediaFileSize,
          isMediaOptimized: true,
          isProcessing: false,
        });
      }
    }
    this.handleLocalSetFocus();
  }

  handleDeletePictureFile = () => {
    trace(`handleDeletePictureFile`);
    this.setState({
      media: null,
      mediaFileSize: 0,
      mediaPercent: 0,
      isMediaOptimized: false,
      isProcessing: false,
    });
    this.handleLocalSetFocus();
  }

  handleSendMessage = async () => {
    trace(`handleSendMessage`);
    this.setState({isProcessing: true});
    if (this.isFormValid()) {
      const {chatId, chatType, richBody, media} = this.state;
      const nextMessage = {
        chatId: chatId,
        chatType: chatType,
        body: cleanifyConmark(toConmark(richBody))};
      const {file:mediaFile, url:mediaUrl} = media || {};
      if (mediaFile) {
        const {status, pictureId, pictureXhash2, pictureExt} = await uploadPicture(mediaFile, this.handleMediaProgress);
        if (status === 'ok' && pictureXhash2 && pictureExt) {
          nextMessage.attachments = {[PICTURES_FLD]: {[pictureXhash2]: {[PICTURE_EXT_FLD]: pictureExt}}}; // {{ ATTACHMENTS }}
        }
      }
      if (chatId && ((nextMessage.body && !isConmarkEmpty(nextMessage.body)) || nextMessage.attachments)) {
        const {status, errors} = await createChatMessage(nextMessage, mediaUrl);
        switch(status) {
          case 'ok':
            this.setState({ // clear previous value
              richBody: toRich(''),
              richBodyError: '',
              formError: '',
              media: null,
              mediaFileSize: 0,
              mediaPercent: 0,
              isMediaOptimized: false,
              isProcessing: false,
            });
            this.handleClose();
            break;
          default:
            const {[BODY_FLD]:richBodyError, formError = status} = errors;
            switch(formError) {
              case 'message_is_too_long':
                confirmAction({
                  title: getErrorTitle(formError),
                  description: t`The message can't be larger than 512 symbols.`,
                });
                break;
              case 'invalid_formdata':
                confirmAction({
                  title: getErrorTitle(formError),
                  description: getErrorDescription(formError),
                });
                break;
              default:
                traceError(`handleSendMessage: ERROR=${JSON.stringify(errors)}`);
                confirmAction({
                  title: getErrorTitle(formError),
                  description: getErrorDescription(formError),
                });
            }
            this.setState({
              richBodyError: getErrorTitle(richBodyError),
              formError: getErrorTitle(formError),
              isProcessing: false,
            });
        }
      } else {
        this.setState({
          formError: getErrorTitle('empty_form_data'),
          isProcessing: false
        });
      }
    } else {
      this.setState({isProcessing: false});
    }
  }

  render() {
    const {isDisplayed} = this.state;
    if (!isDisplayed) {
      return null;
    }
    const {chatId, richBody, richBodyError, formError, media, mediaPercent, isProcessing} = this.state;
    const {file:mediaFile, url:mediaUrl} = media || {};
    return (
      <div className={styles.backgroundWrapper} onClick={this.handleClose}>
        <div className={styles.CreateMediaMessage} onClick={e => e.stopPropagation()}>
          <h1>{t`Compose Media Message`}</h1>
          {mediaUrl &&
            <div className={styles.pictureHolder}>
              <img style={{backgroundImage: `url("${mediaUrl}")`}} />
            </div>
          }
          <div className={styles.controlPanel}>
            {!media &&
              <MediaFilePicker
                className={classnames(styles.mediaPicker, {[styles.hasPicture]: mediaUrl})}
                onFileSelected={this.handleSelectedPictureFile}>
                <Icon symbolName={isProcessing ? "loading" : "image"} className={styles.icon} />
              </MediaFilePicker>
            }
            {!!media &&
              <PictButton
                className={styles.deleteButton}
                iconClassName={styles.icon}
                symbolName="image-remove"
                isDisabled={isProcessing || !chatId}
                onClick={this.handleDeletePictureFile}
              />
            }
            <RichField
              className={styles.editorWrapper}
              editorClassName={styles.editor}
              inputRef={this.localEditorRef}
              value={richBody}
              error={''}
              placeholder={t`Enter text... (optional)`}
              autoFocus={true}
              hasToolbar={false}
              isDisabled={isProcessing || !chatId}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleBodyChange}
            />
            <PictButton
              className={styles.sendButton}
              iconClassName={styles.icon}
              symbolName="send-message"
              title="ENTER"
              isDisabled={isProcessing || !chatId}
              onClick={this.handleSendMessage}
            />
          </div>
        {/*
          {formError &&
            <section className={styles.formErrorsSection}>
              <div>{formError}</div>
            </section>
          }
        */}
        </div>
        <NoTabbar />
      </div>
    );
  }
}
