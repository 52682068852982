// -------------------------------------------------------------------------------------------------
//  UserAPI.js
//  - - - - - - - - - -
//  Users & friends APIs.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
// -------------------------------------------------------------------------------------------------
import {
  DESCRIPTION_FLD,
  EMAIL_FLD,
  EMAIL_ID_FLD,
  LANGUAGE_ID_FLD,
  SLUGS_FLD,
  USER_ID_FLD,
  USER_IDS_FLD,
  CONTACT_IDS_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_USERS_CMD,
  FETCH_USERS_AS_GUEST_CMD,
  FETCH_USERS_BY_SLUGS_CMD,
  FETCH_USERS_BY_SLUGS_AS_GUEST_CMD,
  FETCH_USER_PROFILES_CMD,
  FETCH_USER_PROFILES_AS_GUEST_CMD,
  FETCH_USER_PROFILES_BY_SLUGS_CMD,
  FETCH_USER_PROFILES_BY_SLUGS_AS_GUEST_CMD,
  FETCH_MY_FRIENDS_CMD,
  SEND_FRIENDSHIP_REQUEST_CMD,
  SEND_FRIENDSHIP_REQUEST_EMAIL_CMD,
  SEND_FRIENDSHIP_REQUEST_AGAIN_CMD,
  SEND_FRIENDSHIP_REQUEST_TO_CONTACTS_CMD,
  ACCEPT_FRIENDSHIP_REQUEST_CMD,
  REJECT_FRIENDSHIP_REQUEST_CMD,
  TERMINATE_FRIENDSHIP_REQUEST_CMD,
  CANCEL_FRIENDSHIP_CMD} from 'core/apiTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - UserAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUsers(ids) {
  trace(`apiFetchUsers: ids=${ids}`);
  return await wsockFetch({
    [CMD]: FETCH_USERS_CMD,
    [PAYLOAD]: {
      [USER_IDS_FLD]: ids
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUsersAsGuest(ids) {
  trace(`apiFetchUsersAsGuest: ids=${ids}`);
  return await httpFetch(FETCH_USERS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [USER_IDS_FLD]: ids,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUsersBySlugs(slugs) {
  trace(`apiFetchUsersBySlugs: slugs=${slugs}`);
  return await wsockFetch({
    [CMD]: FETCH_USERS_BY_SLUGS_CMD,
    [PAYLOAD]: {
      [SLUGS_FLD]: slugs
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUsersBySlugsAsGuest(slugs) {
  trace(`apiFetchUsersBySlugsAsGuest: slug=${slugs}`);
  return await httpFetch(FETCH_USERS_BY_SLUGS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [SLUGS_FLD]: slugs,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserProfiles(ids) {
  trace(`apiFetchUserProfiles: ids=${ids}`);
  return await wsockFetch({
    [CMD]: FETCH_USER_PROFILES_CMD,
    [PAYLOAD]: {
      [USER_IDS_FLD]: ids
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserProfilesAsGuest(ids) {
  trace(`apiFetchUserProfilesAsGuest: ids=${ids}`);
  return await httpFetch(FETCH_USER_PROFILES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [USER_IDS_FLD]: ids,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserProfilesBySlugs(slugs) {
  trace(`apiFetchUserProfilesBySlugs: slugs=${slugs}`);
  return await wsockFetch({
    [CMD]: FETCH_USER_PROFILES_BY_SLUGS_CMD,
    [PAYLOAD]: {
      [SLUGS_FLD]: slugs
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserProfilesBySlugsAsGuest(slugs) {
  trace(`apiFetchUserProfilesBySlugsAsGuest: slug=${slugs}`);
  return await httpFetch(FETCH_USER_PROFILES_BY_SLUGS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [SLUGS_FLD]: slugs,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyFriends() {
  trace(`apiFetchMyFriends`);
  return await wsockFetch({
    [CMD]: FETCH_MY_FRIENDS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendFriendshipRequest(id) {
  trace(`apiSendFriendshipRequest: id=${id}`);
  return await wsockFetch({
    [CMD]: SEND_FRIENDSHIP_REQUEST_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendFriendshipRequestByEmail(email, description, languageId) {
  trace(`apiSendFriendshipRequestByEmail: email=${email}`);
  return await wsockFetch({
    [CMD]: SEND_FRIENDSHIP_REQUEST_EMAIL_CMD,
    [PAYLOAD]: {
      [EMAIL_FLD]: email,
      [DESCRIPTION_FLD]: description || '',
      [LANGUAGE_ID_FLD]: languageId
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendFriendshipRequestAgain(id) {
  trace(`apiSendFriendshipRequestAgain: id=${id}`);
  return await wsockFetch({
    [CMD]: SEND_FRIENDSHIP_REQUEST_AGAIN_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendFriendshipRequestToContacts(contactIds, languageId) {
  trace(`apiSendFriendshipRequestToContacts`);
  return await wsockFetch({
    [CMD]: SEND_FRIENDSHIP_REQUEST_TO_CONTACTS_CMD,
    [PAYLOAD]: {
      [CONTACT_IDS_FLD]: contactIds,
      [LANGUAGE_ID_FLD]: languageId
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiAcceptFriendshipRequest(id) {
  trace(`apiAcceptFriendshipRequest: id=${id}`);
  return await wsockFetch({
    [CMD]: ACCEPT_FRIENDSHIP_REQUEST_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiRejectFriendshipRequest(id) {
  trace(`apiRejectFriendshipRequest: id=${id}`);
  return await wsockFetch({
    [CMD]: REJECT_FRIENDSHIP_REQUEST_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiTerminateFriendshipRequest(id) {
  trace(`apiTerminateFriendshipRequest: id=${id}`);
  return await wsockFetch({
    [CMD]: TERMINATE_FRIENDSHIP_REQUEST_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCancelFriendship(id) {
  trace(`apiCancelFriendship: id=${id}`);
  return await wsockFetch({
    [CMD]: CANCEL_FRIENDSHIP_CMD,
    [PAYLOAD]: {
      [USER_ID_FLD]: id
    }
  });
}
