// -------------------------------------------------------------------------------------------------
//  CloneStapleContainer.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchMyCollections} from 'actions/CollectionActions';
import {fetchStaple} from 'actions/StapleActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import Loader from 'utils/ComponentLoader';

const CloneStaple = Loader.create(() => import('./CloneStaple'));
const isVerbose = DEBUG && true;
const prefix = '- - - CloneStapleContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CloneStapleContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [CollectionStore, StapleStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myId = StapleStore.getMyId();
    const myAccount = AccountStore.getMyAccount();
    const myCollections = CollectionStore.getMyCollections();
    const staple = StapleStore.getStaple(props.match.params.stapleId);
    return {myId, myAccount, myCollections, staple};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || !is(this.state.staple, nextState.staple)
      || !is(this.state.myAccount, nextState.myAccount)
      || !is(this.state.myCollections, nextState.myCollections);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!CollectionStore.areMyCollectionsLoaded()) {
      fetchMyCollections();
    }
    if (!StapleStore.isStapleLoaded(this.props.match.params.stapleId)) {
      fetchStaple(this.props.match.params.stapleId);
    }
  }

  render() {
    trace(`render`);
    const {myId, myAccount, myCollections, staple} = this.state;
    const {accountType:myAccountType} = myAccount || {};
    if (!staple) {
      return null;
    }
    return (
      <CloneStaple
        myId={myId}
        myAccountType={myAccountType}
        myCollections={myCollections}
        staple={staple}
      />
    );
  }
}

export default Container.create(CloneStapleContainer, {withProps: true, pure: false});
