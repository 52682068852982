// -------------------------------------------------------------------------------------------------
//  SVG Icon
//  - - - - - - - - - -
//  2018-05-22 / 2022-10-28
//
//  Usage:
//  - - - - -
//  1.  WebPack config should have:
//      ...
//      { test: /\.(sprite|...)$/, use: 'html-loader' },
//
//  2.  Create icons sprite by Fontastic.me and place it as 'src/assets/icons/sprites/ccc-icons.sprite';
//
//  3.  Include in index.html:
//      ${require('./assets/icons/sprites/ccc-icons.sprite')}
//
//  4.  Add following classes to 'core/reset.scss':
//      [class^="common-icon-"], [class*=" common-icon-"] {
//        display: inline-block;
//        vertical-align: middle;
//      }
//
//  5.  Use imported component inside render as usual:
//
//      JS:
//      - - -
//      import Icon from 'components/UI/icons/Icon';
//      ...
//      <Icon symbolName="facebook" className={styles.icon} />
//      <Icon symbolName="facebook" className={styles.icon} styles={{ width: heartSize }} />
//
//      SCSS:
//      - - -
//      .closeButton {
//        width: $navbar-mobile-height;
//        border-left: 1px solid $second-divider-color;
//
//        .icon {
//          width: 32px;
//          height: 32px;
//        }
//
//        &:hover {
//          .icon {
//            fill: $marker-color;
//          }
//        }
//      }
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';

const symbolPrefix = 'common-icon';       // same prefix must be set in 'reset.scss' in SVG/Icons section !!!

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const Icon = ({symbolName, className, styles}) => (
  <svg className={`${symbolPrefix} ${symbolPrefix}-${symbolName} ${className}`} style={styles}>
    <use xlinkHref={`#${symbolPrefix}-${symbolName}`} />
  </svg>
);

Icon.propTypes = {
  symbolName: Types.string,               // the name of icon symbol from sprite
  className: Types.string,                // custom class name
  styles: Types.object,                   // custom styles (as object)
};

export default Icon;
