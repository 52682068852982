// -------------------------------------------------------------------------------------------------
//  PageNavbar.js
//  - - - - - - - - - -
//  Компонент використовується для задання поточних параметрів для компоненту RootNavbar.
//  При деконструкції компоненту видаляємо переданий набір параметрів.
// -------------------------------------------------------------------------------------------------

import React from 'react';
import {v1 as uuid} from 'uuid';
import RootNavbar from './RootNavbar';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class PageNavbar extends React.Component {
  componentDidMount() {
    this.id = uuid();
    RootNavbar.setProps(this.id, this.props);
  }

  componentDidUpdate() {
    RootNavbar.setProps(this.id, this.props);
  }

  componentWillUnmount() {
    RootNavbar.removeProps(this.id);
  }

  render() {
    return null;
  }
}
