// -------------------------------------------------------------------------------------------------
//  LayerSwitch
//  - - - - - - - - - -
//  Відображення компонент в різних шарах інтерфейсу в залежності від параметру 'layer'.
//  Зберігає локейшени попереднього рівня при переході на вищий рівень інтерфейсу,
//  відновлює локейшени при поверненні на попередній рівень інтерфейсу.
//  Попередній рівень визначається по заповненості змінних prevBaseLocation та prevSubmodalLocation.
//  Якщо prevSubmodalLocation має значення, то повернення буде на цей рівень. Інакше на базовий.
//  Значення зберігаються в localStorage у змінних PREV_BASE_LOCATION та PREV_SUBMODAL_LOCATION.
//
//  Існуючі рівні:
//  - modal layer     (2) = модальний рівень
//  - submodal layer  (1) = субмодальний рівень
//  - base layer      (0) = основний рівень
//
//  Notes:
//  - - - - -
//  - при стрибку з базового рівня на модальний потрібно затирати prevSubmodalLocation;
//  - localStorage потрібен для повернення після перевантаження сторінки (прямі лінки в Markdown);
//
//  Attn:
//  - - - - -
//  - для URL-адрес, що містяь '/auth', '/add', '/create', '/import', '/feedback' в історії робимо replace замість push;
//  - URL-адреси, що відмічені як 'trackless' не зберігаємо в локалстор (зручно для переадресації);
//  - в prevBaseLocation для ЗАЛОГІНЕНИХ юзерів не зберігаємо адреси '/', '/:userSlug'
//    по яким є переадресація в компонентах SocialRedirectPage / UserRedirectPage;
//    a) (this.props.location.pathname === '/') --> redirected in SocialRedirectPage !!!
//    b) (this.props.location.pathname === myRootUrl) --> redirected in UserRedirectPage !!!
//  - функція scrollWrapperToTop використовується для позиціонування враппера на початок;
//  - кнопку PlusButton розміщуємо між елементами ScrollWrapper щоб вона була розміщена
//    ближче до кореня в dom-дереві, аніж класи що візуально повинні її перекривати;
//    це тому, що в мобільних браузерах порядок приорітетніше аніж z-index;
//      <ScrollWrapper>...</ScrollWrapper>
//      <PlusButton />
//      <ScrollWrapper>...</ScrollWrapper>
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import {Switch, matchPath, withRouter} from 'react-router';
import Types from 'prop-types';
import {toggleSidebar} from 'actions/LayoutActions';
import {LS_PREV_BASE_LOCATION, LS_PREV_SUBMODAL_LOCATION} from 'core/commonTypes';
import PlusButton from 'components/UI/buttons/PlusButton';
import AdaptiveWrapper from 'components/UI/wrappers/AdaptiveWrapper';
import ScrollWrapper from 'components/UI/wrappers/ScrollWrapper';
import ModalLayer from 'application/ModalLayer';
import SubmodalLayer from 'application/SubmodalLayer';

export const MODAL = 'modal';             // don't change this value (it used as literal in App.js) !!!
export const SUBMODAL = 'submodal';       // don't change this value (it used as literal in App.js) !!!

const isVerbose = DEBUG && true;
const prefix = '- - - LayerSwitch';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let scrollWrapperRef = React.createRef();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function scrollWrapperToTop() {
  if (scrollWrapperRef && scrollWrapperRef.current && scrollWrapperRef.current.scrollTop) {
    scrollWrapperRef.current.scrollTop = 0;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class LayerSwitch extends React.Component {
  static propTypes = {
    mySlug: Types.string,                 // slug користувача
    children: Types.node,                 // дочірні компоненти
    location: Types.object,               // поточний url
    history: Types.object,                // історія браузера
    isMobile: Types.bool,                 // is an app running in mobile layout?
  }

  prevBaseLocation = JSON.parse(localStorage.getItem(LS_PREV_BASE_LOCATION)) || { pathname: '/' }; // sic!: дефолтна адреса = / !!!
  prevSubmodalLocation = JSON.parse(localStorage.getItem(LS_PREV_SUBMODAL_LOCATION)) || null; // sic!

  state = {
    route: this.getCurrentRoute(this.props)
  }

  getCurrentRoute({location, children}) {
    // - - - - -
    // після login/logout змінюється список children,
    // і в новому списку children немає попереднього location (напр: /auth/login/success),
    // тому this.state.route буде null
    // - - - - -
    return React.Children.toArray(children)
      .filter(React.isValidElement)
      .reduce((current, route) => {
        if (current) {
          return current;
        }
        const {path, from, exact, strict} = route.props;
        const matchRoute = { path: path || from, exact, strict };
        return matchPath(location.pathname, matchRoute) ? route : null;
      }, null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({route: this.getCurrentRoute(nextProps) });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.location !== nextProps.location
      || this.props.isMobile !== nextProps.isMobile
      || !this.state.route || this.state.route.props.layer !== nextState.route.props.layer;
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // sic!: перевірка на route обовʼязкова (кейс: login/logout)
    if (this.state.route && nextProps.location !== this.props.location) {
      // don't memorize modal locations in any case
      if (this.state.route.props.layer !== MODAL) {
        // don't memorize submodal locations into prevBaseLocation
        if (this.state.route.props.layer !== SUBMODAL) {
          // зберігаємо тільки track-роути
          if (!this.state.route.props.trackless) {
            // в prevBaseLocation для ЗАЛОГІНЕНИХ юзерів не зберігаємо адреси '/', '/:userSlug'
            // по яким є переадресація в компонентах SocialRedirectPage / UserRedirectPage;
            // a) (this.props.location.pathname === '/') --> redirected in SocialRedirectPage !!!
            // b) (this.props.location.pathname === myRootUrl) --> redirected in UserRedirectPage !!!
            const myRootUrl = `/${this.props.mySlug}`;
            const isLoggedIn = this.props.mySlug;
            if (!(isLoggedIn && (this.props.location.pathname === '/' ||
                                 this.props.location.pathname === myRootUrl)))
            {
              this.prevBaseLocation = this.props.location;
              localStorage.setItem(LS_PREV_BASE_LOCATION, JSON.stringify(this.props.location));
            }
            // ATTENTION: clear submodal if jumped from base layer (neither MODAL nor SUBMODAL)
            this.prevSubmodalLocation = null;
            localStorage.setItem(LS_PREV_SUBMODAL_LOCATION, JSON.stringify(this.prevSubmodalLocation));
          }
        } else {
          // memorize submodal location into prevSubmodalLocation
          this.prevSubmodalLocation = this.props.location;
          localStorage.setItem(LS_PREV_SUBMODAL_LOCATION, JSON.stringify(this.props.location));
        }
      }
    }
  }

  gotoPrevLocation = () => {
    // sic!: перевірка на route обовʼязкова (кейс: login/logout)
    if (this.state.route) {
      const {isMobile} = this.props;
      const {layer, path: currentPath} = this.state.route.props;
      const baseLoc = this.prevBaseLocation;
      const subLoc = this.prevSubmodalLocation;
      // sic!: в мобільний сайдбар накриває інші layers, тому при закритті треба його згорнути
      if (isMobile) {
        toggleSidebar(false);
      }
      // ToDo: при натисканні CLOSE повертати на попередню адресу НАВІТЬ то ж само рівня (заважає: || layer === SUBMODAL) !!!
      const prevLocation = !subLoc || (baseLoc && subLoc && (baseLoc.pathname === subLoc.pathname || layer === SUBMODAL)) ?
        this.prevBaseLocation :
        this.prevSubmodalLocation;
      const {pathname, search = '', hash = '', state} = prevLocation;
      const path = `${pathname}${search}${hash}`;
      // attn: критичні адреси НЕ зберігаємо в history !!!
      if (currentPath.search(/\/auth/) === 0
        || currentPath.search(/\/add/) >= 0
        || currentPath.search(/\/edit/) >= 0
        || currentPath.search(/\/clone/) >= 0
        || currentPath.search(/\/create/) >= 0
        || currentPath.search(/\/feedback/) >= 0
        || currentPath.search(/\/import/) === 0)
      {
        this.props.history.replace(path, state);
      } else {
        this.props.history.push(path.search(/^\/i\/[\dA-z]{12}$/) === 0 ? '/' : path, state); // FixMe: патч для старих url виду '/i/1qCld2xhqa01' !!!
      }
    } else {
      this.props.history.replace(`/`);
    }
  }

  render() {
    const {children, location, isMobile} = this.props;
    const {route} = this.state;
    const isModal = route && route.props.layer === MODAL;
    const isSubmodal = route && route.props.layer === SUBMODAL;
    const baseLocation = isModal || isSubmodal ? this.prevBaseLocation : location;
    const submodalLocation = isSubmodal ? location : isModal ? this.prevSubmodalLocation : null;
    trace(`render:`, baseLocation, submodalLocation, location);
    return (
      <F>
        <ScrollWrapper reference={scrollWrapperRef}>
          <AdaptiveWrapper>
            <Switch location={baseLocation}>
              {children}
            </Switch>
          </AdaptiveWrapper>
        </ScrollWrapper>
        {/* - - - entry point: inner area - - - */}
        <PlusButton />
        {(isModal || isSubmodal) && !!submodalLocation &&
          <ScrollWrapper>
            <SubmodalLayer isMobile={isMobile} onClose={this.gotoPrevLocation}>
              <Switch location={submodalLocation}>
                {children}
              </Switch>
            </SubmodalLayer>
          </ScrollWrapper>
        }
        {isModal &&
          <ModalLayer onClose={this.gotoPrevLocation}>
            <Switch location={location}>
              {children}
            </Switch>
          </ModalLayer>
        }
      </F>
    );
  }
}

export default withRouter(LayerSwitch);
