// -------------------------------------------------------------------------------------------------
//  autoreplace/index.js
//  - - - - - - - - - -
//
//  Inspired By:
//  https://github.com/Canner/slate-md-editor
//  https://github.com/PrismJS/prism/blob/gh-pages/components/prism-markdown.js
// -------------------------------------------------------------------------------------------------
// ToDo: renderImage
// ToDo: додати перевірку контексту isBlockquote (не всі функції працюють у ньому) !!!

import EditCodePlugin from 'slate-edit-code';
import {defaultCodeBlockOptions} from 'components/RichEditor/core/rich-codeblock';
import {isInTableContext} from 'components/RichEditor/core/utils';
import autoreplaceBold from './handlers/bold';
import autoreplaceBoldItalic from './handlers/bold-italic';
import autoreplaceItalic from './handlers/italic';
import autoreplaceStrike from './handlers/strike';
import autoreplaceCodeBlock from './handlers/codeblock';
import autoreplaceInlineCode from './handlers/inlinecode';
import autoreplaceBlockquote from './handlers/blockquote';
import autoreplaceParagraph from './handlers/paragraph';
import autoreplaceHeading from './handlers/heading';
import autoreplaceHorizontal from './handlers/hr';
import autoreplaceTable from './handlers/table';
import autoreplaceList from './handlers/list';
import autoreplaceLink from './handlers/link';
import autoreplaceImage from './handlers/image';

const KEY_ENTER = 'Enter';
const KEY_SPACE = ' ';

const {isInCodeBlock} = EditCodePlugin(defaultCodeBlockOptions).utils;

const checkPatterns = function(change) {
  const {value} = change;
  const {texts} = value;
  const node = texts.get(0);
  const currentLine = node.text;

  let matched;

  // ignore matched patterns inside code block
  if (isInCodeBlock(value)) {
    return;
  }

  const isOutOfTable = !isInTableContext(change);

  // a) - - - - - - - - - -

  // [codeblock]:
  // ```lang
  if (isOutOfTable && (matched = currentLine.match(/^\s*```(\w+)?\s/m))) {
    return autoreplaceCodeBlock(node, matched, change, matched[1]);

  // // prefixed by 4 spaces or 1 tab
  // } else if (isOutOfTable && (matched = currentLine.match(/^(?: {4}|\t)/m))) {
  //   return autoreplaceCodeBlock(node, matched, change);

  // [blockquote]:
  // > ...
  } else if (isOutOfTable && (matched = currentLine.match(/^>\s/m))) {
    return autoreplaceBlockquote(node, matched, change);

  // [unordered list]:
  // * item
  // + item
  // - item
  } else if (isOutOfTable && (matched = currentLine.match(/((?:^\s*)(?:[*+-]\s))/m))) {
    return autoreplaceList(node, matched, change, false);

  // [ordered list]:
  // 1. item
  } else if (isOutOfTable && (matched = currentLine.match(/((?:^\s*)(?:\d+\.\s))/m))) {
    return autoreplaceList(node, matched, change, true);

  // [table]:
  // '--|--|--...|--[SPACE]' --> Table(cols, 2)
  } else if (isOutOfTable && (matched = currentLine.match(/(^\s*)(-{2,6}\|){1,20}(-{2,6})\s/m))) {
    return autoreplaceTable(node, matched, change);

  // [horizontal ruler]:
  //  a) {`***` | `---` | `* * *` | `-----------`}[SPACE] ---> <hr>
  //  b) ...[SPACE]{`***` | `---` | `* * *` | `-----------`}[SPACE] ---> ...' ―'
  } else if (isOutOfTable && (matched = currentLine.match(/(\s*)([*-])(?:[\t ]*\2){2,}/m))) {
    return autoreplaceHorizontal(node, matched, change);

  // [headings]:
  // # h1
  // ## h2
  // ### h3
  // #### h4
  // ##### h5
  // ###### h6
  } else if (isOutOfTable && (matched = currentLine.match(/(^\s*)[#№]{1,6}\s/m))) {
    return autoreplaceHeading(node, matched, change);
  }

  // b) - - - - - - - - - -

  const offsetBeforeSpace = value.selection.anchorOffset - 2;
  const lastChar = currentLine.charAt(offsetBeforeSpace);
  const prevTextFromSpace = currentLine.substr(0, offsetBeforeSpace + 1);

  // [inlinecode]: `inlinecode`
  if ((matched = lastChar === '`' && prevTextFromSpace.match(/`([^`\t\v\b]+)`/m))) {
    return autoreplaceInlineCode(node, matched, change);

  // [link]: [example](http://example.com "Optional title")
  } else if ((matched = currentLine.match(/\[([^\]]+)\]\(([^\s)]+(?:[\t ]+"(?:\\.|[^"\\])*")?)\)/))) {
    return autoreplaceLink(node, matched, change);

  // [image]: ![example](http://example.com "Optional title")
  } else if (isOutOfTable && (matched = currentLine.match(/!\[([^\]]+)\]\(([^\s)]+(?:[\t ]+"(?:\\.|[^"\\])*")?)\)/))) {
    return autoreplaceImage(node, matched, change);
  }

  // c) - - - - - - - - - -

  if (lastChar === '*' || lastChar === '_' || lastChar === '~') {
    // [bold + italic]: ***[strong + italic]***, ___[strong + italic]___
    if ((matched = prevTextFromSpace.match(/\s?(\*\*\*|___)((?!\1).)+?\1$/m))) {
      return autoreplaceBoldItalic(node, matched, change);

    // [bold]: **strong**, __strong__
    } else if ((matched = prevTextFromSpace.match(/\s?(\*\*|__)((?!\1).)+?\1$/m))) {
      return autoreplaceBold(node, matched, change);

    // [italic]: _em_, *em*
    } else if ((matched = prevTextFromSpace.match(/\s?(\*|_)((?!\1).)+?\1$/m))) {
      return autoreplaceItalic(node, matched, change);

    // [strike]: ~strike~
    } else if ((matched = prevTextFromSpace.match(/\s?(~)((?!\1).)+?\1$/m))) {
        return autoreplaceStrike(node, matched, change);
    }
  }
};

const AutoReplacePlugin = (opts = {}) => {
  return {
    onKeyDown: (event, change) => {
      switch (event.key) {
        case KEY_ENTER:
          return autoreplaceParagraph(change);
      }
    },
    onKeyUp: (event, change) => {
      switch (event.key) {
        case KEY_SPACE:
          return checkPatterns(change);
      }
    },
  };
};

export default AutoReplacePlugin;
