// -------------------------------------------------------------------------------------------------
//  CollectionActions.js
//  - - - - - - - - - -
//  Collection & MyCollection actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' та робимо обробку catch-помилок;
//  - інформація про помилку міститься в status та errors обʼєкту response;
//  - в полі errors.formError повертаємо додаткову інформацію про помилки;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
//
//  Attn: a)
//  - - - - -
//  -   підписка CSubscription є надмножиною над Collection;
//  -   в базі даних унікальність пари [collection_id, user_id] гарантується унікальним індексом;
//  -   на фронтенді клас Collection просто розширений полями підписки (див. формат {{ CSUBSCRIPTION }});
//  -   тому вся робота з підписками здійснюється НЕ через csubscriptionId, а через collectionId !!!
// -------------------------------------------------------------------------------------------------
// import ReactGA from 'react-ga4';
import {t} from 'ttag';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  STAPLE_WAS_UPDATED_ACTION,
  DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION,
  FEATURED_COLLECTIONS_WERE_FETCHED_ACTION,
  SUBJECT_COLLECTIONS_WERE_FETCHED_ACTION,
  SEARCHED_COLLECTIONS_WERE_FETCHED_ACTION,
  USER_COLLECTIONS_WERE_FETCHED_AS_GUEST_ACTION,
  USER_COLLECTIONS_WERE_FETCHED_ACTION,
  MY_CSUBSCRIPTIONS_WERE_FETCHED_ACTION,
  MY_COLLECTIONS_WERE_FETCHED_ACTION,
  COLLECTION_WAS_FETCHED_AS_GUEST_ACTION,
  COLLECTION_WAS_FETCHED_ACTION,
  COLLECTION_WAS_CREATED_ACTION,
  COLLECTION_WAS_UPDATED_ACTION,
  COLLECTION_WAS_BANNED_ACTION,
  COLLECTION_WAS_DELETED_ACTION,
  COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION,
  COLLECTION_WAS_FOLLOWED_ACTION,
  COLLECTION_WAS_UNFOLLOWED_ACTION,
  FOLLOWED_COLLECTION_IDS_WERE_FETCHED_ACTION,
  CSUBSCRIBED_COLLECTION_IDS_WERE_FETCHED_ACTION,
  CSUBSCRIPTION_WAS_FETCHED_ACTION,
  CSUBSCRIPTION_WAS_CREATED_ACTION,
  CSUBSCRIPTION_WAS_CANCELED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  OWNER_ID_FLD,
  STAPLE_ID_FLD,
  STAPLES_FLD,
  STAPLEDATAS_FLD,
  COLLECTION_FLD,
  COLLECTION_ID_FLD,
  COLLECTIONS_FLD,
  CSUBSCRIPTION_FLD,
  CSUBSCRIPTIONS_FLD,
  MASTER_COLLECTION_IDS_FLD,
  CSUBSCRIBED_COLLECTION_IDS_FLD,
  USER_ID_FLD,
  USERS_FLD,
  BLOB_FLD,
  BLOBS_FLD,
  POSITION2_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR, TOPMOST_TSN12_CURSOR} from 'core/commonTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchDiscoverCollectionsAsGuest,
  apiFetchDiscoverCollections,
  apiFetchSubjectCollections,
  apiFetchUserCollectionsAsGuest,
  apiFetchUserCollections,
  apiFetchMyCSubscriptions,
  apiFetchMyCollections,
  apiFetchCollectionAsGuest,
  apiFetchCollection,
  apiCreateCollection,
  apiUpdateCollection,
  apiBanCollection,
  apiDeleteCollection,
  apiAddStaplesToCollection,
  apiRemoveStaplesFromCollection,
  apiUpdateCollectionStapleDatas,
  apiFollowCollection,
  apiUnfollowCollection,
  apiFetchFollowedCollectionIds,
  apiFetchCSubscribedCollectionIds,
  apiFetchCSubscription,
  apiCreateCSubscription,
  apiCancelCSubscription} from 'api/CollectionAPI';
import {showSuccess} from 'components/UI/Informer';

const COLLS_PER_REQUEST_LIMIT = 12;       // типова к-сть колекцій за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - CollectionActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let areMyCollectionsFetched = false;      // чи викликали завантаження списку колекцій?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverCollectionsAsGuest(opts = {}) {
  trace(`fetchDiscoverCollectionsAsGuest`);
  const {
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchDiscoverCollectionsAsGuest({type, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COLLECTIONS_FLD]:collections, [USERS_FLD]:collectionOwners} = payload;
      if (collections) {
        const areLoaded = collections.length < limit;
        const nextCursor = collections.length > 0 ?
          collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION,
          payload: {
            type: type,
            users:collectionOwners,       // attn: відразу отримуємо власників курсів !!!
            collections: collections,
            collectionCursor: nextCursor,
            areCollectionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverCollectionsAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverCollections(opts = {}) {
  trace(`fetchDiscoverCollections`);
  const {
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchDiscoverCollections({type, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COLLECTIONS_FLD]:collections} = payload;
      if (collections) {
        const areLoaded = collections.length < limit;
        const nextCursor = collections.length > 0 ?
          collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION,
          payload: {
            type: type,
            collections: collections,
            collectionCursor: nextCursor,
            areCollectionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverCollections: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSubjectCollections(opts = {}) {
  trace(`fetchSubjectCollections`);
  const {
    type,                                 // тип колекції (default, course, ...)
    subjectId,                            // код предмета
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchSubjectCollections({type, subjectId, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COLLECTIONS_FLD]:collections} = payload;
      if (collections) {
        const areLoaded = collections.length < limit;
        const nextCursor = collections.length > 0 ?
          collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: SUBJECT_COLLECTIONS_WERE_FETCHED_ACTION,
          payload: {
            type: type,
            subjectId: subjectId,
            collections: collections,
            collectionCursor: nextCursor,
            areCollectionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchSubjectCollections: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserCollectionsAsGuest(opts = {}) {
  trace(`fetchUserCollectionsAsGuest`);
  const {
    userId,                               // код юзера, по якому запитуємо колекції
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchUserCollectionsAsGuest({userId, type, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COLLECTIONS_FLD]:collections} = payload;
      if (collections) {
        const areLoaded = collections.length < limit;
        const nextCursor = collections.length > 0 ?
          collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: USER_COLLECTIONS_WERE_FETCHED_AS_GUEST_ACTION,
          payload: {
            userId: userId,
            type: type,
            collections: collections,
            collectionCursor: nextCursor,
            areCollectionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserCollectionsAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserCollections(opts = {}) {
  trace(`fetchUserCollections`);
  const {
    userId,                               // код юзера, по якому запитуємо колекції
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchUserCollections({userId, type, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COLLECTIONS_FLD]:collections} = payload;
      if (collections) {
        const areLoaded = collections.length < limit;
        const nextCursor = collections.length > 0 ?
          collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: USER_COLLECTIONS_WERE_FETCHED_ACTION,
          payload: {
            userId: userId,
            type: type,
            collections: collections,
            collectionCursor: nextCursor,
            areCollectionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserCollections: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyCSubscriptions(opts = {}) {
  trace(`fetchMyCSubscriptions`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchMyCSubscriptions({cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[CSUBSCRIPTIONS_FLD]:csubscriptions} = payload;
      if (csubscriptions) {
        const areLoaded = csubscriptions.length < limit;
        const nextCursor = csubscriptions.length > 0 ?
          csubscriptions.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: MY_CSUBSCRIPTIONS_WERE_FETCHED_ACTION,
          payload: {
            csubscriptions: csubscriptions,
            csubscriptionCursor: nextCursor,
            areCSubscriptionsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchMyCSubscriptions: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyCollections(isForced = false) {
  trace(`fetchMyCollections`);
  const cursor = DEFAULT_TSN12_CURSOR;          // значення курсора для фільтрації обʼєктів

  const fetcher = async () => {
    let resp;
    if (!areMyCollectionsFetched || isForced) {
      try {
        areMyCollectionsFetched = true;
        resp = await apiFetchMyCollections();
      } catch(error) {
        areMyCollectionsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        // attn: акаунт ініціалізується ПІСЛЯ виклику my.collections.get, тому треба параметр myId
        const {[USER_ID_FLD]:myId, [COLLECTIONS_FLD]:collections} = payload;
        if (collections) {
          const areLoaded = true; // sic!: тому що для власних колекцій поки немає пагінації
          const nextCursor = collections.length > 0 ?
            collections.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
            cursor; // sic!: 'i' = external, 'id' = inner !!!
          Dispatcher.dispatch({
            type: MY_COLLECTIONS_WERE_FETCHED_ACTION,
            payload: {
              myId: myId,
              collections: collections,
              collectionCursor: nextCursor,
              areCollectionsLoaded: areLoaded,
            }
          });
        }
      } else {
        traceError(`fetchMyCollections: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCollectionAsGuest(id) {
  trace(`fetchCollectionAsGuest: collectionId=${id}`);
  let status;
  let payload;
  let errors = {};
  let ownerId;
  try {
    const response = await apiFetchCollectionAsGuest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchCollectionAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_FLD]:collection} = payload;
        if (collection) {
          ({[OWNER_ID_FLD]:ownerId} = collection);
          Dispatcher.dispatch({
            type: COLLECTION_WAS_FETCHED_AS_GUEST_ACTION,
            payload: {
              collection: collection,
            }
          });
        }
        break;
      default:
        traceError(`fetchCollectionAsGuest: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors, ownerId}; // sic!: для прискорення отримання власника колекції !!!
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCollection(id) {
  trace(`fetchCollection: collectionId=${id}`);
  let status;
  let payload;
  let errors = {};
  let ownerId;
  try {
    const response = await apiFetchCollection(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_FLD]:collection} = payload;
        if (collection) {
          ({[OWNER_ID_FLD]:ownerId} = collection);
          Dispatcher.dispatch({
            type: COLLECTION_WAS_FETCHED_ACTION,
            payload: {
              collection: collection,
            }
          });
        }
        break;
      default:
        traceError(`fetchCollection: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors, ownerId}; // sic!: для прискорення отримання власника колекції !!!
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createCollection(nextCollection, pictureBlobUrl) {
  trace(`createCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateCollection(nextCollection);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_FLD]:createdCollection} = payload;
        if (createdCollection) {
          // attn: превьюшка генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
          if (pictureBlobUrl) {
            Object.assign(createdCollection, {[BLOB_FLD]: pictureBlobUrl});
          }
          Dispatcher.dispatch({
            type: COLLECTION_WAS_CREATED_ACTION,
            payload: {
              collection: createdCollection,
            }
          });
          // ReactGA.event({
          //   category: gaCategories.KNOWLEDGE,
          //   action: gaActions.COLLECTION_CREATED,
          //   label: 'ui:form'
          // });
        }
        break;
      case 'invalid_formdata':
        traceError(`createCollection: status=${status}`);
        break
      default:
        traceError(`createCollection: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateCollection(collection, patchObject, pictureBlobUrl) {
  trace(`updateCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateCollection(collection, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_FLD]:updatedCollection} = payload;
        if (updatedCollection) {
          // attn: превьюшка генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
          if (pictureBlobUrl) {
            Object.assign(updatedCollection, {[BLOB_FLD]: pictureBlobUrl});
          }
          Dispatcher.dispatch({
            type: COLLECTION_WAS_UPDATED_ACTION,
            payload: {
              prevCollection: collection,
              collection: updatedCollection,
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`updateCollection: status=${status}`);
        break
      default:
        traceError(`updateCollection: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function banCollection(collection) {
  trace(`banCollection`);
  let status;
  let payload;
  let errors = {};
  const {id, type} = collection;
  try {
    const response = await apiBanCollection(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`banCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_ID_FLD]:collectionId} = payload || [];
        if (collectionId) {
          Dispatcher.dispatch({
            type: COLLECTION_WAS_BANNED_ACTION,
            payload: {
              collectionId: collectionId,
              type: type,
            }
          });
        }
        break;
      default:
        traceError(`banCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteCollection(collection) {
  trace(`deleteCollection`);
  let status;
  let payload;
  let errors = {};
  const {id} = collection;
  try {
    const response = await apiDeleteCollection(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);

    // - - - - -
    // FixMe: 'forbidden' при видаленні вже видаленої колекції !!!
    // видає 'forbidden' при спробі видалити колекцію, яка була видалена в іншій вкладці;
    // потрібно обробити ситуацію - напр. видалити цю колекцію із списку ???
    // - - - - -
    switch(status) {
      case 'ok':
        trace(`deleteCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_ID_FLD]:collectionId} = payload;
        if (collectionId) {
          Dispatcher.dispatch({
            type: COLLECTION_WAS_DELETED_ACTION,
            payload: {
              collectionId: collectionId,
            }
          });
          // ReactGA.event({
          //   category: gaCategories.KNOWLEDGE,
          //   action: gaActions.COLLECTION_DELETED,
          //   label: collectionId
          // });
        }
        break;
      default:
        traceError(`deleteCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function addStaplesToCollection(collId, stapleIds) {
  trace(`addStaplesToCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiAddStaplesToCollection(collId, stapleIds);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`apiAddStaplesToCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_ID_FLD]:updatedCollId, [STAPLES_FLD]:updatedStaples} = payload || [];
        if (updatedCollId && updatedStaples && updatedStaples.length > 0) {
          showSuccess(t`Processing has started. Please wait while the operation completes.`, 1000);
          updatedStaples.map(staple => {
            const {[ID_FLD]:stapleId, [POSITION2_FLD]:position2} = staple;
            Dispatcher.dispatch({
              type: STAPLE_WAS_UPDATED_ACTION,
              payload: {
                staple: staple,
                stapleDatas: [{[STAPLE_ID_FLD]:stapleId, [COLLECTION_ID_FLD]:updatedCollId, [POSITION2_FLD]:position2}],
                addedCollectionIds: [updatedCollId],
                removedCollectionIds: [],
              }
            });
          });
        }
        showSuccess(t`Processed ${stapleIds.length} staples.\nCollection was added to ${updatedStaples.length} staples.`, 4000);
        break;
      default:
        traceError(`apiAddStaplesToCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function removeStaplesFromCollection(collId, stapleIds) {
  trace(`removeStaplesFromCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiRemoveStaplesFromCollection(collId, stapleIds);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`apiRemoveStaplesFromCollection: OK, payload=${JSON.stringify(payload)}`);
        const {[COLLECTION_ID_FLD]:updatedCollId, [STAPLES_FLD]:updatedStaples} = payload || [];
        if (updatedCollId && updatedStaples && updatedStaples.length > 0) {
          showSuccess(t`Processing has started. Please wait while the operation completes.`, 1000);
          updatedStaples.map(staple => {
            Dispatcher.dispatch({
              type: STAPLE_WAS_UPDATED_ACTION,
              payload: {
                staple: staple,
                stapleDatas: [],
                addedCollectionIds: [],
                removedCollectionIds: [updatedCollId],
              }
            });
          });
        }
        showSuccess(t`Processed ${stapleIds.length} staples.\nCollection was removed from ${updatedStaples.length} staples.`, 4000);
        break;
      default:
        traceError(`apiRemoveStaplesFromCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateCollectionStaplesDatas(collId, stapleDatas) {
  trace(`updateCollectionStaplesDatas`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateCollectionStapleDatas(collId, stapleDatas);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`apiUpdateCollectionStapleDatas: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLEDATAS_FLD]:updatedStapleDatas} = payload || [];
        if (updatedStapleDatas && updatedStapleDatas.length > 0) {
          Dispatcher.dispatch({
            type: COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION,
            payload: payload
          });
        }
        break;
      default:
        traceError(`apiUpdateCollectionStapleDatas: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function followCollection(id) {
  trace(`followCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFollowCollection(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`followCollection: OK, payload=${JSON.stringify(payload)}`);
        Dispatcher.dispatch({
          type: COLLECTION_WAS_FOLLOWED_ACTION,
          payload: {
            collectionId: id,
          }
        });
        // ReactGA.event({
        //   category: gaCategories.SOCIAL,
        //   action: gaActions.COLLECTION_FOLLOWED
        // });
        break;
      default:
        traceError(`followCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function unfollowCollection(id) {
  trace(`unfollowCollection`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUnfollowCollection(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`unfollowCollection: OK, payload=${JSON.stringify(payload)}`);
        Dispatcher.dispatch({
          type: COLLECTION_WAS_UNFOLLOWED_ACTION,
          payload: {
            collectionId: id,
          }
        });
        // ReactGA.event({
        //   category: gaCategories.SOCIAL,
        //   action: gaActions.COLLECTION_UNFOLLOWED
        // });
        break;
      default:
        traceError(`unfollowCollection: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchFollowedCollectionIds() {
  trace(`fetchFollowedCollectionIds`);
  try {
    const response = await apiFetchFollowedCollectionIds();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[MASTER_COLLECTION_IDS_FLD]:followedCollectionIds} = payload;
      if (followedCollectionIds) {
        Dispatcher.dispatch({
          type: FOLLOWED_COLLECTION_IDS_WERE_FETCHED_ACTION,
          payload: {
            followedCollectionIds: followedCollectionIds,
          }
        });
      }
    } else {
      traceError(`fetchFollowedCollectionIds: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCSubscribedCollectionIds() {
  trace(`fetchCSubscribedCollectionIds`);
  try {
    const response = await apiFetchCSubscribedCollectionIds();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[CSUBSCRIBED_COLLECTION_IDS_FLD]:csubscribedCollectionIds} = payload;
      if (csubscribedCollectionIds) {
        Dispatcher.dispatch({
          type: CSUBSCRIBED_COLLECTION_IDS_WERE_FETCHED_ACTION,
          payload: {
            csubscribedCollectionIds: csubscribedCollectionIds,
          }
        });
      }
    } else {
      traceError(`fetchCSubscribedCollectionIds: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCSubscription(collectionId) { // attn: a) !!!
  trace(`fetchCSubscription: collectionId=${collectionId}`);
  let status;
  let payload;
  let errors = {};
  let ownerId;
  try {
    const response = await apiFetchCSubscription(collectionId);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchCSubscription: OK, payload=${JSON.stringify(payload)}`);
        const {[CSUBSCRIPTION_FLD]:csubscription} = payload;
        if (csubscription) {
          ({[OWNER_ID_FLD]:ownerId} = csubscription);
          Dispatcher.dispatch({
            type: CSUBSCRIPTION_WAS_FETCHED_ACTION,
            payload: {
              csubscription: csubscription,
            }
          });
        }
        break;
      default:
        traceError(`fetchCSubscription: collectionId=${collectionId}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors, ownerId}; // sic!: для прискорення отримання власника колекції !!!
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createCSubscription(id) {
  trace(`createCSubscription`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateCSubscription(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createCSubscription: OK, payload=${JSON.stringify(payload)}`);
        const {[CSUBSCRIPTION_FLD]:csubscription} = payload;
        Dispatcher.dispatch({
          type: CSUBSCRIPTION_WAS_CREATED_ACTION,
          payload: {
            csubscription: csubscription,
          }
        });
        // ReactGA.event({
        //   category: gaCategories.SOCIAL,
        //   action: gaActions.CSUBSCRIPTION_CREATED
        // });
        break;
      default:
        traceError(`createCSubscription: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function cancelCSubscription(id) {
  trace(`cancelCSubscription`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCancelCSubscription(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`cancelCSubscription: OK, payload=${JSON.stringify(payload)}`);
        Dispatcher.dispatch({
          type: CSUBSCRIPTION_WAS_CANCELED_ACTION,
          payload: {
            collectionId: id,
          }
        });
        // ReactGA.event({
        //   category: gaCategories.SOCIAL,
        //   action: gaActions.CSUBSCRIPTION_DELETED
        // });
        break;
      default:
        traceError(`cancelCSubscription: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
