// -------------------------------------------------------------------------------------------------
//  prism/index.js
//  - - - - - - - - - -
//  A Slate plugin to highlight code syntax.
//
//  Notes:
//  - - - - -
//  - цей плагін є заміною для slate-prism;
//  - для редагування списку мов дивись .babelrc розділ 'prismjs';
//  - поки не зрозуміло для чого використовується PRISM_TOKEN;
//
//  API:
//  - - - - -
//  https://github.com/PrismJS/prism
//  https://github.com/mAAdhaTTah/babel-plugin-prismjs
//  https://prismjs.com/#languages-list
//  https://prismjs.com/#plugins
//
//  Inspired By:
//  https://github.com/GitbookIO/slate-prism
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Prism from 'prismjs';
import {blockTypes} from 'core/richTypes';

const PRISM_TOKEN = 'prism-token';

export const defaultPrismOptions = {
  onlyIn: (node) => node.object === 'block' && node.type === blockTypes.CODEBLOCK,
  getSyntax: (node) => node.data.get('syntax'),
  renderMark: (props) => {
    const {mark} = props;
    if (mark.type !== PRISM_TOKEN) {
      return undefined;
    }
    return <span className={mark.data.get('className')}>{props.children}</span>;
  },
};

function PrismPlugin(opts = {}) {
  const options = Object.assign(defaultPrismOptions, opts);

  return {
    decorateNode: (node) => {
      if (!options.onlyIn(node)) {
        return undefined;
      }
      return decorateNode(options, node);
    },
    renderMark: options.renderMark,
    'prism-token': PRISM_TOKEN
  };
}

// - - - - - - - - -
// Returns the decoration for a node
// - - - - - - - - -
function decorateNode(options, block) {
  const grammarName = options.getSyntax(block);
  const grammar = Prism.languages[grammarName];

  if (!grammar) {
    // Grammar not loaded
    return [];
  }

  // Tokenize the whole block text
  const texts = block.getTexts();
  const blockText = texts.map(t => t.text).join('\n');
  const tokens = Prism.tokenize(blockText, grammar);

  // The list of decorations to return
  const decorations = [];
  let textStart = 0;
  let textEnd = 0;

  texts.forEach(text => {
    textEnd = textStart + text.text.length;

    let offset = 0;

    function processToken(token, accu) {
      accu = accu || '';

      if (typeof token === 'string') {
        if (accu) {
          const decoration = createDecoration({
            text,
            textStart,
            textEnd,
            start: offset,
            end: offset + token.length,
            className: `prism-token token ${accu}`
          });
          if (decoration) {
            decorations.push(decoration);
          }
        }
        offset += token.length;
      } else {
        accu = `${accu} ${token.type} ${token.alias || ''}`;

        if (typeof token.content === 'string') {
          const decoration = createDecoration({
            text,
            textStart,
            textEnd,
            start: offset,
            end: offset + token.content.length,
            className: `prism-token token ${accu}`
          });
          if (decoration) {
            decorations.push(decoration);
          }

          offset += token.content.length;
        } else {
          // When using token.content instead of token.matchedStr, token can be deep
          for (let i = 0; i < token.content.length; i += 1) {
            processToken(token.content[i], accu);
          }
        }
      }
    }

    tokens.forEach(processToken);
    textStart = textEnd + 1; // account for added `\n`
  });

  return decorations;
}

// - - - - - - - - -
// Return a decoration range for the given text
//
// text, // The text being decorated
// textStart, // Its start position in the whole text
// textEnd, // Its end position in the whole text
// start, // The position in the whole text where the token starts
// end, // The position in the whole text where the token ends
// className // The prism token classname
// - - - - - - - - -
function createDecoration({text, textStart, textEnd, start, end, className }) {

  if (start >= textEnd || end <= textStart) {
    // Ignore, the token is not in the text
    return null;
  }

  // Shrink to this text boundaries
  start = Math.max(start, textStart);
  end = Math.min(end, textEnd);

  // Now shift offsets to be relative to this text
  start -= textStart;
  end -= textStart;

  return {
    anchorKey: text.key,
    anchorOffset: start,
    focusKey: text.key,
    focusOffset: end,
    marks: [{ type: PRISM_TOKEN, data: { className } }]
  };
}

export default PrismPlugin;
