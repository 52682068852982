// -------------------------------------------------------------------------------------------------
//  inlinecode.js
//  - - - - - - - - - -
//  `{...}`[SPACE] ---> <samp>{...}</samp>
//
//  Notes:
//  - - - - -
//  - slateTypes.INLINE_CODE може містити лише текст + заборона autoreplace плагінів всередині;
// -------------------------------------------------------------------------------------------------
// ToDo: виправити спосіб редагування (щоб додати останній символ треба вийти за межі елементу) !!!

import {Range} from 'slate';
import {inlineTypes} from 'core/richTypes';

export default function autoreplaceInlineCode(node, matched, change) {
  const matchedLength = matched[0].length;

  change.deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );

  // eliminate preceding SPACE for some cases by expanding selection
  if (matched.index === 0 || matched.input[matched.index - 1] === ' ') {
    change.extend(-1);
  }

  change.insertText(matched[1]);
  change.extend(0 - matched[1].length);
  change.wrapInline({
    type: inlineTypes.INLINE_CODE,
  });
  change.collapseToEnd();

  return change;
}
