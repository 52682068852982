// -------------------------------------------------------------------------------------------------
//  UserStore.js
//  - - - - - - - - - -
//  Users, Friends & related information.
//
//  Attn:
//  - - - - -
//  a) в guest-режимі отримуємо USERS_FLD з інформацією про юзерів;
//  b) в logged-in-режимі для зменшення трафіку USERS_FLD немає, тому додатково запитуємо лише
//     юзерів, яких немає в сторі;
//
//  Attn:
//  - - - - -
//  - ідентифікація юзерів проводиться по ідентифікатору (id);
//  - пошук юзера по slug є допоміжним (для реалізації url-адрес);
//  - функції getSomeUsers/getSomeUsersMap повертають лише юзерів, що знайдені в сторі;
//  - поле isLoaded показує, чи були дані юзера у форматі {{ USER }} завантажені з серверу;
//    у випадку friendship-реквестів можуть прийти часткові дані (лише chat_id),
//    тому потрібно виставляти значення по наявності поля: 'slug' ? true : false;
//  - поле isProfileLoaded показує, чи були дані юзера у форматі {{ PROFILE }} завантажені з серверу;
//    профайл завантажується окремо лише для перегляду повної інформації про юзера (форма OneUser);
//  - для стейплів потрібно перевіряти чи завантажені юзери для ownerId та authorId,
//    адже в верстці показуються власники не лише стейплів, а й контенту;
// -------------------------------------------------------------------------------------------------
// ToDo: створити список юзерів, яких треба завантажити, і робити ПЕРІОДИЧНИЙ запит (а не ситуативно порціями) !!!
// ToDo: додати unreadMessagesByUser для відображення к-сті непрочитаних в розрізі юзера ???

import {ReduceStore} from 'flux/utils';
import {List, Map, Set, OrderedSet, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  MY_FRIENDS_WERE_FETCHED_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION,
  FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
  FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION,
  FRIENDSHIP_WAS_CANCELED_ACTION,
  MY_CONTACTS_WERE_FETCHED_ACTION,
  CONTACT_WAS_CREATED_ACTION,
  CONTACT_WAS_UPDATED_ACTION,
  CONTACT_WAS_DELETED_ACTION,
  CONTACT_WAS_SELECTED_ACTION,
  CONTACT_WAS_UNSELECTED_ACTION,
  CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION,
  DISCOVER_ADVERTS_WERE_FETCHED_ACTION,
  SUBJECT_ADVERTS_WERE_FETCHED_ACTION,
  USER_ADVERTS_WERE_FETCHED_ACTION,
  ADVERT_WAS_CREATED_ACTION,
  ADVERT_WAS_DELETED_ACTION,
  DISCOVER_STAPLES_WERE_FETCHED_ACTION,
  FEATURED_STAPLES_WERE_FETCHED_ACTION,
  FOLLOWING_STAPLES_WERE_FETCHED_ACTION,
  USER_STAPLES_WERE_FETCHED_ACTION,
  STAPLE_WAS_CREATED_ACTION,
  STAPLE_WAS_CLONED_ACTION,
  STAPLES_WERE_DELETED_ACTION,
  DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION,
  FEATURED_COLLECTIONS_WERE_FETCHED_ACTION,
  SEARCHED_COLLECTIONS_WERE_FETCHED_ACTION,
  MY_COLLECTIONS_WERE_FETCHED_ACTION,
  COLLECTION_WAS_CREATED_ACTION,
  COLLECTION_WAS_DELETED_ACTION,
  // MY_SCTX_CHATS_WERE_FETCHED_ACTION,
  // USER_SCTX_CHATS_WERE_FETCHED_ACTION,
  STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION,
  USER_UCTX_CHATS_WERE_FETCHED_ACTION,
  CHAT_WAS_CREATED_ACTION,
  USERS_WERE_FETCHED_ACTION,
  USER_PROFILES_WERE_FETCHED_ACTION,
  CALL_STATE_WAS_SUBSTITUTED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  OWNER_ID_FLD,
  AUTHOR_ID_FLD,
  USER_ID_FLD,
  TYPE_FLD,
  BILLING_TYPE_FLD,
  SLUG_FLD,
  ALIAS_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  EXPIRED_AT_FLD,
  COMMENTS_FLD,
  CONTEXTS_FLD,
  CHAT_ID_FLD,
  DIRECT_CHAT_ID_FLD,
  LANGUAGE_ID_FLD,
  COUNTRY_ID_FLD,
  ZIP_CODE_FLD,
  ADDRESSES_FLD,
  DETAILS_FLD,
  PHONES_FLD,
  EMAIL_FLD,
  EMAILS_FLD,
  URLS_FLD,
  SUBJECTS_FLD,
  GROUPS_FLD,
  STAPLES_QTY_FLD,
  COLLECTIONS_QTY_FLD,
  ADVERTS_QTY_FLD,
  FOLLOWERS_QTY_FLD,
  MASTERS_QTY_FLD,
  COINS_AMOUNT_FLD,
  CONTACT_FLD,
  MEMBER_IDS_FLD,
  FRIENDSHIP_STATUS_FLD,
  AVATAR_ID_FLD,
  AVATAR_SOURCE_URL_FLD,
  AVATAR_XHASH2_FLD,
  AVATAR_EXT_FLD,
  USER_SLUG_FLD,
  USER_ALIAS_FLD,
  USER_AVATAR_SOURCE_URL_FLD,
  USER_XHASH2_FLD,
  USER_EXT_FLD,
  BLOB_FLD,
  IS_FRIEND_FLD,
  IS_HELP_MODE_FLD,
  IS_PROFILE_LOADED_FLD} from 'core/apiFields';
import {LS_SETTINGS, DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {
  USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX,
  UCTX_CHAT, SCTX_CHAT,
  IDLE_STATE, RINGING_STATE, ESTABLISHED_STATE} from 'core/communicationTypes';
import {
  FS_NONE_STATUS,
  FS_FRIEND_STATUS,
  FS_REQUEST_SENT_STATUS,
  FS_REQUEST_RECEIVED_STATUS} from 'core/friendshipTypes';
import {fetchUsers} from 'actions/UserActions';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {toDxTagsPlus, toDxListStr, toDxSelect2} from 'components/UI/fields/DetailSelectField';
import {toPnTagsPlus, toPnListStr, toPnSelect2} from 'components/UI/fields/PhoneSelectField';
import {toUrTagsPlus, toUrListStr, toUrSelect2} from 'components/UI/fields/UrlSelectField';
import {toAdTagsPlus, toAdListStr, toAdSelect2,
        toEmTagsPlus, toEmListStr, toEmSelect2,
        toGrTagsPlus, toGrListStr, toGrSelect2} from 'components/UI/fields/SelectField';
import {composeAvatarUrl} from 'utils/settingsTools';
import {domain} from 'settings/local.yaml';

const isVerbose = DEBUG && true;
const prefix = '- - - UserStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const UserRecord = Record({
  id: null,                               //
  slug: '',                               //
  alias: '',                              //
  description: '',                        // опис юзера про себе
  strAddresses: '[]',                     // список адрес       = {{ AD-LIST-STR }}
  strDetails: '[]',                       // список приміток    = {{ DX-LIST-STR }}
  strPhones: '[]',                        // список телефонів   = {{ PN-LIST-STR }}
  strEmails: '[]',                        // список мейлів      = {{ EM-LIST-STR }}
  strUrls: '[]',                          // список посилань    = {{ UR-LIST-STR }}
  strSubjects: '[]',                      // список предметів   = {{ SJ-LIST-STR }}
  staplesQty: 0,                          // к-сть стейплів (публічних/усіх)
  collectionsQty: 0,                      // к-сть колекцій (публічних/усіх)
  advertsQty: 0,                          // к-сть оголошень
  avatarSourceUrl: '',                    // url-адреса джерела аватарки
  avatarXhash2: '',                       // хеш-код аватара юзера
  avatarExt: '',                          // розширення файлу файлу аватара юзера
  // ...custom:
  friendshipStatus: undefined,            // sic!: значення FS_NONE_STATUS виставляється при отриманні списку друзів (MY_FRIENDS_WERE_FETCHED_ACTION) !!!
  presenceStatus: undefined,              // флаг присутності юзера в мережі
  isMaster: false,                        // флаг спостереження за цим юзером
  isLoaded: false,                        // флаг завантаження даних юзера у форматі {{ USER }}
  isProfileLoaded: false,                 // флаг завантаження даних юзера у форматі {{ PROFILE }}
  isSelected: false,                      // флаг відмітки юзера для множинної операції
  // ...chats/contexts:
  directChatId: '',                       // ідентифікатор прямого чату між користувачем та юзером
  contextIds: Map({                       // ідентифікатори контекстів (для фіду UserTopicStaples)
    [USER_CTX]: OrderedSet(),             // ідентифікатори контекстів типу user
    [STAPLE_CTX]: OrderedSet(),           // ідентифікатори контекстів типу staple
    [COLLECTION_CTX]: OrderedSet(),       // ідентифікатори контекстів типу collection
  }),
  chatsCursor: DEFAULT_TSN12_CURSOR,      // наступний курсор для запиту списку чатів з цим юзером
  areChatsLoaded: false,                  // чи завантажені усі чати з цим юзером в стор?
  // ...contact:
  contactId: null,                        // код привʼязаного до юзера контакту
  contactName: '',                        // назва привʼязаного до юзера контакту
  contactAvatarSourceUrl: '',             // url-адреса джерела аватарки привʼязаного контакту
  contactXhash2: '',                      // хеш-код аватара контакту
  contactExt: '',                         // розширення файлу файлу аватара контакту
  contactBlob: '',                        // файл-джерело аватару контакту
});

class User extends UserRecord {
  get value() {                           // required!: by UserSelectField
    return this.slug;
  }
  get label() {                           // required!: by UserSelectField
    return this.alias;
  }
  get descriptionHtml() {
    return toReact(this.description);
  }
  get lstAddresses() {
    return JSON.parse(this.strAddresses); // '[]' --> []
  }
  get lstDetails() {
    return JSON.parse(this.strDetails);   // '[]' --> []
  }
  get lstPhones() {
    return JSON.parse(this.strPhones);    // '[]' --> []
  }
  get lstEmails() {
    return JSON.parse(this.strEmails);    // '[]' --> []
  }
  get lstUrls() {
    return JSON.parse(this.strUrls);      // '[]' --> []
  }
  get lstSubjects() {
    return JSON.parse(this.strSubjects);  // '[]' --> []
  }
  get ulink() {
    return this.slug ? `/${this.slug}/` : `/u/${this.id}/`;
  }
  get title() {
    return this.contactName && this.contactName !== this.alias ? `${this.contactName} (${this.alias})` : this.alias;
  }
  get avatarSsUrl() { // required!: by UserSelectField
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 's', this.avatarExt) : this.avatarSourceUrl;
  }
  get avatarMdUrl() {
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'm', this.avatarExt) : this.avatarSourceUrl;
  }
  get avatarXlUrl() {
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'x', this.avatarExt) : this.avatarSourceUrl;
  }
  get userAvatarSsUrl() {
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 's', this.avatarExt) : this.avatarSourceUrl;
  }
  get userAvatarMdUrl() {
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'm', this.avatarExt) : this.avatarSourceUrl;
  }
  get userAvatarXlUrl() {
    return this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'x', this.avatarExt) : this.avatarSourceUrl;
  }
  get contactAvatarSsUrl() {
    return this.contactBlob ? this.contactBlob : this.contactXhash2 ? composeAvatarUrl(this.contactXhash2, 's', this.contactExt) : this.contactAvatarSourceUrl;
  }
  get contactAvatarMdUrl() {
    return this.contactBlob ? this.contactBlob : this.contactXhash2 ? composeAvatarUrl(this.contactXhash2, 'm', this.contactExt) : this.contactAvatarSourceUrl;
  }
  get contactAvatarXlUrl() {
    return this.contactBlob ? this.contactBlob : this.contactXhash2 ? composeAvatarUrl(this.contactXhash2, 'x', this.contactExt) : this.contactAvatarSourceUrl;
  }
  get defaultSortKey() { // кастомний порядок сортування юзерів (в фідах)
    return this.contactName && this.contactName !== this.alias ? `${this.contactName} (${this.alias})` : this.alias;
  }
  get userFSStatusKey() { // кастомний порядок сортування з врахування статусу дружби
    const prefix =
      this.friendshipStatus === FS_REQUEST_RECEIVED_STATUS ? `1` :
        this.friendshipStatus === FS_REQUEST_SENT_STATUS ? `2` :
          this.friendshipStatus === FS_FRIEND_STATUS ? `3` :
            this.friendshipStatus === FS_NONE_STATUS ? `4` : `8`;
    return this.contactName && this.contactName !== this.alias ? `${prefix}${this.contactName}${this.alias}` : `${prefix}${this.alias}`;
  }
}

// Attn: 1) якщо прийшов slug, то це формат даних {{ USER }} !!!
// Attn: 2) якщо прийшов isProfileLoaded, то це формат даних {{ PROFILE }} !!!
// Attn: 3) {{ USER }} || {{ PROFILE }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeUser(state, patchObject) {
  const userId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['users', userId], prevUser => {
    const {
      [ID_FLD]:id,
      [SLUG_FLD]:slug,
      [ALIAS_FLD]:alias,
      [DESCRIPTION_FLD]:description,
      [ADDRESSES_FLD]:tgxAddresses,
      [DETAILS_FLD]:tgxDetails,
      [PHONES_FLD]:tgxPhones,
      [EMAILS_FLD]:tgxEmails,
      [URLS_FLD]:tgxUrls,
      [SUBJECTS_FLD]:strSubjects,
      [STAPLES_QTY_FLD]:staplesQty,
      [COLLECTIONS_QTY_FLD]:collectionsQty,
      [ADVERTS_QTY_FLD]:advertsQty,
      [AVATAR_SOURCE_URL_FLD]:avatarSourceUrl,
      [AVATAR_XHASH2_FLD]:avatarXhash2,
      [AVATAR_EXT_FLD]:avatarExt,
      [FRIENDSHIP_STATUS_FLD]:friendshipStatus,
      [DIRECT_CHAT_ID_FLD]:directChatId,
      [IS_PROFILE_LOADED_FLD]:isProfileLoaded,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = userId;}
    if (slug !== undefined)               {formattedObject.slug = slug;}
    if (alias !== undefined)              {formattedObject.alias = alias;}
    if (description !== undefined)        {formattedObject.description = description;}
    if (tgxAddresses !== undefined)       {formattedObject.strAddresses = toAdListStr(JSON.parse(tgxAddresses));}
    if (tgxDetails !== undefined)         {formattedObject.strDetails = toDxListStr(JSON.parse(tgxDetails));}
    if (tgxPhones !== undefined)          {formattedObject.strPhones = toPnListStr(JSON.parse(tgxPhones));}
    if (tgxEmails !== undefined)          {formattedObject.strEmails = toEmListStr(JSON.parse(tgxEmails));}
    if (tgxUrls !== undefined)            {formattedObject.strUrls = toUrListStr(JSON.parse(tgxUrls));}
    if (strSubjects !== undefined)        {formattedObject.strSubjects = strSubjects;}
    if (staplesQty !== undefined)         {formattedObject.staplesQty = staplesQty;}
    if (collectionsQty !== undefined)     {formattedObject.collectionsQty = collectionsQty;}
    if (advertsQty !== undefined)         {formattedObject.advertsQty = advertsQty;}
    if (avatarSourceUrl !== undefined)    {formattedObject.avatarSourceUrl = avatarSourceUrl;}
    if (avatarXhash2 !== undefined)       {formattedObject.avatarXhash2 = avatarXhash2;}
    if (avatarExt !== undefined)          {formattedObject.avatarExt = avatarExt;}
    if (friendshipStatus !== undefined)   {formattedObject.friendshipStatus = friendshipStatus;}
    if (directChatId !== undefined)       {formattedObject.directChatId = directChatId;}
    if (patchObject[SLUG_FLD] || patchObject['slug']) {formattedObject.isLoaded = true;} // attn: 1)
    if (isProfileLoaded !== undefined)    {formattedObject.isProfileLoaded = isProfileLoaded;} // attn: 2)
    // - - -
    return (prevUser || new User()).merge(formattedObject);
  });
}

// Attn: обробляємо лише контакти в яких вказано userId !!!
// Attn: {{ CONTACT }} --> inner format ('ui' --> 'userId', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeContactInfo(state, patchObject) {
  const userId = patchObject[USER_ID_FLD] || patchObject['userId'];
  return !userId ?
    state :
    state.updateIn(['users', userId], prevUser => {
      const {
        [ID_FLD]:contactId,                       // ідентифікатор контакту
        [NAME_FLD]:contactName,                   // назва контакту
        [AVATAR_SOURCE_URL_FLD]:contactAvSrcUrl,  // url-адреса джерела аватарки контакту
        [AVATAR_XHASH2_FLD]:contactXhash2,        // xhash2 аватара контакту
        [AVATAR_EXT_FLD]:contactExt,              // розширення файлу аватара контакту
        [BLOB_FLD]:contactBlob,                   // файл-джерело аватара контакту
      } = patchObject;
      const formattedObject = {};
      // - - - присвоєння робиться тільки коли є значення !!!
      if (userId !== undefined)           {formattedObject.id = userId;}
      if (contactId !== undefined)        {formattedObject.contactId = contactId;}
      if (contactName !== undefined)      {formattedObject.contactName = contactName;}
      if (contactAvSrcUrl !== undefined)  {formattedObject.contactAvatarSourceUrl = contactAvSrcUrl;}
      if (contactXhash2 !== undefined)    {formattedObject.contactXhash2 = contactXhash2;}
      if (contactExt !== undefined)       {formattedObject.contactExt = contactExt;}
      if (contactBlob !== undefined)      {formattedObject.contactBlob = contactBlob;}
      // - - -
      return (prevUser || new User()).merge(formattedObject);
    });
}

// ToDo: додати fsrQty = к-сть отриманих запитів на дружбу (швидкий геттер getFSRequestCount) !!!
// Attn: 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class UserStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // sic!: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // код користувача
      myFriendIds: Set(),                 // коди друзів, в т.ч. REQUEST_SENT & REQUEST_RECEIVED (для фіду MyFriends)
      users: Map(),                       // завантажені в стор юзери
      callState: IDLE_STATE,              // поточний стан дзвінка
      areMyFriendsLoaded: false,          // чи всі друзі завантажені з серверу?
    });
  }

  // - - - predicates:

  areMyFriendsLoaded() {
    const result = this.getState().get('areMyFriendsLoaded');
    trace(`areMyFriendsLoaded: loaded=${result}`);
    return result;
  }

  isUserLoaded(id) {
    const {isLoaded = false} = this.getUser(id) || {};
    trace(`isUserLoaded: userId=${id}, loaded=${isLoaded}`);
    return isLoaded;
  }

  isUserLoadedBySlug(slug) {
    const {isLoaded = false} = this.getUserBySlug(slug) || {};
    trace(`isUserLoadedBySlug: userSlug=${slug}, loaded=${isLoaded}`);
    return isLoaded;
  }

  isUserProfileLoaded(id) {
    const {isProfileLoaded = false} = this.getUser(id) || {};
    trace(`isUserProfileLoaded: userId=${id}, loaded=${isProfileLoaded}`);
    return isProfileLoaded;
  }

  isUserProfileLoadedBySlug(slug) {
    const {isProfileLoaded = false} = this.getUserBySlug(slug) || {};
    trace(`isUserProfileLoadedBySlug: userSlug=${slug}, loaded=${isProfileLoaded}`);
    return isProfileLoaded;
  }

  areUserChatsLoaded(id) {
    const result = id ? this.getState().getIn(['users', id, 'areChatsLoaded']) : false;
    trace(`areUserChatsLoaded: userId=${id}, loaded=${result}`);
    return result;
  }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getUser(id) {
    trace(`getUser`, id);
    return this.getState().getIn(['users', id]);
  }

  getUserBySlug(slug) {
    trace(`getUserBySlug: ${slug}`);
    const formattedSlug = slug.toLowerCase();
    return this.getState().get('users').find(user => user.slug === formattedSlug);
  }

  getUsers() {
    trace(`getUsers`);
    return this.getState().get('users').toList();
  }

  getSomeUsers(ids = []) {
    trace(`getSomeUsers`);
    return ids.reduce((acc, id) => {
      const user = this.getUser(id);
      return user ? acc.add(user) : acc;
    }, Set());
  }

  getUsersMap() {
    trace(`getUsersMap`);
    return this.getState().get('users');
  }

  getSomeUsersMap(ids = []) {
    trace(`getSomeUsersMap`);
    return ids.reduce((acc, id) => {
      const user = this.getUser(id);
      return user ? acc.set(id, user) : acc; // sic!: 'set' зберігає Record !!!
    }, Map());
  }

  getMyFriendIds() {
    trace(`getMyFriendIds`);
    return this.getState().get('myFriendIds');
  }

  getMyFriends() {
    trace(`getMyFriends`);
    return List(this.getState().get('myFriendIds').map(id => this.getUser(id)));
  }

  getAllFriends() {
    trace(`getAllFriends`);
    return this.getState().get('users')
      .filter(user => user.friendshipStatus === FS_FRIEND_STATUS)
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getAllFriendsAndCandidates() {
    trace(`getAllFriendsAndCandidates`);
    return this.getState().get('users')
      .filter(user => user.friendshipStatus !== FS_NONE_STATUS)
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getFSRequestUsers() {
    trace(`getFSRequestUsers`);
    return this.getState().get('users')
      .filter(user => user.friendshipStatus === FS_REQUEST_SENT_STATUS || user.friendshipStatus === FS_REQUEST_RECEIVED_STATUS)
      .toList()
      .sort((a, b) => { return a.userFSStatusKey < b.userFSStatusKey ? -1 : 1; });
  }

  // ToDo: додати fsrQty = к-сть отриманих запитів на дружбу (швидкий геттер getFSRequestCount) !!!
  getFSRequestCount() {
    trace(`getFSRequestCount`);
    const count = this.getState().get('users')
      .filter(user => user.friendshipStatus === FS_REQUEST_RECEIVED_STATUS)
      .size;
    return count > 0 ? count < 100 ? '' + count : '99+' : ''; // sic!: string !!!
  }

  getMyDirectUsers() {
    trace(`getMyDirectUsers`);
    return this.getState().get('users')
      .filter(user => user.directChatId !== '') // з юзером повинен ТОЧНО бути прямий чат !!!
      .toList()
      .sort((a, b) => { return a.userFSStatusKey < b.userFSStatusKey ? -1 : 1; });
  }

  getCallOptions() {
    const callState = this.getState().get('callState');
    trace(`getCallOptions: callState=${callState}`);
    return {callState};
  }

  getUserChatsCursor(id) {
    trace(`getUserChatsCursor`);
    return id ? this.getState().getIn(['users', id, 'chatsCursor']) : DEFAULT_TSN12_CURSOR;
  }

  getUserContextIds(id) {
    trace(`getUserContextIds`);
    const stapleIds = this.getState().getIn(['users', id, 'contextIds', STAPLE_CTX]);
    return {stapleIds};
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users, adverts} = payload;
    if (users) {
      return users.reduce(mergeUser, state); // attn: 1) !!!
    }
    if (adverts) {
      const receivedUserIds = adverts.reduce((acc, advert) => {
        const {[OWNER_ID_FLD]:ownerId} = advert;
        return acc.add(ownerId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SUBJECT_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {adverts} = payload;
    if (adverts) {
      const receivedUserIds = adverts.reduce((acc, advert) => {
        const {[OWNER_ID_FLD]:ownerId} = advert;
        return acc.add(ownerId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, adverts} = payload;
    if (adverts) {
      const receivedUserIds = adverts.reduce((acc, advert) => {
        const {[OWNER_ID_FLD]:ownerId} = advert;
        return acc.add(ownerId);
      }, Set().add(userId));
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users, staples} = payload;
    if (users) {
      return users.reduce(mergeUser, state); // attn: 1) !!!
    }
    if (staples) {
      const receivedUserIds = staples.reduce((acc, staple) => {
        const {[OWNER_ID_FLD]:ownerId, [AUTHOR_ID_FLD]:authorId} = staple;
        return acc.add(ownerId).add(authorId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // attn: 2) !!! + xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FEATURED_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users, staples} = payload;
    if (users) {
      return users.reduce(mergeUser, state); // attn: 1) !!!
    }
    if (staples) {
      const receivedUserIds = staples.reduce((acc, staple) => {
        const {[OWNER_ID_FLD]:ownerId, [AUTHOR_ID_FLD]:authorId} = staple;
        return acc.add(ownerId).add(authorId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // attn: 2) !!! + xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FOLLOWING_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples} = payload;
    if (staples) {
      const receivedUserIds = staples.reduce((acc, staple) => {
        const {[OWNER_ID_FLD]:ownerId, [AUTHOR_ID_FLD]:authorId} = staple;
        return acc.add(ownerId).add(authorId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_STAPLES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staples} = payload;
    if (staples) {
      const receivedUserIds = staples.reduce((acc, staple) => {
        const {[OWNER_ID_FLD]:ownerId, [AUTHOR_ID_FLD]:authorId} = staple;
        return acc.add(ownerId).add(authorId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users, collections} = payload;
    if (users) {
      return users.reduce(mergeUser, state); // attn: 1) !!!
    }
    if (collections) {
      const receivedUserIds = collections.reduce((acc, coll) => {
        const {[OWNER_ID_FLD]:ownerId} = coll;
        return acc.add(ownerId);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // attn: 2) !!! + xtra-fetch: Users
      }
    }
    return state;
  }

  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [MY_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {chats} = payload;
  //   if (chats) {
  //     const receivedUserIds = chats.reduce((accReceivedUserIds, chat) => {
  //       const {[MEMBER_IDS_FLD]:memberIds = []} = chat;
  //       return memberIds.reduce((acc, memberId) => {
  //         return memberId ? acc.add(memberId) : acc;
  //       }, accReceivedUserIds);
  //     }, Set());
  //     const missedUserIds = receivedUserIds.reduce((acc, userId) => {
  //       return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
  //     }, Set());
  //     if (missedUserIds.size > 0) {
  //       fetchUsers(missedUserIds); // xtra-fetch: Users
  //     }
  //   }
  //   return state;
  // }

  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [USER_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {userId, chats, chatsCursor, areChatsLoaded} = payload;
  //   const myId = state.get('myId');
  //   if (!userId) {
  //     return state;
  //   }
  //   if (chats) {
  //     const receivedUserIds = chats.reduce((accReceivedUserIds, chat) => {
  //       const {[MEMBER_IDS_FLD]:memberIds = []} = chat;
  //       return memberIds.reduce((acc, memberId) => {
  //         return memberId ? acc.add(memberId) : acc;
  //       }, accReceivedUserIds);
  //     }, Set());
  //     const missedUserIds = receivedUserIds.reduce((acc, userId) => {
  //       return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
  //     }, Set());
  //     if (missedUserIds.size > 0) {
  //       fetchUsers(missedUserIds); // xtra-fetch: Users
  //     }
  //   }
  //   // sic!: викликається ДО обробки контекстів (щоб створити юзерів) !!!
  //   const nextState = chats ? mergeUser(state, {id:userId, chatsCursor:chatsCursor, areChatsLoaded:areChatsLoaded}) : state;
  //   return chats.reduce((accState, chat) => {
  //     const {[CONTEXTS_FLD]:contexts = []} = chat;
  //     let currCtxId = '';
  //     let currCtxType = '';
  //     let currCtxOwnerId = '';
  //     contexts.map((context) => {
  //       const {
  //         [ID_FLD]:ctxId = '',
  //         [TYPE_FLD]:ctxType = '',
  //         [OWNER_ID_FLD]:ctxOwnerId = ''} = context;
  //       // відбираємо єдиний контекст до чату: a) власні понад чужі; b) нові понад давні
  //       if (ctxOwnerId === myId) {
  //         if (ctxId > currCtxId || currCtxOwnerId !== myId || currCtxOwnerId === '') {
  //           currCtxId = ctxId;
  //           currCtxType = ctxType;
  //           currCtxOwnerId = ctxOwnerId;
  //         }
  //       } else {
  //         if ((ctxId > currCtxId && currCtxOwnerId !== myId) || currCtxOwnerId === '') {
  //           currCtxId = ctxId;
  //           currCtxType = ctxType;
  //           currCtxOwnerId = ctxOwnerId;
  //         }
  //       }
  //     });
  //     const ctxIds = accState.getIn(['users', userId, 'contextIds', currCtxType]);
  //     return accState.setIn(['users', userId, 'contextIds', currCtxType], ctxIds.add(currCtxId));
  //   }, nextState);
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {chats = []} = payload;
    if (chats) {
      const receivedUserIds = chats.reduce((accReceivedUserIds, chat) => {
        const {[MEMBER_IDS_FLD]:memberIds = []} = chat;
        return memberIds.reduce((acc, memberId) => {
          return memberId ? acc.add(memberId) : acc;
        }, accReceivedUserIds);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // ToDo: COLLECTION_CTX !!!
  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {chats = []} = payload;
  //   if (chats) {
  //     const receivedUserIds = chats.reduce((accReceivedUserIds, chat) => {
  //       const {[MEMBER_IDS_FLD]:memberIds = []} = chat;
  //       return memberIds.reduce((acc, memberId) => {
  //         return memberId ? acc.add(memberId) : acc;
  //       }, accReceivedUserIds);
  //     }, Set());
  //     const missedUserIds = receivedUserIds.reduce((acc, userId) => {
  //       return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
  //     }, Set());
  //     if (missedUserIds.size > 0) {
  //       fetchUsers(missedUserIds); // xtra-fetch: Users
  //     }
  //   }
  //   return state;
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_UCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, chats = []} = payload;
    const initialSet = !this.isUserLoaded(userId) ? Set().add(userId) : Set(); // sic!: ++userId !!!
    if (chats) {
      const receivedUserIds = chats.reduce((accReceivedUserIds, chat) => {
        const {[MEMBER_IDS_FLD]:memberIds = []} = chat;
        return memberIds.reduce((acc, memberId) => {
          return memberId ? acc.add(memberId) : acc;
        }, accReceivedUserIds);
      }, Set());
      const missedUserIds = receivedUserIds.reduce((acc, userId) => {
        return userId && !this.isUserLoaded(userId) ? acc.add(userId) : acc;
      }, Set());
      if (missedUserIds.size > 0) {
        fetchUsers(missedUserIds); // xtra-fetch: Users
      }
    }
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const myId = state.get('myId');
    const {chat} = payload;
    if (!chat) {
      return state;
    }
    const {[ID_FLD]:chatId, [TYPE_FLD]:chatType, [CONTEXTS_FLD]:contexts = []} = chat || {};
    const {[ID_FLD]:ctxId = '', [TYPE_FLD]:ctxType = '', [OWNER_ID_FLD]:ctxOwnerId = ''} = contexts[0];
    // ...a): прямий чат створений користувачем !!!
    if (chatType === UCTX_CHAT && ctxType === USER_CTX && ctxId && ctxOwnerId === myId) {
      return mergeUser(state, {[ID_FLD]: ctxId, [DIRECT_CHAT_ID_FLD]:chatId});
    }
    // ...b): прямий чат створений іншим юзером !!!
    if (chatType === UCTX_CHAT && ctxType === USER_CTX && ctxOwnerId && ctxId === myId) {
      return mergeUser(state, {[ID_FLD]: ctxOwnerId, [DIRECT_CHAT_ID_FLD]:chatId});
    }
    // ...c): створений чат - це не прямий чат, тому відмічати не потрібно !!!
    return state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const myId = this.getState().get('myId');
    const {advertsQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, advertsQty: advertsQty + 1}); // counts: advertsQty
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const myId = this.getState().get('myId');
    const {advertsQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, advertsQty: advertsQty - 1}); // counts: advertsQty
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CREATED_ACTION] = (state, {payload}) => {
    const myId = this.getState().get('myId');
    const {staplesQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, staplesQty: staplesQty + 1}); // counts: STAPLES_QTY_FLD
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CLONED_ACTION] = (state, {payload}) => {
    const myId = this.getState().get('myId');
    const {staplesQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, staplesQty: staplesQty + 1}); // counts: STAPLES_QTY_FLD
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_WERE_DELETED_ACTION] = (state, {payload}) => {
    const {deletedIds} = payload;
    const myId = this.getState().get('myId');
    const {staplesQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, staplesQty: staplesQty - deletedIds.length}); // counts: STAPLES_QTY_FLD
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {collection} = payload;
    const myId = this.getState().get('myId');
    const {collectionsQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, collectionsQty: collectionsQty + 1}); // counts: collectionsQty
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    const myId = this.getState().get('myId');
    const {collectionsQty} = state.getIn(['users', myId]);
    return mergeUser(state, {id: myId, collectionsQty: collectionsQty - 1}); // counts: collectionsQty
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {myId, collections = []} = payload;
    return mergeUser(state, {id: myId, collectionsQty: collections.length}); // counts: collectionsQty
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_CONTACTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {contacts} = payload;
    // запит юзерів, що привʼязані до контактів, але не є друзями (друзів приносить fetchMyFriends)
    if (contacts) {
      const connectedNonFriendsUserIds = contacts.reduce((acc, contact) => {
        const {[USER_ID_FLD]:userId, [IS_FRIEND_FLD]:isFriend} = contact;
        return userId && !isFriend ? acc.add(userId) : acc;
      }, Set());
      if (connectedNonFriendsUserIds.size > 0) {
        fetchUsers(connectedNonFriendsUserIds); // xtra-fetch: Users
      }
    }
    return contacts ?
      contacts.reduce(mergeContactInfo, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {contact} = payload;
    return contact ?
      mergeContactInfo(state, contact) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {contact} = payload;
    return contact ?
      mergeContactInfo(state, contact) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {[CONTACT_FLD]:contact} = payload;
    if (!contact) return state;
    const {[USER_ID_FLD]:userId, [IS_FRIEND_FLD]:isFriend} = contact;
    const nextState = mergeContactInfo(state, contact);
    return userId && isFriend ?
      mergeUser(nextState, {id:userId, [FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS}) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    if (!contactId) return state;
    const {id:userId} = this.getState().get('users').find(user => user.contactId === contactId) || {};
    return userId ?
      state
        .setIn(['users', userId, 'contactId'], null)
        .setIn(['users', userId, 'contactName'], '')
        .setIn(['users', userId, 'contactAvatarSourceUrl'], '')
        .setIn(['users', userId, 'contactXhash2'], '')
        .setIn(['users', userId, 'contactExt'], '')
        .setIn(['users', userId, 'contactBlob'], '') :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_SELECTED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    const {id:userId} = state.get('users').find(user => user.contactId === contactId) || {};
    return userId ?
      state.setIn(['users', userId, 'isSelected'], true) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_UNSELECTED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    const {id:userId} = state.get('users').find(user => user.contactId === contactId) || {};
    return userId ?
      state.setIn(['users', userId, 'isSelected'], false) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [CONTACTS_WERE_UNSELECTED_ACTION] = (state, {payload}) => {
  //   return state;
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_FRIENDS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {friends, sentRequests, receivedRequests, directUsers} = payload;
    // ...виставляємо дефолтне значення для friendshipStatus
    const nextState0 = state.get('users').reduce((accState, user) => {
      const {id:userId, friendshipStatus} = user;
      return userId && friendshipStatus === undefined ?
        mergeUser(accState, {[ID_FLD]:userId, [FRIENDSHIP_STATUS_FLD]: FS_NONE_STATUS}) :
        accState;
    }, state);
    const nextState = directUsers.reduce((accState, user) => {
      const {[ID_FLD]:userId, [DIRECT_CHAT_ID_FLD]:chatId} = user;
      return userId && chatId ?
        mergeUser(accState, user) :
        accState;
    }, nextState0);
    const nextState1 = sentRequests.reduce((accState, user) => {
      return mergeUser(accState, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS, ...user});
    }, nextState);
    const nextState2 = receivedRequests.reduce((accState, user) => {
      return mergeUser(accState, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_RECEIVED_STATUS, ...user});
    }, nextState1);
    const nextState3 = friends.reduce((accState, user) => {
      return mergeUser(accState, {[FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS, ...user});
    }, nextState2);
    const myFriendIds = friends.reduce((acc, user) => {
      return acc.add(user[ID_FLD] || user.id);
    }, Set());
    return nextState3
      .set('myFriendIds', myFriendIds)
      .set('areMyFriendsLoaded', true);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_SENT_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return mergeUser(state, {id: userId, [FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION] = (state, {payload}) => {
    const {user} = payload;
    const prevFriendshipStatus = state.getIn(['users', user[ID_FLD], 'friendshipStatus']);
    return prevFriendshipStatus !== FS_FRIEND_STATUS ?
      mergeUser(state, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS, ...user}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {user} = payload;
    return mergeUser(state, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_RECEIVED_STATUS, ...user});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION] = (state, {payload}) => {
    const {user, chat} = payload;
    const {[ID_FLD]:chatId} = chat || {}; // sic!: якщо чат не був створений, то chatId повинен бути undefined, щоб нічого НЕ змінювати !!!
    const myFriendIds = state.get('myFriendIds').add(user[ID_FLD]);
    return mergeUser(state, {[FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS, [DIRECT_CHAT_ID_FLD]: chatId, ...user})
      .set('myFriendIds', myFriendIds);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    const myFriendIds = state.get('myFriendIds').add(userId);
    return mergeUser(state, {id: userId, [FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS})
      .set('myFriendIds', myFriendIds);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return mergeUser(state, {id: userId, [FRIENDSHIP_STATUS_FLD]: FS_NONE_STATUS});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_WAS_CANCELED_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    const nextState = mergeUser(state, {id: userId, [FRIENDSHIP_STATUS_FLD]: FS_NONE_STATUS});
    return nextState
      .set('myFriendIds', nextState.get('myFriendIds').delete(userId));
  }

  // {{ USER }}
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USERS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users} = payload;
    return users ?
      users.reduce(mergeUser, state) :
      state;
  }

  // {{ PROFILE }}
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_PROFILES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users} = payload;
    return users ?
      users.map(u => Object.assign(u, {[IS_PROFILE_LOADED_FLD]: true})).reduce(mergeUser, state) :
      state;
  }

  // 1) формат {{ ACCOUNT }} містить більше полів чим {{ USER }}, тому їх потрібно відкинути;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    if (!account) {
      return state;
    }
    const {[ID_FLD]:myId} = account;
    const {
      // account fields to exclude:
      [TYPE_FLD]:accountType,               // sic!: 1)
      [BILLING_TYPE_FLD]:billingType,       // sic!: 1)
      [EXPIRED_AT_FLD]:expiredAt,           // sic!: 1)
      [COINS_AMOUNT_FLD]:coinsAmount,       // sic!: 1)
      [LANGUAGE_ID_FLD]:languageId,         // sic!: 1)
      [COUNTRY_ID_FLD]:countryId,           // sic!: 1)
      [ZIP_CODE_FLD]:zipCode,               // sic!: 1)
      [EMAIL_FLD]:email,                    // sic!: 1)
      [AVATAR_ID_FLD]:avatarId,             // sic!: 1)
      [IS_HELP_MODE_FLD]:isHelpMode,        // sic!: 1)
      ...formattedObject} = account;
    return mergeUser(state, formattedObject)
      .mergeIn(['myId'], myId);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CALL_STATE_WAS_SUBSTITUTED_ACTION] = (state, {payload}) => {
    const {callState} = payload;
    return state.mergeIn(['callState'], callState); // sic!: бо IDLE_STATE === '' !!!
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS()); // ToDo: DEV/TEST !!!
      return nextState;
    }
    return state;
  }
}

export default new UserStore(Dispatcher);
