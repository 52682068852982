// -------------------------------------------------------------------------------------------------
//  AdvertActions.js
//  - - - - - - - - - -
//  Advert actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' та робимо обробку catch-помилок;
//  - інформація про помилку міститься в status та errors обʼєкту response;
//  - в полі errors.formError повертаємо додаткову інформацію про помилки;
//  - запити для фідів повинні бути або з явно вказаним курсором, або DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  DISCOVER_ADVERTS_WERE_FETCHED_ACTION,
  SUBJECT_ADVERTS_WERE_FETCHED_ACTION,
  USER_ADVERTS_WERE_FETCHED_ACTION,
  ADVERT_WAS_FETCHED_AS_GUEST_ACTION,
  ADVERT_WAS_FETCHED_ACTION,
  ADVERT_WAS_CREATED_ACTION,
  ADVERT_WAS_UPDATED_ACTION,
  ADVERT_WAS_DELETED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  ADVERT_FLD,
  ADVERT_ID_FLD,
  ADVERTS_FLD,
  SUBJECTS_FLD,
  USERS_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR, TOPMOST_TSN12_CURSOR} from 'core/commonTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchDiscoverAdvertsAsGuest,
  apiFetchDiscoverAdverts,
  apiFetchSubjectAdverts,
  apiFetchUserAdverts,
  apiFetchAdvertAsGuest,
  apiFetchAdvert,
  apiCreateAdvert,
  apiUpdateAdvert,
  apiDeleteAdvert,
  apiSendAdvertFeedbackByEmail} from 'api/AdvertAPI';

const ADVERTS_PER_REQUEST_LIMIT = 16;     // типова к-сть стейплів за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - AdvertActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverAdvertsAsGuest(opts = {}) {
  trace(`fetchDiscoverAdvertsAsGuest`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchDiscoverAdvertsAsGuest({cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[ADVERTS_FLD]:adverts, [USERS_FLD]:users} = payload;
      if (adverts) {
        const areLoaded = adverts.length < limit;
        const nextCursor = adverts.length > 0 ?
          adverts.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: DISCOVER_ADVERTS_WERE_FETCHED_ACTION,
          payload: {
            users: users,
            adverts: adverts,
            advertCursor: nextCursor,
            areAdvertsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverAdvertsAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverAdverts(opts = {}) {
  trace(`fetchDiscoverAdverts`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchDiscoverAdverts({cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[ADVERTS_FLD]:adverts} = payload;
      if (adverts) {
        const areLoaded = adverts.length < limit;
        const nextCursor = adverts.length > 0 ?
          adverts.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: DISCOVER_ADVERTS_WERE_FETCHED_ACTION,
          payload: {
            adverts: adverts,
            advertCursor: nextCursor,
            areAdvertsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverAdverts: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSubjectAdverts(opts = {}) {
  trace(`fetchSubjectAdverts`);
  const {
    subjectId,                            // код предмета, по якому запитуємо оголошення
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchSubjectAdverts({subjectId, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[ADVERTS_FLD]:adverts} = payload;
      if (adverts) {
        const areLoaded = adverts.length < limit;
        const nextCursor = adverts.length > 0 ?
          adverts.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: SUBJECT_ADVERTS_WERE_FETCHED_ACTION,
          payload: {
            subjectId: subjectId,
            adverts: adverts,
            advertCursor: nextCursor,
            areAdvertsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserAdverts: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserAdverts(opts = {}) {
  trace(`fetchUserAdverts`);
  const {
    userId,                               // код юзера, по якому запитуємо стейпли
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchUserAdverts({userId, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[ADVERTS_FLD]:adverts} = payload;
      if (adverts) {
        const areLoaded = adverts.length < limit;
        const nextCursor = adverts.length > 0 ?
          adverts.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: USER_ADVERTS_WERE_FETCHED_ACTION,
          payload: {
            userId: userId,
            adverts: adverts,
            advertCursor: nextCursor,
            areAdvertsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserAdverts: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchAdvertAsGuest(id) {
  trace(`fetchAdvertAsGuest: advertId=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchAdvertAsGuest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchAdvertAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[ADVERT_FLD]:advert} = payload;
        if (advert) {
          Dispatcher.dispatch({
            type: ADVERT_WAS_FETCHED_AS_GUEST_ACTION,
            payload: {
              advert: advert,
            }
          });
        }
        break;
      default:
        traceError(`fetchAdvertAsGuest: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchAdvert(id) {
  trace(`fetchAdvert: advertId=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchAdvert(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchAdvert: OK, payload=${JSON.stringify(payload)}`);
        const {[ADVERT_FLD]:advert} = payload;
        if (advert) {
          Dispatcher.dispatch({
            type: ADVERT_WAS_FETCHED_ACTION,
            payload: {
              advert: advert,
            }
          });
        }
        return {status, errors, advert}; // sic!: повертаємо advert заради ownerId в розрахунку sidebarContext !!!
        break;
      default:
        traceError(`fetchAdvert: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createAdvert(advert) {
  trace(`createAdvert`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateAdvert(advert);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createAdvert: OK, payload=${JSON.stringify(payload)}`);
        const {[ADVERT_FLD]:createdAdvert} = payload;
        if (createdAdvert) {
          Dispatcher.dispatch({
            type: ADVERT_WAS_CREATED_ACTION,
            payload: {
              advert: createdAdvert,
            }
          });
          ReactGA.event({
            category: gaCategories.MARKETPLACE,
            action: gaActions.ADVERT_CREATED
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`createAdvert: status=${status}`);
        break
      case 'subjects_processing_error':
        traceError(`createAdvert: status=${status}`);
        errors.formError = status;
        break;
      default:
        traceError(`createAdvert: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateAdvert(advert, patchObject) {
  trace(`updateAdvert`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateAdvert(advert, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateAdvert: OK, payload=${JSON.stringify(payload)}`);
        const {[ADVERT_FLD]:updatedAdvert} = payload;
        const {lstSubjects:prevAdvertSubjects = []} = advert || {};
        const {[SUBJECTS_FLD]:tgxSubjects = {}} = updatedAdvert || {};
        const nextAdvertSubjectIds = Object.keys(JSON.parse(tgxSubjects)) || [];
        // ...1) формуємо список предметів, які були видалені із попереднього оголошення
        const removedSubjectIds = prevAdvertSubjects.reduce((acc, currSubj) => {
          const {i:subjectId} = currSubj;
          const isSubjectDeleted = nextAdvertSubjectIds.findIndex(id => id === subjectId) < 0;
          return isSubjectDeleted ? acc.concat(subjectId) : acc;
        }, []);
        // ...2) формуємо список предметів, які були додані до попереднього оголошення
        const appendedSubjectIds = nextAdvertSubjectIds.reduce((acc, subjectId) => {
          const isSubjectAdded = prevAdvertSubjects.findIndex(sj => sj.i === subjectId) < 0;
          return isSubjectAdded ? acc.concat(subjectId) : acc;
        }, []);
        if (updatedAdvert) {
          Dispatcher.dispatch({
            type: ADVERT_WAS_UPDATED_ACTION,
            payload: {
              advert: updatedAdvert,
              appendedSubjectIds: appendedSubjectIds,
              removedSubjectIds: removedSubjectIds,
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`updateAdvert: status=${status}`);
        break
      default:
        traceError(`updateAdvert: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

//  Attn: - в параметрах передаємо АДВЕРТ-обʼєкт (а не id), бо потрібна інфа про змінені предмети;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteAdvert(advert) {
  trace(`deleteAdvert`);
  let status;
  let payload;
  let errors = {};
  try {
    const {id} = advert;
    const response = await apiDeleteAdvert(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    // - - - - -
    // FixMe: 'forbidden' при видаленні вже видаленого оголошення !!!
    // видає 'forbidden' при спробі видалити оголошення, яке було видалене в іншій вкладці;
    // потрібно обробити ситуацію - напр. видалити це оголошення із списку ???
    // - - - - -
    switch(status) {
      case 'ok':
        trace(`deleteAdvert: OK, payload=${JSON.stringify(payload)}`);
        const {[ADVERT_ID_FLD]:deletedId} = payload || [];
        if (id === deletedId) {
          const {lstSubjects:advertSubjects = []} = advert || {};
          const affectedSubjectIds = advertSubjects.reduce((acc, currSubj) => {
            const {[ID_FLD]:subjectId} = currSubj;
            return subjectId ? acc.concat(subjectId) : acc;
          }, []);
          Dispatcher.dispatch({
            type: ADVERT_WAS_DELETED_ACTION,
            payload: {
              deletedId: deletedId,
              affectedSubjectIds: affectedSubjectIds,
            }
          });
          ReactGA.event({
            category: gaCategories.MARKETPLACE,
            action: gaActions.ADVERT_DELETED
          });
        }
        break;
      default:
        traceError(`deleteAdvert: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendAdvertFeedbackByEmail(advertId, ownerId, name, phone, description) {
  trace(`sendAdvertFeedbackByEmail: phone=${phone}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiSendAdvertFeedbackByEmail(advertId, ownerId, name, phone, description);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendAdvertFeedbackByEmail: status=${status}, id=${phone}`);
        // const {[USER_FLD]:user} = payload;
        // // attn: якщо юзер зареєстрований в системі, то user !== undefined;
        // if (user) {
        //   Dispatcher.dispatch({
        //     type: ..._ACTION,
        //     payload: {
        //       user: user,
        //     }
        //   });
        // }
        ReactGA.event({
          category: gaCategories.MARKETPLACE,
          action: gaActions.ADVERT_FEEDBACK_SENT,
          label: `by-email`
        });
        break;
      default:
        traceError(`sendAdvertFeedbackByEmail: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
