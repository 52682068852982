// -------------------------------------------------------------------------------------------------
//  MediaActions.js
//  - - - - - - - - - -
//  Image, Video & Audio actions.
// -------------------------------------------------------------------------------------------------
import {
  AVATAR_ID_FLD,
  AVATAR_XHASH2_FLD,
  AVATAR_EXT_FLD,
  PICTURE_ID_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {apiUploadAvatar, apiUploadPicture} from 'api/MediaAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - MediaActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function uploadAvatar(file, onProgress) {
  trace(`uploadAvatar`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUploadAvatar(file, onProgress);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`uploadAvatar: OK, payload=${JSON.stringify(payload)}`);
        const {
          [AVATAR_ID_FLD]:avatarId,
          [AVATAR_XHASH2_FLD]:avatarXhash2,
          [AVATAR_EXT_FLD]:avatarExt} = payload;
        return {status, errors, avatarId, avatarXhash2, avatarExt}; // sic!: повертаємо avatarId, avatarXhash2, avatarExt !!!
        break;
      case 'unsupported_type':
        traceError(`uploadAvatar: status=${status}`);
        errors.formError = status;
        break;
      default:
        traceError(`uploadAvatar: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function uploadPicture(file, onProgress) {
  trace(`uploadPicture`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUploadPicture(file, onProgress);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`uploadPicture: OK, payload=${JSON.stringify(payload)}`);
        const {
          [PICTURE_ID_FLD]:pictureId,
          [PICTURE_XHASH2_FLD]:pictureXhash2,
          [PICTURE_EXT_FLD]:pictureExt} = payload;
        return {status, errors, pictureId, pictureXhash2, pictureExt}; // sic!: повертаємо pictureId, xhash2, ext !!!
        break;
      case 'unsupported_type':
        traceError(`uploadPicture: status=${status}`);
        errors.formError = status;
        break;
      default:
        traceError(`uploadPicture: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
