// -------------------------------------------------------------------------------------------------
//  rich-codeblock.js
//  - - - - - - - - - -
//  CodeBlock node: plugin.
//
//  Notes:
//  - - - - -
//  - [mod+enter] will exit the code container and create 'exit' block type;
//  - [backspace] at start of empty code container, will turn it into the 'exitBlockType';
//
//  Attn:
//  - - - - -
//  - CODEBLOCK заборонений в контексті TABLE;
//  - всередині CODEBLOCK заборонені інші типи, окрім тексту;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {blockTypes} from 'core/richTypes';
import styles from './rich-codeblock.scss';

export const defaultCodeBlockOptions = {
  containerType: blockTypes.CODEBLOCK,    // type of the code containers
  lineType: blockTypes.CODEBLOCK_LINE,    // type of the code lines
  exitBlockType: blockTypes.PARAGRAPH,    // type of 'exit' block container
  onExit: null,                           // exitBlockType option is useless if onExit is provided
  allowMarks: false,                      // allow marks inside code blocks
  selectAll: true,                        // should the plugin handle the select all inside a code container
  getIndent: null,                        // returns the indent unit to use at the given selection, as a string
  getSyntax: (node) => node.data.get('syntax'), // get language name function
};

const renderCodeBlock = (options) => {
  const CodeBlock = ({attributes, children, node}) => {
    const syntax = options.getSyntax(node);
    return (
      <div className={styles.CodeBlock}>
        <div className={styles.language} contentEditable={false}>
          {syntax || 'TXT'}
        </div>
        <pre spellCheck={false}>
          <code {...attributes}>
            {children}
          </code>
        </pre>
      </div>
    );
  };

  CodeBlock.displayName = 'codeblock-node';
  return CodeBlock;
}

const renderCodeBlockLine = () => {
  const CodeLine = ({attributes, children}) => {
    return (
      <div {...attributes}>
        {children}
      </div>
    );
  };

  CodeLine.displayName = 'codeblock-line-node';
  return CodeLine;
}

const CodeBlockPlugin = (opts = {}) => {
  const options = Object.assign(defaultCodeBlockOptions, opts);

  return {
    renderNode: (props) => {
      if (props.node.type === options.containerType) {
        return renderCodeBlock(options)(props);

      } else if (props.node.type === options.lineType) {
        return renderCodeBlockLine()(props);
      }
    }
  }
};

export default CodeBlockPlugin;
