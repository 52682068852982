// -------------------------------------------------------------------------------------------------
//  SettingsContainer.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchSystemLanguages, fetchSystemCountries} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const Settings = Loader.create(() => import('./Settings'));
const isVerbose = DEBUG && true;
const prefix = '- - - SettingsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class SettingsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myAccount = AccountStore.getMyAccount();
    const languages = SystemStore.getLanguages();
    const countries = SystemStore.getCountries();
    return {myAccount, languages, countries};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = !is(this.state.myAccount, nextState.myAccount)
      || !is(this.state.languages, nextState.languages)
      || !is(this.state.countries, nextState.countries);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    // ToDo: доробити на використання списку мов з БД !!!
    // if (!SystemStore.areLanguagesLoaded()) {
    //   await fetchSystemLanguages();
    // }
    if (!SystemStore.areCountriesLoaded()) {
      await fetchSystemCountries();
    }
  }

  render() {
    const {myAccount, languages, countries} = this.state;
    trace(`render`);
    return (
      <Settings
        myAccount={myAccount}
        languages={languages}
        countries={countries}
      />
    );
  }
}

export default Container.create(SettingsContainer, {pure: false});
