// -------------------------------------------------------------------------------------------------
//  ContactActions.js
//  - - - - - - - - - -
//  Contact & MyContact actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' та робимо обробку catch-помилок;
//  - інформація про помилку міститься в status та errors обʼєкту response;
//  - в полі errors.formError повертаємо додаткову інформацію про помилки;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
//  - запит контактів fetchMyContacts() обовʼязково робити ДО запиту юзерів fetchMyFriends() !!!
//    Причина: Якщо першим виконується запит юзерів, то в процесі обробки інформація про
//    привʼязаних юзерів заноситься тільки в ІСНУЮЧІ контакти - нові контакти НЕ створюються,
//    немає куди записати і інформація про контакт втрачається (friendshipStatus, ...).
//    Тоді ж як при обробці списку контактів в сторі UserStore створюються юзери для кожного
//    отриманого контакту і заповнюються поля про контакт (contactId, contactName, ...).
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_CONTACTS_WERE_FETCHED_ACTION,
  CONTACT_WAS_CREATED_ACTION,
  CONTACT_WAS_UPDATED_ACTION,
  CONTACT_WAS_DELETED_ACTION,
  CONTACT_WAS_SELECTED_ACTION,
  CONTACT_WAS_UNSELECTED_ACTION,
  CONTACTS_WERE_UNSELECTED_ACTION,
  CONTACTS_OF_GROUP_WERE_SELECTED_ACTION,
  CONTACTS_OF_GROUP_WERE_UNSELECTED_ACTION} from 'core/actionTypes';
import {
  CONTACT_FLD,
  CONTACT_ID_FLD,
  CONTACT_IDS_FLD,
  CONTACTS_FLD,
  GROUPS_FLD,
  USER_ID_FLD,
  USER_SLUG_FLD,
  USER_ALIAS_FLD,
  USER_AVATAR_SOURCE_URL_FLD,
  USER_XHASH2_FLD,
  USER_EXT_FLD,
  AVATAR_XHASH2_FLD,
  AVATAR_EXT_FLD,
  BLOB_FLD,
  IS_FRIEND_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {fetchMyGroups} from 'actions/GroupActions';
import {substitutePresetGroups} from 'actions/LayoutActions';
import {
  apiFetchMyContacts,
  apiCreateContact,
  apiUpdateContact,
  apiDeleteContact} from 'api/ContactAPI';
import UserStore from 'stores/UserStore';

const isVerbose = DEBUG && true;
const prefix = '- - - ContactActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let areMyContactsFetched = false;         // чи викликали завантаження списку контактів?

// 1) Attn: Запит контактів fetchMyContacts() обовʼязково робити ДО запиту юзерів fetchMyFriends() !!!
//    - - - - -
//    Причина: Якщо першим виконується запит юзерів, то в процесі обробки інформація про
//    привʼязаних юзерів заноситься тільки в ІСНУЮЧІ контакти - нові контакти НЕ створюються,
//    немає куди записати і інформація про контакт втрачається (friendshipStatus, ...).
//    Тоді ж як при обробці списку контактів в сторі UserStore створюються юзери для кожного
//    отриманого контакту і заповнюються поля про контакт (contactId, contactName, ...).
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyContacts(isForced = false) {
  trace(`fetchMyContacts`);

  const fetcher = async () => {
    let resp;
    if (!areMyContactsFetched || isForced) {
      try {
        areMyContactsFetched = true;
        resp = await apiFetchMyContacts();
      } catch(error) {
        areMyContactsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[CONTACTS_FLD]:contacts} = payload;
        if (contacts) {
          const areLoaded = true; // sic!: тому що для контактів поки немає пагінації
          Dispatcher.dispatch({
            type: MY_CONTACTS_WERE_FETCHED_ACTION,
            payload: {
              contacts: contacts,
              areContactsLoaded: areLoaded,
            }
          });
        }
      } else {
        traceError(`fetchMyContacts: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

//  Attn:
//  - якщо контакт просто створюється, то поля про привʼязаного юзера НЕ заповнені;
//  - якщо контакт створюється на основі картки юзера, то додатково беремо зі стору інформацію
//    і заповнюємо поля (userAlias, userAvatarSourceUrl, ...);
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createContact(contact, tempMediaUrl) {
  trace(`createContact`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateContact(contact);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createContact: OK, payload=${JSON.stringify(payload)}`);
        const {[CONTACT_FLD]:newContact} = payload;
        if (newContact) {
          // sic!: превьюшки генеруються з затримкою, тому ініціалізуємо з канвасу !!!
          if (tempMediaUrl) {
            Object.assign(newContact, {[BLOB_FLD]: tempMediaUrl});
          }
          // ...встановлюємо дефолтні групи
          const contactGroups = newContact[GROUPS_FLD];
          if (contactGroups) {
            await substitutePresetGroups(Object.keys(JSON.parse(contactGroups)));
          }
          // ...визначаємо, чи юзер створював нові групи (т.з. плюс-групи)
          const {userId, groups:tgxGroups} = contact;
          const hasPlusGroups = !!Object.keys(tgxGroups || {}).find(elem => elem.startsWith('+'));
          const appendedGroupIds = Object.keys(tgxGroups);
          // ...якщо контакт має привʼязаного юзера, тоді заповнюємо відповідні поля !!!
          if (userId) {
            const {
              id:userIdFound,
              alias:userAlias = undefined,
              avatarSourceUrl:userAvatarSourceUrl = undefined,
              xhash2:userXhash2 = undefined,
              ext:userExt = undefined} = UserStore.getUser(userId) || {};
            if (userIdFound) {
              newContact[USER_ALIAS_FLD] = userAlias;
              newContact[USER_AVATAR_SOURCE_URL_FLD] = userAvatarSourceUrl;
              newContact[USER_XHASH2_FLD] = userXhash2;
              newContact[USER_EXT_FLD] = userExt;
            }
          }
          Dispatcher.dispatch({
            type: CONTACT_WAS_CREATED_ACTION,
            payload: {
              contact: newContact,
              hasPlusGroups: hasPlusGroups,
              appendedGroupIds: appendedGroupIds,
            }
          });
          // counts: примусове оновлення списку груп, якщо юзер додав НОВІ групи
          if (hasPlusGroups) {
            fetchMyGroups(true);
          }
          ReactGA.event({
            category: gaCategories.CRM,
            action: gaActions.CONTACT_CREATED
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`createContact: status=${status}`);
        break
      case 'groups_processing_error':
        traceError(`createContact: status=${status}`);
        errors.formError = status;
        break;
      default:
        traceError(`createContact: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// Attn: 1) превьюшки генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateContact(contact, patchObject, tempMediaUrl) {
  trace(`updateContact`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateContact(contact, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateContact: OK, payload=${JSON.stringify(payload)}`);
        const {[CONTACT_FLD]:updContact} = payload;
        if (updContact) {
          if (tempMediaUrl) {
            Object.assign(updContact, {[BLOB_FLD]: tempMediaUrl}); // attn: 1)
          }
          // ...визначаємо, чи юзер створював НОВІ групи (т.з. плюс-групи)
          const {groups:patchTgxGroups} = patchObject;
          const hasPlusGroups = !!Object.keys(patchTgxGroups || {}).find(elem => elem.startsWith('+'));
          if (!hasPlusGroups) {
            // ...вибіркове оновлення списку колекцій, бо юзер НЕ створював нові колекції
            const {lstGroups:prevContactGroups = []} = contact || {};
            const {[GROUPS_FLD]:tgxGroups = {}} = updContact || {};
            const nextContactGroupIds = Object.keys(JSON.parse(tgxGroups)) || [];
            const appendedGroupIds = nextContactGroupIds.reduce((acc, groupId) => {
              const isGroupAdded = prevContactGroups.findIndex(coll => coll.i === groupId) < 0;
              return isGroupAdded ? acc.concat(groupId) : acc;
            }, []);
            const removedGroupIds = prevContactGroups.reduce((acc, currGrp) => {
              const {i:groupId} = currGrp;
              const isGroupDeleted = nextContactGroupIds.findIndex(id => id === groupId) < 0;
              return isGroupDeleted ? acc.concat(groupId) : acc;
            }, []);
            Dispatcher.dispatch({
              type: CONTACT_WAS_UPDATED_ACTION,
              payload: {
                contact: updContact,
                hasPlusGroups: hasPlusGroups,
                appendedGroupIds: appendedGroupIds,
                removedGroupIds: removedGroupIds,
              }
            });
          } else {
            // ...повне оновлення списку груп, бо юзер створив НОВІ групи !!!
            Dispatcher.dispatch({
              type: CONTACT_WAS_UPDATED_ACTION,
              payload: {
                contact: updContact,
                hasPlusGroups: hasPlusGroups,
                appendedGroupIds: [], // оскільки: 2) --> []
                removedGroupIds: [], // оскільки: 2) --> []
              }
            });
            if (hasPlusGroups) {
              fetchMyGroups(true); // бо юзер створював НОВІ групи !!!
            }
          }
        }
        break;
      case 'invalid_formdata':
        traceError(`updateContact: status=${status}`);
        break
      default:
        traceError(`updateContact: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteContact(contact) {
  trace(`deleteContact`);
  let status;
  let payload;
  let errors = {};
  const {id} = contact;
  try {
    const response = await apiDeleteContact(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    // - - - - -
    // FixMe: 'forbidden' при видаленні вже видаленої групи !!!
    // видає 'forbidden' при спробі видалити групу, яка була видалена в іншій вкладці;
    // потрібно обробити ситуацію - напр. видалити цю групу із списку ???
    // - - - - -
    switch(status) {
      case 'ok':
        trace(`deleteContact: OK, payload=${JSON.stringify(payload)}`);
        const {[CONTACT_ID_FLD]:contactId} = payload;
        if (contactId) {
          Dispatcher.dispatch({
            type: CONTACT_WAS_DELETED_ACTION,
            payload: {
              contactId: contactId,
            }
          });
          ReactGA.event({
            category: gaCategories.CRM,
            action: gaActions.CONTACT_DELETED
          });
        }
        break;
      default:
        traceError(`deleteContact: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setContactSelected(contactId) {
  trace(`setContactSelected`);
  Dispatcher.dispatch({
    type: CONTACT_WAS_SELECTED_ACTION,
    payload: {
      contactId: contactId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setContactUnselected(contactId) {
  trace(`setContactUnselected`);
  Dispatcher.dispatch({
    type: CONTACT_WAS_UNSELECTED_ACTION,
    payload: {
      contactId: contactId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setAllContactsUnselected() {
  trace(`setAllContactsUnselected`);
  Dispatcher.dispatch({
    type: CONTACTS_WERE_UNSELECTED_ACTION,
    payload: {},
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setContactsOfGroupSelected(groupId) {
  trace(`setContactsOfGroupSelected`);
  Dispatcher.dispatch({
    type: CONTACTS_OF_GROUP_WERE_SELECTED_ACTION,
    payload: {
      groupId: groupId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setContactsOfGroupUnselected(groupId) {
  trace(`setContactsOfGroupUnselected`);
  Dispatcher.dispatch({
    type: CONTACTS_OF_GROUP_WERE_UNSELECTED_ACTION,
    payload: {
      groupId: groupId
    },
  });
}
