// -------------------------------------------------------------------------------------------------
//  conmarkTypes.js
//  - - - - - - - - - -
//  Conmark (Contentle Markup) enumerated types and constants.
// -------------------------------------------------------------------------------------------------

export const conmarkTokens = {
  BREAK:                '\\',
};

export const EMPTY_CONMARK = "\\\n\n";
