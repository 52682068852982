// -------------------------------------------------------------------------------------------------
//  RichEditor.js
//  - - - - - - - - - -
//  Rich text editor (на основі редактора Slate).
//  Саме тут задаємо список усіх можливих плагінів та їх параметри.
//
//  Notes:
//  - - - - -
//  https://github.com/ianstormtaylor/slate
//  https://github.com/ianstormtaylor/slate/blob/master/docs/general/plugins.md
//
//  API:
//  - - - - -
//  https://docs.slatejs.org/slate-core/document
//  https://docs.slatejs.org/slate-core/change
//  https://docs.slatejs.org/slate-core/node
//  https://docs.slatejs.org/slate-core/block
//  https://docs.slatejs.org/slate-core/inline
//  https://docs.slatejs.org/slate-core/text
//  https://docs.slatejs.org/slate-core/character
//  https://docs.slatejs.org/slate-core/mark
//
//  https://docs.slatejs.org/slate-react/editor
//  https://docs.slatejs.org/slate-react/plugins
//  https://docs.slatejs.org/slate-react/core-plugins
//
//  Plugins:
//  - - - - -
//  https://yarnpkg.com/en/package/slate-collapse-on-escape (CollapseOnEscape)
//  https://yarnpkg.com/en/package/slate-edit-blockquote (EditBlockquote)
//  https://yarnpkg.com/en/package/slate-edit-code (EditCode)
//  https://yarnpkg.com/en/package/slate-edit-list (EditList)
//  https://yarnpkg.com/en/package/slate-edit-table (EditTable)
//  https://yarnpkg.com/en/package/slate-paste-linkify (PasteLinkify)
//  https://yarnpkg.com/en/package/slate-trailing-block (TrailingBlock)
//  https://www.npmjs.com/package/slate-prism (Prism)
//
//  Not Used:
//  - - - - - - - -
//  https://yarnpkg.com/en/package/slate-soft-break (SoftBreak) - we need hard break, not soft
//  https://yarnpkg.com/en/package/slate-edit-footnote - ERROR
//  https://yarnpkg.com/en/package/slate-sticky-inlines - ERROR
//
//  ToDo: https://yarnpkg.com/en/package/slate-drop-or-paste-images (InsertImages)
//  ToDo: https://yarnpkg.com/en/package/slate-no-empty (NoEmpty)
//  ToDo: https://yarnpkg.com/en/package/slate-suggestions (SuggestionsPlugin)
// -------------------------------------------------------------------------------------------------
// ToDo: впорядкувати список встановлених пакунків !!!
// ToDo: оформити редактор у вигляді окремого продукту !!!
// ToDo: зробити toolbar, який адаптується до ширини полоси перегляду з кнопками [next], [prev] !!!
// FixMe: текст, що починається на цифру і закінчується крапкою перетворюється на список з втратою чисел (додати перевірку по довжині тексту) !!!
// FixMe: не показуються усі цифри в нумерованому списку, у який вкладено ненумерований !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {Editor} from 'slate-react';
import CollapseOnEscapePlugin from 'slate-collapse-on-escape';
import EditBlockquotePlugin from 'slate-edit-blockquote';
import EditCodePlugin from 'slate-edit-code';
import EditListPlugin from 'slate-edit-list';
import EditTablePlugin from 'slate-edit-table';
import PasteLinkifyPlugin from 'slate-paste-linkify';

// import InsertImagesPlugin from 'slate-drop-or-paste-images';
// import NoEmptyPlugin from 'slate-no-empty';
// import SuggestionsPlugin from 'slate-suggestions';
// import HtmlSerializer from 'slate-html-serializer';
// import PlainSerializer from 'slate-plain-serializer';

import {blockTypes, inlineTypes} from 'core/richTypes';
import AutoReplacePlugin from './rich-autoreplace';
import BoldPlugin, {BoldIcon} from './core/rich-mark-bold';
import ItalicPlugin, {ItalicIcon} from './core/rich-mark-italic';
import StrikePlugin, {StrikeIcon} from './core/rich-mark-strike';
import BlockquotePlugin, {defaultBlockquoteOptions, BlockquoteIcon} from './core/rich-blockquote';
import CodeBlockPlugin, {defaultCodeBlockOptions} from './core/rich-codeblock';
import InlineCodePlugin from './core/rich-inlinecode';
import HeadingOnePlugin, {HeadingOneIcon} from './core/rich-heading-h1';
import HeadingTwoPlugin, {HeadingTwoIcon} from './core/rich-heading-h2';
import HeadingThreePlugin, {HeadingThreeIcon} from './core/rich-heading-h3';
import HeadingFourPlugin from './core/rich-heading-h4';
import HeadingFivePlugin from './core/rich-heading-h5';
import HeadingSixPlugin from './core/rich-heading-h6';
import HorizontalPlugin from './core/rich-hr';
import LinkPlugin from './core/rich-link';
import ListPlugin, {defaultListOptions} from './core/rich-list';
import PrismPlugin from './rich-prism';
import TablePlugin, {
  defaultTableOptions,
  TableCreateIcon,
  TableDeleteIcon,
  TableInsertRowIcon,
  TableInsertColumnIcon,
  TableRemoveRowIcon,
  TableRemoveColumnIcon} from './core/rich-table';
import toolbarDecorator from './RichToolbar';
import 'styles/prism.css';
import styles from './RichEditor.scss';

const pasteLinkifyOptions = {
  type: inlineTypes.LINK,
};

const availablePlugins = [
  // ...vendor:
  CollapseOnEscapePlugin(),
  EditBlockquotePlugin(defaultBlockquoteOptions),
  EditCodePlugin(defaultCodeBlockOptions),
  EditListPlugin(defaultListOptions),
  EditTablePlugin(defaultTableOptions),
  PasteLinkifyPlugin(pasteLinkifyOptions),
  // ...custom:
  AutoReplacePlugin(),
  BoldPlugin(),
  ItalicPlugin(),
  StrikePlugin(),
  BlockquotePlugin(),
  CodeBlockPlugin(),
  InlineCodePlugin(),
  HeadingOnePlugin(),
  HeadingTwoPlugin(),
  HeadingThreePlugin(),
  HeadingFourPlugin(),
  HeadingFivePlugin(),
  HeadingSixPlugin(),
  HorizontalPlugin(),
  LinkPlugin(),
  ListPlugin(),
  PrismPlugin(),
  TablePlugin(),
];

const toolbarOptions = {
  icons: [
    BoldIcon,
    ItalicIcon,
    StrikeIcon,
    BlockquoteIcon,
    HeadingOneIcon,
    HeadingTwoIcon,
    HeadingThreeIcon,
    TableCreateIcon,
    TableInsertColumnIcon,
    TableInsertRowIcon,
    TableRemoveColumnIcon,
    TableRemoveRowIcon,
    TableDeleteIcon,
  ],
  position: 'top',                        // 'top' | 'bottom'
  disabledTypes: [                        // disable toolbar in specified block types
    blockTypes.CODEBLOCK,
    blockTypes.CODEBLOCK_LINE,
  ],
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const renderPlaceholder = (props) => {
  const {node, editor} = props;
  if (!editor.props.placeholder) return;
  if (editor.state.isComposing) return;
  if (node.object !== 'block') return;
  if (node.text !== '') return;
  if (editor.value.document.getBlocks().size > 1) return;
  return (
    <span className={styles.placeholder} contentEditable={false}>
      {editor.props.placeholder}
    </span>
  );
};

//  More info about Editor here:
//  https://docs.slatejs.org/slate-react/editor#plugin-like-props
//  https://docs.slatejs.org/slate-react/plugins
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export class SimpleRichEditor extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    inputRef: Types.object,               // forwarded reference to input object
    value: Types.object,                  // value to edit
    plugins: Types.array,                 // list of plugins
    placeholder: Types.string,            // placeholder text
    autoFocus: Types.bool,                // is editor field focused?
    isDisabled: Types.bool,               // is editor disabled?
    onBeforeInput: Types.func,            // is called right before a string is inserted into the element
    onBlur: Types.func,                   // is called when the editor's contenteditable element is blurred
    onFocus: Types.func,                  // is called when the editor's contenteditable element is focused
    onCopy: Types.func,                   // is called when there is a copy event in the editor's contenteditable element
    onCut: Types.func,                    // is equivalent to the onCopy handler
    onDrop: Types.func,                   // is called when the user drops content into the contenteditable element
    onPaste: Types.func,                  // is called when the user pastes content into the contenteditable element
    onKeyDown: Types.func,                // is called when any key is pressed in the contenteditable element, before any action is taken
    onChange: Types.func,                 // is called on any content change
  };

  static defaultProps = {
    plugins: availablePlugins,
    placeholder: '...',
    autoFocus: false,
    isDisabled: false,
  };

  handleChange = (change) => {
    this.props.onChange && this.props.onChange(change);
  }

  handleKeyDown = (event, change, editor) => {
    // Example:
    // - - - -
    // if (event.key === 'Enter'
    //   && event.altKey === false
    //   && event.ctrlKey === false
    //   && event.metaKey === false
    //   && event.shiftKey === true)
    // {
    //   console.log(`- - - handleKeyDown: ENTER`);
    // }
    this.props.onKeyDown && this.props.onKeyDown(event, change, editor);
  }

  render() {
    const {
      className,
      inputRef,
      value,
      plugins,
      placeholder,
      autoFocus,
      onBeforeInput,
      onBlur,
      onFocus,
      onCopy,
      onCut,
      onDrop,
      onPaste,
      isDisabled} = this.props;
    // sic!: ref={inputRef}
    return (
      <Editor
        className={classnames(styles.RichEditor, className)}
        ref={inputRef}
        value={value}
        plugins={plugins}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={isDisabled}
        renderPlaceholder={renderPlaceholder}
        onBeforeInput={onBeforeInput}
        onBlur={onBlur}
        onFocus={onFocus}
        onCopy={onCopy}
        onCut={onCut}
        onDrop={onDrop}
        onPaste={onPaste}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
      />
    );
  }
}

export default toolbarDecorator(toolbarOptions)(SimpleRichEditor);
