// -------------------------------------------------------------------------------------------------
//  gradeTypes.js
//  - - - - - - - - - -
//  All grade values.
//
//  Attn: 1)
//  - - - - -
//  Коди оцінок присвоєні саме такі для вирішення двох задач:
//  - значення поля id повинно бути довжиною САМЕ два символи!!! (?REPLICA2_ID_LEN);
//  - відображення сортуванням від найвищої ('A+', код: 'A1') до найнижчої ('F', код: 'F2');
//  - формуванні назв текстовою заміною: '1' --> '+', '2' --> '', '3' --> '-';
// -------------------------------------------------------------------------------------------------

export const A1_GRADE                     = 'A1';     // оцінка A+
export const A2_GRADE                     = 'A2';     // оцінка A
export const A3_GRADE                     = 'A3';     // оцінка A-
export const B1_GRADE                     = 'B1';     // оцінка B+
export const B2_GRADE                     = 'B2';     // оцінка B
export const B3_GRADE                     = 'B3';     // оцінка B-
export const C1_GRADE                     = 'C1';     // оцінка C+
export const C2_GRADE                     = 'C2';     // оцінка C
export const C3_GRADE                     = 'C3';     // оцінка C-
export const D1_GRADE                     = 'D1';     // оцінка D+
export const D2_GRADE                     = 'D2';     // оцінка D
export const D3_GRADE                     = 'D3';     // оцінка D-
export const F2_GRADE                     = 'F2';     // оцінка F

export const MC_GRADE                     = 'MC';     // 12 coins
export const MB_GRADE                     = 'MB';     // 11 coins
export const MA_GRADE                     = 'MA';     // 10 coins
export const M9_GRADE                     = 'M9';     // 9 coins
export const M8_GRADE                     = 'M8';     // 8 coins
export const M7_GRADE                     = 'M7';     // 7 coins
export const M6_GRADE                     = 'M6';     // 6 coins
export const M5_GRADE                     = 'M5';     // 5 coins
export const M4_GRADE                     = 'M4';     // 4 coins
export const M3_GRADE                     = 'M3';     // 3 coins
export const M2_GRADE                     = 'M2';     // 2 coins
export const M1_GRADE                     = 'M1';     // 1 coin
