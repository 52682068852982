// -------------------------------------------------------------------------------------------------
//  Grade.js
//  - - - - - - - - - -
//  Оцінка до стейплу.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import {
  A1_GRADE, A2_GRADE, A3_GRADE,
  B1_GRADE, B2_GRADE, B3_GRADE,
  C1_GRADE, C2_GRADE, C3_GRADE,
  D1_GRADE, D2_GRADE, D3_GRADE,
  F2_GRADE,
  MC_GRADE, MB_GRADE, MA_GRADE,
  M9_GRADE, M8_GRADE, M7_GRADE,
  M6_GRADE, M5_GRADE, M4_GRADE,
  M3_GRADE, M2_GRADE, M1_GRADE} from 'core/gradeTypes';
import Icon from 'components/UI/icons/Icon';
import styles from './Grade.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getGradeTitle = (gradeId) => {
  switch(gradeId) {
    case A1_GRADE:  return t`Grade A1`;
    case A2_GRADE:  return t`Grade A2`;
    case A3_GRADE:  return t`Grade A3`;
    case B1_GRADE:  return t`Grade B1`;
    case B2_GRADE:  return t`Grade B2`;
    case B3_GRADE:  return t`Grade B3`;
    case C1_GRADE:  return t`Grade C1`;
    case C2_GRADE:  return t`Grade C2`;
    case C3_GRADE:  return t`Grade C3`;
    case D1_GRADE:  return t`Grade D1`;
    case D2_GRADE:  return t`Grade D2`;
    case D3_GRADE:  return t`Grade D3`;
    case F2_GRADE:  return t`Grade F2`;
    // - - -
    case MC_GRADE:  return t`12 Coins`;
    case MB_GRADE:  return t`11 Coins`;
    case MA_GRADE:  return t`10 Coins`;
    case M9_GRADE:  return t`9 Coins`;
    case M8_GRADE:  return t`8 Coins`;
    case M7_GRADE:  return t`7 Coins`;
    case M6_GRADE:  return t`6 Coins`;
    case M5_GRADE:  return t`5 Coins`;
    case M4_GRADE:  return t`4 Coins`;
    case M3_GRADE:  return t`3 Coins`;
    case M2_GRADE:  return t`2 Coins`;
    case M1_GRADE:  return t`1 Coin`;
    default:
      return gradeId;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const Grade = ({className, iconClassName, iconName = "coin", gradeId, hasTitle = false}) => (
  gradeId === MC_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === MB_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === MA_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M9_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M8_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M7_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M6_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M5_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M4_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M3_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M2_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
  gradeId === M1_GRADE ?
    <div className={classnames(styles.Grade, className)}>
      {hasTitle && <div className={styles.title}>{getGradeTitle(gradeId)}</div>}
      <Icon symbolName={iconName} className={classnames(styles.icon, iconClassName)} />
    </div> :
    null
);

Grade.propTypes = {
  className: Types.string,                // custom class name
  iconClassName: Types.string,            // class name for the component's icon
  iconName: Types.string,                 // назва символу, що відображає оцінку ('star', 'coin', ...)
  gradeId: Types.string,                  // код оцінки ('A1', 'A2', 'A3', ...)
};

export default Grade;
