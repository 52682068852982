// -------------------------------------------------------------------------------------------------
//  CollectionStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки стейплів вказаної колекції.
//
//  Attn:
//  - - - - -
//  - якщо колекцію не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в колекцію контекст сайдбару встановлюємо на закладку колекцій (COLLECTION_TAB);
//  - функція getCollectionStaples віддає стейпли тільки ДО курсора, щоб не було "пропусків" в фіді;
//
//  Attn: a)
//  - - - - -
//  - колекції різних типів не показуються ОДНОЧАСНО;
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається feedType та яке API буде застосовано;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {List, Map, Set, OrderedSet, is} from 'immutable';
import {DEFAULT_POS10_CURSOR} from 'core/commonTypes';
import {DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes'; // ToDo: COLLECTION_CTX !!!
import {COLLECTION_STAPLES_FEED, COURSE_STAPLES_FEED} from 'core/uiTypes';
import {fetchCollection, fetchCollectionAsGuest} from 'actions/CollectionActions';
import {fetchCollectionStaples, fetchCollectionStaplesAsGuest} from 'actions/StapleActions';
import {fetchSystemSubjects, fetchSystemSubjectsAsGuest} from 'actions/SystemActions';
import {fetchUsers, fetchUsersAsGuest} from 'actions/UserActions';
import {substitutePresetCollections} from 'actions/LayoutActions'; // ToDo: COLLECTION_CTX - substituteSidebarContext !!!
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const CollectionStaples = Loader.create(() => import('./CollectionStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - CollectionStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CollectionStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про стейпл?
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CollectionStore, StapleStore, SystemStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const {collectionId} = props.match.params;
    const feedType = collectionType === COURSE_COLLECTION ? COURSE_STAPLES_FEED : COLLECTION_STAPLES_FEED; // attn: a) !!!
    const {[feedType]:viewMode, isSidebarOpen} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const collection = collectionId ? CollectionStore.getCollection(collectionId) : null;
    const staples = collection ? StapleStore.getCollectionStaples(collectionId) : OrderedSet();
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    const relatedCollections = staples && staples.size > 0 ?
      staples.reduce((acc, currStaple) => {
        const localColls = currStaple.lstCollections || [];
        return localColls.reduce((localAccumulator, currColl) => {
          return localAccumulator.setIn([currColl.i], currColl);
        }, acc);
      }, Map())
        .toList()
        .sort((a, b) => { return ((a.t + a.n.toLowerCase()) < (b.t + b.n.toLowerCase())) ? -1 : 1; }) : // attn: sorting collections by type + name !!!
      List();
    const unreadCountsByStaple = isLoggedIn ? ChatStore.getUnreadCountsByCtxType(STAPLE_CTX) : Map();
    const allSubjects = SystemStore.getSubjectList();
    const stapleCursor = collection ? StapleStore.getCollectionStapleCursor(collectionId) : DEFAULT_POS10_CURSOR;
    const areStaplesLoaded = collection ? StapleStore.areCollectionStaplesLoaded(collectionId) : false;
    return {
      isLoggedIn, collectionType, myAccountType, myId, owners, staples, collection, relatedCollections,
      unreadCountsByStaple, allSubjects, feedType, viewMode, isSidebarOpen, stapleCursor, areStaplesLoaded
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.isSidebarOpen !== nextState.isSidebarOpen
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.state.relatedCollections.size !== nextState.relatedCollections.size
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.collectionType !== nextState.collectionType
      || this.state.feedType !== nextState.feedType
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.collection, nextState.collection)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {collectionId} = this.props.match.params;
    const {isLoggedIn, collection} = this.state;
    const {ownerId:ownerId1} = collection || {};
    let ownerId2;
    if (collectionId && !CollectionStore.isCollectionLoaded(collectionId)) {
      const result = isLoggedIn ?
        await fetchCollection(collectionId) :
        await fetchCollectionAsGuest(collectionId);
      ({ownerId:ownerId2} = result || {});
      if (ownerId2) {
        if (!UserStore.isUserLoaded(ownerId2)) {
          isLoggedIn ?
            await fetchUsers([ownerId2]) :
            await fetchUsersAsGuest([ownerId2]);
        }
      }
    }
    if (isLoggedIn) {
      const ownerId = ownerId1 || ownerId2;
      if (collectionId) {
        await substitutePresetCollections([collectionId]);
      }
      // ToDo: COLLECTION_CTX !!!
      // if (collectionId && ownerId) {
      //   await substituteSidebarContext({ctxId: collectionId, ctxType: COLLECTION_CTX, ctxOwnerId: ownerId});
      // }
    }
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
    this.setState({isServerRequested: true});
  }

  async componentDidUpdate() {
    trace(`componentDidUpdate`);
    const {collectionId} = this.props.match.params;
    if (collectionId) {
      await substitutePresetCollections([collectionId]);
      // ToDo: COLLECTION_CTX !!!
      // const {pathname = ''} = this.context.router.history.location;
      // const isCollection = pathname.includes(`/co/`); // sic!: це повинен бути url колекції
      // trace(`===> componentDidUpdate: isCollection=${isCollection}, pathname=${pathname}`);
      //
      // if (isCollection) {
      //   const {collection} = this.state;
      //   const {ownerId} = collection || {};
      //
      //   if (ownerId) {
      //     await substituteSidebarContext({ctxId: collectionId, ctxType: COLLECTION_CTX, ctxOwnerId: ownerId});
      //   }
      // }
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {collectionId} = nextProps.match.params;
    const {isLoggedIn} = this.state;
    this.setState({isServerRequested: false});
    if (collectionId && !CollectionStore.isCollectionLoaded(collectionId)) {
      const {ownerId} = isLoggedIn ?
        await fetchCollection(collectionId) :
        await fetchCollectionAsGuest(collectionId);
      if (ownerId) {
        if (!UserStore.isUserLoaded(ownerId)) {
          isLoggedIn ?
            await fetchUsers([ownerId]) :
            await fetchUsersAsGuest([ownerId]);
        }
      }
    }
  }

  isFetchingCollectionStaples = false;

  fetchCollectionStaplesPage = async () => {
    trace(`fetchCollectionStaplesPage(1): isFetching=${this.isFetchingCollectionStaples}`);
    const {isLoggedIn, collection, stapleCursor = DEFAULT_POS10_CURSOR} = this.state;
    const {id:collectionId} = collection || {};
    if (collectionId && !this.isFetchingCollectionStaples) {
      trace(`fetchCollectionStaplesPage(2): collectionId=${collectionId}, cursor='${stapleCursor}'`);
      this.isFetchingCollectionStaples = true;
      isLoggedIn ?
        await fetchCollectionStaples({collectionId: collectionId, cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT}) :
        await fetchCollectionStaplesAsGuest({collectionId: collectionId, cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT});
      this.isFetchingCollectionStaples = false;
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const {
      isServerRequested,
      isLoggedIn,
      collectionType,
      myAccountType,
      myId,
      owners,
      staples,
      collection,
      relatedCollections,
      unreadCountsByStaple,
      allSubjects,
      feedType,
      viewMode,
      isSidebarOpen,
      areStaplesLoaded} = this.state;
    if (isServerRequested && !collection) {
      return (
        <NotFoundPage />
      );
    }
    trace(`render`);
    return (
      <CollectionStaples
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        staples={staples}
        collection={collection}
        relatedCollections={relatedCollections}
        unreadCountsByStaple={unreadCountsByStaple}
        allSubjects={allSubjects}
        pathname={pathname}
        feedType={feedType}
        viewMode={viewMode}
        isSidebarOpen={isSidebarOpen}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchCollectionStaplesPage}
      />
    );
  }
}

export default Container.create(CollectionStaplesContainer, {withProps: true, pure: false});
