// -------------------------------------------------------------------------------------------------
//  RichField.js
//  - - - - - - - - - -
//  Поле для вводу тексту з розміткою та утиліти роботи з форматами тексту.
//
//  Attn:
//  - - - -
//  - жорстко прописана url-адреса '/markdown' для додаткової інформації про формат;
//
//  Docs:
//  - - -
//  https://docs.slatejs.org
//  https://docs.slatejs.org/guides
//  https://github.com/ianstormtaylor/slate
//  https://github.com/ianstormtaylor/slate/tree/master/examples
// -------------------------------------------------------------------------------------------------
// ToDo: зробити функцію, що по rich-формату визначає чи поле порожнє (без перетворень) !!!
// ToDo: додати роботу з шаблонами для вводу початкового тексту !!!
// ToDo: опцію, щоб натискання ENTER не додавало новий символ (бо признак завершення вводу) !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import PictButton from 'components/UI/buttons/PictButton';
import RichEditor, {SimpleRichEditor} from 'components/RichEditor';
import ConmarkProcessor from 'components/RichEditor/rich-conmark-processors';
import MdastProcessor from 'components/RichEditor/rich-mdast-processors';
import styles from './RichField.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function toRich(conmark) {
  return MdastProcessor.toRich(ConmarkProcessor.toMdast(conmark || '\\'));
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isRichEmpty(rich) {
  return (ConmarkProcessor.fromMdast(MdastProcessor.fromRich(rich)) + '')
      .search(/[^ \n\r\t\v\f\0\\\|\/\#\*\>\_\[\]\(\)]/) < 0;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function toConmark(rich) {
  return (ConmarkProcessor.fromMdast(MdastProcessor.fromRich(rich)) + '');
}

// 	Validate if Conmark text is empty
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isConmarkEmpty(value) {
  let val = value || '';
  val = val.replaceAll(/[ \\\b\r\n\t]/g, '');
  return !val;
}

//  https://www.w3schools.com/charsets/ref_emoji_smileys.asp
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyConmark(value) {
  let result = '';
  let val = value || '';
  val = val.replace(/[`]/g, `'`);                 // replace '`' --> '''
  val = val.replace(/^"/g, '«');                  // replace '"' --> '«' (start of the string)
  val = val.replace(/[ \b\r\n\t]{1,}"/g, ' «');   // replace ' "' --> ' «'
  val = val.replace(/["]/g, '»');                 // replace '"'  --> '»'
  val = val.replace(/^[\\\b\n\r\t ]{1,}/g,'');    // remove leading spaces & control symbols
  val = val.replace(/:\)\)/g, '😂');              // replace ':)!'     --> '😂'
  val = val.replace(/:\)/g, '😀');                // replace ':)'      --> '😀'
  val = val.replace(/:;\)/g, '😘');               // replace ':;)'     --> '😘'
  val = val.replace(/:\(/g, '😕');                // replace ':)'      --> '😕'
  val = val.replace(/:hot/g, '🔥');               // replace ':hot'    --> '🔥'
  val = val.replace(/:heart/g, '💖');             // replace ':heart'  --> '💖'
  val = val.replace(/:like/g, '👍');              // replace ':like'   --> '👍'
  val = val.replace(/:love/g, '😍');              // replace ':love'   --> '😍'
  val = val.trim();
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class RichField extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name (wrapper)
    editorClassName: Types.string,        // custom class name for the editor field
    errorClassName: Types.string,         // custom class name for the error message field
    inputRef: Types.object,               // forwarded reference to input object
    value: Types.object,                  // field value
    error: Types.string,                  // error message
    label: Types.string,                  // label text (shown above field)
    placeholder: Types.string,            // placeholder text (shown in field)
    autoFocus: Types.bool,                // is editor field focused?
    hasToolbar: Types.bool,               // has editor the toolbar?
    hasRequirementMark: Types.bool,       // has special 'is required' mark?
    isDisabled: Types.bool,               // is field disabled?
    onBeforeInput: Types.func,            // is called right before a string is inserted into the element
    onBlur: Types.func,                   // is called when the editor's contenteditable element is blurred
    onFocus: Types.func,                  // is called when the editor's contenteditable element is focused
    onCopy: Types.func,                   // is called when there is a copy event in the editor's contenteditable element
    onCut: Types.func,                    // is equivalent to the onCopy handler
    onDrop: Types.func,                   // is called when the user drops content into the contenteditable element
    onPaste: Types.func,                  // is called when the user pastes content into the contenteditable element
    onKeyDown: Types.func,                // is called when any key is pressed in the contenteditable element, before any action is taken
    onChange: Types.func,                 // is called on any content change
  }

  static defaultProps = {
    className: '',
    editorClassName: '',
    errorClassName: '',
    error: '',
    label: '',
    placeholder: '',
    autoFocus: false,
    hasToolbar: true,
    hasRequirementMark: false,
    isDisabled: false,
  }

  static contextTypes = {
    router: Types.object.isRequired,
  }

  handleOpenMarkdownUrl = () => {
    this.context.router.history.push('/markdown');
  }

  render() {
    const {
      className,
      editorClassName,
      errorClassName,
      inputRef,
      value,
      error,
      label,
      placeholder,
      autoFocus,
      hasToolbar,
      hasRequirementMark,
      isDisabled,
      onBeforeInput,
      onBlur,
      onFocus,
      onCopy,
      onCut,
      onDrop,
      onPaste,
      onKeyDown,
      onChange} = this.props;
    const EditorToRender = hasToolbar ? RichEditor : SimpleRichEditor;
    return (
      <div className={classnames(styles.RichField, className, {
        [styles.isDisabled]: isDisabled,
        [styles.isError]: error,
      })}>
        {label &&
          <div className={styles.label}>{label}{hasRequirementMark && <span>*</span>}:
            <PictButton
              title={`Markdown Powered Field`}
              className={styles.markdownButton}
              iconClassName={styles.icon}
              symbolName="markdown-outline"
              isDisabled={false}
              onClick={this.handleOpenMarkdownUrl}
            />
          </div>
        }
        <EditorToRender
          className={classnames(styles.editor, editorClassName)}
          inputRef={inputRef}
          value={value}
          placeholder={isDisabled ? '' : placeholder}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          onBeforeInput={!isDisabled ? onBeforeInput : undefined}
          onBlur={!isDisabled ? onBlur : undefined}
          onFocus={!isDisabled ? onFocus : undefined}
          onCopy={!isDisabled ? onCopy : undefined}
          onCut={!isDisabled ? onCut : undefined}
          onDrop={!isDisabled ? onDrop : undefined}
          onPaste={!isDisabled ? onPaste : undefined}
          onKeyDown={!isDisabled ? onKeyDown : undefined}
          onChange={!isDisabled ? onChange : undefined}
        />
        {error &&
          <div className={classnames(styles.error, errorClassName)}>{error}</div>
        }
      </div>
    );
  }
}
