// -------------------------------------------------------------------------------------------------
//  RichToolbar.js
//  - - - - - - - - - -
//  Декорує RichEditor контекстним тулбаром.
// -------------------------------------------------------------------------------------------------
// ToDo: поява тулбара тільки на MouseUp (якщо ми не відпустили кнопку - не показувати) !!!
// ToDo: fixed Toolbar + onScroll event для зміни позиції !!!
// ToDo: тулбар ховається під сайдбар (враховувати позицію до краю Layout) !!!

import React from 'react';
import classnames from 'classnames';
import {getVisibleSelectionRect} from 'get-selection-range';
import styles from './RichToolbar.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default (options = {}) => {
  let i = 0;
  let {icons = [], position = 'top', disabledTypes = []} = options;

  return (Editor) => {
    class ToolbarWrapper extends React.Component {
      constructor(props) {
        super(props);
      }

      toolbarWrapperNode = undefined;
      toolbarNode = undefined;

      componentDidMount() {
        window.addEventListener('scroll', () => this.componentDidUpdate());
      }

      componentWillUnmount() {
        window.removeEventListener('scroll', () => this.componentDidUpdate());
      }

      componentDidUpdate() {
        const {value} = this.props;
        const rect = getVisibleSelectionRect();
        if (!rect || !this.toolbarNode || !this.toolbarWrapperNode) {
          return;
        }
        // hide toolbar for disabled node types
        if (
          value.blocks.find(block =>
            disabledTypes.find(
              type => type === value.document.getParent(block.key).type
            )
          ) ||
          value.blocks.find(block =>
            disabledTypes.find(type => type === block.type)
          )
        ) {
          this.toolbarNode.style.display = 'none';
          return;
        }
        this.toolbarNode.style.display = 'flex';
        const containerBound = this.toolbarWrapperNode.getBoundingClientRect();
        const {left: containerBoundLeft, top: containerBoundTop} = containerBound;
        const left = rect.left + rect.width / 2 - containerBoundLeft - this.toolbarNode.offsetWidth / 2;
        this.toolbarNode.style.left = `${left}px`;
        if (position === 'bottom') {
          const top = rect.top - containerBoundTop + this.toolbarNode.offsetHeight;
          this.toolbarNode.style.top = `${top}px`;
        } else if (position === 'top') {
          const top = rect.top - containerBoundTop - this.toolbarNode.offsetHeight - 4;
          this.toolbarNode.style.top = `${top}px`;
        }
      }

      renderIcon = (IconType) => {
        const {value, onChange} = this.props;
        if (IconType === 'divider') {
          return <div className={styles.toolbarDivider} key={i++} />;
        }
        return (
          <IconType
            key={i++}
            change={value.change()}
            onChange={onChange}
          />
        );
      }

      renderToolbar = () => {
        const {value} = this.props;
        // don't show empty toolbar
        if (!icons || icons.length === 0) return;
        return (
          value.isExpanded &&
          value.isFocused && (
            <div
              className={classnames(styles.Toolbar, {
                [styles.isTop]: position === 'top',
                [styles.isBottom]: position === 'bottom',
              })}
              ref={(ref) => { this.toolbarNode = ref; }}
            >
              {icons.map(this.renderIcon)}
            </div>
          )
        );
      }

      render() {
        return (
          <div className={styles.ToolbarWrapper} ref={(ref) => { this.toolbarWrapperNode = ref; }} >
            {this.renderToolbar()}
            <Editor {...this.props} />
          </div>
        );
      }
    } // class ToolbarWrapper

    return class ToolbarDecorator extends React.Component {
      render() {
        return (
          <ToolbarWrapper {...this.props} />
        );
      }
    }; // return class ToolbarDecorator
  }; // return func Editor
}; // return func toolbarDecorator
