// -------------------------------------------------------------------------------------------------
//  humanFormats.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import dateFormat from 'dateformat';
import {t} from 'ttag';

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanDateLong(date) {
  const then = new Date(date);
  const now = new Date();
  const diff = now - then;
  if (isNaN(diff)) {
    return t`Invalid Date`;
  }
  if (diff > YEAR) {
    const years = Math.floor(diff / YEAR);
    return years > 1 ? t`${years} years ago` : t`a year ago`;
  }
  if (diff > MONTH) {
    const months = Math.floor(diff / MONTH);
    return months > 1 ? t`${months} months ago` : t`a month ago`;
  }
  if (diff > WEEK) {
    const weeks = Math.floor(diff / WEEK);
    return weeks > 1 ? t`${weeks} weeks ago` : t`a week ago`;
  }
  if (diff > DAY) {
    const days = Math.floor(diff / DAY);
    return days > 1 ? t`${days} days ago` : t`a day ago`;
  }
  if (diff > HOUR) {
    const hours = Math.floor(diff / HOUR);
    return hours > 1 ? t`${hours} hours ago` : t`a hour ago`;
  }
  if (diff > MINUTE) {
    const minutes = Math.floor(diff / MINUTE);
    return minutes > 1 ? t`${minutes} minutes ago` : t`a minute ago`;
  }
  return t`just now`;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanDateShort(date) {
  const then = new Date(date);
  const now = new Date();
  const diff = now - then;
  if (isNaN(diff)) {
    return t`Invalid Date`;
  }
  if (diff > YEAR) {
    const years = Math.floor(diff / YEAR);
    return years > 1 ? t`${years}yrs` : t`1yr`;
  }
  if (diff > MONTH) {
    const months = Math.floor(diff / MONTH);
    return months > 1 ? t`${months}mo` : t`1mo`;
  }
  if (diff > WEEK) {
    const weeks = Math.floor(diff / WEEK);
    return weeks > 1 ? t`${weeks}w` : t`1w`;
  }
  if (diff > DAY) {
    const days = Math.floor(diff / DAY);
    return days > 1 ? t`${days}d` : t`1d`;
  }
  if (diff > HOUR) {
    const hours = Math.floor(diff / HOUR);
    return hours > 1 ? t`${hours}h` : t`1h`;
  }
  if (diff > MINUTE) {
    const minutes = Math.floor(diff / MINUTE);
    return minutes > 1 ? t`${minutes}min` : t`1min`;
  }
  return t`just now`;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanDate(date, format = t`mmm d, yyyy HH:MM`) {
  const then = new Date(date);
  const now = new Date();
  const diff = now - then;
  if (isNaN(diff)) {
    return t`Invalid Date`;
  }
  if (diff > DAY) {
    return dateFormat(date, format); // Default: "Jun 9, 2007 17:46"
  }
  if (diff > HOUR) {
    const hours = Math.floor(diff / HOUR);
    return hours > 1 ? t`${hours} hours ago` : t`a hour ago`;
  }
  if (diff > MINUTE) {
    const minutes = Math.floor(diff / MINUTE);
    return minutes > 1 ? t`${minutes} minutes ago` : t`a minute ago`;
  }
  return t`just now`;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanNumber(number) {
  if (number > 1e3 && number <= 1e6) {
    return Math.floor(number / 1e3) + 'K';
  }
  if (number > 1e6 && number <= 1e9) {
    return Math.floor(number / 1e6) + 'M';
  }
  if (number > 1e9) {
    return Math.floor(number / 1e9) + 'Bln';
  }
  return number;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanNumberK(number) {
  if (number > 1e6 && number <= 1e9) {
    return Math.floor(number / 1e3) + 'K';
  }
  if (number > 1e9) {
    return Math.floor(number / 1e6) + 'M';
  }
  return number;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function humanCount(number) {
  return number && number > 0 ? number < 100 ? '' + number : '99+' : '';
}
