// -------------------------------------------------------------------------------------------------
//  commonMark.js
//  - - - - - - - - - -
//  Mark type node helpers.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import isHotkey from 'is-hotkey';

export function renderMark(Tag) {
  const MarkComponent = ({attributes, children, mark}) => {
    return (
      <Tag
        {...attributes}
        data-slate-type={Tag}
      >
        {children}
      </Tag>
    );
  };
  MarkComponent.displayName = `${Tag}-mark`;
  return MarkComponent;
}

export function markPlugin(options, hotkey) {
  return {
    onKeyDown(event, change) {
      if (isHotkey(hotkey, event)) {
        change.call(change => change.toggleMark(options.type));
      }
    },
    renderMark: (props) => {
      if (props.mark.type === options.type) {
        return renderMark(options.tagName)(props);
      }
    },
  }
}
