// -------------------------------------------------------------------------------------------------
//  table.js
//  - - - - - - - - - -
//  '--|--|--...|--[SPACE]' --> Table(cols, 2)
//
//  Attn:
//  - - - - -
//  - TABLE заборонений в контексті CODEBLOCK, TABLE (антирекурсивність);
//  - всередині TABLE заборонені інші типи, окрім INLINE_CODE, LINK, BOLD, ITALIC, STRIKE;
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import EditTablePlugin from 'slate-edit-table';
import {defaultTableOptions} from 'components/RichEditor/core/rich-table';

const {changes, utils} = EditTablePlugin(defaultTableOptions);

export default function autoreplaceTable(node, matched, change) {
  const matchedLength = matched[0].length;
  const matchedCols = ((matched[0].match(/\|/g) || []).length) + 1 || 2;

  if (!utils.isSelectionInTable(change.value)) {
    change.deleteAtRange(
      Range.create({
        anchorKey: node.key,
        focusKey: node.key,
        anchorOffset: matched.index,
        focusOffset: matched.index + matchedLength
      })
    );

    // add extra SPACES before & after table
    change.insertText('  ').move(-1);

    return changes.insertTable(change, matchedCols, 2);
  }

  return change;
}
