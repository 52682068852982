// -------------------------------------------------------------------------------------------------
//  bold.js
//  - - - - - - - - - -
//  {**{...}** | __{...}__}[SPACE] ---> <strong>{...}</strong>
// -------------------------------------------------------------------------------------------------
import {Mark, Range} from 'slate';
import {markTypes} from 'core/richTypes';
import {appendSpace, removeAllMarks} from '../utils';

export default function autoreplaceBold(node, matched, change) {
  const matchedLength = matched[0].length;
  const reg = matched[1] === '**' ? /\*\*/ : matched[1];
  const addedText = matched[0].trim().replace(new RegExp(reg, 'g'), '');

  change.deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );

  change.insertTextByKey(node.key, matched.index, addedText, [
    Mark.create({type: markTypes.BOLD})
  ]);

  // if previous char is SPACE append SPACE outside(!) of bold markup
  if (matched[0][0] === ' ') {
    change.call(appendSpace, node, matched.index);
  }

  change.call(removeAllMarks);

  return change;
}
