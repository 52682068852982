// -------------------------------------------------------------------------------------------------
//  systemTools.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import UAParser from 'ua-parser-js';

let serverResetTime = null;
let performanceResetTime = null;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getServerTime() {
  return Math.ceil(serverResetTime + (performance.now() - performanceResetTime));
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function resetServerTime(serverTimeStamp) {
  serverResetTime = new Date(serverTimeStamp).getTime();
  performanceResetTime = performance.now();
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isIOS() {
  return /iphone|ipad/i.test(navigator.userAgent);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isWindows() {
  return /win/i.test(navigator.userAgent);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isMediaCaptureSupported() {
  const input = document.createElement('input');
  return 'capture' in input;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getOsName() {
  const {os} = (new UAParser()).getResult();
  return (os.name || '').toLowerCase();
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getBrowserName() {
  const {browser} = (new UAParser()).getResult();
  return (browser.name || '').toLowerCase();
}

// https://stackoverflow.com/questions/44491084/javascript-create-a-a-element-and-open-it-in-a-new-tab
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    href: href,
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  }).click();
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";
    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

// Math.max(currentIndex - 1, 0)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getPreviousIndex(currentIndex = 0, elementsQty = 1) {
  return (elementsQty - ((elementsQty - currentIndex + 1) % elementsQty)) % elementsQty;
}

// Math.min(currentIndex + 1, elementsQty - 1)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getNextIndex(currentIndex = 0, elementsQty = 1) {
  return (currentIndex + 1) % elementsQty;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function setCookie(name, value, days = 7) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function eraseCookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
