// -------------------------------------------------------------------------------------------------
//  UserTopicStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки тем для спілкування поточного користувача (контекстів типу стейпл).
//
//  Attn: 1)
//  - - - - -
//  Для ініціалізації на сайдбарі прямого чату з юзером і таби CHAT_TAB враховувати/робити таке:
//  - після перезавантаження сторінки на момент виконання componentDidMount() значення поля
//    user.directChatId встановлюється НЕ ВІДРАЗУ (стор не встигає обробити API getMyFriends());
//  - тому доводиться викликати setSidebar() із componentDidUpdate;
//  - isSetSidebarRepaired потрібен щоб викликати setSidebar() із componentDidUpdate лише ОДИН раз;
//  - щоб обійти помилку "Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch"
//    доводиться робити затримку через setTimeout ~50ms;
//
//  Attn:
//  - - - - -
//  - список ідентифікаторів контекстів зберігається в UserStore.users.contextIds.{s | c | u}.[id, ...];
//  - список UserStore.users.contextIds при запиті спільних чатів чи при створенні нових з юзером;
//  - пагінація стрічки контекстів ведеться по списку ЧАТІВ (ендпоінт: fd.u.chats);
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, ACTION_TAB, CHAT_TAB, USER_TOPIC_STAPLES_FEED} from 'core/uiTypes';
import {fetchUserContexts} from 'actions/ChatActions';
import {fetchUsers, fetchUsersBySlugs} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import CrmStore from 'stores/CrmStore';
import ChatStore from 'stores/ChatStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const UserTopicStaples = Loader.create(() => import('./UserTopicStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - UserTopicStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let isSetSidebarRepaired = false;         // чи викликали функцію setSidebar?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class UserTopicStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, CollectionStore, StapleStore, UserStore];
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про юзера?
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {userId:uid, userSlug} = props.match.params;
    const {[USER_TOPIC_STAPLES_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const user = uid ? UserStore.getUser(uid) : userSlug ? UserStore.getUserBySlug(userSlug) : null;
    const {id:userId = null} = user || {};
    const unreadIds = isLoggedIn ? ChatStore.getUnreadIds() : Set();
    const unreadCountsByStaple = ChatStore.getUnreadCountsByCtxType(STAPLE_CTX);
    const {stapleIds, stapleCursor, areStaplesLoaded} = userId ? ChatStore.getUserTopicStaplesFeed(userId) : {};
    const staples = StapleStore.getSomeStaples(stapleIds);
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    const hasAnySelected = myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, user, owners, staples, viewMode, unreadIds, unreadCountsByStaple,
      stapleCursor, areStaplesLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.unreadIds.size !== nextState.unreadIds.size
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners)
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {userId, userSlug} = this.props.match.params;
    const {isLoggedIn, myId, user} = this.state;
    if (userId) {
      if (!UserStore.isUserLoaded(userId)) {
        await fetchUsers([userId]);
      }
    } else if (userSlug) {
      if (!UserStore.isUserLoadedBySlug(userSlug)) {
        await fetchUsersBySlugs([userSlug]);
      }
    }
    this.setState({isServerRequested: true});
    this.setSidebar(); // attn: 1)
  }

  // CTX: Attn: 1)
  async componentDidUpdate(prevProps, prevState) {
    const {user:prevUser} = prevState;
    const {isLoggedIn, user} = this.state;
    if (isLoggedIn) {
      const {directChatId:prevDirectChatId} = prevUser || {};
      const {directChatId} = user || {};
      if (prevDirectChatId === '' && directChatId) {
        trace(`componentDidUpdate: directChatId =`, directChatId);
        if (!isSetSidebarRepaired) {
          setTimeout(this.setSidebar, 50);
          isSetSidebarRepaired = true;
        }
      }
    }
  }

  // CTX: Attn: 1)
  setSidebar = async () => {
    trace(`setSidebar`);
    const {isLoggedIn, myId, user, hasAnySelected} = this.state;
    if (isLoggedIn) {
      if (user && user.id !== myId) {
        await substituteSidebarContext({ctxId: user.id, ctxType: USER_CTX, ctxOwnerId: user.id});
        if (user.directChatId) {
          await substituteSidebarTabMode(CHAT_TAB, true);
          await substituteSidebarChat({chatId: user.directChatId});
        } else {
          await substituteSidebarTabMode(ACTION_TAB);
          await substituteSidebarChat({chatId: ''});
        }
      }
      if (user && user.id === myId) {
        await substituteSidebarTabMode(COLLECTION_TAB);
        await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
        await substituteSidebarChat({chatId: ''});
      }
    }
  }

  isFetchingMyChats = false;

  fetchUserContextsPage = async () => {
    trace(`fetchUserContextsPage(1): isFetching=${this.isFetchingMyChats}`);
    if (!this.isFetchingMyChats) {
      const {user, stapleCursor = DEFAULT_TSN12_CURSOR} = this.state;
      const {id:userId} = user || {};
      if (userId) {
        trace(`fetchUserContextsPage(2): userId=${userId}, cursor='${stapleCursor}'`);
        this.isFetchingMyChats = true;
        await fetchUserContexts({userId, ctxType: STAPLE_CTX, cursor: stapleCursor, limit: CONTEXTDATA_FEED_FETCH_LIMIT});
        this.isFetchingMyChats = false;
      }
    }
  }

  render() {
    const {
      isServerRequested,
      isLoggedIn,
      myId,
      user,
      owners,
      staples,
      viewMode,
      unreadIds,
      unreadCountsByStaple,
      areStaplesLoaded} = this.state;
    if (isServerRequested && !user) {
      return (
        <NotFoundPage />
      );
    }
    trace(`render`);
    return (
      <UserTopicStaples
        isLoggedIn={isLoggedIn}
        myId={myId}
        user={user}
        owners={owners}
        staples={staples}
        viewMode={viewMode}
        unreadIds={unreadIds}
        unreadCountsByStaple={unreadCountsByStaple}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchUserContextsPage}
      />
    );
  }
}

export default Container.create(UserTopicStaplesContainer, {withProps: true, pure: false});
