// -------------------------------------------------------------------------------------------------
//  rich-link.js
//  - - - - - - - - - -
//  Link node: plugin.
//
//  Attn:
//  - - - - -
//  - rel="nofollow noopener noreferrer" повинно бути обовʼязково !!!
// -------------------------------------------------------------------------------------------------
// ToDo: LinkIcon + LinkPicker !!!

import React from 'react';
import {inlineTypes} from 'core/richTypes';

export const defaultLinkOptions = {
  type: inlineTypes.LINK,
  getHref: (node) => node.data.get('href')
};

function renderLinkNode(options) {
  const LinkNode = ({attributes, children, node}) => {
    return (
      <a
        {...attributes}
        href={options.getHref(node)}
        rel="nofollow noopener noreferrer"
        data-slate-type="link"
      >
        {children}
      </a>
    );
  };
  LinkNode.displayName = 'link-node';
  return LinkNode;
}

const LinkPlugin = (opts = {}) => {
  const options = Object.assign(defaultLinkOptions, opts);

  return {
    renderNode: (props) => {
      if (props.node.type === options.type) {
        return renderLinkNode(options)(props);
      }
    },
  }
};

export default LinkPlugin;
