// -------------------------------------------------------------------------------------------------
//  rich-blockquote.js
//  - - - - - - - - - -
//  Blockquote node: plugin & toolbar icon.
//
//  Attn:
//  - - - - -
//  - BLOCKQUOTE заборонений в контексті CODEBLOCK, TABLE;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import isHotkey from 'is-hotkey';
import EditBlockquotePlugin from 'slate-edit-blockquote';
import RichIcon from '../RichIcon';
import {blockTypes, hotkeyTypes, htmlTagTypes} from 'core/richTypes';
import {isInTableContext} from './utils';
import {renderNode} from './commonNode';

export const defaultBlockquoteOptions = {
  type: blockTypes.BLOCKQUOTE,            // type for blockquotes
  typeDefault: blockTypes.PARAGRAPH,      // type for default block in blockquote
  tagName: htmlTagTypes.BLOCKQUOTE,       // html tag
  hotkey: hotkeyTypes.BLOCKQUOTE,         // shortcut key
};

const EditBlockquote = EditBlockquotePlugin(defaultBlockquoteOptions);

function toggleBlockquote(change) {
  return EditBlockquote.utils.isSelectionInBlockquote(change.value)
    ? EditBlockquote.changes.unwrapBlockquote(change)
    : EditBlockquote.changes.wrapInBlockquote(change);
}

export class BlockquoteIcon extends React.Component {
  onClick = (event) => {
    let {change, onChange} = this.props;

    event.preventDefault();
    onChange(toggleBlockquote(change));
  }

  render() {
    const {change, ...other} = this.props;
    const onClick = event => this.onClick(event);

    if (isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="textnote"
        commandName={blockTypes.BLOCKQUOTE}
        isActive={EditBlockquote.utils.isSelectionInBlockquote(change.value)}
        onClick={onClick}
        {...other}
      />
    );
  }
}

const BlockquotePlugin = (opts = {}) => {
  const options = Object.assign(defaultBlockquoteOptions, opts);

  return {
    onKeyDown(event, change) {
      if (isHotkey(options.hotkey, event)) {
        return toggleBlockquote(change);
      }
    },
    renderNode: (props) => {
      if (props.node.type === options.type) {
        return renderNode(options.tagName)(props);
      }
    },
  }
};

export default BlockquotePlugin;
