// -------------------------------------------------------------------------------------------------
//  GAListener.js
//  - - - - - - - - - -
//  https://github.com/react-ga/react-ga
//  https://developers.google.com/analytics/devguides/collection/analyticsjs/events
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import ReactGA from 'react-ga4';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class GAListener extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
  }

  render() {
    return null;
  }
}
