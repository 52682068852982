// -------------------------------------------------------------------------------------------------
//  MyCommunityUsersContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки юзерів з якими у користувача є прямий чат (з ким користувач може спілкуватись).
//
//  Attn:
//  - - - - -
//  - запит контактів fetchMyContacts() обовʼязково робити ДО запиту юзерів fetchMyFriends() !!!
//    Причина: Якщо першим виконується запит юзерів, то в процесі обробки інформація про
//    привʼязаних юзерів заноситься тільки в ІСНУЮЧІ контакти - нові контакти НЕ створюються,
//    немає куди записати і інформація про контакт втрачається (friendshipStatus, ...).
//    Тоді ж як при обробці списку контактів в сторі UserStore створюються юзери для кожного
//    отриманого контакту і заповнюються поля про контакт (contactId, contactName, ...).
// -------------------------------------------------------------------------------------------------
// ToDo: переробити з пагінацією списка друзів !!!
// ToDo: перевіряти чи пройшло завантаження з серверу (щоб не показувати "коробку з мухою") !!!

import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {UCTX_CHAT, SCTX_CHAT} from 'core/communicationTypes';
import {GROUP_TAB, SELECTION_TAB, MY_COMMUNITY_USERS_FEED} from 'core/uiTypes';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyCommunityUsers = Loader.create(() => import('./MyCommunityUsers'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyCommunityUsersContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyCommunityUsersContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {[MY_COMMUNITY_USERS_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const users = UserStore.getMyDirectUsers();
    const unreadCountsByDirectChat = ChatStore.getUnreadCountsByChatType(UCTX_CHAT);
    const areFriendsLoaded = UserStore.areMyFriendsLoaded();
    const hasAnySelected = myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, users, viewMode, unreadCountsByDirectChat,
      areFriendsLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.areFriendsLoaded !== nextState.areFriendsLoaded
      || this.state.unreadCountsByDirectChat.size !== nextState.unreadCountsByDirectChat.size
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.users, nextState.users)
      || !is(this.state.unreadCountsByDirectChat, nextState.unreadCountsByDirectChat);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn, hasAnySelected} = this.state;
    // CTX:
    if (isLoggedIn) {
      await substituteSidebarTabMode(!hasAnySelected ? GROUP_TAB : SELECTION_TAB);
      await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      await substituteSidebarChat({chatId: ''});
    }
  }

  render() {
    trace(`render`);
    const {isLoggedIn, myId, users, viewMode, unreadCountsByDirectChat, areFriendsLoaded} = this.state;
    return (
      <MyCommunityUsers
        isLoggedIn={isLoggedIn}
        myId={myId}
        users={users}
        viewMode={viewMode}
        unreadCountsByDirectChat={unreadCountsByDirectChat}
        areFriendsLoaded={areFriendsLoaded}
      />
    );
  }
}

export default Container.create(MyCommunityUsersContainer, {pure: false});
