// -------------------------------------------------------------------------------------------------
//  MyContactsContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки контактів користувача.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Map, is} from 'immutable';
import {GROUP_TAB, SELECTION_TAB} from 'core/uiTypes';
import {MY_CONTACTS_FEED} from 'core/uiTypes';
import {fetchMyContacts, setAllContactsUnselected} from 'actions/ContactActions';
import {setAllStaplesUnselected} from 'actions/StapleActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyContacts = Loader.create(() => import('./MyContacts'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyContactsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyContactsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {[MY_CONTACTS_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const user = myId ? UserStore.getUser(myId) : null;
    const contacts = CrmStore.getMyContacts();
    const fsrUsers = UserStore.getFSRequestUsers();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const unreadCountsByUser = isLoggedIn ? ChatStore.getUnreadCountsByUser() : {};
    const areContactsLoaded = CrmStore.areMyContactsLoaded();
    const hasAnySelected = myId && user && user.id === myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myAccountType, myId, user, contacts, viewMode, fsrUsers, fsrCount, groupsQty, contactsQty,
      unreadCountsByUser, areContactsLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.unreadCountsByUser.size !== nextState.unreadCountsByUser.size
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.viewMode !== nextState.viewMode
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.areContactsLoaded !== nextState.areContactsLoaded
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.user, nextState.user)
      || !is(this.state.contacts, nextState.contacts)
      || !is(this.state.fsrUsers, nextState.fsrUsers)
      || !is(this.state.unreadCountsByUser, nextState.unreadCountsByUser);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn, hasAnySelected} = this.state;
    if (!CrmStore.areMyContactsLoaded()) {
      fetchMyContacts();
    }
    this.setSidebar();
  }

  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    this.setSidebar();
  }

  // CTX:
  setSidebar = async () => {
    trace(`setSidebar`);
    const {isLoggedIn, hasAnySelected} = this.state;
    if (isLoggedIn) {
      await substituteSidebarTabMode(!hasAnySelected ? GROUP_TAB : SELECTION_TAB);
      await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      await substituteSidebarChat({chatId: ''});
    }
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  render() {
    trace(`render`);
    const {
      isLoggedIn,
      myAccountType,
      myId,
      user,
      contacts,
      viewMode,
      fsrUsers,
      fsrCount,
      groupsQty,
      contactsQty,
      unreadCountsByUser,
      areContactsLoaded,
      hasAnySelected} = this.state;
    return (
      <MyContacts
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        user={user}
        contacts={contacts}
        viewMode={viewMode}
        fsrUsers={fsrUsers}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        unreadCountsByUser={unreadCountsByUser}
        areContactsLoaded={areContactsLoaded}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
      />
    );
  }
}

export default Container.create(MyContactsContainer, {pure: false});
