// -------------------------------------------------------------------------------------------------
//  heading.js
//  - - - - - - - - - -
//  #[SPACE] --> <h1>...</h1>
//  ##[SPACE] --> <h2>...</h2>
//  ###[SPACE] --> <h3>...</h3>
//  ####[SPACE] --> <h4>...</h4>
//  #####[SPACE] --> <h5>...</h5>
//  ######[SPACE] --> <h6>...</h6>
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import {blockTypes} from 'core/richTypes';

export default function autoreplaceHeading(node, matched, change) {
  const matchedLength = matched[0].length;
  const depth = (matched[0].match(/[#№]/g) || []).length;

  let type;

  if (depth === 1) type = blockTypes.HEADING_1;
  else if (depth === 2) type = blockTypes.HEADING_2;
  else if (depth === 3) type = blockTypes.HEADING_3;
  else if (depth === 4) type = blockTypes.HEADING_4;
  else if (depth === 5) type = blockTypes.HEADING_5;
  else if (depth === 6) type = blockTypes.HEADING_6;
  else return;

  return change.setBlocks(type).deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );
};
