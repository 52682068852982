// -------------------------------------------------------------------------------------------------
//  GroupAPI.js
//  - - - - - - - - - -
//  API для роботи з групами користувача (MyGroup).
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
// -------------------------------------------------------------------------------------------------
import {
  ID_FLD,
  NAME_FLD,
  GROUP_FLD,
  GROUP_ID_FLD,
  CONTACT_IDS_FLD,
  COMMENTS_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_MY_GROUPS_CMD,
  FETCH_GROUP_CMD,
  CREATE_GROUP_CMD,
  UPDATE_GROUP_CMD,
  DELETE_GROUP_CMD,
  ADD_CONTACTS_TO_GROUP_CMD,
  REMOVE_CONTACTS_FROM_GROUP_CMD} from 'core/apiTypes';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - GroupAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyGroups() {
  trace(`apiFetchMyGroups`);
  return await wsockFetch({
    [CMD]: FETCH_MY_GROUPS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchGroup(id) {
  trace(`apiFetchGroup: id=${id}`);
  return await wsockFetch({
    [CMD]: FETCH_GROUP_CMD,
    [PAYLOAD]: {
      [GROUP_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateGroup(group) {
  trace(`apiCreateGroup`);
  const {name, comments} = group;
  return await wsockFetch({
    [CMD]: CREATE_GROUP_CMD,
    [PAYLOAD]: {
      [NAME_FLD]: name,
      [COMMENTS_FLD]: comments
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateGroup(group, patchObject) {
  trace(`apiUpdateGroup`);
  const {id} = group;
  const {name, comments} = patchObject;
  return await wsockFetch({
    [CMD]: UPDATE_GROUP_CMD,
    [PAYLOAD]: {
      [GROUP_FLD]: {
        [ID_FLD]: id,
        [NAME_FLD]: name,
        [COMMENTS_FLD]: comments
      }
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteGroup(id) {
  trace(`apiDeleteGroup: id=${id}`);
  return await wsockFetch({
    [CMD]: DELETE_GROUP_CMD,
    [PAYLOAD]: {
      [GROUP_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiAddContactsToGroup(groupId, contactIds) {
  trace(`apiAddContactsToGroup: groupId=${groupId}, contactIds=${contactIds}`);
  return await wsockFetch({
    [CMD]: ADD_CONTACTS_TO_GROUP_CMD,
    [PAYLOAD]: {
      [GROUP_ID_FLD]: groupId,
      [CONTACT_IDS_FLD]: contactIds
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiRemoveContactsFromGroup(groupId, contactIds) {
  trace(`apiRemoveContactsFromGroup: groupId=${groupId}, contactIds=${contactIds}`);
  return await wsockFetch({
    [CMD]: REMOVE_CONTACTS_FROM_GROUP_CMD,
    [PAYLOAD]: {
      [GROUP_ID_FLD]: groupId,
      [CONTACT_IDS_FLD]: contactIds
    }
  });
}
