// -------------------------------------------------------------------------------------------------
//  GlobalNavigation.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import PubSub from 'utils/PubSub';
import Icon from 'components/UI/icons/Icon';
import styles from './GlobalNavigation.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function globalNavigation({goPrevious, goNext, elementQtyText}) {
  pubsub.publish({goPrevious: goPrevious, goNext: goNext, elementQtyText: elementQtyText});
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class GlobalNavigation extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    goPrevious: null,
    goNext: null,
    elementQtyText: ''
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({goPrevious, goNext, elementQtyText}) => {
      this.setState({goPrevious, goNext, elementQtyText});
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleGoPrevious = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {goPrevious} = this.state;
    goPrevious && goPrevious();
  }

  handleGoNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {goNext} = this.state;
    goNext && goNext();
  }

  render() {
    const {elementQtyText} = this.state;
    if (!elementQtyText) {
      return null;
    }
    return (
      <F>
        <div className={styles.goPreviousButton} onClick={this.handleGoPrevious}>
          <Icon symbolName="chevron-left" className={styles.icon} />
        </div>
        <div className={styles.goPreviousButton2} onClick={this.handleGoPrevious}>
          <Icon symbolName="chevron-left" className={styles.icon} />
        </div>
        <div className={styles.goNextButton} onClick={this.handleGoNext}>
          <Icon symbolName="chevron-right" className={styles.icon} />
        </div>
        <span className={styles.elementQtyText}>{elementQtyText}</span>
      </F>
    );
  }
}
