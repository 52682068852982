// -------------------------------------------------------------------------------------------------
//  blockquote.js
//  - - - - - - - - - -
//  >[SPACE]{...} --> <blockquote>{...}</blockquote>
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import {blockTypes} from 'core/richTypes';

export default function autoreplaceBlockquote(node, matched, change) {
  const matchedLength = matched[0].length;

  return change.setBlocks(blockTypes.BLOCKQUOTE).deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );
}
