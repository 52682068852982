// -------------------------------------------------------------------------------------------------
//  EditAdvertContainer.js
//  - - - - - - - - - -
//  Контейнер форми редагування оголошення.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchAdvert} from 'actions/AdvertActions';
import {fetchSystemSubjects} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import MarketplaceStore from 'stores/MarketplaceStore';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const EditAdvert = Loader.create(() => import('./EditAdvert'));
const isVerbose = DEBUG && true;
const prefix = '- - - EditAdvertContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class EditAdvertContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, MarketplaceStore, SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const advert = MarketplaceStore.getAdvert(props.match.params.advertId);
    const subjects = SystemStore.getSubjectList();
    return {isLoggedIn, myAccountType, myId, advert, subjects};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.subjects.size !== nextState.subjects.size
      || !is(this.state.advert, nextState.advert)
      || !is(this.state.subjects, nextState.subjects);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!SystemStore.areSubjectsLoaded()) {
      fetchSystemSubjects();
    }
    if (!MarketplaceStore.isAdvertLoaded(this.props.match.params.advertId)) {
      fetchAdvert(this.props.match.params.advertId);
    }
  }

  render() {
    const {isLoggedIn, myAccountType, myId, advert, subjects} = this.state;
    if (!advert || advert.ownerId !== myId) {
      return null;
    }
    trace(`render`);
    return (
      <EditAdvert
        myAccountType={myAccountType}
        myId={myId}
        advert={advert}
        subjects={subjects}
      />
    );
  }
}

export default Container.create(EditAdvertContainer, {withProps: true, pure: false});
