// -------------------------------------------------------------------------------------------------
//  list.js
//  - - - - - - - - - -
//  [unordered list]:
//  * item
//  + item
//  - item
//
//  [ordered list]:
//  1. item
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import EditListPlugin from 'slate-edit-list';
import {blockTypes} from 'core/richTypes';
import {defaultListOptions} from 'components/RichEditor/core/rich-list';

export default function autoreplaceList(node, matched, change, ordered) {
  const matchedLength = matched[0].length;
  const currentType = ordered ? blockTypes.OL_LIST : blockTypes.UL_LIST;
  const options = Object.assign(defaultListOptions, {ordered: ordered});
  const {utils, changes} = EditListPlugin(options);

  change.deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );

  let nextChange;

  if (utils.isSelectionInList(change.value)) {
    if (utils.getCurrentList(change.value).type !== currentType) {
      nextChange = changes.wrapInList(change, currentType);
    } else {
      nextChange = changes.unwrapList(change);
    }
  } else {
    nextChange = changes.wrapInList(change, currentType);
  }

  return nextChange;
}
