// -------------------------------------------------------------------------------------------------
//  MyTopicStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки тем для спілкування поточного користувача (контекстів типу стейпл).
//
//  Attn:
//  - - - - -
//  - список ідентифікаторів контекстів (стейплів, колекцій) зберігається в ChatStore.contextIds;
//  - список ChatStore.contextIds обновлюється усіма контекстами з чатів, що додаються в стор;
//  - пагінація стрічки контекстів ведеться по списку ЧАТІВ (ендпоінт: feeds.my.chats)
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {List, Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, SELECTION_TAB, MY_TOPIC_STAPLES_FEED} from 'core/uiTypes';
import {fetchMyContexts} from 'actions/ChatActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyTopicStaples = Loader.create(() => import('./MyTopicStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyTopicStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyTopicStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {[MY_TOPIC_STAPLES_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const unreadIds = isLoggedIn ? ChatStore.getUnreadIds() : Set();
    const unreadCountsByStaple = ChatStore.getUnreadCountsByCtxType(STAPLE_CTX);
    const {stapleIds, stapleCursor, areStaplesLoaded} = ChatStore.getMyTopicStaplesFeed();
    const staples = StapleStore.getSomeStaples(stapleIds);
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    const hasAnySelected = myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, owners, staples, viewMode, unreadIds, unreadCountsByStaple,
      stapleCursor, areStaplesLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.unreadIds.size !== nextState.unreadIds.size
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn, hasAnySelected} = this.state;
    // CTX:
    if (isLoggedIn) {
      await substituteSidebarTabMode(!hasAnySelected ? COLLECTION_TAB : SELECTION_TAB);
      await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      await substituteSidebarChat({chatId: ''});
    }
  }

  isFetching = false;

  fetchMyContextsPage = async () => {
    trace(`fetchMyContextsPage(1): isFetching=${this.isFetching}`);
    if (!this.isFetching) {
      const {stapleCursor = DEFAULT_TSN12_CURSOR} = this.state;
      trace(`fetchMyContextsPage(2): cursor='${stapleCursor}'`);
      this.isFetching = true;
      await fetchMyContexts({ctxType: STAPLE_CTX, cursor: stapleCursor, limit: CONTEXTDATA_FEED_FETCH_LIMIT});
      this.isFetching = false;
    }
  }

  render() {
    const {
      isLoggedIn,
      myId,
      owners,
      staples,
      viewMode,
      unreadIds,
      unreadCountsByStaple,
      areStaplesLoaded} = this.state;
    trace(`render`);
    return (
      <MyTopicStaples
        isLoggedIn={isLoggedIn}
        myId={myId}
        owners={owners}
        staples={staples}
        viewMode={viewMode}
        unreadIds={unreadIds}
        unreadCountsByStaple={unreadCountsByStaple}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchMyContextsPage}
      />
    );
  }
}

export default Container.create(MyTopicStaplesContainer, {withProps: true, pure: false});
