// -------------------------------------------------------------------------------------------------
//  EditGroupContainer.js
//  - - - - - - - - - -
//  Контейнер форми редагування групи.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchGroup} from 'actions/GroupActions';
import CrmStore from 'stores/CrmStore';
import Loader from 'utils/ComponentLoader';

const EditGroup = Loader.create(() => import('./EditGroup'));
const isVerbose = DEBUG && true;
const prefix = '- - - EditGroupContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class EditGroupContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [CrmStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myId = CrmStore.getMyId();
    const group = CrmStore.getMyGroup(props.match.params.groupId);
    return {myId, group};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || !is(this.state.group, nextState.group);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {groupId} = this.props.match.params;
    if (groupId && !CrmStore.isMyGroupLoaded(groupId)) {
      fetchGroup(groupId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {groupId} = nextProps.match.params;
    if (groupId && !CrmStore.isMyGroupLoaded(groupId)) {
      fetchGroup(groupId);
    }
  }

  render() {
    const {group} = this.state;
    if (!group || !group.id) {
      return null;
    }
    trace(`render`);
    return (
      <EditGroup
        group={group}
      />
    );
  }
}

export default Container.create(EditGroupContainer, {withProps: true, pure: false});
