// -------------------------------------------------------------------------------------------------
//  errors.js
//  - - - - - - - - - -
//  Обробка та переклад повідомлень про помилки.
// -------------------------------------------------------------------------------------------------
import {t} from 'ttag';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getErrorTitle = (errorCode) => {
  switch(errorCode) {
    case 'default':                        return t`Incorrect Data`; // default error message
    case 'incompatible_version_error':     return t`New Version Available`; // існує новіша версія веб-сторінки
    case 'account_type_error':             return t`Restricted for your account type`; // операція заборонена для цього типу акаунту
    case 'already_accepted':               return t`User was already added to your Friends list`; // юзер вже знаходиться в списку друзів
    case 'already_requested':              return t`Friendship request had been submitted before`; // запит friendship request виконується повторно
    case 'banned':                         return t`User had been banned`; // юзер забанений
    case 'cannot_be_empty':                return t`Required field`; // поле мусить мати значення
    case 'chat_already_exists':            return t`Some direct chats with given users have existed before`; // деякі чати з юзерами вже існують
    case 'collections_processing_error':   return t`Collection processing error`; // помилка при створенні колекцій
    case 'duplicate_name_error':           return t`Duplicate name error`; // помилка дублювання імені
    case 'elements_data_errors':           return t`Elements have one or more errors`; // дані елементів містять одну або декілька помилок
    case 'empty_form_data':                return t`Empty form data`; // форма не містить жодних даних
    case 'forbidden':                      return t`Forbidden`; // у доступі відмовлено
    case 'format_error':                   return t`Format error`; // невірно заданий формат даних
    case 'grade_already_exists':           return t`Grade already exists`; // оцінка вже існує
    case 'invalid_formdata':               return t`Invalid form data`; // передані дані не задоволняють вимогам
    case 'invalid_src':                    return t`Incorrect Data`; // нерозпізнаний тип url-адреси чи iframe-коду
    case 'is_not_context_owner':           return t`Only owner of an item can create chat`; // юзер не може створити чат в чужому стейплі
    case 'message_is_too_long':            return t`Message is too long`; // повідомлення занадто довге
    case 'not_found':                      return t`Not found`; // обʼєкт не знайдено
    case 'own_email_passed_error':         return t`You can't send friendship request to your own email`; // спроба надіслати запрошення на дружбу самому собі
    case 'required':                       return t`Required field`; // поле є обовʼязковим
    case 'server_error':                   return t`Server error`; // серверна помилка
    case 'suspicious_document':            return t`PDF document is encrypted or has suspicious scripts`; // документ містить скрипти чи є закодованим
    case 'too_short':                      return t`Text is too short`; // текст повинен бути довшим
    case 'too_long':                       return t`Text is too long`; // перевищення допустимої довжини тексту
    case 'unknown_command':                return t`Unknown command`; // невідома команда
    case 'unknown_contenttype_error':      return t`Unknown content type`; // помилка визначення типу контенту
    case 'unknown_email':                  return t`Unknown email`; // мейл для імпорту не знайдено
    case 'unknown_error':                  return t`Unknown error`; // невідома помилка
    case 'unsupported_type':               return t`Unsupported file type`; // тип файлу не підтримується
    case 'url_already_exists':             return t`URL already exists`; // таке посилання вже є в базі
    // ...RTC Calls:
    case 'no_available_members':           return t`No available members`; // усі учасники наразі недоступні
    case 'member_limit_exceeded':          return t`Member limit exceeded`; // перевищено к-сть учасників розмови
    case 'not_enough_coins':               return t`Not enough coins`; // недостатньо грошей на рахунку
    case 'already_joined':                 return t`You are already joined`; // користувач вже підʼєднаний до цього дзвінку
    // ...Hardware requirements:
    case 'outdated_chrome_browser':        return t`Outdated Chrome Browser`; // Outdated Chrome Browser
    case 'outdated_firefox_browser':       return t`Outdated Firefox Browser`; // Outdated Firefox Browser
    case 'outdated_safari_browser':        return t`Outdated Safari Browser`; // Outdated Safari Browser
    case 'outdated_opera_browser':         return t`Outdated Opera Browser`; // Outdated Opera Browser
    case 'outdated_android_system':        return t`Outdated Android System`; // Outdated Android System
    case 'safari_only':                    return t`Safari Browser Only`; // Safari Browser Only
    case 'chrome_only':                    return t`Chrome Browser Only`; // Chrome Browser Only
    case 'unsupported_browser':            return t`Unsupported Browser`; // Unsupported browser
    case 'unsupported_feature':            return t`Unsupported Feature`; // Unsupported feature
    default:
      return errorCode;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getErrorDescription = (errorCode) => {
  switch(errorCode) {
    case 'incompatible_version_error':     return t`The current webpage needs to be reloaded to update to the newest version.`; // операція заборонена для цього типу акаунту
    case 'account_type_error':             return t`To perform this action, you need to have a different type of account. Please contact support for clarification.`; // операція заборонена для цього типу акаунту
    case 'forbidden':                      return t`You don't have permission to perform this action.`; // виконання цієї операції заборонено
    case 'grade_already_exists':           return t`The specified user has already received a grade for this document.`; // цьому юзеру вже було виставлено оцінку
    case 'invalid_formdata':               return t`Some error has occurred. Please try to reload webpage or inform us via Feedback`; // передані дані не задоволняють вимогам
    default:
      return t`An unexpected error has occurred. Please try to reload webpage or inform us via Feedback`;
  }
}
