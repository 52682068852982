// -------------------------------------------------------------------------------------------------
//  SelectAction.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import Icon from 'components/UI/icons/Icon';
import styles from './SelectAction.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function selectAction(buttons) {
  pubsub.publish({buttons});
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class SelectAction extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    buttons: [],
    isDisplayed: false,
  }

  classNames = {
    enter: styles.entering,
    enterActive: styles.entered,
    exit: styles.leaving,
    exitActive: styles.leaved,
  }

  handleClose = () => this.setState({isDisplayed: false})

  componentDidMount() {
    pubsub.subscribe(({buttons}) => {
      this.setState({buttons, isDisplayed: true});
    });
    // close on location changes
    this.context.router.history.listen(() => {
      this.setState({isDisplayed: false});
    });
  }

  render() {
    const {buttons, isDisplayed} = this.state;
    return (
      <CSSTransition in={isDisplayed} classNames={this.classNames} timeout={200} unmountOnExit={true}>
        <div className={styles.SelectAction} onClick={this.handleClose}>
          <div className={styles.container}>
            <div className={styles.actions}>
              {buttons.map(({title, symbolName, description, onClick}, index) =>
                <button key={index} onClick={onClick}>
                  {symbolName && <Icon symbolName={symbolName} className={styles.icon}/>}
                  <div className={styles.title}><p>{title}</p></div>
                  {description &&
                    <p className={styles.description}>{description}</p>
                  }
                </button>
              )}
            </div>
            <button className={styles.cancelButton}>{t`Cancel`}</button>
          </div>
        </div>
      </CSSTransition>
    );
  }
}
