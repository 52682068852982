// -------------------------------------------------------------------------------------------------
//  custom-text.js
//  - - - - - - - - - - - - - -
//  Примусовий перевід стрічки (break) по символу '/'.
//
//  Notes:
//  - - - - -
//  - conmark повинен бути згенерований в режимі commonmark (перевід стрічки кодується як '\');
// -------------------------------------------------------------------------------------------------
// ToDo: створення break type node робити в custom-break.js, а не тут !!!

import {mdastTypes} from 'core/mdastTypes';
import {conmarkTokens} from 'core/conmarkTypes';

export function parse() {
  let Parser = this.Parser;
  let tokenizers = Parser.prototype.inlineTokenizers;

  tokenizeText.locator = tokenizers.text.locator;
  tokenizers.text = tokenizeText;

  function tokenizeText(eat, value, silent) {
    let self = this;
    let methods;
    let tokenizers;
    let index;
    let length;
    let subvalue;
    let pos;
    let tokenizer;
    let name;
    let min;
    let now;

    if (silent) {
      return true;
    }

    methods = self.inlineMethods;
    length = methods.length;
    tokenizers = self.inlineTokenizers;
    index = -1;
    min = value.length;

    while (++index < length) {
      name = methods[index];

      if (name === 'text' || !tokenizers[name]) {
        continue;
      }

      tokenizer = tokenizers[name].locator;

      if (!tokenizer) {
        eat.file.fail('Missing locator: `' + name + '`');
      }

      pos = tokenizer.call(self, value, 1);

      if (pos !== -1 && pos < min) {
        min = pos;
      }
    }

    // ToDo: створення break type node робити в custom-break.js, а не тут !!!

    // - - - - - -
    // Old Version
    // - - - - - -
    // subvalue = value.slice(0, min);
    // now = eat.now();
    //
    // self.decode(subvalue, now, function (content, pos, source) {
    //   eat(source || content)({
    //     type: 'text',
    //     value: content === conmarkTokens.BREAK ? '' : content // sic! щоб не дублювався токен
    //   });
    // });
    // - - - - - -

    subvalue = value.slice(0, min);
    now = eat.now();

    self.decode(subvalue, now, function (content, pos, source) {
      // sic! щоб не дублювався токен
      if (source === conmarkTokens.BREAK || content === conmarkTokens.BREAK) {
        eat(source || content)({
          type: mdastTypes.BREAK
        });
      } else {
        eat(source || content)({
          type: 'text',
          value: content
        });
      }
    });
    // - - - - - -

  }
}
