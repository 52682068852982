// -------------------------------------------------------------------------------------------------
//  rich-heading-h3.js
//  - - - - - - - - - -
//  <H3> node: plugin & toolbar icon.
//
//  Attn:
//  - - - - -
//  - H3 заборонений в контексті CODEBLOCK, TABLE;
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import RichIcon from '../RichIcon';
import {blockTypes, hotkeyTypes, htmlTagTypes} from 'core/richTypes';
import {toggleHeading, headingPlugin} from './commonHeading';
import {isInTableContext, haveBlocks} from './utils';

const defaultOptions = {
  type: blockTypes.HEADING_3,
  tagName: htmlTagTypes.HEADING_3,
  hotkey: hotkeyTypes.HEADING_3,
};

export class HeadingThreeIcon extends React.Component {
  onClick = (event) => {
    let {change, onChange} = this.props;

    event.preventDefault();
    onChange(toggleHeading(change, defaultOptions.type));
  }

  render() {
    const {change, ...other} = this.props;
    const onClick = event => this.onClick(event);

    if (isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolText={<F>H3</F>}
        commandName={defaultOptions.type}
        isActive={haveBlocks(change, defaultOptions.type)}
        onClick={onClick}
        {...other}
      />
    );
  }
}

const HeadingThreePlugin = (opts) => {
  const options = Object.assign(defaultOptions, opts);
  return headingPlugin(options);
};

export default HeadingThreePlugin;
