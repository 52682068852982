// -------------------------------------------------------------------------------------------------
//  ScrollWrapper.js
//  - - - - - - - - - -
//  Враппер додає властивість скролювання.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import styles from './ScrollWrapper.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ScrollWrapper extends React.Component {
  static propTypes = {
    children: Types.element.isRequired,   // дочірні компоненти
    reference: Types.object,              // reference to the component
  }

  render() {
    const {reference, children} = this.props;
    return (
      <div ref={reference} className={styles.ScrollWrapper}>
        {children}
      </div>
    );
  }
}
