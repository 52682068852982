// -------------------------------------------------------------------------------------------------
//  ModalLayer.js
//  - - - - - - - - - -
//  Допоміжна компонента для LayerSwitch.
//  Відповідає за відображення шару Modal Layer.
//
//  Notes:
//  - - - - -
//  - містить кнопку закриття для переходу на попередній шар;
//  - ESC закриває рівень;
// -------------------------------------------------------------------------------------------------
// ToDo: імплементувати z-порядок для keydown-подій (події клавіатури) !!!

import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import Icon from 'components/UI/icons/Icon';
import styles from './ModalLayer.scss';

const mountedModals = [];
const ESC = 27;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ModalLayer extends React.Component {
  static propTypes = {
    children: Types.element.isRequired,   // дочірні компоненти
    onClose: Types.func.isRequired,       // close handler
  }

  state = {
    isDisplayed: false,
  }

  classNames = {
    enter: styles.entering,
    enterActive: styles.entered,
    exit: styles.leaving,
    exitActive: styles.leaved,
  }

  componentDidMount() {
    this.setState({isDisplayed: true});
    mountedModals.push(true);
    if (mountedModals.length === 1) {
      PreventBodyScroll();
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.setState({isDisplayed: false});
    mountedModals.pop();
    if (mountedModals.length === 0) {
      RestoreBodyScroll();
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === ESC) {
      const {onClose} = this.props;
      onClose && onClose();
    }
  }

  render() {
    const {children, onClose} = this.props;
    const {isDisplayed} = this.state;
    return (
      <CSSTransition in={isDisplayed} classNames={this.classNames} timeout={60} unmountOnExit={true}>
        <div id="modal" onClick={e => { e.stopPropagation(); onClose(); }}>
          <F>
            {children}
            <button className={styles.closeButton} onClick={onClose}>
              <Icon symbolName="cross" className={styles.icon} />
            </button>
          </F>
        </div>
      </CSSTransition>
    );
  }
}
