// -------------------------------------------------------------------------------------------------
//  OneUserContainer.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {
  fetchUserProfiles,
  fetchUserProfilesAsGuest,
  fetchUserProfilesBySlugs,
  fetchUserProfilesBySlugsAsGuest} from 'actions/UserActions';
import AccountStore from 'stores/AccountStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const OneUser = Loader.create(() => import('./OneUser'));
const isVerbose = DEBUG && true;
const prefix = '- - - OneUserContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class OneUserContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {userId, userSlug} = props.match.params;
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = UserStore.getMyId();
    const user = userId ? UserStore.getUser(userId) : userSlug ? UserStore.getUserBySlug(userSlug) : null;
    return {isLoggedIn, myId, user};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {userId, userSlug} = this.props.match.params;
    const {isLoggedIn} = this.state;
    if (userId) {
      if (!UserStore.isUserProfileLoaded(userId)) {
        isLoggedIn ?
          await fetchUserProfiles([userId]) :
          await fetchUserProfilesAsGuest([userId]);
      }
    } else if (userSlug) {
      if (!UserStore.isUserProfileLoadedBySlug(userSlug)) {
        isLoggedIn ?
          await fetchUserProfilesBySlugs([userSlug]) :
          await fetchUserProfilesBySlugsAsGuest([userSlug]);
      }
    }
  }

  render() {
    const {myId, user} = this.state;
    if (!user) {
      return null;
    }
    trace(`render`);
    return (
      <OneUser
        myId={myId}
        user={user}
      />
    );
  }
}

export default Container.create(OneUserContainer, {withProps: true, pure: false});
