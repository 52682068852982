// -------------------------------------------------------------------------------------------------
//  SubjectCollectionsContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки оголошень вказаного предмету.
//
//  Attn:
//  - - - - -
//  - якщо предмет не знайдено в сторі і на сервері --> сторінка /404;
//  - функція getSubjectCollections віддає оголошення тільки ДО курсора, щоб не було "пропусків" в фіді;
//
//  Attn: a)
//  - - - - -
//  - колекції різних типів не показуються ОДНОЧАСНО;
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /collections/:subjectId/ --> DEFAULT_COLLECTION;
//    якщо /courses/:subjectId/ --> COURSE_COLLECTION;
//    якщо /serials/:subjectId/ --> SEQUENCE_COLLECTION;
//    якщо /randoms/:subjectId/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається feedType та яке API буде застосовано;
//  - для даного фіду тип колекції SUBJECT_COLLECTIONS_FEED не підтримується, тому === undefined;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, OrderedSet, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR, DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {SUBJECT_TAB, SUBJECT_COLLECTIONS_FEED, SUBJECT_COURSES_FEED} from 'core/uiTypes';
import {fetchSubjectCollections} from 'actions/CollectionActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import {fetchSystemSubjects, fetchSystemSubjectsAsGuest} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const SubjectCollections = Loader.create(() => import('./SubjectCollections'));
const isVerbose = DEBUG && true;
const prefix = '- - - SubjectCollectionsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class SubjectCollectionsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, SystemStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const {subjectSlug} = props.match.params;
    const feedType = collectionType === COURSE_COLLECTION ? SUBJECT_COURSES_FEED : undefined; // attn: a) !!!
    const {[feedType]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const subject = subjectSlug ? SystemStore.getSubjectBySlug(subjectSlug) : null;
    const allSubjects = SystemStore.getSubjectList();
    const {
      collections = OrderedSet(),
      collectionCursor = DEFAULT_TSN12_CURSOR,
      areCollectionsLoaded = false} = subject ? CollectionStore.getSubjectCollectionsFeedByType(collectionType, subject.id) : {};
    const owners = UserStore.getSomeUsersMap(collections.reduce((a, c) => a.add(c.ownerId), Set().add(myId)));
    return {
      isLoggedIn, collectionType, myAccountType, myId, owners, subject, allSubjects, collections,
      feedType, viewMode, collectionCursor, areCollectionsLoaded};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.viewMode !== nextState.viewMode
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.collectionType !== nextState.collectionType
      || this.state.collectionCursor !== nextState.collectionCursor
      || this.state.areCollectionsLoaded !== nextState.areCollectionsLoaded
      || this.state.collections.size !== nextState.collections.size
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.feedType !== nextState.feedType
      || !is(this.state.subject, nextState.subject)
      || !is(this.state.collections, nextState.collections)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn} = this.state;
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
    // CTX:
    if (isLoggedIn) {
      substituteSidebarTabMode(SUBJECT_TAB);
      substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {isLoggedIn} = this.state;
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
  }

  isFetchingSubjectCollections = false;

  fetchSubjectCollectionsPage = async () => {
    trace(`fetchSubjectCollectionsPage(1): isFetching=${this.isFetchingSubjectCollections}`);
    const {isLoggedIn, collectionType, subject, collectionCursor = DEFAULT_TSN12_CURSOR} = this.state;
    const {id:subjectId} = subject || {};
    if (subjectId && !this.isFetchingSubjectCollections) {
      trace(`fetchSubjectCollectionsPage(2): subjectId=${subjectId}, cursor='${collectionCursor}'`);
      this.isFetchingSubjectCollections = true;
      if (isLoggedIn) {
        await fetchSubjectCollections({type: collectionType, subjectId: subjectId, cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT});
      }
      this.isFetchingSubjectCollections = false;
    }
  }

  render() {
    const {
      isLoggedIn,
      myAccountType,
      myId,
      owners,
      subject,
      allSubjects,
      collections,
      feedType,
      viewMode,
      areCollectionsLoaded} = this.state;
    trace(`render`);
    return (
      <SubjectCollections
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        subject={subject}
        allSubjects={allSubjects}
        collections={collections}
        feedType={feedType}
        viewMode={viewMode}
        areCollectionsLoaded={areCollectionsLoaded}
        onPageTrigger={this.fetchSubjectCollectionsPage}
      />
    );
  }
}

export default Container.create(SubjectCollectionsContainer, {withProps: true, pure: false});
