// -------------------------------------------------------------------------------------------------
//  SystemAPI.js
//  - - - - - - - - - -
//  System APIs (Languages, Countries, Categories, Subjects, ..., StaticPages)
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - параметр cursor або повинен містити значення дати-часу, або бути відсутнім в запиті;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - для спрощення параметрів параметр мови (локаль) додаємо в api-функціях (а не в actions);
// -------------------------------------------------------------------------------------------------
import {LANGUAGE_ID_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_SYSTEM_LANGUAGES_CMD,
  FETCH_SYSTEM_COUNTRIES_CMD,
  FETCH_SYSTEM_CATEGORIES_AS_GUEST_CMD,
  FETCH_SYSTEM_CATEGORIES_CMD,
  FETCH_SYSTEM_SUBJECTS_AS_GUEST_CMD,
  FETCH_SYSTEM_SUBJECTS_CMD,
  FETCH_SYSTEM_STATICPAGES_CMD} from 'core/apiTypes';
import {LS_LOCALE} from 'core/commonTypes';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - AccountAPI';

const locale = localStorage.getItem(LS_LOCALE) || ENGLISH_LANG;

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemLanguages() {
  trace(`apiFetchSystemLanguages`);
  return await httpFetch(FETCH_SYSTEM_LANGUAGES_CMD, {
    method: 'GET',
    headers: {
      'accept-language': locale,
      'content-type': 'application/json'
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemCountries(languageId = locale) {
  trace(`apiFetchSystemCountries`);
  return await wsockFetch({
    [CMD]: FETCH_SYSTEM_COUNTRIES_CMD,
    [PAYLOAD]: {
      [LANGUAGE_ID_FLD]: languageId,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemCategoriesAsGuest(languageId = locale) {
  trace(`apiFetchSystemCategoriesAsGuest: languageId=${languageId}`);
  return await httpFetch(FETCH_SYSTEM_CATEGORIES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [LANGUAGE_ID_FLD]: languageId,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemCategories(languageId = locale) {
  trace(`apiFetchSystemCategories: languageId=${languageId}`);
  return await wsockFetch({
    [CMD]: FETCH_SYSTEM_CATEGORIES_CMD,
    [PAYLOAD]: {
      [LANGUAGE_ID_FLD]: languageId,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemSubjectsAsGuest(languageId = locale) {
  trace(`apiFetchSystemSubjectsAsGuest: languageId=${languageId}`);
  return await httpFetch(FETCH_SYSTEM_SUBJECTS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [LANGUAGE_ID_FLD]: languageId,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemSubjects(languageId = locale) {
  trace(`apiFetchSystemSubjects: languageId=${languageId}`);
  return await wsockFetch({
    [CMD]: FETCH_SYSTEM_SUBJECTS_CMD,
    [PAYLOAD]: {
      [LANGUAGE_ID_FLD]: languageId,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSystemStaticpages() {
  trace(`apiFetchSystemStaticpages`);
  return await httpFetch(FETCH_SYSTEM_STATICPAGES_CMD, {
    method: 'GET',
    headers: {
      'accept-language': ENGLISH_LANG, // ToDo: поки не заповнимо сторінки іншими мовами !!!
      'content-type': 'application/json'
    },
  });
}
