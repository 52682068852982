// -------------------------------------------------------------------------------------------------
//  CreateContactContainer.js
//  - - - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  1)  додатково в стейті можуть бути передані наступні параметри:
//      this.context.router.history.push({pathname: `/k/create`, state: {user: user}});
//
//  2)  список груп по-замовчуванню беремо із localStorage напряму (PRESET_GROUP_IDS);
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchMyGroups} from 'actions/GroupActions';
import CrmStore from 'stores/CrmStore';
import Loader from 'utils/ComponentLoader';

const CreateContact = Loader.create(() => import('./CreateContact'));
const isVerbose = DEBUG && true;
const prefix = '- - - CreateContactContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CreateContactContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [CrmStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myGroups = CrmStore.getMyGroups();
    return {myGroups};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myGroups.size !== nextState.myGroups.size
      || !is(this.state.myGroups, nextState.myGroups);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!CrmStore.areMyGroupsLoaded()) {
      fetchMyGroups();
    }
  }

  render() {
    trace(`render`);
    const {myGroups} = this.state;
    const {user} = this.context.router.history.location.state || {}; // attn: 1) читання параметрів для створення контакту !!!
    return (
      <CreateContact
        user={user}
        myGroups={myGroups}
      />
    );
  }
}

export default Container.create(CreateContactContainer, {pure: false});
