// -------------------------------------------------------------------------------------------------
//  OneStapleContainer.js
//  - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  - форма має два параметри: stapleId, chatId (опціонально);
//  - якщо стейпл не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в стейпл контекст сайдбару встановлюємо на закладку чату (CHAT_TAB);
//  - якщо другим параметром вказаний chatId, то встановлюємо цей чат;
//  - якщо у нас є виділені стейпли, то контекст і чат встановлюємо, але активна закладка SELECTION_TAB;
//  - при виході встановлюємо на закладку колекцій (бо стираємо ctxId, ctxType, userId);
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {OWNER_ID_FLD} from 'core/apiFields';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchStaple, fetchStapleAsGuest} from 'actions/StapleActions';
import {fetchUsers, fetchUsersAsGuest} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const OneStaple = Loader.create(() => import('./OneStaple'));
const isVerbose = DEBUG && true;
const prefix = '- - - OneStapleContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let prevTabMode;                          // CTX:
let prevCtxId;                            // CTX:
let prevCtxType;                          // CTX:
let prevCtxOwnerId;                       // CTX:
let prevChatId;                           // CTX:

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class OneStapleContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, StapleStore, UserStore];
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про стейпл?
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const stapleId = props.match.params.stapleId;
    const staple = stapleId ? StapleStore.getStaple(props.match.params.stapleId) : null;
    const {authorId:ownerId} = staple || {};
    const owner = ownerId ? UserStore.getUser(ownerId) : null;
    return {isLoggedIn, myId, owner, staple};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.props.location.pathname !== nextProps.location.pathname
      || !is(this.state.owner, nextState.owner)
      || !is(this.state.staple, nextState.staple);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  // FixMe: нестабільно (інколи так, інколи ні) відкриває чат до стейплу для адрес "/i/:stapleId/:elemId/:chatId" !!!
  async componentDidMount() {
    trace(`componentDidMount`);
    const {stapleId, chatId} = this.props.match.params;
    const {isLoggedIn, staple:staple1} = this.state;
    const {ownerId:ownerId1} = staple1 || {}; // стейпл зі стору, тому 'owner_id'
    let ownerId2;
    if (stapleId && !StapleStore.isStapleLoaded(stapleId)) {
      const {staple:staple2} = isLoggedIn ?
        await fetchStaple(stapleId) :
        await fetchStapleAsGuest(stapleId);
      ({[OWNER_ID_FLD]:ownerId2} = staple2 || {}); // стейпл із серверу, тому [OWNER_ID_FLD]
    }
    // CTX:
    if (isLoggedIn) {
      // ...memorize previous context
      const {tabMode, ctxId, ctxType, ctxOwnerId, chatId:chId} = ChatStore.getSideOptions();
      prevTabMode = tabMode;
      prevCtxId = ctxId;
      prevCtxType = ctxType;
      prevCtxOwnerId = ctxOwnerId;
      prevChatId = chId;
      // ...set new context
      const ownerId = ownerId1 || ownerId2;
      if (ownerId) {
        // FixMe: Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch !!!
        await substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : CHAT_TAB);
        await substituteSidebarContext({ctxId: stapleId, ctxType: STAPLE_CTX, ctxOwnerId: ownerId});
        await substituteSidebarChat({chatId: chatId});
        if (!UserStore.isUserLoaded(ownerId)) {
          await fetchUsers([ownerId]);
        }
      }
    }
    this.setState({isServerRequested: true});
  }

  // FixMe: нестабільно (інколи так, інколи ні) відкриває чат до стейплу для адрес "/i/:stapleId/:elemId/:chatId" !!!
  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    const {stapleId:prevStapleId, chatId:prevChatId} = prevProps.match.params;
    const {stapleId, chatId} = this.props.match.params;
    const {isLoggedIn, staple} = this.state;
    const {ownerId} = staple || {};
    // CTX:
    if (isLoggedIn) {
      if (stapleId && stapleId !== prevStapleId) {
        await substituteSidebarContext({ctxId: stapleId, ctxType: STAPLE_CTX, ctxOwnerId: ownerId});
      }
      if (!stapleId && (StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts())) {
        await substituteSidebarTabMode(SELECTION_TAB);
      }
      // attn: якщо змінився чат або НЕ виділені стейпли !!!
      if ((!prevChatId && chatId) || (prevChatId && !chatId) || (!StapleStore.hasSelectedStaples() && !CrmStore.hasSelectedContacts())) {
        await substituteSidebarTabMode(CHAT_TAB);
        await substituteSidebarChat({chatId: chatId});
      }
    }
    if (!isLoggedIn && ownerId && !UserStore.isUserLoaded(ownerId)) {
      await fetchUsersAsGuest([ownerId]); // FixMe: повторні виклики функції якщо відразу відкриваємо url '/i/{userId}/1' !!!
    }
  }

  async componentWillUnmount() {
    trace(`componentWillUnmount`);
    const {isLoggedIn} = this.state;
    // CTX: поки повертаємо попередній контекст, але це погано працює при переході з форми НЕ на попередній URL !!!
    if (isLoggedIn) {
      // await substituteSidebarTabMode(StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() ? SELECTION_TAB : COLLECTION_TAB);
      // await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      // await substituteSidebarChat({chatId: ''});
      await substituteSidebarTabMode(prevTabMode);
      await substituteSidebarContext({ctxId: prevCtxId, ctxType: prevCtxType, ctxOwnerId: prevCtxOwnerId});
      await substituteSidebarChat({chatId: prevChatId});
    }
  }

  render() {
    const {isServerRequested, isLoggedIn, myId, owner, staple} = this.state;
    if (isServerRequested && !staple) {
      return (
        <NotFoundPage />
      );
    }
    if (!staple) {
      return null;
    }
    trace(`render`);
    return (
      <OneStaple
        isLoggedIn={isLoggedIn}
        myId={myId}
        owner={owner}
        staple={staple}
      />
    );
  }
}

export default Container.create(OneStapleContainer, {withProps: true, pure: false});
