// -------------------------------------------------------------------------------------------------
//  hastTypes.js
//  - - - - - - - - - -
//  HAST (Hypertext Abstract Syntax Tree) enumerated types.
// -------------------------------------------------------------------------------------------------

export const hastTypes = {
  LINK:                 'a',
};

