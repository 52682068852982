// -------------------------------------------------------------------------------------------------
//  EditCollectionContainer.js
//  - - - - - - - - - -
//  Контейнер форми редагування колекції.
//
//  Attn: a)
//  - - - - -
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається яке API буде застосовано;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchCollection} from 'actions/CollectionActions';
import {fetchSystemSubjects} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const EditCollection = Loader.create(() => import('./EditCollection'));
const isVerbose = DEBUG && true;
const prefix = '- - - EditCollectionContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class EditCollectionContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const {collectionId} = props.match.params;
    const myId = CollectionStore.getMyId();
    const myAccount = AccountStore.getMyAccount();
    const collection = CollectionStore.getCollection(collectionId);
    const subjects = SystemStore.getSubjectListOrderedByName();
    return {myId, myAccount, collection, subjects};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || this.state.subjects.size !== nextState.subjects.size
      || !is(this.state.myAccount, nextState.myAccount)
      || !is(this.state.collection, nextState.collection)
      || !is(this.state.subjects, nextState.subjects);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {collectionId} = this.props.match.params;
    if (collectionId && !CollectionStore.isCollectionLoaded(collectionId)) {
      fetchCollection(collectionId);
    }
    if (!SystemStore.areSubjectsLoaded()) {
      fetchSystemSubjects();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {collectionId} = nextProps.match.params;
    if (collectionId && !CollectionStore.isCollectionLoaded(collectionId)) {
      fetchCollection(collectionId);
    }
  }

  render() {
    const {myId, myAccount, collection, subjects} = this.state;
    const {accountType:myAccountType} = myAccount || {};
    if (!collection || collection.ownerId !== myId) {
      return null;
    }
    trace(`render`);
    return (
      <EditCollection
        myAccountType={myAccountType}
        collection={collection}
        subjects={subjects}
      />
    );
  }
}

export default Container.create(EditCollectionContainer, {withProps: true, pure: false});
