// -------------------------------------------------------------------------------------------------
//  SubmodalLayer.js
//  - - - - - - - - - -
//  Допоміжна компонента для LayerSwitch.
//  Відповідає за відображення шару Submodal Layer.
//
//  Notes:
//  - - - - -
//  - ESC закриває рівень;
//
//  Attn:
//  - - - - -
//  - в мобільному режимі кнопка закриття знаходиться на навбарі; в декстопному - на формі;
// -------------------------------------------------------------------------------------------------
// ToDo: імплементувати z-порядок для keydown-подій (події клавіатури) !!!

import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import PageNavbar from 'components/Navbars/PageNavbar';
import Icon from 'components/UI/icons/Icon';
import styles from './SubmodalLayer.scss';

const mountedSubmodals = [];
const ESC = 27;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class SubmodalLayer extends React.Component {
  static propTypes = {
    children: Types.element.isRequired,   // дочірні компоненти
    isMobile: Types.bool,                 // is an app running in mobile layout?
    onClose: Types.func.isRequired,       //
  }

  state = {
    isDisplayed: false,
  }

  classNames = {
    enter: styles.entering,
    enterActive: styles.entered,
    exit: styles.leaving,
    exitActive: styles.leaved,
  }

  componentDidMount() {
    this.setState({isDisplayed: true});
    mountedSubmodals.push(true);
    if (mountedSubmodals.length === 1) {
      PreventBodyScroll();
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.setState({isDisplayed: false});
    mountedSubmodals.pop();
    if (mountedSubmodals.length === 0) {
      RestoreBodyScroll();
    }
  }

  // Attn: 1) флаг 'no-submodal-esc' відключає закриття форми на рівні Submodal !!!
  handleKeyDown = (e) => {
    if (e.keyCode === ESC) {
      const {onClose} = this.props;
      const hasNoEscapeFlag = document.body.classList.contains('no-submodal-esc'); // sic!: 1)
      if (!hasNoEscapeFlag) {
        onClose && onClose();
      }
    }
  }

  render() {
    const {children, isMobile, onClose} = this.props;
    const {isDisplayed} = this.state;
    return (
      <F>
        {isMobile &&
          <PageNavbar onClose={onClose} />
        }
        <CSSTransition in={isDisplayed} classNames={this.classNames} timeout={60} unmountOnExit={true}>
          <div className={styles.SubmodalLayer} onClick={onClose}>
            <div className={styles.submodalWrapper} onClick={event => event.stopPropagation()}>
              {children}
              {!isMobile &&
                <button className={styles.closeButton} onClick={onClose}>
                  <Icon symbolName="cross" className={styles.icon} />
                </button>
              }
            </div>
          </div>
        </CSSTransition>
      </F>
    );
  }
}
