// -------------------------------------------------------------------------------------------------
//  PictButton.js
//  - - - - - - - - - -
//  Pictogram button (just icon as pictogram).
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/UI/icons/Icon';
import styles from './PictButton.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const PictButton = ({symbolName, className, iconClassName,
                      title = '', badgeText = '', qtyText = 0, isDisabled, onClick}) => (
  <div className={classnames(styles.PictButton, className, {[styles.isDisabled]: isDisabled})}
       title={title}
       onClick={!isDisabled ? onClick : null}>
    <Icon symbolName={symbolName} className={classnames(styles.icon, iconClassName)}/>
    {badgeText && <span className={styles.badgeText}>{badgeText}</span>}
    {qtyText > 0 && <span className={styles.qtyText}>{qtyText}</span>}
  </div>
);

PictButton.propTypes = {
  symbolName: Types.string,               // the name of icon symbol from sprite (optional)
  className: Types.string,                // class name for component's box
  iconClassName: Types.string,            // class name for the component's icon
  title: Types.string,                    // title
  badgeText: Types.string,                // текстова мітка (напр: к-сть запитів на дружбу)
  qtyText: Types.number,                  // числова мітка (напр: к-сть колекцій)
  isDisabled: Types.bool,                 // is button disabled?
  onClick: Types.func,                    // on click callback
};

export default PictButton;
