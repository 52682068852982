// -------------------------------------------------------------------------------------------------
//  MobileMenu
//  - - - - - - - - - -
//  Головне/системне меню для мобільного відображення.
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {Link} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import {logout} from 'actions/AuthActions';
import {confirmAction} from 'components/UI/ConfirmAction';
import Icon from 'components/UI/icons/Icon';
import styles from './MobileMenu.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function open() {
  pubsub.publish('open');
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function close() {
  pubsub.publish('close');
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function toggle() {
  pubsub.publish('toggle');
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MenuItem = ({symbolName, to, rel="", caption}) => (
  symbolName ?
    <Link to={to} rel={rel}><Icon symbolName={symbolName} className={styles.menuIcon} />{caption}</Link> :
    <Link to={to} rel={rel}>{caption}</Link>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class MobileMenu extends React.Component {
  static propTypes = {
    isLoggedIn: Types.bool.isRequired,    // is current user logged in?
  }

  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    isDisplayed: false,
  }

  classNames = {
    enter: styles.entering,
    enterActive: styles.entered,
    exit: styles.leaving,
    exitActive: styles.leaved,
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe((command) => {
      if (command === 'open') this.setState({isDisplayed: true});
      if (command === 'close') this.setState({isDisplayed: false});
      if (command === 'toggle') this.setState({isDisplayed: !this.state.isDisplayed});
    });
    this.context.router.history.listen(close); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  confirmSignOut = () => {
    confirmAction({
        title: t`Sign Out Confirmation`,
        description: t`Are you sure?`
      }, [
      {
        title: t`Yes`,
        isAccented: true,
        onClick: () => {
          close();
          logout();
          this.context.router.history.replace(`/`); // `/auth/logout/success`
        }
      }, {
        title: t`No`,
        isAccented: false
      }]
    );
  }

  render() {
    const {isLoggedIn} = this.props;
    const {isDisplayed} = this.state;
    isDisplayed ? PreventBodyScroll() : RestoreBodyScroll();
    return (
      <CSSTransition in={isDisplayed} classNames={this.classNames} timeout={300} unmountOnExit={true}>
        <nav className={styles.MobileMenu}>
          {!isLoggedIn &&
            <F>
              <MenuItem to="/about"             rel="nofollow noopener noreferrer" caption={t`About Us`}              symbolName="logo-icon-bw" />
              <MenuItem to="/pricing"           rel="nofollow noopener noreferrer" caption={t`Pricing Page`}          symbolName="gift" />
              <MenuItem to="/courses"           rel="nofollow noopener noreferrer" caption={t`Discover Courses Feed`} symbolName="school" />
              <MenuItem to="/featured"          rel="nofollow noopener noreferrer" caption={t`Featured Staples Feed`} symbolName="star-circle" />
              <MenuItem to="/discover"          rel="nofollow noopener noreferrer" caption={t`Discover Staples Feed`} symbolName="explore" />
              <MenuItem to="/terms-of-service"  rel="nofollow noopener noreferrer" caption={t`Terms of Service`} />
              <MenuItem to="/privacy-policy"    rel="nofollow noopener noreferrer" caption={t`Privacy Policy`} />
              <MenuItem to="/auth/login"        rel="nofollow noopener noreferrer" caption={t`Login`}                 symbolName="login" />
            </F>
          }
          {isLoggedIn &&
            <F>
              <MenuItem to="/settings"          rel="nofollow noopener noreferrer" caption={t`Settings`}              symbolName="gear6x" />
              <MenuItem to="/pricing"           rel="nofollow noopener noreferrer" caption={t`Pricing Page`}          symbolName="gift" />
              <MenuItem to="/courses"           rel="nofollow noopener noreferrer" caption={t`Discover Courses Feed`} symbolName="school" />
              <MenuItem to="/featured"          rel="nofollow noopener noreferrer" caption={t`Featured Staples Feed`} symbolName="star-circle" />
              <MenuItem to="/discover"          rel="nofollow noopener noreferrer" caption={t`Discover Staples Feed`} symbolName="explore" />
              <MenuItem to="/following"         rel="nofollow noopener noreferrer" caption={t`Following Feed`}        symbolName="eye" />
              <MenuItem to="/terms-of-service"  rel="nofollow noopener noreferrer" caption={t`Terms of Service`} />
              <MenuItem to="/privacy-policy"    rel="nofollow noopener noreferrer" caption={t`Privacy Policy`} />
              <p onClick={this.confirmSignOut}><Icon symbolName="logout" className={styles.menuIcon} />{t`Sign Out`}</p>
            </F>
          }
        </nav>
      </CSSTransition>
    );
  }
}
