// -------------------------------------------------------------------------------------------------
//  ColorTagsEditor.js
//  - - - - - - - - - -
//  Компонента редагування бітів поля colorBits (в модальному вікні).
//  Перший біт використовується для відображення статусу Active/Inactive.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {COLLECTION_OBJECT, COURSE_OBJECT, CONTACT_OBJECT, GROUP_OBJECT, STAPLE_OBJECT} from 'core/commonTypes';
import {addBodyClass, removeBodyClass, NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import {COLOR_TAGS_FLD} from 'core/apiFields';
import {changePendingFields} from 'actions/MiscActions';
import BitmaskField from 'components/UI/fields/BitmaskField';
import styles from './ColorTagsEditor.scss';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - OneChat';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function editColorTags({objectId, objectType, colorTags, position = 0}) {
  pubsub.publish({
    objectId: objectId,
    objectType: objectType,
    colorTags: colorTags,
    position: position,
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ColorTagsEditor extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      objectId: null,                     // ідентифікатор обʼєкту в якому змінюємо теги
      objectType: null,                   // тип обʼєкту в якому змінюємо теги
      colorTags: 0,                       // вміст поля 'colorTags'
      position: 0,                        // вміст поля 'position' (потрібно для Contacts/Collections щоб оновити ОБИДВА поля одночасно) !!!
      initialColorTags: 0,                // початкове значення поля 'colorTags'
      initialPosition: 0,                 // початкове значення поля 'position'
      isProcessing: false,                // чи знаходиться компонент в стані обробки?
      isDisplayed: false,                 // чи відображається компонент взагалі?
    }
    this.localEditorRef = React.createRef(); // посилання на редактор повідомлення
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({objectId, objectType, colorTags, position = 0}) => {
      this.setState({
        objectId,
        objectType,
        colorTags,
        position,
        initialColorTags:colorTags,
        initialPosition:position,
        isProcessing: false,
        isDisplayed: true
      });
      PreventBodyScroll();
      addBodyClass('no-submodal-esc');
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleRemoveNoEscape = () => {
    removeBodyClass('no-submodal-esc');
  }

  // attn: 2): якщо швидко зняти флаг, то спрацює закриття форми SubmodalLevel !!!
  handleClose = () => {
    this.setState({
      isProcessing: false,
      isDisplayed: false,
    });
    RestoreBodyScroll();
    const {handleSetFocus} = this.state;
    handleSetFocus && handleSetFocus();
    setTimeout(this.handleRemoveNoEscape, 500); // sic!: 2)
  }

  handleChangeColorTags = (nextColorTags) => {
    trace(`handleChangeColorTags`);
    this.setState({
      colorTags: nextColorTags,
    });
  }

  handleSubmit = async () => {
    trace(`handleSubmit`);
    this.setState({isProcessing: true});
    const {objectId, objectType, colorTags, initialColorTags, position, initialPosition} = this.state;
    if (objectId && objectType && (colorTags !== initialColorTags || position !== initialPosition)) {
      changePendingFields({objectId, objectType, fieldType:COLOR_TAGS_FLD, colorTags, position});
    }
    this.setState({isProcessing: false});
    this.handleClose();
  }

  getHeader = () => {
    const {objectType} = this.state;
    let header;
    switch (objectType) {
      case CONTACT_OBJECT:
        header = t`Edit Contact Tags`;
        break;
      case GROUP_OBJECT:
        header = t`Edit Group Tags`;
        break;
      case COLLECTION_OBJECT:
        header = t`Edit Collection Tags`;
        break;
      case COURSE_OBJECT:
        header = t`Edit Course Tags`;
        break;
    }
    return header;
  }

  getSymbolName1 = (isTrue) => {
    const {objectType} = this.state;
    let symbolName;
    switch (objectType) {
      case CONTACT_OBJECT:
        symbolName = isTrue ? "account-circle" : "account-circle-outline";
        break;
      case GROUP_OBJECT:
        // symbolName = isTrue ? "folder-account" : "folder-account-outline";
        symbolName = isTrue ? "folder-open" : "folder-outline";
        break;
      case COLLECTION_OBJECT:
        symbolName = isTrue ? "folder-open" : "folder-outline";
        break;
      case COURSE_OBJECT:
        // symbolName = isTrue ? "folder-star" : "folder-star-outline";
        symbolName = isTrue ? "folder-open" : "folder-outline";
        break;
    }
    return symbolName;
  }

  getSymbolNameX = (isTrue) => {
    return isTrue ? "checkbox-blank" : "checkbox-blank-outline";
  }

  getTitle1 = (isTrue) => {
    return isTrue ? t`Active State` : t`Inactive State`;
  }

  render() {
    const {isDisplayed} = this.state;
    if (!isDisplayed) {
      return null;
    }
    const {colorTags, isProcessing} = this.state;
    return (
      <div className={styles.backgroundWrapper} onClick={this.handleClose}>
        <div className={styles.ColorTagsEditor} onClick={e => e.stopPropagation()}>
          <h1>{this.getHeader()}</h1>
          <BitmaskField
            className={styles.bitmaskField}
            value={colorTags}
            mode="editor"
            getSymbolName1={this.getSymbolName1}
            getSymbolNameX={this.getSymbolNameX}
            getTitle1={this.getTitle1}
            onChange={this.handleChangeColorTags}
          />
          <div className={styles.controlPanel}>
            <button
              className={classnames({[styles.isDisabled]: isProcessing})}
              onClick={this.handleSubmit}>
              {t`Save`}
            </button>
          </div>
        </div>
        <NoTabbar />
      </div>
    );
  }
}
