// -------------------------------------------------------------------------------------------------
//  FirstSidebarContainer.js
//  - - - - - - - - - -
//  Контейнер основного сайдбару.
//
//  Attn:
//  - - - - -
//  - параметр relatedCollections задається через компоненту <PageSidebar .../>;
//
//  - ідеологія роботи з сайдбаром (FirstSidebar):
//    - в ChatStore зберігаємо код та тип поточного контексту (sideCtxId, sideCtxType);
//    - для кожного контексту зберігаємо вектор поточних параметрів (sideOptions);
//    - коли юзер міняє контекст --> зберігаємо новий контекст + змінюємо вектор згідно нового контексту;
//
//  - ідеологія роботи зі списками:
//    - friendIds         - коди активних френдів (стан ACCEPTED)
//    - allFriendIds      - коди усіх друзів (активні + усі друзі з чатів) + користувач
//                          в чатах друзі можуть вже не бути активними друзями,
//                          бо були видалені юзером зі списку друзів
//    - chatMemberIds     - коди учасників поточного чату
//    - allChatMemberIds  - коди усіх учасники з усіх чатів,
//                          необхідний для визначення списку друзів БЕЗ_ЧАТІВ/з чатами;
//    - friends           - усі активні друзі (стан ACCEPTED)
//    - allUsers          - усі активні друзі + усі друзі з чатів (після того вони могли бути видалені) + користувач
//
//    - порівняння множин:
//      1)  friendIds << allFriendIds
//      2)  chatMemberIds <<= allChatMemberIds <<= allFriendIds
// -------------------------------------------------------------------------------------------------
// ToDo: створити окремий стор LayoutStore зі станом сайдбару (бо при переході на стейпл активує табу чату) !!!

import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {isChatScrolled, scrollChatToBottom, saveChatScrollPosition, restoreChatScrollPosition} from 'components/Chats/OneChat';
import {fetchStapleSctxChats, fetchChats, fetchChatMessages} from 'actions/ChatActions'; // ToDo: COLLECTION_CTX - fetchCollectionCctxChats !!!
import {fetchMyCollections} from 'actions/CollectionActions';
import {fetchMyGroups} from 'actions/GroupActions';
import {fetchSystemCategories, fetchSystemSubjects} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CollectionStore from 'stores/CollectionStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';
import {sidebar as settings} from 'settings/local.yaml';

const FirstSidebar = Loader.create(() => import('./FirstSidebar'));
const isVerbose = DEBUG && true;
const prefix = '- - - FirstSidebarContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

const {displayedFriendsQty:DISPLAYED_FRIENDS_QTY} = settings;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class FirstSidebarContainer extends React.Component {
  static propTypes = {
    isLoggedIn: Types.bool,               // is current user logged in?
    isMobile: Types.bool,                 // is an app running in mobile layout?
    pathname: Types.string,               // поточна url-адреса
    relatedCollections: Types.object,     // список повʼязаних колекцій у форматі {{ CO-LIST }}
    onHideSidebar: Types.func,            // callback called when the overlay is clicked
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CollectionStore, CrmStore, StapleStore, SystemStore, UserStore];
  }

  // - - - - -
  // 1) якщо змінити на AccountStore, то не оновлює список колекцій !!!
  // 2) allChatMemberIds НЕ ПОВИНЕН містити myId, бо саме тут визначається наявність чату з юзером !!!
  // - - - - -
  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {isLoggedIn} = props;
    const myId = ChatStore.getMyId(); // sic!: 1)
    const myAccount = AccountStore.getMyAccount();
    const myCollections = CollectionStore.getMyCollections();
    const myGroups = CrmStore.getMyGroups();
    const selectedContacts = CrmStore.getSelectedContacts();
    const selectedStaples = StapleStore.getSelectedStaples();
    const allSubjects = SystemStore.getSubjectList();
    const {callState} = UserStore.getCallOptions();
    const {tabMode, ctxId, ctxType, ctxOwnerId, chatId} = ChatStore.getSideOptions();
    const chats = ctxId && ctxType ? ChatStore.getTopicChats(ctxId, ctxType) : undefined;
    const chat = chatId ? ChatStore.getChat(chatId) : undefined;
    const friendIds = UserStore.getMyFriendIds();
    const allFriendIds = chats ?
      chats.reduce((mainAccumulator, chat) => {
        return chat.memberIds.reduce((acc, id) => acc.add(id), mainAccumulator);
      }, friendIds) :
      friendIds;
    const allUserIds = chat ?
      chat.memberIds.reduce((acc, id) => acc.add(id), allFriendIds) :
      allFriendIds;
    const allChatMemberIds = chats ?
      chats.reduce((mainAccumulator, chat) => {
        return chat.memberIds.reduce((acc, id) => acc.add(id), mainAccumulator);
      }, Set()).delete(myId) :
      Set(); // sic!: 2) - без myId !!!
    const allUsers = UserStore.getSomeUsers(allUserIds);
    const unreadIds = isLoggedIn ? ChatStore.getUnreadIds() : Set();
    const unreadCountsByChat = isLoggedIn ? ChatStore.getUnreadCountsByChat() : {};
    const messageCursor = chat ? chat.messageCursor : DEFAULT_TSN12_CURSOR;
    return {
      myId, myAccount, myCollections, myGroups, allSubjects,
      selectedContacts, selectedStaples,
      tabMode, ctxId, ctxType, ctxOwnerId, callState,
      friendIds, allChatMemberIds, allUsers,
      chats, chat, chatId, unreadIds, unreadCountsByChat, messageCursor
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || this.state.unreadCountsByChat.size !== nextState.unreadCountsByChat.size
      || this.state.messageCursor !== nextState.messageCursor
      || this.state.callState !== nextState.callState
      || this.state.tabMode !== nextState.tabMode
      || this.state.chatId !== nextState.chatId
      || this.state.ctxId !== nextState.ctxId
      || this.state.ctxType !== nextState.ctxType
      || this.state.ctxOwnerId !== nextState.ctxOwnerId
      || this.props.pathname !== nextProps.pathname
      || this.props.isMobile !== nextProps.isMobile
      || this.props.isLoggedIn !== nextProps.isLoggedIn
      || this.state.friendIds.size !== nextState.friendIds.size
      || this.state.allChatMemberIds.size !== nextState.allChatMemberIds.size
      || this.state.allUsers.size !== nextState.allUsers.size
      || this.state.selectedContacts.size !== nextState.selectedContacts.size
      || this.state.selectedStaples.size !== nextState.selectedStaples.size
      || this.state.myCollections.size !== nextState.myCollections.size
      || this.state.myGroups.size !== nextState.myGroups.size
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || this.props.relatedCollections && nextProps.relatedCollections && this.props.relatedCollections.size !== nextProps.relatedCollections
      || !is(this.state.unreadCountsByChat, nextState.unreadCountsByChat)
      || !is(this.state.selectedContacts, nextState.selectedContacts)
      || !is(this.state.selectedStaples, nextState.selectedStaples)
      || !is(this.state.chat, nextState.chat)
      || !is(this.state.chats, nextState.chats)
      || !is(this.state.allUsers, nextState.allUsers)
      || !is(this.props.relatedCollections, nextProps.relatedCollections)
      || !is(this.state.myCollections, nextState.myCollections)
      || !is(this.state.myGroups, nextState.myGroups)
      || !is(this.state.myAccount, nextState.myAccount);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {ctxId:prevCtxId, ctxType:prevCtxType, chatId:prevChatId, ctxOwnerId:prevUserId} = this.state;
    const {ctxId:nextCtxId, ctxType:nextCtxType, chatId:nextChatId, ctxOwnerId:nextUserId} = nextState;
    trace(`UNSAFE_componentWillUpdate(1): ctxId=['${prevCtxId}'-->'${nextCtxId}'], chatId=['${prevChatId}'-->'${nextChatId}'], ctxOwnerId=['${prevUserId}'-->'${nextUserId}']`);
    // ...my collections
    if (!CollectionStore.areMyCollectionsLoaded()) {
      fetchMyCollections();
    }
    // ...my groups
    if (!CrmStore.areMyGroupsLoaded()) {
      fetchMyGroups();
    }
    // ...all subjects
    if (!SystemStore.areSubjectsLoaded()) {
      const {tabMode} = nextState;
      if (tabMode === SUBJECT_TAB) {
        fetchSystemSubjects();
      }
    }
    // ...fetch context chats for STAPLE
    if (nextCtxId && prevCtxId !== nextCtxId && nextCtxType === STAPLE_CTX
      && !StapleStore.areStapleChatsLoaded(nextCtxId)) {
      this.isFetchingChats = false;
      fetchStapleSctxChats({stapleId: nextCtxId}); // FixMe: переробити з пагінацією !!!
    }
    // ToDo: COLLECTION_CTX !!!
    // // - - - fetch chats for COLLECTION
    // if (nextCtxId && prevCtxId !== nextCtxId && nextCtxType === COLLECTION_CTX
    //   && !CollectionStore.areCollectionChatsLoaded(nextCtxId)) {
    //   this.isFetchingChats = false;
    //   fetchCollectionCctxChats({collectionId: nextCtxId});
    // }
    // ...chat (fetch active chat of current context)
    if (nextChatId && prevChatId !== nextChatId) {
      if (!ChatStore.isChatLoaded(nextChatId)) {
        fetchChats([nextChatId]);
      }
      this.isFetchingMessages = false;
    }
  }

  // ToDo: кейс: a) отримали меседж в неактивному чаті (потрібна корекція delta на висоту отриманого) !!!
  componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate(1)`);
    const {chatId:prevChatId, chat:prevChat} = prevState;
    const {chatId:nextChatId, chat:nextChat} = this.state;
    if (prevChatId === nextChatId && prevChat && nextChat && nextChat.messages.size > prevChat.messages.size) {
      trace(`componentDidUpdate(2): message q-ty has changed: ${prevChat.messages.size} --> ${nextChat.messages.size}`);
      const prevLastMessage = prevChat.getIn(['messages', -1]);
      const nextLastMessage = nextChat.getIn(['messages', -1]);
      if (prevLastMessage && nextLastMessage && prevLastMessage.id !== nextLastMessage.id) {
        trace(`componentDidUpdate(3): new message has arrived`);
        const {myId} = this.state;
        if (myId && myId === nextLastMessage.authorId) {
          trace(`componentDidUpdate(4): it's my message`);
          scrollChatToBottom(nextChatId);
        } else {
          trace(`componentDidUpdate(5): it isn't my message`);
          if (!isChatScrolled(nextChatId)) {
            scrollChatToBottom(nextChatId);
          }
        }
      }
    }
  }

  isFetchingChats = false;

  // ToDo: імплементувати пагінацію та chatsCursor !!!
  fetchChatsPage = async () => {
    const {stapleId, chatsCursor = DEFAULT_TSN12_CURSOR} = this.state;
    trace(`fetchChatsPage(1): stapleId=${stapleId}, isFetching=${this.isFetchingChats}`);
    if (stapleId && !this.isFetchingChats) {
      trace(`fetchChatsPage(2): stapleId=${stapleId}, cursor='${chatsCursor}'`);
      this.isFetchingChats = true;
      await fetchStapleSctxChats({stapleId, cursor: chatsCursor, limit: CHATS_FEED_FETCH_LIMIT});
      this.isFetchingChats = false;
    }
  }

  isFetchingMessages = false;

  fetchMessagesPage = async () => {
    const {chatId, messageCursor = DEFAULT_TSN12_CURSOR} = this.state;
    trace(`fetchMessagesPage(1): chatId=${chatId}, isFetching=${this.isFetchingMessages}`);
    if (chatId && !this.isFetchingMessages) {
      trace(`fetchMessagesPage(2): chatId=${chatId}, cursor='${messageCursor}'`);
      this.isFetchingMessages = true;
      saveChatScrollPosition(chatId);
      await fetchChatMessages({chatId, cursor: messageCursor, limit: MESSAGES_FEED_FETCH_LIMIT});
      restoreChatScrollPosition(chatId);
      this.isFetchingMessages = false;
    }
  }

  render() {
    const {isLoggedIn, isMobile, pathname, relatedCollections, onHideSidebar} = this.props;
    const {
      myId,
      myAccount,
      myCollections,
      myGroups,
      selectedContacts,
      selectedStaples,
      tabMode,
      ctxId,
      ctxType,
      ctxOwnerId,
      callState,
      chats,
      chat,
      chatId,
      friendIds,
      allChatMemberIds,
      allUsers,
      allSubjects,
      unreadIds,
      unreadCountsByChat} = this.state;
    trace(`render`);
    return (
      <FirstSidebar
        isLoggedIn={isLoggedIn}
        isMobile={isMobile}
        pathname={pathname}
        myId={myId}
        myAccount={myAccount}
        myCollections={myCollections}
        myGroups={myGroups}
        allSubjects={allSubjects}
        relatedCollections={relatedCollections}
        selectedContacts={selectedContacts}
        selectedStaples={selectedStaples}
        tabMode={tabMode}
        ctxId={ctxId}
        ctxType={ctxType}
        ctxOwnerId={ctxOwnerId}
        callState={callState}
        chats={chats}
        chat={chat}
        chatId={chatId}
        friendIds={friendIds}
        allChatMemberIds={allChatMemberIds}
        allUsers={allUsers}
        unreadIds={unreadIds}
        unreadCountsByChat={unreadCountsByChat}
        onChatsPageTrigger={this.fetchChatsPage}
        onMessagesPageTrigger={this.fetchMessagesPage}
        onHideSidebar={onHideSidebar}
      />
    );
  }
}

export default Container.create(FirstSidebarContainer, {withProps: true, pure: false});
