// -------------------------------------------------------------------------------------------------
//  EditContactContainer.js
//  - - - - - - - - - -
//  Контейнер форми редагування контакту.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchMyContacts} from 'actions/ContactActions';
import {fetchMyGroups} from 'actions/GroupActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import CrmStore from 'stores/CrmStore';
import Loader from 'utils/ComponentLoader';

const EditContact = Loader.create(() => import('./EditContact'));
const isVerbose = DEBUG && true;
const prefix = '- - - EditContactContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class EditContactContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [CrmStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myId = CrmStore.getMyId();
    const contact = CrmStore.getMyContact(props.match.params.contactId);
    const myGroups = CrmStore.getMyGroups();
    return {myId, contact, myGroups};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || !is(this.state.contact, nextState.contact)
      || !is(this.state.myGroups, nextState.myGroups);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!CrmStore.areMyGroupsLoaded()) {
      fetchMyGroups();
    }
    if (!CrmStore.areMyContactsLoaded()) {
      fetchMyContacts();
    }
    // CTX:
    substituteSidebarTabMode(GROUP_TAB);
    substituteSidebarChat({chatId: ''});
  }

  async componentDidUpdate() {
    trace(`componentDidUpdate`);
    // CTX:
    substituteSidebarTabMode(GROUP_TAB);
    substituteSidebarChat({chatId: ''});
  }

  render() {
    const {myId, contact, myGroups} = this.state;
    if (!contact) {
      return null;
    }
    trace(`render`);
    return (
      <EditContact
        myId={myId}
        contact={contact}
        myGroups={myGroups}
      />
    );
  }
}

export default Container.create(EditContactContainer, {withProps: true, pure: false});
