// -------------------------------------------------------------------------------------------------
//  ConmarkProcessor.js
//  - - - - - - - - - - - - - -
//  Перетворення між форматами Conmark <- - -> { Mdast || HTML || React }.
//
//  Inspired by:
//  https://github.com/orbiting/mdast
//  https://github.com/orbiting/mdast/tree/master/packages/remark-preset
//
//  Docs:
//  - - - - -
//  https://github.com/unifiedjs/unified - an interface for processing text using syntax trees
//  https://github.com/syntax-tree/mdast - MDAST / Markdown Abstract Syntax Tree format
//  https://github.com/syntax-tree/hast - HAST / Hypertext Abstract Syntax Tree format
//  https://github.com/rehypejs/rehype - rehype is an ecosystem of plug-ins for processing HTML
//  https://github.com/remarkjs/remark - remark is an ecosystem of plugins for processing markdown
//  https://spec.commonmark.org/0.28/ - Commonmark specification
//  https://github.com/syntax-tree/hast-util-sanitize#schema - the schema is documented here
//
//  Plugins:
//  - - - - -
//  https://github.com/remarkjs/remark/blob/master/doc/plugins.md             - remark plugins
//  https://github.com/rehypejs/rehype/blob/master/doc/plugins.md             - rehype plugins
//  https://github.com/rehypejs/rehype-minify/tree/master/packages            - rehype minify plugins
//
//  Packages:
//  - - - - -
//  https://github.com/remarkjs/remark/tree/master/packages/remark-parse      - parse options
//  https://github.com/remarkjs/remark/tree/master/packages/remark-stringify  - stringify options
//  https://github.com/remarkjs/remark-rehype                                 - mdast --> hast
//  https://github.com/rehypejs/rehype-sanitize                               - sanitize hast tree
//  https://github.com/rhysd/rehype-react                                     - hast --> react
//
//  Usage:
//  - - - - -
//  const conmark = ConmarkProcessor.fromMdast(mdast);
//  const mdast = ConmarkProcessor.toMdast(conmark);
//  const html = ConmarkProcessor.toHtml(conmark);
//  const react = ConmarkProcessor.toReact(conmark);
// -------------------------------------------------------------------------------------------------
// ToDo: оформити специфікацію формату Conmark !!!
// ToDo: при генерації html додавати div в т.ч. і для одного елементу (та видалити classnames(..., 'markdown')) !!!
// FixMe: не додає враппер з класом .markdown для conmark-полів з простою розміткою !!!

import React from 'react';
import unified from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import rehypeSanitize from 'rehype-sanitize';
import rehypeCollapseSpaces from 'rehype-minify-whitespace';
import rehypeHtml from 'rehype-stringify';
import rehypeLinkAttrs from './plugins/rehype-link-attrs';
import * as customBreak from './plugins/custom-break';
import * as customText from './plugins/custom-text';
import {EMPTY_CONMARK} from 'core/conmarkTypes';
import {MARKDOWN_CSS, SELECTABLE_CSS} from 'core/commonTypes';
import schema from './schema.json';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const fromMdastProcessor = unified()
  .use(remarkStringify, {
    gfm: true, // якщо true, то додаткова розмітка по правилам GFM
    commonmark: true, // sic!: break повинен відмічатися як '\' !!!
    pedantic: false,
    entities: false, // sic!: без перетворень (ескейпінг проводиться на стороні бекенду) !!!
    setext: false, //
    closeAtx: false, //
    looseTable: false, // якщо false, то не викидаються символи '|' по краям таблиці
    spacedTable: true, // якщо true, то додаткові пробіли навколо контенту
    paddedTable: false, // якщо false, то не вирівнюються стрічок в комірках по довжині (пробілами)
    incrementListMarker: true, // якщо true, то номери в елементах списку інкрементуються
    listItemIndent: 'tab',
    bullet: '-', // item bullet symbol
    strong: '*', // strong / bold symbol
    emphasis: '_', // emphasis / italic symbol
    fence: '`', // inline_code symbol
    fences: false, //
    rule: '*', //
    ruleSpaces: true, //
    ruleRepetition: 3, //
  });

export function fromMdast(mdast) {
  return fromMdastProcessor.stringify(fromMdastProcessor.runSync(mdast)); // --> conmark
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const toMdastProcessor = unified()
  .use(remarkParse, {
    gfm: true,
    commonmark: true, // sic!: break повинен парситись по символу '\' !!!
    pedantic: false,
    position: false,
    footnotes: false,
  })
  .use(customBreak.parse)
  .use(customText.parse);

export function toMdast(conmark) {
  return toMdastProcessor.runSync(toMdastProcessor.parse(conmark)); // --> mdast
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const toHtmlProcessor = unified()
  .use(remarkParse, {
    gfm: true,
    commonmark: true, // sic!: break повинен парситись по символу '\' !!!
    pedantic: false,
    position: false,
    footnotes: false,
  })
  .use(customBreak.parse)
  .use(customText.parse)
  .use(remark2rehype)
  .use(rehypeSanitize, schema)
  .use(rehypeCollapseSpaces)
  .use(rehypeLinkAttrs)
  .use(rehypeHtml);

export function toHtml(conmark) {
  return toHtmlProcessor.processSync(conmark).toString(); // --> html
}

function customCreateElement(component, props, children) {
  // - - - - -
  // Attn:
  // - покладаємось на те, що є лише один тег div (той, що огортає згенерований html);
  // - до цього тегу додаємо клас 'markdown';
  // - - - - -
  // FixMe: не додає клас MARKDOWN_CSS для тексту з одним типом розмітки !!!
  // FixMe: через це Conmark-полів доводиться додавати врапер з класом MARKDOWN_CSS !!!
  return React.createElement(
    component, component === 'div' ? { className: MARKDOWN_CSS } : props, children
  );
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const toReactProcessor = unified()
  .use(remarkParse, {
    gfm: true,
    commonmark: true, // sic!: break повинен парситись по символу '\' !!!
    pedantic: false,
    position: false,
    footnotes: false,
  })
  .use(customBreak.parse)
  .use(customText.parse)
  .use(remark2rehype)
  .use(rehypeSanitize, schema)
  .use(rehypeCollapseSpaces)
  .use(rehypeLinkAttrs)
  .use(rehype2react, {
    createElement: customCreateElement
  });

// FixMe: вирішити проблему "<a> cannot appear as a descendant of <a>" !!!
export function toReact(conmark) {
  return conmark && conmark !== EMPTY_CONMARK ? toReactProcessor.processSync(conmark).contents : null; // --> react
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default {
  fromMdast,
  toMdast,
  toHtml,
  toReact,
}
