// -------------------------------------------------------------------------------------------------
//  DesktopNavbar.js
//  - - - - - - - - - -
//  Навігаційне меню десктопного режиму.
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {Link, NavLink} from 'react-router-dom';
import classnames from 'classnames';
import {t} from 'ttag';
import {BASIC_ACCOUNT, PRO_ACCOUNT, PREMIUM_ACCOUNT, ENTERPRISE_ACCOUNT, ADMIN_ACCOUNT, accountTypeName} from 'core/accountTypes';
import {logout} from 'actions/AuthActions';
import {confirmAction} from 'components/UI/ConfirmAction';
import Avatar from 'components/UI/Avatar';
import Icon from 'components/UI/icons/Icon';
import PopupMenu, {togglePopupMenu} from 'components/UI/PopupMenu';
// import FilterField from 'components/UI/fields/FilterField';
import styles from './DesktopNavbar.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - DesktopNavbar';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const IconTab = ({title = '', to, exact, symbolName, badgeText, isActive}) => (
  <NavLink to={to}
           exact={exact}
           title={title}
           className={styles.iconTab}
           activeClassName={styles.isActive}
           isActive={isActive}>
    <Icon symbolName={symbolName} className={styles.icon} />
    {badgeText &&
      <span className={styles.badgeText}>{badgeText}</span>
    }
  </NavLink>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const IconButton = ({symbolName, className, activeClassName, isActive, onClick}) => (
  <div className={classnames(className, {[activeClassName]: isActive})} onClick={onClick}>
    <Icon symbolName={symbolName} className={styles.icon} />
  </div>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const PopupLink = ({to, caption, symbolName, className, rel=""}) => (
    <li><Link to={to} rel={rel}>{caption}<Icon symbolName={symbolName} className={classnames(styles.popupIcon, className)} /></Link></li>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const PopupButton = ({caption, symbolName, onClick}) => (
    <li><p onClick={onClick}>{caption}<Icon symbolName={symbolName} className={styles.popupIcon}/></p></li>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class DesktopNavbar extends React.Component {
  static propTypes = {
    myAccount: Types.object,              // акаунт користувача
    isLoggedIn: Types.bool,               // is current user logged in?
    isSidebarOpen: Types.bool,            // is sidebar open?
    fsrCount: Types.string,               // к-сть отриманих запитів дружби
    topicUnreadCount: Types.string,       // к-сть непрочитаних повідомлень для тематичних чатів
    communityUnreadCount: Types.string,   // к-сть непрочитаних повідомлень для прямих чатів
    csubscriptionUnreadCount: Types.string, // к-сть непрочитаних повідомлень для підписок
    onToggleSidebar: Types.func,          // callback called when the overlay is clicked
  }

  static contextTypes = {
    router: Types.object.isRequired,
  }

  confirmSignOut = () => {
    confirmAction({
        title: t`Sign Out Confirmation`,
        description: t`Are you sure?`
      }, [{
        title: t`Yes`,
        isAccented: true,
        onClick: () => {
          logout();
          this.context.router.history.replace(`/`); // `/auth/logout/success`
        }
      }, {
        title: t`No`,
        isAccented: false
      }]
    );
  }

  isRootButtonActive = () => {
    const {pathname} = this.context?.router?.route?.location || '';
    return [
      '/ads',
      '/courses',
      '/discover',
      '/featured',
      '/following'].some(s => pathname.includes(s));
  }

  // Attn: для відмітки ВЛАСНИХ колекцій встановлюємо глобальний клас addBodyClass('my-tab') !!!
  isAvatarButtonActive = () => {
    const {myAccount} = this.props;
    const {slug:mySlug} = myAccount || {};
    const {pathname} = this.context?.router?.route?.location || '';
    if (pathname !== '/g/create' && ('' + pathname).indexOf('/g/') === 0) return true; // attn: усі групи === власні !!!
    return [
      '/' + mySlug + '/collections',
      '/' + mySlug + '/courses',
      '/' + mySlug + '/items',
      '/' + mySlug + '/ads',
      '/my/groups',
      '/my/contacts',
      '/my/friends',
      '/my/invitations'].some(s => pathname.includes(s));
  }

  isSubscriptionsButtonActive = () => {
    const {pathname} = this.context?.router?.route?.location || '';
    return [
      '/my/subscriptions',
      '/sub/'].some(s => pathname.includes(s));
  }

  isAddButtonActive = () => {
    const {pathname} = this.context?.router?.route?.location || '';
    return [
      '/i/create',
      '/co/create',
      '/a/create',
      '/g/create',
      '/k/create'].some(s => pathname.includes(s));
  }

  isPricingButtonActive = () => {
    const {pathname} = this.context?.router?.route?.location || '';
    return ['/pricing'].some(s => pathname.includes(s));
  }

  render() {
    const {
      myAccount,
      isLoggedIn,
      isSidebarOpen,
      fsrCount,
      topicUnreadCount,
      communityUnreadCount,
      csubscriptionUnreadCount,
      onToggleSidebar} = this.props;
    const currLocation = this.context.router.history.location;
    // const {pathname} = this.context?.router?.route?.location || '';
    const {alias:myAlias, slug:mySlug, accountType:myAccountType, avatarSsUrl} = myAccount || {};
    trace(`render`);
    return (
      <header className={classnames({[styles.isSidebarOpen]: isSidebarOpen})}>
        <nav className={styles.DesktopNavbar}>
          <div className={styles.leftWrapper}>
            <div className={styles.systemMenuWrapper}>
              <IconButton
                className={styles.systemButton}
                symbolName="three-bars"
                onClick={() => togglePopupMenu('system-popupmenu')}
              />
              <PopupMenu id="system-popupmenu" className={styles.popupMenu}>
                <ul>
                  {isLoggedIn &&
                    <F>
                      <PopupLink to={"/settings"} caption={t`Settings`} symbolName="gear6x"/>
                      <span></span>
                      <PopupLink to={"/about"} caption={t`About Us`} symbolName="logo-icon-bw"/>
                      <PopupLink to={"/pricing"} caption={t`Pricing Page`} symbolName="gift"/>
                      <PopupLink to={"/courses"} caption={t`Discover Courses Feed`} symbolName="school"/>
                      <PopupLink to={"/featured"} caption={t`Featured Staples Feed`} symbolName="star-circle"/>
                      <PopupLink to={"/discover"} caption={t`Discover Staples Feed`} symbolName="explore"/>
                      <PopupLink to={"/following"} caption={t`Following Feed`} symbolName="eye"/>
                      <span></span>
                      <PopupLink to={"/terms-of-service"} caption={t`Terms of Service`}/>
                      <PopupLink to={"/privacy-policy"} caption={t`Privacy Policy`}/>
                      <span></span>
                      <PopupButton caption={t`Sign Out`} symbolName="logout" onClick={this.confirmSignOut}/>
                    </F>
                  }
                  {!isLoggedIn &&
                    <F>
                      <PopupLink to={"/auth/login"} caption={t`Login`} symbolName="login" rel="nofollow noopener noreferrer"/>
                      <span></span>
                      <PopupLink to={"/about"} caption={t`About Us`} symbolName="logo-icon" className={styles.aboutIcon}/>
                      <PopupLink to={"/pricing"} caption={t`Pricing Page`} symbolName="gift"/>
                      <PopupLink to={"/courses"} caption={t`Discover Courses Feed`} symbolName="school"/>
                      <PopupLink to={"/featured"} caption={t`Featured Staples Feed`} symbolName="star-circle"/>
                      <PopupLink to={"/discover"} caption={t`Discover Staples Feed`} symbolName="explore"/>
                      <span></span>
                      <PopupLink to={"/terms-of-service"} caption={t`Terms of Service`}/>
                      <PopupLink to={"/privacy-policy"} caption={t`Privacy Policy`}/>
                    </F>
                  }
                </ul>
              </PopupMenu>
            </div>
            <NavLink to="/" exact title={t`Social Feeds`} className={styles.logoButton} activeClassName={styles.isActive} isActive={this.isRootButtonActive}>
              <Icon symbolName="logo-icon" className={styles.logoIcon} />
              <div className={styles.logoWrapper}>
                <Icon symbolName="logo-text" className={styles.logoText} />
                <div className={styles.accountTypeName}>{accountTypeName(myAccountType)}</div>
              </div>
            </NavLink>
          </div>
          <div className={styles.middleWrapper}>
          {/*
            {pathname.includes('/my/contacts') &&
              <FilterField
                className={styles.filterField}
                title={t`Filter Contacts`}
                // value={contactFilterAnyCase}
                placeholder={t`Filter Contacts...`}
                symbolName="person-search"
                autoFocus={false}
                // onChange={this.generateFieldHandler('contactFilter')}
                // onClear={this.handleClearContactFilter}
              />
            }
          */}
            {isLoggedIn &&
              <div className={styles.tabsWrapper}>
                <ul>
                  <li title={myAlias}>
                    <NavLink to={`/${mySlug}`} className={styles.myTab} activeClassName={styles.isActive} isActive={this.isAvatarButtonActive}>
                      <Avatar
                        className={styles.myAvatar}
                        title={myAlias}
                        badgeText={fsrCount}
                        largePreviewUrl={avatarSsUrl}
                      />
                    </NavLink>
                  </li>
                  <li>
                    <IconTab
                      title={t`My Topics Feed`}
                      to="/my/topics"
                      // symbolName={topicUnreadCount ? "inbox-arrow-down" : "inbox-full"}
                      symbolName={topicUnreadCount ? "inbox-full" : "inbox-full"}
                      badgeText={topicUnreadCount}
                      activeClassName={styles.isActive}
                    />
                  </li>
                  <li>
                    <IconTab
                      title={t`My Community Feed`}
                      to="/my/community"
                      symbolName="chat-directtype"
                      badgeText={communityUnreadCount}
                      activeClassName={styles.isActive}
                    />
                  </li>
                  <li>
                    <IconTab
                      title={t`My Subscriptions Feed`}
                      to="/my/subscriptions"
                      symbolName="bookmark-education"
                      badgeText={csubscriptionUnreadCount}
                      activeClassName={styles.isActive}
                      isActive={this.isSubscriptionsButtonActive}
                    />
                  </li>
                {/* - - - ToDo: Hidden functionality of Neuralnets !!!
                  <li>
                    <IconTab
                      title={t`My GPT Conversations Feed`}
                      to="/gpt/topics"
                      symbolName="openai"
                      activeClassName={styles.isActive}
                    />
                  </li>
                */}
                  <li className={styles.addMenuWrapper} title={t`Create new object`}>
                    <IconButton
                      className={styles.addButton}
                      symbolName="add"
                      activeClassName={styles.isButtonActive}
                      isActive={this.isAddButtonActive()}
                      onClick={() => togglePopupMenu('add-popupmenu')}
                    />
                    <PopupMenu id="add-popupmenu" className={styles.popupMenu}>
                      <ul>
                        <PopupLink symbolName="document-common" to={{pathname: "/i/create", state: {prevLocation: currLocation}}} caption={t`Create Staple`}/>
                        <PopupLink symbolName="folder-open" to={{pathname: "/co/create", state: {prevLocation: currLocation}}} caption={t`Create Collection`}/>
                        <PopupLink symbolName="school" to={{pathname: "/crs/create", state: {prevLocation: currLocation}}} caption={t`Create Course`}/>
                        <span></span>
                      {/* ToDo: Hidden functionality of Adverts !!!
                        <PopupLink symbolName="pencil" to={{pathname: "/a/create", state: {prevLocation: currLocation}}} caption={t`Create Advert`}/>
                        <span></span>
                      */}
                        <PopupLink symbolName="account-circle" to={{pathname: "/k/create", state: {prevLocation: currLocation}}} caption={t`Create Contact`}/>
                        <PopupLink symbolName="people3" to={{pathname: "/g/create", state: {prevLocation: currLocation}}} caption={t`Create Group`}/>
                        <span></span>
                        <PopupLink symbolName="person" to={{pathname: "/f/invite", state: {prevLocation: currLocation}}} caption={t`Invite a Friend by Email`}/>
                      </ul>
                    </PopupMenu>
                  </li>
                  {myAccountType && myAccountType === BASIC_ACCOUNT &&
                    <NavLink to={`/pricing`} className={styles.pricingTab} activeClassName={styles.isActive} isActive={this.isPricingButtonActive}>
                      <span>
                        {/* <Icon symbolName="gift" className={styles.icon} /> */}
                        {t`Pricing Page`}
                      </span>
                    </NavLink>
                  }
                </ul>
              </div>
            }
          </div>
          <div className={styles.rightWrapper}>
            {!isLoggedIn &&
              <NavLink to={`/pricing`} className={styles.pricingButton} activeClassName={styles.isActive} isActive={this.isPricingButtonActive}>
                {/* <Icon symbolName="gift" className={styles.icon} /> */}
                <span>{t`Pricing Page`}</span>
              </NavLink>
            }
            {!isLoggedIn &&
              <Link to="/auth/login" rel="nofollow noopener noreferrer" className={styles.loginButton}>
                <Icon symbolName="login" className={styles.icon} />
                <span>{t`Login`}</span>
              </Link>
            }
            {isLoggedIn &&
              <IconButton
                className={styles.sidebarButton}
                symbolName={isSidebarOpen ? 'chevron-right' : 'chevron-left'}
                onClick={onToggleSidebar}
              />
            }
          </div>
        </nav>
      </header>
    );
  }
}
