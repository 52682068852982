// -------------------------------------------------------------------------------------------------
//  CreateGradeMessage.js
//  - - - - - - - - - -
//  Attn: 1) error-system: clear error message on any change;
//  Attn: 2) тип чату зберігається в полі chat_type mnesia-таблиці непрочитаних повідомлень #unread_message);
// -------------------------------------------------------------------------------------------------
// ToDo: впорядкувати formError, richBodyError: показувати помилки прямо у формі, а не у confirmAction() !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {BODY_FLD} from 'core/apiFields';
import {UCTX_CHAT, SCTX_CHAT} from 'core/communicationTypes';
import {
  MC_GRADE, MB_GRADE, MA_GRADE,
  M9_GRADE, M8_GRADE, M7_GRADE,
  M6_GRADE, M5_GRADE, M4_GRADE,
  M3_GRADE, M2_GRADE, M1_GRADE} from 'core/gradeTypes';
import {addBodyClass, removeBodyClass, NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import {createStapleGrade} from 'actions/StapleActions';
import {confirmAction} from 'components/UI/ConfirmAction';
import {getErrorTitle, getErrorDescription} from 'utils/errors';
import PictButton from 'components/UI/buttons/PictButton';
import RichField, {toRich, isRichEmpty, toConmark, cleanifyConmark, isConmarkEmpty} from 'components/UI/fields/RichField';
import Grade, {getGradeTitle} from 'components/UI/Grade';
import styles from './CreateGradeMessage.scss';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - OneChat';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function createGradeMessage({myId, userId, chatId, chatType, stapleId, richBody, handleSetFocus = null}) {
  pubsub.publish({
    myId: myId,
    userId: userId,
    chatId: chatId,
    chatType: chatType,
    stapleId: stapleId,
    richBody: richBody,
    handleSetFocus: handleSetFocus,
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class CreateGradeMessage extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      myId: null,                         // код користувача
      userId: null,                       // код юзера, якому виставляється оцінка
      chatId: null,                       // код чату в який надсилатимемо оцінку
      chatType: '',                       // тип чату в який надсилатимемо оцінку
      stapleId: null,                     // код стейплу за який виставляється оцінка
      gradeId: '',                        // код оцінки, яку планується виставити
      gradeIdError: '',                   // помилка, що відноситься до поля 'gradeId'
      richBody: toRich(''),               // текст повідомлення в форматі Conmark (опціонально)
      richBodyError: '',                  // помилка, що відноситься до поля 'richBody'
      formError: '',                      // помилка, що відноситься до форми
      handleSetFocus: null,               // функція встановлення фокусу на елементі OneChat
      isProcessing: false,                // чи знаходиться компонент в стані обробки?
      isDisplayed: false,                 // чи відображається компонент взагалі?
    }
    this.localEditorRef = React.createRef(); // посилання на редактор повідомлення
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({myId, userId, chatId, chatType, stapleId, richBody, handleSetFocus}) => {
      this.setState({
        myId,
        userId,
        chatId,
        chatType,
        stapleId,
        richBody,
        handleSetFocus,
        isProcessing: false,
        isDisplayed: true
      });
      PreventBodyScroll();
      addBodyClass('no-submodal-esc');
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleRemoveNoEscape = () => {
    removeBodyClass('no-submodal-esc');
  }

  // Attn: 2) якщо швидко зняти флаг, то спрацює закриття форми SubmodalLevel !!!
  handleClose = () => {
    this.setState({
      isProcessing: false,
      isDisplayed: false,
    });
    RestoreBodyScroll();
    const {handleSetFocus} = this.state;
    handleSetFocus && handleSetFocus();
    setTimeout(this.handleRemoveNoEscape, 500); // sic!: 2)
  }

  handleKeyDown = (event, change, editor) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
    if (event.key === 'Enter'
      && event.altKey === false
      && event.ctrlKey === false
      && event.metaKey === false
      && event.shiftKey === false)
    {
      trace(`handleKeyDown: ENTER`);
      this.handleCreateStapleGrade();
    }
  }

  handleLocalSetFocus = () => {
    this.localEditorRef
    && this.localEditorRef.current
    && this.localEditorRef.current.focus
    && this.localEditorRef.current.focus();
  }

  checkBody = (value) => {
    let error = '';
    return error;
  }

  isFormValid = () => {
    trace(`isFormValid`);
    const {gradeId, richBody} = this.state;
    const gradeIdError = gradeId === '' ? t`Please select Grade to assign.` : '';
    const richBodyError = this.checkBody(richBody);
    this.setState({
      gradeIdError: gradeIdError,
      richBodyError: richBodyError,
      formError: '',
    });
    return gradeIdError === '' && richBodyError === '';
  }

  handleBodyChange = ({value}) => {
    trace(`handleBodyChange`);
    this.setState({
      richBody: value,
      richBodyError: '', // sic!: 1)
      formError: '', // sic!: 1)
    });
  }

  curryGrade = (f) => {
    return function(carriedGrade) {
      return function() {
        return f(carriedGrade);
      };
    };
  }

  handleSelectGrade = (gradeId) => {
    trace(`handleSelectGrade: ${gradeId}`);
    this.setState({
      gradeId: gradeId,
      gradeIdError: ''
    });
  }

  handleCreateStapleGrade = async () => {
    trace(`handleCreateStapleGrade`);
    this.setState({isProcessing: true});
    if (this.isFormValid()) {
      const {userId, chatId, chatType, stapleId, gradeId, richBody} = this.state;
      if (userId && chatId && chatType === SCTX_CHAT && stapleId && gradeId) {
        const nextGrade = {
          userId: userId,
          chatId: chatId,
          stapleId: stapleId,
          gradeId: gradeId,
          body: cleanifyConmark(toConmark(richBody))};
        const {status, errors} = await createStapleGrade(nextGrade);
        switch(status) {
          case 'ok':
            this.setState({ // clear previous value
              myId: null,
              userId: null,
              chatId: null,
              chatType: '',
              stapleId: null,
              gradeId: '',
              gradeIdError: '',
              richBody: toRich(''),
              richBodyError: '',
              formError: '',
              isProcessing: false,
            });
            this.handleClose();
            break;
          case 'account_type_error':
          case 'forbidden':
          case 'grade_already_exists':
          case 'invalid_formdata':
            confirmAction({
              title: getErrorTitle(status),
              description: getErrorDescription(status),
            });
            this.handleClose();
            break;
          default: // нештатні помилки, тому їх відображаємо !!!
            const {[BODY_FLD]:richBodyError, formError = status} = errors;
            traceError(`handleCreateStapleGrade: ERROR=${JSON.stringify(errors)}`);
            confirmAction({
              title: getErrorTitle(formError),
              description: getErrorDescription(formError),
            });
            this.setState({
              richBodyError: getErrorTitle(richBodyError),
              formError: getErrorTitle(formError),
              isProcessing: false,
            });
        }
      } else {
        this.setState({
          formError: getErrorTitle('empty_form_data'),
          isProcessing: false
        });
      }
    } else {
      this.setState({isProcessing: false});
    }
  }

  render() {
    const {isDisplayed} = this.state;
    if (!isDisplayed) {
      return null;
    }
    const {
      myId,
      userId,
      chatId,
      chatType,
      stapleId,
      gradeId,
      gradeIdError,
      richBody,
      richBodyError,
      formError,
      isProcessing} = this.state;
    return (
      <div className={styles.backgroundWrapper} onClick={this.handleClose}>
        <div className={styles.CreateGradeMessage} onClick={e => e.stopPropagation()}>
          <h1>{t`Assign Grade`}</h1>
          <div className={styles.gradeWrapper}>
            <ul className={styles.gradeList}>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === MC_GRADE})}
                  onClick={this.curryGrade(this.handleSelectGrade)(MC_GRADE)}>
                <div className={styles.title}>{getGradeTitle(MC_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={MC_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === MB_GRADE})}
                  onClick={this.curryGrade(this.handleSelectGrade)(MB_GRADE)}>
                <div className={styles.title}>{getGradeTitle(MB_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={MB_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === MA_GRADE})}
                  onClick={this.curryGrade(this.handleSelectGrade)(MA_GRADE)}>
                <div className={styles.title}>{getGradeTitle(MA_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={MA_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M9_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M9_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M9_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M9_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M8_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M8_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M8_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M8_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M7_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M7_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M7_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M7_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M6_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M6_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M6_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M6_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M5_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M5_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M5_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M5_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M4_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M4_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M4_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M4_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M3_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M3_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M3_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M3_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M2_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M2_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M2_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M2_GRADE} />
              </li>
              <li className={classnames(styles.gradeItem, {[styles.isSelected] : gradeId === M1_GRADE})}
                onClick={this.curryGrade(this.handleSelectGrade)(M1_GRADE)}>
                <div className={styles.title}>{getGradeTitle(M1_GRADE)}<span>:</span></div>
                <Grade className={styles.grade} gradeId={M1_GRADE} />
              </li>
            </ul>
          </div>
          <div className={styles.controlPanel}>
            <RichField
              className={styles.editorWrapper}
              editorClassName={styles.editor}
              inputRef={this.localEditorRef}
              value={richBody}
              error={''}
              placeholder={t`Enter text... (optional)`}
              autoFocus={true}
              hasToolbar={false}
              isDisabled={isProcessing || !chatId}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleBodyChange}
            />
            <PictButton
              className={styles.sendButton}
              iconClassName={styles.icon}
              symbolName="send-message"
              title="ENTER"
              isDisabled={isProcessing || !chatId || !gradeId}
              onClick={this.handleCreateStapleGrade}
            />
          </div>
        {/*
          {formError &&
            <section className={styles.formErrorsSection}>
              <div>{formError}</div>
            </section>
          }
        */}
        </div>
        <NoTabbar />
      </div>
    );
  }
}
