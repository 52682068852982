// -------------------------------------------------------------------------------------------------
//  communicationTypes.js
//  - - - - - - - - - -
//  Chats & RTC enumerated types.
// -------------------------------------------------------------------------------------------------
import {t} from 'ttag';

// presenceTypes:
export const ONLINE                       = '+';      // online
export const OFFLINE                      = '-';      // offline

// contextTypes:
export const USER_CTX                     = 'u';      // контекст юзера
export const STAPLE_CTX                   = 's';      // контекст стейплу
export const COLLECTION_CTX               = 'c';      // контекст колекції
export const ADVERT_CTX                   = 'a';      // контекст оголошення
export const ROOM_CTX                     = 'r';      // контекст кімнати

// chatTypes:
export const UCTX_CHAT                    = 'u';      // прямий чат між юзерами
export const SCTX_CHAT                    = 's';      // тематичний чат в контексті стейплу
export const CCTX_CHAT                    = 'c';      // тематичний чат в контексті колекції
export const ACTX_CHAT                    = 'a';      // тематичний чат в контексті оголошення
export const RCTX_CHAT                    = 'r';      // тематичний чат в контексті кімнати
export const SYSTEM_CHAT                  = '#';      // системний чат

// chatStatuses:
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const INITIAL_CHAT_STATUS          = '!';      // початковий стан чату
export const ACTIVE_CHAT_STATUS           = '+';      // чат повторно активовано
export const ASSIGNED_CHAT_STATUS         = '1';      // завдання присвоєно
export const SUBMITTED_CHAT_STATUS        = '2';      // по завданню надана відповідь
export const REVISION_CHAT_STATUS         = '3';      // завдання потрібно переробити
export const GRADED_CHAT_STATUS           = 'g';      // завдання оцінено
export const DISPUTE_CHAT_STATUS          = '4';      // до оцінки подано апеляцію
export const CLOSED_CHAT_STATUS           = '.';      // закритий чат
export const ARCHIVED_CHAT_STATUS         = ':';      // архівований чат (а значить і закритий)

export function chatStatusName(chatStatus, isBasicDisplayed) {
  switch(chatStatus) {
    case INITIAL_CHAT_STATUS:             return isBasicDisplayed ? t`Initial Chat Status` : '';
    case ACTIVE_CHAT_STATUS:              return t`Activated Chat Status`;
    case ASSIGNED_CHAT_STATUS:            return t`Assigned Chat Status`;
    case SUBMITTED_CHAT_STATUS:           return t`Submitted Chat Status`;
    case REVISION_CHAT_STATUS:            return t`Revision Requested Chat Status`;
    case GRADED_CHAT_STATUS:              return t`Graded Chat Status`;
    case DISPUTE_CHAT_STATUS:             return t`Dispute Requested Chat Status`;
    case CLOSED_CHAT_STATUS:              return t`Closed Chat Status`;
    default:                              return '';
  }
}

// messageTypes: ('q' & 'a' reserved for GPT queries & answers !!!)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const INITIAL_MESSAGE              = '!';      // встановлення статусу INITIAL_CHAT_STATUS
export const ACTIVATION_MESSAGE           = '+';      // встановлення статусу ACTIVE_CHAT_STATUS
export const ASSIGNED_MESSAGE             = '1';      // встановлення статусу ASSIGNED_CHAT_STATUS
export const SUBMISSION_MESSAGE           = '2';      // встановлення статусу SUBMITTED_CHAT_STATUS
export const REVISION_MESSAGE             = '3';      // встановлення статусу REVISION_CHAT_STATUS
export const DISPUTE_MESSAGE              = '4';      // встановлення статусу DISPUTE_CHAT_STATUS
export const TEXT_MESSAGE                 = 't';      // звичайне текстове повідомлення
export const EMBED_MESSAGE                = 'e';      // повідомлення з embed-обʼєктом
export const MEDIA_MESSAGE                = 'm';      // повідомлення з зображеннями (jpeg, png, ...)
export const DOCUMENT_MESSAGE             = 'd';      // повідомлення з документом (pdf, doc, xls, ...)
export const GRADE_MESSAGE                = 'g';      // встановлення статусу GRADED_CHAT_STATUS
export const REPLY_MESSAGE                = '<';      // повідомлення-відповідь на інше повідомлення
export const WIPED_MESSAGE                = '_';      // затерте повідомлення
export const SYSTEM_MESSAGE               = '#';      // системне повідомлення
export const MISSED_CALL_MESSAGE          = ':';      // повідомлення про пропущений дзвінок
export const REJECTED_CALL_MESSAGE        = ';';      // повідомлення про відбитий дзвінок
export const FINISHED_CALL_MESSAGE        = '*';      // повідомлення про завершення дзвінку
export const CLOSED_MESSAGE               = '.';      // встановлення статусу CLOSED_CHAT_STATUS

export function messageTypeName(msgType) {
  switch(msgType) {
    case INITIAL_MESSAGE:                 return t`Chat created`;
    case ACTIVATION_MESSAGE:              return t`Chat activated`;
    case ASSIGNED_MESSAGE:                return t`Staple assigned`;
    case SUBMISSION_MESSAGE:              return t`Staple submitted`;
    case REVISION_MESSAGE:                return t`Staple grade revision requested`;
    case DISPUTE_MESSAGE:                 return t`Staple grade dispute requested`;
    case CLOSED_MESSAGE:                  return t`Chat closed`;
    default:                              return '';
  }
}

export function messageTypeText(msgType) {
  switch(msgType) {
    case INITIAL_MESSAGE:                 return t`Chat has been created`;
    case ACTIVATION_MESSAGE:              return t`Chat has been activated`;
    case ASSIGNED_MESSAGE:                return t`Staple has been assigned`;
    case SUBMISSION_MESSAGE:              return t`Staple has been submitted`;
    case REVISION_MESSAGE:                return t`Staple revision has been requested`;
    case DISPUTE_MESSAGE:                 return t`Staple grade dispute has been requested`;
    case CLOSED_MESSAGE:                  return t`Chat has been closed`;
    default:                              return '';
  }
}

export function isStatusMessage(msgType) {
  switch(msgType) {
    case INITIAL_MESSAGE:
    case ACTIVATION_MESSAGE:
    case ASSIGNED_MESSAGE:
    case SUBMISSION_MESSAGE:
    case REVISION_MESSAGE:
    case DISPUTE_MESSAGE:
    case CLOSED_MESSAGE:
      return true;
  }
  return false
}

// mediumTypes:
export const AUDIO_MEDIUM                 = 'a';      // тип носія: аудіо дзвінок
export const VIDEO_MEDIUM                 = 'v';      // тип носія: відео дзвінок

// callRoles:
export const CALLER                       = 'clr';    // юзер, який розпочав дзвінок
export const CALLEE                       = 'cle';    // юзер, якому подзвонили

// callStates:
export const IDLE_STATE                   = '';       // sic!: '' !!!
export const RINGING_STATE                = 'r';      //
export const ESTABLISHED_STATE            = '+';      //

// callTypes:
export const DIRECT_CALL_TYPE             = 'd';      // дзвінок, у якого рівно два учасники
export const GROUP_CALL_TYPE              = 'g';      // дзвінок в якому два та більше учасники
