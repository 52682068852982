// -------------------------------------------------------------------------------------------------
//  DetailField.js
//  - - - - - - - - - -
//  Поле для вводу подробиць (details).
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import Icon from 'components/UI/icons/Icon';
import {cleanifyDetail} from 'utils/converters';
import styles from './DetailField.scss';

export const MAX_DETAIL_WIDTH             = 96;     // к-сть символів в стрічні подробиць

export const FATHER_DETAIL                = '1';    // ('Father Detail')
export const MOTHER_DETAIL                = '2';    // ('Mother Detail')
export const CHILD_DETAIL                 = '3';    // ('Child Detail')
export const RELATIVE_DETAIL              = '8';    // ('Relative Detail')
export const FAMILIAR_DETAIL              = '9';    // ('Familiar Detail')
export const LOCATION_DETAIL              = 'a';    // ('Location Detail')
export const ORGANIZATION_DETAIL          = 'c';    // ('Organization Detail')
export const JOB_TITLE_DETAIL             = 'j';    // ('Job Title Detail')
export const EXPERIENCE_DETAIL            = 'n';    // ('Experience Detail')
export const SKILLS_DETAIL                = 's';    // ('Skills Detail')
export const EDUCATION_DETAIL             = 't';    // ('Education Detail')
export const HONOR_N_AWARD_DETAIL         = 'u';    // ('Honor & Award Detail')
export const INTEREST_DETAIL              = 'v';    // ('Interest Detail')
export const HOBBY_DETAIL                 = 'x';    // ('Hobby Detail')
export const LANGUAGE_DETAIL              = 'y';    // ('Language Detail')
export const MISC_DETAIL                  = 'z';    // ('Misc Detail')

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function decodeDetailType(pnType) {
  let decodedType;
  switch (pnType) {
    case FATHER_DETAIL:                   decodedType = t`Father Detail`; break;
    case MOTHER_DETAIL:                   decodedType = t`Mother Detail`; break;
    case CHILD_DETAIL:                    decodedType = t`Child Detail`; break;
    case RELATIVE_DETAIL:                 decodedType = t`Relative Detail`; break;
    case FAMILIAR_DETAIL:                 decodedType = t`Familiar Detail`; break;
    case LOCATION_DETAIL:                 decodedType = t`Location Detail`; break;
    case ORGANIZATION_DETAIL:             decodedType = t`Organization Detail`; break;
    case JOB_TITLE_DETAIL:                decodedType = t`Job Title Detail`; break;
    case EXPERIENCE_DETAIL:               decodedType = t`Experience Detail`; break;
    case SKILLS_DETAIL:                   decodedType = t`Skills Detail`; break;
    case EDUCATION_DETAIL:                decodedType = t`Education Detail`; break;
    case HONOR_N_AWARD_DETAIL:            decodedType = t`Honor & Award Detail`; break;
    case INTEREST_DETAIL:                 decodedType = t`Interest Detail`; break;
    case HOBBY_DETAIL:                    decodedType = t`Hobby Detail`; break;
    case LANGUAGE_DETAIL:                 decodedType = t`Language Detail`; break;
    case MISC_DETAIL:                     decodedType = t`Misc Detail`; break;
    default:                              decodedType = t`Misc Detail`; break;
  }
  return decodedType;
}

export const contactDetailTypes = [       // типи подробиць, що відносяться до контактів (superset від userDetailTypes)
  {id: FATHER_DETAIL},
  {id: MOTHER_DETAIL},
  {id: CHILD_DETAIL},
  {id: RELATIVE_DETAIL},
  {id: FAMILIAR_DETAIL},
  {id: LOCATION_DETAIL},
  {id: ORGANIZATION_DETAIL},
  {id: JOB_TITLE_DETAIL},
  {id: EXPERIENCE_DETAIL},
  {id: SKILLS_DETAIL},
  {id: EDUCATION_DETAIL},
  {id: HONOR_N_AWARD_DETAIL},
  {id: INTEREST_DETAIL},
  {id: HOBBY_DETAIL},
  {id: LANGUAGE_DETAIL},
  {id: MISC_DETAIL},
];

export const userDetailTypes = [          // типи подробиць, що відносяться до юзерів (subset від contactDetailTypes)
  {id: LOCATION_DETAIL},
  {id: ORGANIZATION_DETAIL},
  {id: JOB_TITLE_DETAIL},
  {id: EXPERIENCE_DETAIL},
  {id: SKILLS_DETAIL},
  {id: EDUCATION_DETAIL},
  {id: HONOR_N_AWARD_DETAIL},
  {id: INTEREST_DETAIL},
  {id: HOBBY_DETAIL},
  {id: LANGUAGE_DETAIL},
  {id: MISC_DETAIL},
];

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class DetailField extends React.Component {
  static propTypes = {
    label: Types.string,                  // label text (is shown above the field)
    error: Types.string,                  // повідомлення про помилку; якщо вказане, то відмальовуємо поле червоним
    placeholder: Types.string,            // placeholder (e.g.: 'подробиці...')
    choices: Types.array,                 // список можливих варіантів подробиць
    isDisabled: Types.bool,               // is field disabled?
    isRequired: Types.bool,               // is field required?
    onChange: Types.func,                 // -spec: onChange({type, label}, event)
    onAddButton: Types.func,              // -spec: onAddButton({type, label})
    onKeyDown: Types.func,                // -spec: ?
  }

  static defaultProps = {
    label: '',
    error: '',
    placeholder: t`details...`,
    isDisabled: false,
    isRequired: false,
    keys: {
      UP: 38, DOWN: 40, RIGHT: 39, LEFT: 37, ENTER: 13,
      ESC: 27, PLUS: 43, A: 65, Z: 90, SPACE: 32, TAB: 9,
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTypeId: MISC_DETAIL,         // код типу подробиці
      currentValue: '',                   // текстове значення подробиці
      pickedItemIndex: 11,                // індекс поточного вибраного типу
      isDetailValid: false,               // is entered detail valid?
      isDropdownVisible: false,           // current state of dropdown
    };
  }

  componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (document.removeEventListener) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  // display methods
  scrollTo = (country, middle) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;
    const element = country;
    const elementOffset = element.getBoundingClientRect();
    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;
    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = (containerHeight / 2) - (elementHeight / 2);
    if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  }

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  }

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.inputRef;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len-1)=== ')') len = len-1;
    input.setSelectionRange(len, len);
  }

  handleDropdownClick = (e) => {
    e.preventDefault();
    const {choices} = this.props;
    if (!this.state.isDropdownVisible && this.props.isDisabled) return;
    const {currentTypeId} = this.state
    const pickedItemIndex = choices.findIndex(item => item.id === currentTypeId);
    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
      pickedItemIndex: pickedItemIndex,
    });
  }

  handleChange = (e) => {
    const {value} = e.target;
    const {onChange} = this.props;
    const {currentTypeId} = this.state;
    if (onChange) e.persist();
    this.setState({
      currentValue: cleanifyDetail(value).substr(0, MAX_DETAIL_WIDTH),
      isDetailValid: !!currentTypeId && ('' + value).length > 1
    });
    onChange && onChange({type: currentTypeId, label: value});
  }

  handleInputClick = (e) => {
    this.setState({isDropdownVisible: false});
  }

  handleDoubleClick = (e) => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  }

  handleTypeItemClick = (detailTypeItem, e) => {
    const {id:typeId} = detailTypeItem;
    this.setState({
      currentTypeId: typeId,
      isDropdownVisible: false,
    });
    const input = this.inputRef;
    input.focus();
  }

  handleKeydown = (e) => {
    const {choices, keys} = this.props;
    const {target: {className}} = e;
    if (className.includes(styles.dropdownButton) && e.which === keys.ENTER && !this.state.isDropdownVisible) return this.handleDropdownClick(e);
    if (className.includes(styles.inputControl) && (e.which === keys.ENTER || e.which === keys.ESC)) return e.target.blur();
    if (!this.state.isDropdownVisible || this.props.isDisabled) return;
    // if (className.includes(styles.searchBox)) {
    //   if (e.which !== keys.UP && e.which !== keys.DOWN && e.which !== keys.ENTER) {
    //     if (e.which === keys.ESC && e.target.value === '') {
    //       // do nothing // if search field is empty, pass event (close dropdown)
    //     } else {
    //       return; // don't process other events coming from the search field
    //     }
    //   }
    // }
    // ie hack
    if (e.preventDefault) {e.preventDefault(); }
    else {e.returnValue = false;}
    // const moveHighlight = (direction) => {
    //   this.setState({
    //     pickedItemIndex: this.getHighlightCountryIndex(direction)
    //   }, () => {
    //     this.scrollTo(this.getElement(this.state.pickedItemIndex), true);
    //   });
    // }
    switch (e.which) {
      // case keys.DOWN:
      //   moveHighlight(1);
      //   break;
      // case keys.UP:
      //   moveHighlight(-1);
      //   break;
      case keys.ENTER:
        this.handleTypeItemClick(choices[this.state.pickedItemIndex], e);
        break;
      case keys.ESC:
      case keys.TAB:
        this.setState({
          isDropdownVisible: false
        }, this.cursorToEnd);
        break;
      default:
        if ((e.which >= keys.A && e.which <= keys.Z) || e.which === keys.SPACE) {
          // this.setState({
          //   queryString: this.state.queryString + String.fromCharCode(e.which)
          // }, this.state.debouncedQueryStingSearcher);
        }
    }
  }

  handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.state.isDropdownVisible && this.setState({isDropdownVisible: false});
    }
  }

  formatDetail = () => {
    const {currentTypeId, currentValue} = this.state;
    this.setState({ // стираємо поточний номер (готовність для наступного номера)
      currentValue: '',
      isDetailValid: false
    });
    setTimeout(() => document.getElementById("dx-input").focus(), 300); // FixMe: поки що так (чомусь не виходить повернути фокус через this.inputRef) !!!
    return {dxType:currentTypeId, dxLabel:currentValue};
  }

  handleInputKeyDown = (e) => {
    const {keys, onAddButton, onKeyDown} = this.props;
    if (e.which === keys.ENTER) {
      this.state.isDetailValid && onAddButton && onAddButton(this.formatDetail());
    }
    if (onKeyDown) onKeyDown(e);
  }

  handleAddDetail = (e) => {
    const {onAddButton} = this.props;
    this.state.isDetailValid && onAddButton && onAddButton(this.formatDetail());
  }

  getDropdownList = () => {
    const {choices} = this.props;
    const {pickedItemIndex, isDropdownVisible} = this.state;
    return (
      <ul className={classnames(styles.dropdownList, {[styles.isHidden]: !isDropdownVisible})}
          ref={elem => this.dropdownRef = elem}
          role='listbox'
          tabIndex='0'
      >
        {choices.map((detailTypeItem, index) => {
          const {id:typeId} = detailTypeItem;
          return (
            <li className={classnames(styles.dropdownItem, {[styles.isPicked]: pickedItemIndex === index})}
                key={index}
                tabIndex={'0'}
                onClick={(e) => this.handleTypeItemClick(detailTypeItem, e)}
                role='option'>
              <span className={styles.optionLabel}>{decodeDetailType(typeId)}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const {label, error, placeholder, isDisabled, isRequired, onAddButton} = this.props;
    const {currentTypeId, currentValue, isDetailValid, isDropdownVisible} = this.state;
    const isAddButtonActive = isDetailValid && !!onAddButton;
    return (
      <div className={classnames(styles.DetailField, {[styles.hasError]: error})}
           onKeyDown={this.handleKeydown}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <div className={styles.inputWrapper}>
          <input
            id="dx-input"
            className={classnames(styles.inputControl, {[styles.open]: isDropdownVisible})}
            ref={elem => this.inputRef = elem}
            value={currentValue}
            placeholder={isDisabled ? '' : placeholder}
            type='text'
            autoComplete='off'
            autoCorrect='off'
            onChange={this.handleChange}
            onClick={this.handleInputClick}
            onDoubleClick={this.handleDoubleClick}
            onKeyDown={this.handleInputKeyDown}
            disabled={isDisabled}
          />
          <div className={classnames(styles.dropdown, {[styles.open]: isDropdownVisible})}
               ref={elem => this.dropdownRef = elem}>
            <div className={classnames(styles.dropdownButton, {[styles.open]: isDropdownVisible})}
                 tabIndex={'0'}
                 title={''}
                 role='button'
                 aria-haspopup={'listbox'}
                 aria-expanded={isDropdownVisible ? true : undefined}
                 onClick={this.handleDropdownClick}>
              <div className={styles.typeLabel}>{decodeDetailType(currentTypeId)}</div>
              <div className={classnames(styles.arrow, {[styles.up]: isDropdownVisible})}></div>
            </div>
            {isDropdownVisible && this.getDropdownList()}
          </div>
          <div className={classnames(styles.addButton, {[styles.isActive]: isAddButtonActive})}
               onClick={isAddButtonActive ? this.handleAddDetail : null}>
            <Icon symbolName="plus" className={styles.icon} />
          </div>
        </div>
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
