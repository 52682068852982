// -------------------------------------------------------------------------------------------------
//  PopupMenu.js
//  - - - - - - - - - -
//  Каркас для створення меню та попапів.
//
//  Attn:
//  - - - - -
//  - закриття PopupMenu проводити з setTimeout, інакше не викликаються onClick в компонентах !!!
// -------------------------------------------------------------------------------------------------
// FixMe: помилка "Can't call setState ... on an unmounted component..." для UserToolbar !!!
// FixMe: помилка "Can't call setState ... on an unmounted component..." для CollectionToolbar !!!

import React from 'react';
import classnames from 'classnames';
import withClickOutside from 'react-click-outside';
import PubSub from 'utils/PubSub';
import styles from './PopupMenu.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function togglePopupMenu(id) {
  pubsub.publish(id);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class PopupMenu extends React.Component {
  state = {
    isDisplayed: false,
  }

  show = () => this.setState({isDisplayed: true});
  hide = () => this.setState({isDisplayed: false});
  toggle = () => this.setState({isDisplayed: !this.state.isDisplayed});

  handleClickOutside() {
    if (this.state.isDisplayed) {
      // sic!: потрібна затримка, інакше не викликаються onClick в компонентах !!!
      setTimeout(this.hide, 50);
    }
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe((id) => {
      if (id === this.props.id) {
        this.toggle();
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    if (!this.state.isDisplayed) {
      return null;
    }
    const {children, className} = this.props;
    return (
      <nav className={classnames(styles.PopupMenu, className)}>
        <div className={styles.wrapper}>
          {children}
        </div>
      </nav>
    );
  }
}

export default withClickOutside(PopupMenu);
