// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Bundle ALL files from 'assets/favicons/' directory into Webpack context
//
//  https://medium.com/tech-angels-publications/bundle-your-favicons-with-webpack-b69d834b2f53
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const faviconsContext = require.context(
    '!!file-loader?name=assets/favicons/[name].[ext]!.',
    true,
    /\.(svg|png|ico|xml|json)$/
);

faviconsContext.keys().forEach(faviconsContext);
