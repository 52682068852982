// -------------------------------------------------------------------------------------------------
//  codeblock.js
//  - - - - - - - - - -
//  ```lang[SPACE] ---> <pre><code>...</code></pre>
//
//  Attn:
//  - - - - -
//  - slateTypes.CODEBLOCK може містити лише текст + заборона autoreplace плагінів всередині;
//  - slateTypes.CODEBLOCK не може бути останнім блоком в документі !!!
//    після створення блоку CODEBLOCK перевіряємо чи останній блок є типу PARAGRAPH;
//    якщо ні - то додаємо такий блок в кінець документу;
// -------------------------------------------------------------------------------------------------
import {Range, Data} from 'slate';
import EditCodePlugin from 'slate-edit-code';
import TrailingBlockPlugin from 'slate-trailing-block';
import {defaultCodeBlockOptions} from 'components/RichEditor/core/rich-codeblock';
import {blockTypes} from 'core/richTypes';

const trailingBlockOptions = {
  type: blockTypes.PARAGRAPH,
};

export default function autoreplaceCodeBlock(node, matched, change, language) {
  const matchedLength = matched[0].length;
  const EditCode = EditCodePlugin(defaultCodeBlockOptions);
  const TrailingBlock = TrailingBlockPlugin(trailingBlockOptions);

  if (language) {
    change.setBlocks({data: Data.create({syntax: language})});
  }

  const nextChange = EditCode.changes.wrapCodeBlock(
    change.deleteAtRange(
      Range.create({
        anchorKey: node.key,
        focusKey: node.key,
        anchorOffset: matched.index,
        focusOffset: matched.index + matchedLength
      })
    )
  );

  const nodeMaker = TrailingBlock.validateNode(nextChange.value.document);

  return nodeMaker ? nodeMaker(nextChange) : nextChange;
}
