// -------------------------------------------------------------------------------------------------
//  gaTypes.js
//  - - - - - - - - - -
//  Google Analytics enumerated types.
//
//  'fs' = friendship
//  'kl' = knowledge library
//  'mp' = marketplace
//  'im' = instant messaging
//  'mtz' = monetization
// -------------------------------------------------------------------------------------------------

export const gaCategories = {
  AUTHENTICATION:           'AUTH',                     // authentication
  FRIENDS:                  'FS',                       // friendship
  KNOWLEDGE:                'LIB',                      // knowledge library
  CRM:                      'CRM',                      // crm
  CHATS:                    'IM',                       // instant messaging
  RTC:                      'RTC',                      // real-time communication
  OTHER:                    'Other',                    //
  SOCIAL:                   'X',                        // social
  MARKETPLACE:              'MP',                       // marketplace
  NEURALNETS:               'AI',                       // neuralnets
  MONETIZATION:             'MTZ',                      // monetization
};

export const gaActions = {
  REGISTRATION_STAGE_0:     'AUTH_Signup_0',            // початок реєстрації (RegistrationConfirm)
  REGISTRATION_STAGE_1:     'AUTH_Signup_1',            // дані введено, потрібно підтвердити мейл (RegistrationEmailSent))
  REGISTRATION_SUCCESS:     'AUTH_Signup_Success',      // мейл підтверджено, зареєструвались (RegistrationSuccess)

  LOGIN_FAILED:             'AUTH_Login_Failed',        //
  RELOGIN_FAILED:           'AUTH_Relogin_Failed',      //

  FRIENDSHIP_STAGE_0:       'FS_0',                     // стадія 0: розпочали створення запиту (відкрили форму)
  FRIENDSHIP_REQUESTED:     'FS_Requested',             // стадія 1: запит на дружбу надісланий
  FRIENDSHIP_ACCEPTED:      'FS_Accepted',              // стадія 2: запит на дружбу прийнятий
  FRIENDSHIP_REJECTED:      'FS_Rejected',              // запит на дружбу відхилений
  FRIENDSHIP_TERMINATED:    'FS_Terminated',            // запит на дружбу скасовано відправником
  FRIENDSHIP_CANCELED:      'FS_Canceled',              // дружба припинена

  ADVERT_CREATED:           'MP_Ad_Created',            // 'mp' = marketplace
  ADVERT_DELETED:           'MP_Ad_Deleted',            //
  ADVERT_FEEDBACK_STAGE_0:  'MP_Ad_Feedback_0',         // відкриття форми фідбеку до оголошення
  ADVERT_FEEDBACK_SENT:     'MP_Ad_Feedback_Sent',      // відравка фідбеку до оголошення

  STAPLE_CREATED:           'LIB_Staple_Created',       // 'lib' = knowledge library
  STAPLE_CLONED:            'LIB_Staple_Cloned',        //
  STAPLE_DELETED:           'LIB_Staple_Deleted',       //
  COLLECTION_CREATED:       'LIB_Coll_Created',         //
  COLLECTION_DELETED:       'LIB_Coll_Deleted',         //

  CONTACT_CREATED:          'CRM_Contact_Created',      //
  CONTACT_DELETED:          'CRM_Contact_Deleted',      //
  GROUP_CREATED:            'CRM_Group_Created',        //
  GROUP_DELETED:            'CRM_Group_Deleted',        //

  CHAT_STAGE_0:             'IM_Chat_0',                // стадія 0: розпочате створення чату (відкрили форму)
  CHAT_CREATED:             'IM_Chat_Created',          // створення чату завершене (через форму чи get-or-create)

  RTC_CALL_STAGE_0:         'RTC_Call_0',               // стадія 0: спроба подзвонити
  RTC_CALL_REQUESTED:       'RTC_Call_Requested',       // стадія 1: дзвінок продзвонив
  RTC_CALL_ACCEPTED:        'RTC_Call_Accepted',        // стадія 2: дзвінок прийнятий

  COLLECTION_FOLLOWED:      'X_Coll_Followed',          //
  COLLECTION_UNFOLLOWED:    'X_Coll_Unfollowed',        //

  CSUBSCRIPTION_CREATED:    'X_Coll_Subscribed',        //
  CSUBSCRIPTION_DELETED:    'X_Coll_Unsubscribed',      //

  BASIC_ACCOUNT:            'MTZ_Basic_Account',        // 'mtz' = monetization
  PRO_ACCOUNT:              'MTZ_Pro_Account',          //
  PREMIUM_ACCOUNT:          'MTZ_Premium_Account',      //
  ENTERPRISE_ACCOUNT:       'MTZ_Enterprise_Account',   //
};
