// -------------------------------------------------------------------------------------------------
//  SystemStore.js
//  - - - - - - - - - -
//  Languages, Countries, Categories, Subjects, ..., StaticPages
//
//  Attn:
//  - - - - -
//  a) при розміщенні обʼєктів в стор перетворюємо назви полів: snake_case --> camelCase;
//  b) сортування текстових стрічок повинно проводитись з врахуванням локалі (.localeCompare);
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {Map, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  SYSTEM_LANGUAGES_WERE_FETCHED_ACTION,
  SYSTEM_COUNTRIES_WERE_FETCHED_ACTION,
  SYSTEM_CATEGORIES_WERE_FETCHED_ACTION,
  SYSTEM_SUBJECTS_WERE_FETCHED_ACTION,
  SYSTEM_STATICPAGES_WERE_FETCHED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  SLUG_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  PARENT_NAME_FLD,
  PARENT_POSITION_FLD,
  POSITION_FLD} from 'core/apiFields';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {toReact} from 'components/RichEditor/rich-conmark-processors';

const isVerbose = DEBUG && true;
const prefix = '- - - SystemStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const Language = Record({
  slug: '',
  name: '',
  position: '',
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeLanguage(state, patchObject) {
  return state.updateIn(['languages', patchObject[SLUG_FLD]], prevLanguage => {
    const {
      [SLUG_FLD]:slug,
      [NAME_FLD]:name,
      [POSITION_FLD]:position} = patchObject;
    return (prevLanguage || new Language()).merge({slug, name, position});
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CountryRecord = Record({
  id: '',
  value: '',
  label: '',
  position: ''
});

class Country extends CountryRecord {
  get defaultSortKey() {
    return ('' + this.position).padStart(3, '.') + this.label; // кастомний порядок сортування країн (в фідах)
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeCountry(state, patchObject) {
  const countryId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['countries', countryId], prevCountry => {
    const {
      [ID_FLD]:id,
      [SLUG_FLD]:slug,
      [NAME_FLD]:name,
      [POSITION_FLD]:position} = patchObject;
    const formattedObject = {};
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = id;
                                           formattedObject.value = id;}
    if (name !== undefined)               {formattedObject.label = name;}
    if (slug !== undefined)               {formattedObject.position = position;}
    // - - -
    return (prevCountry || new Country()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CategoryRecord = Record({
  id: null,                               // sic!: required by SelectField !!!
  slug: '',                               // sic!: required by SelectField !!!
  name: '',                               // назва категорії
  [PARENT_NAME_FLD]: '',                  // назва категорії на рівень вверх
  [PARENT_POSITION_FLD]: 0,               // позиція для сортування на рівень вверх
  [POSITION_FLD]: 0,                      // позиція для сортування
});

class Categories extends CategoryRecord {
  get value() {                           // required by SelectField !!!
    return this.slug;
  }
  get label() {                           // required by SelectField !!!
    return this.name;
  }
  get defaultSortKey() {
    return ('' + this[PARENT_POSITION_FLD]).padStart(4, '.') + ('' + this[POSITION_FLD]).padStart(4, '.') + this.name; // кастомний порядок сортування категорій (в фідах)
  }
}

// Attn: {{ CATEGORY }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeCategory(state, patchObject) {
  const categoryId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['categories', categoryId], prevCategory => {
    const {
      [ID_FLD]:id,
      [SLUG_FLD]:slug,
      [NAME_FLD]:name,
      [PARENT_NAME_FLD]:parentName,
      [PARENT_POSITION_FLD]:parentPosition,
      [POSITION_FLD]:position,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)               {formattedObject.id = categoryId;}
    if (slug !== undefined)             {formattedObject.slug = slug;}
    if (name !== undefined)             {formattedObject.name = name;}
    if (parentName !== undefined)       {formattedObject[PARENT_NAME_FLD] = parentName;}
    if (parentPosition !== undefined)   {formattedObject[PARENT_POSITION_FLD] = parentPosition;}
    if (position !== undefined)         {formattedObject[POSITION_FLD] = position;}
    // - - -
    return (prevCategory || new Categories()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const SubjectsRecord = Record({
  id: null,                               // sic!: required by SelectField !!!
  slug: '',                               // sic!: required by SelectField !!!
  name: '',                               // назва предмету
  [PARENT_NAME_FLD]: '',                  // назва предмету на рівень вверх
  [PARENT_POSITION_FLD]: 0,               // позиція для сортування на рівень вверх
  [POSITION_FLD]: 0,                      // позиція для сортування
  // ...custom:
  advertCursor: DEFAULT_TSN12_CURSOR,     // наступний курсор для списку оголошень по цьому предмету
  areAdvertsLoaded: false,                // чи завантажені усі оголошення по цьому предмету в стор?
});

class Subjects extends SubjectsRecord {
  get value() {                           // required by SelectField !!!
    return this.slug;
  }
  get label() {                           // required by SelectField !!!
    return this.name;                     // return `(${this.parentName}) ${this.name}`;
  }
  get nameSortKey() {
    return this.name;                     // порядок сортування для SelectField !!!
  }
  get defaultSortKey() {
    return ('' + this[PARENT_POSITION_FLD]).padStart(4, '.') + ('' + this[POSITION_FLD]).padStart(4, '.') + this.name; // порядок сортування на сайдбарі та у фідах
  }
}

// Attn: {{ SUBJECT }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeSubject(state, patchObject) {
  const subjectId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['subjects', subjectId], prevSubject => {
    const {
      [ID_FLD]:id,
      [SLUG_FLD]:slug,
      [NAME_FLD]:name,
      [PARENT_NAME_FLD]:parentName,
      [PARENT_POSITION_FLD]:parentPosition,
      [POSITION_FLD]:position,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)               {formattedObject.id = subjectId;}
    if (slug !== undefined)             {formattedObject.slug = slug;}
    if (name !== undefined)             {formattedObject.name = name;}
    if (parentName !== undefined)       {formattedObject[PARENT_NAME_FLD] = parentName;}
    if (parentPosition !== undefined)   {formattedObject[PARENT_POSITION_FLD] = parentPosition;}
    if (position !== undefined)         {formattedObject[POSITION_FLD] = position;}
    // - - -
    return (prevSubject || new Subjects()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const StaticpageRecord = Record({
  name: '',
  description: '',
});

class Staticpage extends StaticpageRecord {
  get descriptionHtml() {
    return toReact(this.description);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeStaticpage(state, patchObject) {
  return state.updateIn(['staticpages', patchObject[SLUG_FLD]], prevStaticpage => {
    const {
      [NAME_FLD]:name,
      [DESCRIPTION_FLD]:description} = patchObject;
    return (prevStaticpage || new Staticpage()).merge({name, description});
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class SystemStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    return Map({
      languages: Map(),                   // список мов інтерфейсу в системі
      countries: Map(),                   // список усіх країн
      categories: Map(),                  // список усіх категорій
      subjects: Map(),                    // список усіх предметів
      staticpages: Map(),                 // список усіх статичних сторінок в системі
    });
  }

  // - - - predicates:

  areLanguagesLoaded() {
    return this.getState().get('languages') && this.getState().get('languages').size > 0;
  }

  areCountriesLoaded() {
    return this.getState().get('countries') && this.getState().get('countries').size > 0;
  }

  areCategoriesLoaded() {
    return this.getState().get('categories') && this.getState().get('categories').size > 0;
  }

  areSubjectsLoaded() {
    return this.getState().get('subjects') && this.getState().get('subjects').size > 0;
  }

  areStaticpagesLoaded() {
    return this.getState().get('staticpages') && this.getState().get('staticpages').size > 0;
  }

  // - - - getters:

  getLanguages() {
    trace(`getLanguages`);
    return this.getState().get('languages');
  }

  getCountries() {
    trace(`getCountries`);
    return this.getState().get('countries')
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getCategory(id) {
    return this.getState().getIn(['categories', id]);
  }

  getCategoryBySlug(slug) {
    trace(`getCategoryBySlug`);
    const formattedSlug = slug.toLowerCase();
    return this.getState().get('categories').find(cg => cg.slug === formattedSlug);
  }

  getCategoryList() {
    trace(`getCategoryList`);
    return this.getState().get('categories')
      .toList()
      .sort((a, b) => (a.defaultSortKey).localeCompare(b.defaultSortKey)); // attn: b) !!!
  }

  getSubject(id) {
    return this.getState().getIn(['subjects', id]);
  }

  getSubjectBySlug(slug) {
    trace(`getSubjectBySlug`);
    const formattedSlug = slug.toLowerCase();
    return this.getState().get('subjects').find(subj => subj.slug === formattedSlug);
  }

  getSubjectList() {
    trace(`getSubjectList`);
    return this.getState().get('subjects')
      .toList()
      .sort((a, b) => (a.defaultSortKey).localeCompare(b.defaultSortKey)); // attn: b) !!!
  }

  getSubjectListOrderedByName() {
    trace(`getSubjectList`);
    return this.getState().get('subjects')
      .toList()
      .sort((a, b) => (a.nameSortKey).localeCompare(b.nameSortKey)); // attn: b) !!!
  }

  getStaticpage(slug) {
    trace(`getStaticpage`);
    return this.getState().getIn(['staticpages', slug]);
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SYSTEM_LANGUAGES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {languages} = payload;
    return languages ?
      languages.reduce(mergeLanguage, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SYSTEM_COUNTRIES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {countries} = payload;
    return countries ?
      countries.reduce(mergeCountry, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SYSTEM_CATEGORIES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {categories} = payload;
    return categories ?
      categories.reduce(mergeCategory, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SYSTEM_SUBJECTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {subjects} = payload;
    return subjects ?
      subjects.reduce(mergeSubject, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SYSTEM_STATICPAGES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {staticpages} = payload;
    return staticpages ?
      staticpages.reduce(mergeStaticpage, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS()); // ToDo: DEV/TEST !!!
      return nextState;
    }
    return state;
  }
}

export default new SystemStore(Dispatcher);
