// -------------------------------------------------------------------------------------------------
//  languageTypes.js
//  - - - - - - - - - -
//  System languages enumerated types.
// -------------------------------------------------------------------------------------------------

export const ENGLISH_LANG                 = 'en';     //
export const UKRAINIAN_LANG               = 'uk';     //
export const POLISH_LANG                  = 'pl';     //
export const SPANISH_LANG                 = 'es';     //
export const GERMAN_LANG                  = 'de';     //
export const FRENCH_LANG                  = 'fr';     //
export const ITALIAN_LANG                 = 'it';     //
export const PORTUGUESE_LANG              = 'pt';     //
export const VIETNAMESE_LANG              = 'vi';     //
export const BENGALI_LANG                 = 'bn';     //
export const HINDI_LANG                   = 'hi';     //
export const HEBREW_LANG                  = 'he';     //
export const ARABIC_LANG                  = 'ar';     //
export const KOREAN_LANG                  = 'ko';     //
export const CHINESE_LANG                 = 'cn';     //
export const JAPANESE_LANG                = 'jp';     //
