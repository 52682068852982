// -------------------------------------------------------------------------------------------------
//  custom-break.js
//  - - - - - - - - - - - - - -
//  Примусовий перевід стрічки (break) по символу '/'.
//
//  Notes:
//  - - - - -
//  - conmark повинен бути згенерований в режимі commonmark (перевід стрічки кодується як '\');
// -------------------------------------------------------------------------------------------------
// ToDo: створення break type node робити тут, а не в custom-text.js !!!

// import {mdastTypes} from 'core/mdastTypes';
import {conmarkTokens} from 'core/conmarkTypes';

export function parse() {
  let Parser = this.Parser;
  let tokenizers = Parser.prototype.inlineTokenizers;

  tokenizeBreak.locator = tokenizers.break.locator;
  tokenizers.break = tokenizeBreak;

  function tokenizeBreak(eat, value, silent) {
    if (value === conmarkTokens.BREAK) {
      if (silent) {
        return true;
      }

      // - - - - - -
      // ToDo: створення break type node робити тут, а не в custom-text.js !!!
      // - - - - - -
      // return eat('')({type: mdastTypes.BREAK});
      // - - - - - -
    }
  }
}
