// -------------------------------------------------------------------------------------------------
//  OneAdvertContainer.js
//  - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  - якщо оголошення не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в стейпл контекст сайдбару встановлюємо на закладку чатів (CHAT_TAB);
//  - при виході встановлюємо на закладку колекцій (бо стираємо ctxId, ctxType, userId);
//  - встановлення контексту можливо в componentDidMount, бо для переходу на інший стейпл
//    потрібно закрити форму, відповідно, для іншого стейплу форма буде відкрита заново;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {OWNER_ID_FLD} from 'core/apiFields';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {fetchAdvert, fetchAdvertAsGuest} from 'actions/AdvertActions';
import {fetchUsers} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import MarketplaceStore from 'stores/MarketplaceStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const OneAdvert = Loader.create(() => import('./OneAdvert'));
const isVerbose = DEBUG && true;
const prefix = '- - - OneAdvertContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class OneAdvertContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, MarketplaceStore, SystemStore];
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про стейпл?
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const advertId = props.match.params.advertId;
    const advert = advertId ? MarketplaceStore.getAdvert(props.match.params.advertId) : null;
    const {ownerId} = advert || {};
    const owner = ownerId ? UserStore.getUser(ownerId) : null;
    return {isLoggedIn, myId, advert, owner};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.isServerRequested !== nextState.isServerRequested
      || !is(this.state.owner, nextState.owner)
      || !is(this.state.advert, nextState.advert);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const advertId = this.props.match.params.advertId;
    const {isLoggedIn, advert:advert1} = this.state;
    const {ownerId:ownerId1} = advert1 || {}; // стейпл зі стору, тому 'owner_id'
    let ownerId2;
    if (advertId && !MarketplaceStore.isAdvertLoaded(advertId)) {
      const {advert:advert2} = isLoggedIn ?
        await fetchAdvert(advertId) :
        await fetchAdvertAsGuest(advertId);
      ({[OWNER_ID_FLD]:ownerId2} = advert2 || {}); // стейпл із серверу, тому [OWNER_ID_FLD]
    }
    if (isLoggedIn) {
      const ownerId = ownerId1 || ownerId2;
      if (ownerId) {
        // substituteSidebarContext({ctxId: advertId, ctxType: ADVERT_CTX, ctxOwnerId: ownerId});
        // if (!MarketplaceStore.hasSelectedAdverts()) {
        //   substituteSidebarTabMode(CHAT_TAB);
        // }
        if (!UserStore.isUserLoaded(ownerId)) {
          await fetchUsers([ownerId]);
        }
      }
    }
    this.setState({isServerRequested: true});
  }

  // componentWillUnmount() {
  //   trace(`componentWillUnmount`);
  //   const {isLoggedIn} = this.state;
  //   if (isLoggedIn) {
  //     if (!MarketplaceStore.hasSelectedAdverts()) {
  //       substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
  //     }
  //   }
  // }

  render() {
    const {isServerRequested, isLoggedIn, myId, advert, owner} = this.state;
    if (isServerRequested && !advert) {
      return (
        <NotFoundPage />
      );
    }
    if (!advert) {
      return null;
    }
    trace(`render`);
    return (
      <OneAdvert
        isLoggedIn={isLoggedIn}
        myId={myId}
        advert={advert}
        owner={owner}
      />
    );
  }
}

export default Container.create(OneAdvertContainer, {withProps: true, pure: false});
