// -------------------------------------------------------------------------------------------------
//  ConnectionStatus.js
//  - - - - - - - - - -
//  Статус інтернет- та сокет- зʼєднань додатку.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import styles from './ConnectionStatus.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function connectionStatus(isDisplayed) {
  pubsub.publish(isDisplayed);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ConnectionStatus extends React.Component {
  state = {
    isDisplayed: !navigator.onLine,
  }

  componentDidMount() {
    // internet
    window.addEventListener('online', () => this.setState({isDisplayed: false}));
    window.addEventListener('offline', () => this.setState({isDisplayed: true}));
    // websocket
    this.unsubscribe = pubsub.subscribe(isDisplayed => {
      this.setState({isDisplayed: isDisplayed});
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    // FixMe: можливо треба removeEventListener ???
  }

  render() {
    if (!this.state.isDisplayed) {
      return null;
    }
    return (
      <div className={styles.ConnectionStatus}>
        <div className={styles.container}>
          <p className={styles.title}>{t`Restoring Connection...`}</p>
        </div>
      </div>
    );
  }
}
