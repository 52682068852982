// -------------------------------------------------------------------------------------------------
//  utils.js
//  - - - - - - - - - -
//  Auto replace helpers.
// -------------------------------------------------------------------------------------------------

export function appendSpace(change, node, offsetIndex) {
  change.insertTextByKey(node.key, offsetIndex, ' ');
}

export function removeAllMarks(change) {
  const {value} = change;

  if (value.marks) {
    // remove all marks
    value.marks.forEach(mark => { change.removeMark(mark); });
  }
  return change;
}
