// -------------------------------------------------------------------------------------------------
//  MiscActions.js
//  - - - - - - - - - -
//  Different types of actions for different types of objects.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' / catch;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
// -------------------------------------------------------------------------------------------------
import {Set} from 'immutable';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION,
  COLLECTION_PENDING_FIELDS_WERE_SENT_ACTION,
  COLLECTION_PENDING_FIELDS_WERE_PROCESSED_ACTION,
  CONTACT_PENDING_FIELDS_WERE_CHANGED_ACTION,
  CONTACT_PENDING_FIELDS_WERE_SENT_ACTION,
  CONTACT_PENDING_FIELDS_WERE_PROCESSED_ACTION,
  GROUP_PENDING_FIELDS_WERE_CHANGED_ACTION,
  GROUP_PENDING_FIELDS_WERE_SENT_ACTION,
  GROUP_PENDING_FIELDS_WERE_PROCESSED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  COLLECTION_IDS_FLD,
  CONTACT_IDS_FLD,
  GROUP_IDS_FLD,
  POSITION_FLD,
  COLOR_TAGS_FLD,
  IS_EDITED_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {COLLECTION_OBJECT, COURSE_OBJECT, CONTACT_OBJECT, GROUP_OBJECT, STAPLE_OBJECT} from 'core/commonTypes';
import {apiUpdatePendingFields} from 'api/MiscAPI';
import CollectionStore from 'stores/CollectionStore';
import CrmStore from 'stores/CrmStore';

const isVerbose = DEBUG && true;
const prefix = '- - - MiscActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// ToDo: поки не задіяний параметр 'fieldType' !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function changePendingFields({objectId, objectType, fieldType, colorTags, position}) {
  trace(`changePendingFields`);
  let status;
  let errors = {};
  try {
    switch(objectType) {
      case CONTACT_OBJECT:
        status = 'ok';
        Dispatcher.dispatch({
          type: CONTACT_PENDING_FIELDS_WERE_CHANGED_ACTION,
          payload: {
            [ID_FLD]: objectId,
            [POSITION_FLD]: position,
            [COLOR_TAGS_FLD]: colorTags,
            [IS_EDITED_FLD]: true,
          }
        });
        break;
      case GROUP_OBJECT:
        status = 'ok';
        Dispatcher.dispatch({
          type: GROUP_PENDING_FIELDS_WERE_CHANGED_ACTION,
          payload: {
            [ID_FLD]: objectId,
            [COLOR_TAGS_FLD]: colorTags,
            [IS_EDITED_FLD]: true,
          }
        });
        break;
      case COLLECTION_OBJECT:
        status = 'ok';
        Dispatcher.dispatch({
          type: COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION,
          payload: {
            [ID_FLD]: objectId,
            [COLOR_TAGS_FLD]: colorTags,
            [IS_EDITED_FLD]: true,
          }
        });
        break;
      case COURSE_OBJECT:
        status = 'ok';
        Dispatcher.dispatch({
          type: COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION,
          payload: {
            [ID_FLD]: objectId,
            [COLOR_TAGS_FLD]: colorTags,
            [IS_EDITED_FLD]: true,
          }
        });
        break;
      default:
        traceError(`changePendingFields: objectType=${objectType}`);
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updatePendingFields() {
  trace(`updatePendingFields`);
  let status;
  let payload;
  let errors = {};
  try {
    const kpf = CrmStore.getContactPendingFields();
    if (kpf && kpf.size > 0) {
      Dispatcher.dispatch({
        type: CONTACT_PENDING_FIELDS_WERE_SENT_ACTION,
        payload: {
          contactPendingFields: kpf
        }
      });
    }
    const gpf = CrmStore.getGroupPendingFields();
    if (gpf && gpf.size > 0) {
      Dispatcher.dispatch({
        type: GROUP_PENDING_FIELDS_WERE_SENT_ACTION,
        payload: {
          groupPendingFields: gpf
        }
      });
    }
    const cpf = CollectionStore.getCollectionPendingFields();
    if (cpf && cpf.size > 0) {
      Dispatcher.dispatch({
        type: COLLECTION_PENDING_FIELDS_WERE_SENT_ACTION,
        payload: {
          collectionPendingFields: cpf
        }
      });
    }
    if ((kpf && kpf.size > 0) || (gpf && gpf.size > 0) || (cpf && cpf.size > 0)) {
      const contactPendingFields = kpf.reduce((acc, curr) => {
        const {id, colorTags} = curr;
        return acc.add({[ID_FLD]:id, [COLOR_TAGS_FLD]:colorTags}); // {{ CONTACT_FIELDS }}
        }, Set());
      const groupPendingFields = gpf.reduce((acc, curr) => {
        const {id, colorTags} = curr;
        return acc.add({[ID_FLD]:id, [COLOR_TAGS_FLD]:colorTags}); // {{ GROUP_FIELDS }}
        }, Set());
      const collectionPendingFields = cpf.reduce((acc, curr) => {
        const {id, colorTags} = curr;
        return acc.add({[ID_FLD]:id, [COLOR_TAGS_FLD]:colorTags}); // {{ COLLECTION_FIELDS }}
        }, Set());
      const response = await apiUpdatePendingFields(contactPendingFields, groupPendingFields, collectionPendingFields);
      ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
      switch(status) {
        case 'ok':
          trace(`updatePendingFields: OK, payload=${JSON.stringify(payload)}`);
          const {
            [CONTACT_IDS_FLD]:processedContactIds,
            [GROUP_IDS_FLD]:processedGroupIds,
            [COLLECTION_IDS_FLD]:processedCollectionIds} = payload || [];
          if (processedContactIds && processedContactIds.length > 0) {
            Dispatcher.dispatch({
              type: CONTACT_PENDING_FIELDS_WERE_PROCESSED_ACTION,
              payload: {
                processedContactIds: processedContactIds
              }
            });
          }
          if (processedGroupIds && processedGroupIds.length > 0) {
            Dispatcher.dispatch({
              type: GROUP_PENDING_FIELDS_WERE_PROCESSED_ACTION,
              payload: {
                processedGroupIds: processedGroupIds
              }
            });
          }
          if (processedCollectionIds && processedCollectionIds.length > 0) {
            Dispatcher.dispatch({
              type: COLLECTION_PENDING_FIELDS_WERE_PROCESSED_ACTION,
              payload: {
                processedCollectionIds: processedCollectionIds
              }
            });
          }
          break;
        default:
          traceError(`updatePendingFields: status=${status}`);
          errors.formError = `server_error`;
          break;
      }
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
