// -------------------------------------------------------------------------------------------------
//  MediaViewer.js
//  - - - - - - - - - -
//  Перегляд контенту (зображення, відео, ...) в модальному режимі.
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import Draggable from 'react-draggable';
import Slider from 'rc-slider';
import imagesLoaded from 'imagesloaded';
import classnames from 'classnames';
import PubSub from 'utils/PubSub';
import {addBodyClass, removeBodyClass, NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import Icon from 'components/UI/icons/Icon';
import Spinner from 'components/UI/Spinner';
import styles from './MediaViewer.scss';
import 'styles/rc-slider.css';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - MediaViewer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function mediaViewer({type, previewUrl, handleSetFocus = null}) {
  pubsub.publish({
    type: type,
    previewUrl: previewUrl,
    handleSetFocus: handleSetFocus,
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class MediaViewer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      type: '',                           // тип медіа для показу
      previewUrl: '',                     // url зображення
      handleSetFocus: null,               // функція встановлення фокусу на елементі OneChat
      zoomRatio: 100,                     // 100% масштабу зображення
      isDisplayed: false,                 // is form displayed?
      isDraggable: false,                 // is staple image draggable?
      isLoaded: false,                    // is staple image loaded?
    }
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({type, previewUrl, handleSetFocus}) => {
      this.setState({
        type,
        previewUrl,
        handleSetFocus,
        isDisplayed: true,
      });
      PreventBodyScroll();
      addBodyClass('no-submodal-esc');
    });
    // 1) інакше НЕ відображає картинки після показу контенту, відмінного від картинок !!!
    const ref = this.pictureRef;
    if (ref) {
      imagesLoaded(ref, {background: true}, () => {
        this.setState({isLoaded: true});
      });
    }
    // ...close on location changes
    this.context.router.history.listen(this.handleClose);
  }

  componentDidUpdate() {
    // 2) інакше НЕ відображає картинки після показу контенту, відмінного від картинок !!!
    // 3) без перевірки isLoaded впадає в нескінчений цикл, бо компонента змінюється внаслідок imagesLoaded !!!
    const ref = this.pictureRef;
    const isLoaded = this.state.isLoaded;
    if (ref && !isLoaded) { // sic!: 3)
      imagesLoaded(ref, {background: true}, () => {
        this.setState({isLoaded: true});
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleRemoveNoEscape = () => {
    removeBodyClass('no-submodal-esc');
  }

  // Attn: 1) zoomRatio: 100, бо інакше нова картинка вантажиться з попереднім ресайзом !!!
  // Attn: 2) якщо швидко зняти флаг, то спрацює закриття форми SubmodalLevel !!!
  handleClose = (e) => {
    this.setState({
      zoomRatio: 100, // sic!: 1)
      isDisplayed: false
    });
    RestoreBodyScroll();
    const {handleSetFocus} = this.state;
    handleSetFocus && handleSetFocus();
    setTimeout(this.handleRemoveNoEscape, 500); // sic!: 2)
  }

  handleKeyDown = (event, change, editor) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  }

  handleChangeZoom = value => {
    this.setState({zoomRatio: value, isDraggable: true});
  };

  handleReset = (e) => {
    if (this.pictureRef && !this.pictureRef.contains(e.target)) {
      this.pictureRef.parentNode.style="transform: translate(0px, 0px);"
      this.setState({zoomRatio: 100, isDraggable: false});
    }
  }

  handleSetDraggable = () => {
    trace(`handleSetDraggable`);
    this.setState({isDraggable: true});
  }

  render() {
    const {type, previewUrl, zoomRatio, isDisplayed, isDraggable, isLoaded} = this.state;
    if (!isDisplayed) {
      return null;
    }
    const zoom = zoomRatio / 100;
    return (
      <div
        tabIndex={0}
        className={styles.MediaViewer}
        onKeyDown={this.handleKeyDown}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.imageHolder}>
          {isDraggable &&
            <Draggable defaultClassNameDragging={styles.isDrugging}>
              <div className={styles.dragWrapper}>
                <div
                  tabIndex={1}
                  className={classnames(styles.picture, {[styles.isLoaded]: isLoaded})}
                  style={{backgroundImage: `url(${previewUrl})`, transform: `scale(${zoom})`}}
                  ref={elem => this.pictureRef = elem}
                  onKeyDown={this.handleKeyDown}
                />
              </div>
            </Draggable>
          }
          {!isDraggable &&
            <div
              tabIndex={1}
              className={classnames(styles.picture, {[styles.isLoaded]: isLoaded})}
              style={{backgroundImage: `url(${previewUrl})`, transform: `scale(${zoom})`}}
              ref={elem => this.pictureRef = elem}
              onKeyDown={this.handleKeyDown}
            />
          }
          {!previewUrl &&
            <Icon symbolName="image" className={styles.typeIcon} />
          }
          {!isLoaded &&
            <F>
              <Icon symbolName="image" className={styles.typeIcon} />
              <Spinner key={type} className={styles.spinner} />
            </F>
          }
          {isLoaded && previewUrl &&
            <F>
              <div tabIndex={2}
                   className={styles.slider}
                   onKeyDown={this.handleKeyDown}>
                <Slider
                  min={100}
                  max={500}
                  vertical={true}
                  value={zoomRatio}
                  onChange={this.handleChangeZoom}
                />
              </div>
              {isDraggable &&
                <div tabIndex={3}
                     className={styles.resetButton}
                     onKeyDown={this.handleKeyDown}
                     onClick={this.handleReset}>
                  <Icon symbolName="center-focus-1" className={styles.icon} />
                </div>
              }
              {!isDraggable &&
                <div tabIndex={3}
                     className={styles.setDraggableButton}
                     onKeyDown={this.handleKeyDown}
                     onClick={this.handleSetDraggable}>
                  <Icon symbolName="media-draggable" className={styles.icon} />
                </div>
              }
            </F>
          }
        </div>
        <button tabIndex={4}
                className={styles.closeButton}
                onKeyDown={this.handleKeyDown}
                onClick={this.handleClose}>
          <Icon symbolName="cross" className={styles.icon} />
        </button>
        <NoTabbar />
      </div>
    );
  }
}
