// -------------------------------------------------------------------------------------------------
//  UrlField.js
//  - - - - - - - - - -
//  Поле для вводу посилань (urls).
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import Icon from 'components/UI/icons/Icon';
import {cleanifyUrl} from 'utils/converters';
import styles from './UrlField.scss';

export const MAX_URL_WIDTH                = 96;     // к-сть символів в url

export const FACEBOOK_URL                 = '1';    // ('Facebook Url')
export const INSTAGRAM_URL                = '2';    // ('Instagram Url')
export const TWITTER_URL                  = '3';    // ('Twitter Url')
export const LINKEDIN_URL                 = '4';    // ('LinkedIn Url')
export const YOUTUBE_URL                  = '5';    // ('YouTube Url')
export const SNAPCHAT_URL                 = '6';    // ('SnapChat Url')
export const PINTEREST_URL                = '7';    // ('Pinterest Url')
export const REDDIT_URL                   = '8';    // ('Reddit Url')
export const BLUESKY_URL                  = 'b';    // ('Bluesky Url')
export const DISCORD_URL                  = 'd';    // ('Discord Url')
export const GITHUB_URL                   = 'h';    // ('GitHub Url')
export const GITLAB_URL                   = 'l';    // ('GitLab Url')
export const MEDIUM_URL                   = 'm';    // ('Medium Url')
export const WEBPAGE_URL                  = 'p';    // ('Webpage Url')
export const WEBSITE_URL                  = 'w';    // ('Website Url')
export const MISC_URL                     = 'x';    // ('Misc Url')

export const urlTypes = [                 // типи веб-посилань
  {id: FACEBOOK_URL},
  {id: INSTAGRAM_URL},
  {id: TWITTER_URL},
  {id: LINKEDIN_URL},
  {id: YOUTUBE_URL},
  {id: SNAPCHAT_URL},
  {id: PINTEREST_URL},
  {id: REDDIT_URL},
  {id: BLUESKY_URL},
  {id: DISCORD_URL},
  {id: GITHUB_URL},
  {id: GITLAB_URL},
  {id: MEDIUM_URL},
  {id: WEBPAGE_URL},
  {id: WEBSITE_URL},
  {id: MISC_URL},
];

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function decodeUrlType(pnType) {
  let decodedType;
  switch (pnType) {
    case FACEBOOK_URL:                    decodedType = t`Facebook Url`;      break;
    case INSTAGRAM_URL:                   decodedType = t`Instagram Url`;     break;
    case TWITTER_URL:                     decodedType = t`Twitter Url`;       break;
    case LINKEDIN_URL:                    decodedType = t`LinkedIn Url`;      break;
    case YOUTUBE_URL:                     decodedType = t`YouTube Url`;       break;
    case SNAPCHAT_URL:                    decodedType = t`SnapChat Url`;      break;
    case PINTEREST_URL:                   decodedType = t`Pinterest Url`;     break;
    case REDDIT_URL:                      decodedType = t`Reddit Url`;        break;
    case BLUESKY_URL:                     decodedType = t`BlueSky Url`;       break;
    case DISCORD_URL:                     decodedType = t`Discord Url`;       break;
    case GITHUB_URL:                      decodedType = t`GitHub Url`;        break;
    case GITLAB_URL:                      decodedType = t`GitLab Url`;        break;
    case MEDIUM_URL:                      decodedType = t`Medium Url`;        break;
    case WEBPAGE_URL:                     decodedType = t`Webpage Url`;       break;
    case WEBSITE_URL:                     decodedType = t`Website Url`;       break;
    case MISC_URL:                        decodedType = t`Misc Url`;          break;
  }
  return decodedType;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function detectUrlType(url0) {
  const url = url0.toLowerCase();
  const regexFacebook   = /facebook.com/;
  const regexInstagram  = /instagram.com/;
  const regexTwitter    = /twitter.com/;
  const regexX          = /x.com/;
  const regexLinkedIn   = /linkedin.com/;
  const regexYouTube    = /youtube.com/;
  const regexSnapChat   = /snapchat.com/;
  const regexPinterest  = /pinterest.com/;
  const regexReddit     = /reddit.com/;
  const regexBlueSky    = /bsky.app/;
  const regexDiscord    = /discord.com/;
  const regexGitHub     = /github.com/;
  const regexGitLab     = /gitlab.com/;
  const regexMedium     = /medium.com/;
  return regexFacebook.test(url) ? FACEBOOK_URL :
          regexInstagram.test(url) ? INSTAGRAM_URL :
            regexTwitter.test(url) ? TWITTER_URL :
              regexX.test(url) ? TWITTER_URL :
                regexLinkedIn.test(url) ? LINKEDIN_URL :
                  regexYouTube.test(url) ? YOUTUBE_URL :
                    regexSnapChat.test(url) ? SNAPCHAT_URL :
                      regexPinterest.test(url) ? PINTEREST_URL :
                        regexReddit.test(url) ? REDDIT_URL :
                          regexBlueSky.test(url) ? BLUESKY_URL :
                            regexDiscord.test(url) ? DISCORD_URL :
                              regexGitHub.test(url) ? GITHUB_URL :
                                regexGitLab.test(url) ? GITLAB_URL :
                                  regexMedium.test(url) ? MEDIUM_URL : WEBSITE_URL;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class UrlField extends React.Component {
  static propTypes = {
    label: Types.string,                  // label text (is shown above the field)
    error: Types.string,                  // повідомлення про помилку; якщо вказане, то відмальовуємо поле червоним
    placeholder: Types.string,            // placeholder (e.g.: 'подробиці...')
    choices: Types.array,                 // список можливих варіантів посилань
    isDisabled: Types.bool,               // is field disabled?
    isRequired: Types.bool,               // is field required?
    onChange: Types.func,                 // -spec: onChange({type, label}, event)
    onAddButton: Types.func,              // -spec: onAddButton({type, label})
    onKeyDown: Types.func,                // -spec: ?
  }

  static defaultProps = {
    label: '',
    error: '',
    placeholder: t`urls...`,
    isDisabled: false,
    isRequired: false,
    keys: {
      UP: 38, DOWN: 40, RIGHT: 39, LEFT: 37, ENTER: 13,
      ESC: 27, PLUS: 43, A: 65, Z: 90, SPACE: 32, TAB: 9,
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTypeId: WEBSITE_URL,         // код типу посилання
      currentValue: '',                   // текстове значення посилання
      pickedItemIndex: 2,                 // індекс поточного вибраного типу
      isUrlValid: false,                  // is entered url valid?
      isDropdownVisible: false,           // current state of dropdown
    };
  }

  componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (document.removeEventListener) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  // display methods
  scrollTo = (country, middle) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;
    const element = country;
    const elementOffset = element.getBoundingClientRect();
    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;
    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = (containerHeight / 2) - (elementHeight / 2);
    if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  }

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  }

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.inputRef;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len-1)=== ')') len = len-1;
    input.setSelectionRange(len, len);
  }

  handleDropdownClick = (e) => {
    e.preventDefault();
    const {choices} = this.props;
    if (!this.state.isDropdownVisible && this.props.isDisabled) return;
    const {currentTypeId} = this.state
    const pickedItemIndex = choices.findIndex(item => item.id === currentTypeId);
    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
      pickedItemIndex: pickedItemIndex,
    });
  }

  handleChange = (e) => {
    const {value} = e.target;
    const {onChange} = this.props;
    const {currentTypeId} = this.state;
    if (onChange) e.persist();
    const type = detectUrlType(`${value}`);
    const nextType = type !== WEBSITE_URL ? type : currentTypeId;
    this.setState({
      currentTypeId: nextType,
      currentValue: cleanifyUrl(value).substr(0, MAX_URL_WIDTH),
      isUrlValid: !!currentTypeId && ('' + value).length > 1
    });
    onChange && onChange({type: nextType, label: value});
  }

  handleInputClick = (e) => {
    this.setState({isDropdownVisible: false});
  }

  handleDoubleClick = (e) => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  }

  handleTypeItemClick = (urlTypeItem, e) => {
    const {id:typeId} = urlTypeItem;
    this.setState({
      currentTypeId: typeId,
      isDropdownVisible: false,
    });
    const input = this.inputRef;
    input.focus();
  }

  handleKeydown = (e) => {
    const {choices, keys} = this.props;
    const {target: {className}} = e;
    if (className.includes(styles.dropdownButton) && e.which === keys.ENTER && !this.state.isDropdownVisible) return this.handleDropdownClick(e);
    if (className.includes(styles.inputControl) && (e.which === keys.ENTER || e.which === keys.ESC)) return e.target.blur();
    if (!this.state.isDropdownVisible || this.props.isDisabled) return;
    // if (className.includes(styles.searchBox)) {
    //   if (e.which !== keys.UP && e.which !== keys.DOWN && e.which !== keys.ENTER) {
    //     if (e.which === keys.ESC && e.target.value === '') {
    //       // do nothing // if search field is empty, pass event (close dropdown)
    //     } else {
    //       return; // don't process other events coming from the search field
    //     }
    //   }
    // }
    // ie hack
    if (e.preventDefault) {e.preventDefault(); }
    else {e.returnValue = false;}
    // const moveHighlight = (direction) => {
    //   this.setState({
    //     pickedItemIndex: this.getHighlightCountryIndex(direction)
    //   }, () => {
    //     this.scrollTo(this.getElement(this.state.pickedItemIndex), true);
    //   });
    // }
    switch (e.which) {
      // case keys.DOWN:
      //   moveHighlight(1);
      //   break;
      // case keys.UP:
      //   moveHighlight(-1);
      //   break;
      case keys.ENTER:
        this.handleTypeItemClick(choices[this.state.pickedItemIndex], e);
        break;
      case keys.ESC:
      case keys.TAB:
        this.setState({
          isDropdownVisible: false
        }, this.cursorToEnd);
        break;
      default:
        if ((e.which >= keys.A && e.which <= keys.Z) || e.which === keys.SPACE) {
          // this.setState({
          //   queryString: this.state.queryString + String.fromCharCode(e.which)
          // }, this.state.debouncedQueryStingSearcher);
        }
    }
  }

  handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.state.isDropdownVisible && this.setState({isDropdownVisible: false});
    }
  }

  formatUrl = () => {
    const {currentTypeId, currentValue} = this.state;
    this.setState({ // стираємо поточний номер (готовність для наступного посилання)
      currentValue: '',
      isUrlValid: false
    });
    setTimeout(() => document.getElementById("ur-input").focus(), 300); // FixMe: поки що так (чомусь не виходить повернути фокус через this.inputRef) !!!
    return {urType:currentTypeId, urLabel:currentValue};
  }

  handleInputKeyDown = (e) => {
    const {keys, onAddButton, onKeyDown} = this.props;
    if (e.which === keys.ENTER) {
      this.state.isUrlValid && onAddButton && onAddButton(this.formatUrl());
    }
    if (onKeyDown) onKeyDown(e);
  }

  handleAddUrl = (e) => {
    const {onAddButton} = this.props;
    this.state.isUrlValid && onAddButton && onAddButton(this.formatUrl());
  }

  getDropdownList = () => {
    const {choices} = this.props;
    const {pickedItemIndex, isDropdownVisible} = this.state;
    return (
      <ul className={classnames(styles.dropdownList, {[styles.isHidden]: !isDropdownVisible})}
          ref={elem => this.dropdownRef = elem}
          role='listbox'
          tabIndex='0'>
        {choices.map((urlTypeItem, index) => {
          const {id:typeId} = urlTypeItem;
          return (
            <li className={classnames(styles.dropdownItem, {[styles.isPicked]: pickedItemIndex === index})}
                key={index}
                tabIndex={'0'}
                onClick={(e) => this.handleTypeItemClick(urlTypeItem, e)}
                role='option'>
              <span className={styles.optionLabel}>{decodeUrlType(typeId)}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const {label, error, placeholder, isDisabled, isRequired, onAddButton} = this.props;
    const {currentTypeId, currentValue, isUrlValid, isDropdownVisible} = this.state;
    const isAddButtonActive = isUrlValid && !!onAddButton;
    return (
      <div className={classnames(styles.UrlField, {[styles.hasError]: error})}
           onKeyDown={this.handleKeydown}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <div className={styles.inputWrapper}>
          <input
            id="ur-input"
            className={classnames(styles.inputControl, {[styles.open]: isDropdownVisible})}
            ref={elem => this.inputRef = elem}
            value={currentValue}
            placeholder={isDisabled ? '' : placeholder}
            type='text'
            autoComplete='off'
            autoCorrect='off'
            onChange={this.handleChange}
            onClick={this.handleInputClick}
            onDoubleClick={this.handleDoubleClick}
            onKeyDown={this.handleInputKeyDown}
            disabled={isDisabled}
          />
          <div className={classnames(styles.dropdown, {[styles.open]: isDropdownVisible})}
               ref={elem => this.dropdownRef = elem}>
            <div className={classnames(styles.dropdownButton, {[styles.open]: isDropdownVisible})}
                 tabIndex={'0'}
                 title={''}
                 role='button'
                 aria-haspopup={'listbox'}
                 aria-expanded={isDropdownVisible ? true : undefined}
                 onClick={this.handleDropdownClick}>
              <div className={styles.typeLabel}>{decodeUrlType(currentTypeId)}</div>
              <div className={classnames(styles.arrow, {[styles.up]: isDropdownVisible})}></div>
            </div>
            {isDropdownVisible && this.getDropdownList()}
          </div>
          <div className={classnames(styles.addButton, {[styles.isActive]: isAddButtonActive})}
               onClick={isAddButtonActive ? this.handleAddUrl : null}>
            <Icon symbolName="plus" className={styles.icon} />
          </div>
        </div>
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
