// -------------------------------------------------------------------------------------------------
//  MyGroupsContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки груп поточного користувача.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {GROUP_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchMyGroups} from 'actions/GroupActions';
import {setAllContactsUnselected} from 'actions/ContactActions';
import {setAllStaplesUnselected} from 'actions/StapleActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyGroups = Loader.create(() => import('./MyGroups'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyGroupsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyGroupsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const user = myId ? UserStore.getUser(myId) : null;
    const groups = CrmStore.getMyGroups();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const areGroupsLoaded = CrmStore.areMyGroupsLoaded();
    const hasAnySelected = myId && user && user.id === myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, user, groups, fsrCount, groupsQty, contactsQty,
      areGroupsLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.areGroupsLoaded !== nextState.areGroupsLoaded
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || !is(this.state.user, nextState.user)
      || !is(this.state.groups, nextState.groups);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn, hasAnySelected} = this.state;
    if (!CrmStore.areMyGroupsLoaded()) {
      fetchMyGroups();
    }
    // CTX:
    if (isLoggedIn) {
      substituteSidebarTabMode(!hasAnySelected ? GROUP_TAB : SELECTION_TAB);
      substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      substituteSidebarChat({chatId: ''});
    }
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  render() {
    trace(`render`);
    const {
      isLoggedIn,
      myId,
      user,
      groups,
      fsrCount,
      groupsQty,
      contactsQty,
      areGroupsLoaded,
      hasAnySelected} = this.state;
    return (
      <MyGroups
        isLoggedIn={isLoggedIn}
        myId={myId}
        user={user}
        groups={groups}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        areGroupsLoaded={areGroupsLoaded}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
      />
    );
  }
}

export default Container.create(MyGroupsContainer, {pure: false});
