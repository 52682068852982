// -------------------------------------------------------------------------------------------------
//  MobileNavbar.js
//  - - - - - - - - - -
//  Навігаційне меню мобільного режиму.
//
//  Attn:
//  - - - - -
//  - є два види кнопки закриття: кнопка для перемикання меню, та кнопка для компоненти PageNavbar;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Link, NavLink} from 'react-router-dom';
import {t} from 'ttag';
import {BASIC_ACCOUNT, PRO_ACCOUNT, PREMIUM_ACCOUNT, ENTERPRISE_ACCOUNT, ADMIN_ACCOUNT, accountTypeName} from 'core/accountTypes';
import Icon from 'components/UI/icons/Icon';
import {toggle as toggleMobileMenu} from './MobileMenu';
import styles from './MobileNavbar.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - MobileNavbar';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// const IconButton = ({symbolName, onClick}) => (
//   <div className={styles.iconButton} onClick={onClick}>
//     <Icon symbolName={symbolName} className={styles.icon} />
//   </div>
// )

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class MobileNavbar extends React.Component {
  static propTypes = {
    myAccount: Types.object,              // акаунт користувача
    isLoggedIn: Types.bool,               // is current user logged in?
    isSidebarOpen: Types.bool,            // is sidebar open?
    onToggleSidebar: Types.func,          // sidebar toggle handler
    onClose: Types.func,                  // close handler (close button action)
  }

  state = {
    isSystemMenuOpen: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({isSystemMenuOpen: false});
  }

  handleToggleMenu = () => {
    this.setState({isSystemMenuOpen: !this.state.isSystemMenuOpen});
    return toggleMobileMenu();
  }

  render() {
    const {myAccount, isLoggedIn, isSidebarOpen, onToggleSidebar, onClose} = this.props;
    const {accountType:myAccountType} = myAccount || {};
    const {isSystemMenuOpen} = this.state;
    trace(`render`);
    return (
      <header>
        <nav className={styles.MobileNavbar}>
          <div className={styles.leftWrapper}>
            <button className={styles.systemButton} aria-label={t`System Menu`} onClick={this.handleToggleMenu}>
              <Icon symbolName={!isSystemMenuOpen ? "three-bars" : "cross"} className={styles.icon} />
            </button>
          </div>
          <div className={styles.middleWrapper}>
            <NavLink to="/" exact title={t`Social Feeds`} className={styles.logoButton} activeClassName={styles.isActive}>
              <Icon symbolName="logo-icon" className={styles.logoIcon} />
              <div className={styles.logoWrapper}>
                <Icon symbolName="logo-text" className={styles.logoText} />
              </div>
              <div className={styles.accountTypeName}>{accountTypeName(myAccountType)}</div>
            </NavLink>
          </div>
          <div className={styles.rightWrapper}>
            {!isLoggedIn &&
              <Link to="/auth/login" className={styles.loginButton}>
                <Icon symbolName="login" className={styles.icon} />
                <span>{t`Login`}</span>
              </Link>
            }
            {!isSystemMenuOpen && !!onClose &&
              <button className={styles.closeButton} onClick={onClose}>
                <Icon symbolName="cross" className={styles.icon} />
              </button>
            }
          </div>
        </nav>
      </header>
    );
  }
}
