// -------------------------------------------------------------------------------------------------
//  ChatAPI.js
//  - - - - - - - - - -
//  Chats & messaging APIs.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - параметр cursor або повинен містити значення дати-часу, або бути відсутнім в запиті;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
// -------------------------------------------------------------------------------------------------
import {
  ID_FLD,
  TYPE_FLD,
  STATUS_FLD,
  BODY_FLD,
  CHAT_ID_FLD,
  CHAT_IDS_FLD,
  CHAT_TYPE_FLD,
  CHAT_STATUS_FLD,
  MESSAGE_ID_FLD,
  MESSAGE_IDS_FLD,
  ATTACHMENTS_FLD,
  MEMBER_IDS_FLD,
  CONTACT_IDS_FLD,
  USER_ID_FLD,
  STAPLE_ID_FLD,
  STAPLE_IDS_FLD,
  CONTEXT_ID_FLD,
  CONTEXT_TYPE_FLD,
  IS_PUBLIC_FLD,
  CURSOR_FLD,
  LIMIT_FLD,
  ORDER_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_MY_CONTEXTS_CMD,
  FETCH_USER_CONTEXTS_CMD,
  // FETCH_MY_SCTX_CHATS_CMD,
  // FETCH_USER_SCTX_CHATS_CMD,
  FETCH_STAPLE_SCTX_CHATS_CMD,
  FETCH_USER_UCTX_CHATS_CMD,
  FETCH_CHATS_CMD,
  CREATE_CHAT_CMD,
  CREATE_SCTX_CHATS_CMD,
  UPDATE_CHAT_CMD,
  CLOSE_CHAT_CMD,
  DELETE_CHAT_CMD,
  FETCH_CHAT_MESSAGES_CMD,
  CREATE_CHAT_MESSAGE_CMD,
  UPDATE_CHAT_MESSAGE_CMD,
  DELETE_CHAT_MESSAGE_CMD,
  FETCH_UNREAD_MESSAGES_CMD,
  SET_UNREAD_MESSAGES_AS_READ_CMD} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {wsockFetch} from 'api/WebSocketAPI';

const CHATS_PER_REQUEST_LIMIT = 24;       // типова к-сть чатів за один запит до бекенду
const MESSAGES_PER_REQUEST_LIMIT = 48;    // типова к-сть меседжів за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - ChatAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyContexts(opts = {}) {
  trace(`apiFetchMyContexts`);
  const {
    ctxType = STAPLE_CTX,                 // тип контексту чатів, які запитуємо
    cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'chat_id', з якого фільтрувати записи
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ;
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_MY_CONTEXTS_CMD,
    [PAYLOAD]: cursor ? {
      [CONTEXT_TYPE_FLD]: ctxType,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [CONTEXT_TYPE_FLD]: ctxType,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserContexts(opts = {}) {
  trace(`apiFetchUserContexts`);
  const {
    userId = '',                          // ідентифікатор юзера, спільні чати з яким повертаємо
    ctxType = STAPLE_CTX,                 // тип контексту чатів, які запитуємо
    cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'chat_id', з якого фільтрувати записи
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ;
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_USER_CONTEXTS_CMD,
    [PAYLOAD]: cursor ? {
      [USER_ID_FLD]: userId,
      [CONTEXT_TYPE_FLD]: ctxType,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [USER_ID_FLD]: userId,
      [CONTEXT_TYPE_FLD]: ctxType,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiFetchMySctxChats(opts = {}) {
//   trace(`apiFetchMySctxChats`);
//   const {
//     cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id', з якого фільтрувати записи
//     order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
//     limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ;
//   } = opts;
//   return await wsockFetch({
//     [CMD]: FETCH_MY_SCTX_CHATS_CMD,
//     [PAYLOAD]: cursor ? {
//       [CURSOR_FLD]: cursor,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     } : {
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     }
//   });
// }

// //  Повертає список спільних між юзером та користувачем тематичних чатів до стейплів (з пагінацією).
// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiFetchUserSctxChats(opts = {}) {
//   trace(`apiFetchUserSctxChats`);
//   const {
//     userId = '',                          // ідентифікатор юзера, спільні чати з яким повертаємо
//     cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id', з якого фільтрувати записи
//     order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
//     limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ;
//   } = opts;
//   return await wsockFetch({
//     [CMD]: FETCH_USER_SCTX_CHATS_CMD,
//     [PAYLOAD]: cursor ? {
//       [USER_ID_FLD]: userId,
//       [CURSOR_FLD]: cursor,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     } : {
//       [USER_ID_FLD]: userId,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     }
//   });
// }

//  Повертає список тематичних чатів до заданого стейплу (з пагінацією).
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchStapleSctxChats(opts = {}) {
  trace(`apiFetchStapleSctxChats`);
  const {
    stapleId = '',                        // ідентифікатор стейплу, чати якого повертаємо
    cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id', з якого фільтрувати записи
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_STAPLE_SCTX_CHATS_CMD,
    [PAYLOAD]: cursor ? {
      [STAPLE_ID_FLD]: stapleId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [STAPLE_ID_FLD]: stapleId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// ToDo: COLLECTION_CTX !!!
// //  Повертає список тематичних чатів до заданої колекції (з пагінацією).
// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiFetchCollectionCctxChats(opts = {}) {
//   trace(`apiFetchCollectionCctxChats`);
//   const {
//     collectionId = '',                    // ідентифікатор колекції, чати до якої повертаємо
//     cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id', з якого фільтрувати записи
//     order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
//     limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ
//   } = opts;
//   return await wsockFetch({
//     [CMD]: FETCH_COLLECTION_CCTX_CHATS_CMD,
//     [PAYLOAD]: cursor ? {
//       [COLLECTION_ID_FLD]: collectionId,
//       [CURSOR_FLD]: cursor,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     } : {
//       [COLLECTION_ID_FLD]: collectionId,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     }
//   });
// }

//  Повертає список прямих чатів до заданого юзера (з пагінацією).
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserUctxChats(opts = {}) {
  const {
    userId = '',                          // ідентифікатор юзера, чати до якого повертаємо
    cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id', з якого фільтрувати записи
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = CHATS_PER_REQUEST_LIMIT,      // к-сть чатів на запит; якщо менше, то це отримано УСІ
  } = opts;
  trace(`apiFetchUserUctxChats`);
  return await wsockFetch({
    [CMD]: FETCH_USER_UCTX_CHATS_CMD,
    [PAYLOAD]: cursor ? {
      [USER_ID_FLD]: userId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [USER_ID_FLD]: userId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchChats(ids) {
  trace(`apiFetchChats: ids=${ids}`);
  return await wsockFetch({
    [CMD]: FETCH_CHATS_CMD,
    [PAYLOAD]: {
      [CHAT_IDS_FLD]: ids
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateChat(opts) {
  trace(`apiCreateChat`);
  const {
    ctxType = '',                         // тип контексту = {'u' || 's' || ...}
    ctxId = '',                           // ідентифікатор контексту
    memberIds = [],                       // список ідентифікаторів учасників чату
  } = opts;
  return await wsockFetch({
    [CMD]: CREATE_CHAT_CMD,
    [PAYLOAD]: {
      [CONTEXT_TYPE_FLD]: ctxType,
      [CONTEXT_ID_FLD]: ctxId,
      [MEMBER_IDS_FLD]: memberIds,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateSctxChats(contactIds, stapleIds) {
  trace(`apiCreateSctxChats`);
  return await wsockFetch({
    [CMD]: CREATE_SCTX_CHATS_CMD,
    [PAYLOAD]: {
      [CONTACT_IDS_FLD]: contactIds,
      [STAPLE_IDS_FLD]: stapleIds
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateChat(chat, patchObject) {
  trace(`apiUpdateChat`);
  const {id = ''} = chat;
  const {[STATUS_FLD]:status} = patchObject;
  return await wsockFetch({
    [CMD]: UPDATE_CHAT_CMD,
    [PAYLOAD]: {
      [CHAT_ID_FLD]: id,
      [STATUS_FLD]: status,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCloseChat(chat) {
  trace(`apiCloseChat`);
  const {id = ''} = chat;
  return await wsockFetch({
    [CMD]: CLOSE_CHAT_CMD,
    [PAYLOAD]: {
      [CHAT_ID_FLD]: id,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteChat(chat) {
  const {id:chatId, currCtxId:ctxId, currCtxType:ctxType} = chat;
  trace(`apiDeleteChat: id=${chatId}, ctxId=${ctxId}, ctxType=${ctxType}`);
  return await wsockFetch({
    [CMD]: DELETE_CHAT_CMD,
    [PAYLOAD]: {
      [CHAT_ID_FLD]: chatId,
      [CONTEXT_ID_FLD]: ctxId,
      [CONTEXT_TYPE_FLD]: ctxType
    }
  });
}

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiAddChatMember(opts = {}) {
//   trace(`apiAddChatMember`);
//   ...
// }

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiUpdateChatMember(opts = {}) {
//   trace(`apiUpdateChatMember`);
//   ...
// }

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiRemoveChatMember(opts = {}) {
//   trace(`apiRemoveChatMember`);
//   ...
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchChatMessages(opts) {
  trace(`apiFetchChatMessages`);
  const {
    chatId = '',                          // ідентифікатор чату
    cursor = DEFAULT_TSN12_CURSOR,        // значення поля 'id' для фільтрації меседжів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = MESSAGES_PER_REQUEST_LIMIT,   // к-сть меседжів на запит; якщо <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_CHAT_MESSAGES_CMD,
    [PAYLOAD]: cursor ? {
      [CHAT_ID_FLD]: chatId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [CHAT_ID_FLD]: chatId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateChatMessage(opts) {
  trace(`apiCreateChatMessage`);
  const {
    chatId = '',                          // ідентифікатор чату, в який додаємо повідомлення
    chatType = '',                        // тип чату (attn: зберігається в полі chat_type mnesia-таблиці непрочитаних повідомлень #unread_message)
    body = '',                            // текст меседжу (в форматі Conmark)
    attachments = undefined,              // вкладення до повідомлення у форматі {{ ATTACHMENTS }}
  } = opts;
  return await wsockFetch({
    [CMD]: CREATE_CHAT_MESSAGE_CMD,
    [PAYLOAD]: attachments ? {
      [CHAT_ID_FLD]: chatId,
      [CHAT_TYPE_FLD]: chatType,
      [BODY_FLD]: body,
      [ATTACHMENTS_FLD]: attachments
    } : {
      [CHAT_ID_FLD]: chatId,
      [CHAT_TYPE_FLD]: chatType,
      [BODY_FLD]: body,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateChatMessage(opts) {
  trace(`apiUpdateChatMessage`);
  const {
    id = '',
    chatId = '',
    body = ''} = opts;
  return await wsockFetch({
    [CMD]: UPDATE_CHAT_MESSAGE_CMD,
    [PAYLOAD]: {
      [MESSAGE_ID_FLD]: id,
      [CHAT_ID_FLD]: chatId,
      [BODY_FLD]: body,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteChatMessage(chatId, messageId) {
  trace(`apiDeleteChatMessage: chatId=${chatId}, messageId=${messageId}`);
  return await wsockFetch({
    [CMD]: DELETE_CHAT_MESSAGE_CMD,
    [PAYLOAD]: {
      [MESSAGE_ID_FLD]: messageId,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUnreadMessages() {
  trace(`apiFetchUnreadMessages`);
  return await wsockFetch({
    [CMD]: FETCH_UNREAD_MESSAGES_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSetUnreadMessagesAsRead(ids) {
  trace(`apiSetUnreadMessagesAsRead: ids=${ids}`);
  return await wsockFetch({
    [CMD]: SET_UNREAD_MESSAGES_AS_READ_CMD,
    [PAYLOAD]: {
      [MESSAGE_IDS_FLD]: ids
    }
  });
}
