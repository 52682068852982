// -------------------------------------------------------------------------------------------------
//  table.js
//  - - - - - - - - - -
//  Table node: plugin & default options & toolbar operation icons.
//
//  Notes:
//  - - - - -
//  - [mod+enter] will exit the table and create 'exit' block type;
//
//  Attn:
//  - - - - -
//  - TABLE заборонений в контексті CODEBLOCK, TABLE (антирекурсивність);
//  - всередині TABLE заборонені інші типи, окрім INLINE_CODE, LINK, BOLD, ITALIC, STRIKE;
// -------------------------------------------------------------------------------------------------
// ToDo: TablePicker !!!
// ToDo: окреме контекстне меню для редагування ТІЛЬКИ таблиці ???

import React from 'react';
import {Range} from 'slate';
import EditTablePlugin from 'slate-edit-table';
import RichIcon from '../RichIcon';
import {blockTypes} from 'core/richTypes';
import {isInTableContext} from './utils';
import styles from './rich-table.scss';

export const defaultTableOptions = {
  typeTable: blockTypes.TABLE,            // the type of table blocks
  typeRow: blockTypes.TABLE_ROW,          // the type of row blocks
  typeCell: blockTypes.TABLE_CELL,        // the type of cell blocks
  typeContent: blockTypes.PARAGRAPH,      // the default type for blocks in cells
  exitBlockType: blockTypes.PARAGRAPH,    // the type of block inserted when exiting
};

const EditTable = EditTablePlugin(defaultTableOptions);

export class TableCreateIcon extends React.Component {
  handleCreateTable = (event) => {
    let {change, onChange} = this.props;

    // add SPACE before & after the table
    change.insertText('  ').move(-1);

    // створення таблиці 2x2 (cols x rows)
    onChange(EditTable.changes.insertTable(change, 2, 2));
  }

  render() {
    const {change, ...other} = this.props;

    if (isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table"
        commandName="table-create"
        isDisplayed={true}
        onClick={this.handleCreateTable}
        {...other}
      />
    );
  }
}

export class TableDeleteIcon extends React.Component {
  handleDeleteTable = (event) => {
    let {change, onChange} = this.props;
    onChange(EditTable.changes.removeTable(change));
  }

  render() {
    const {change, ...other} = this.props;

    if (!isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table-delete"
        commandName="table-delete"
        isDisplayed={true}
        onClick={this.handleDeleteTable}
        {...other}
      />
    );
  }
}

export class TableInsertColumnIcon extends React.Component {
  handleInsertColumn = (event) => {
    let {change, onChange} = this.props;
    onChange(EditTable.changes.insertColumn(change));
  }

  render() {
    const {change, ...other} = this.props;

    if (!isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table-insert-column"
        commandName="table-insert-column"
        isDisplayed={true}
        onClick={this.handleInsertColumn}
        {...other}
      />
    );
  }
}

export class TableInsertRowIcon extends React.Component {
  handleInsertRow = (event) => {
    let {change, onChange} = this.props;
    onChange(EditTable.changes.insertRow(change));
  }

  render() {
    const {change, ...other} = this.props;

    if (!isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table-insert-row"
        commandName="table-insert-row"
        isDisplayed={true}
        onClick={this.handleInsertRow}
        {...other}
      />
    );
  }
}

export class TableRemoveColumnIcon extends React.Component {
  handleRemoveColumn = (event) => {
    let {change, onChange} = this.props;

    onChange(
      EditTable.changes.removeColumn(
        // attn: the whole block must be selected for successful deletion !!!
        change.extendToStartOfBlock().extendToEndOfBlock()
      )
    );
  }

  render() {
    const {change, ...other} = this.props;

    if (!isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table-remove-column"
        commandName="Remove Column"
        isDisplayed={true}
        onClick={this.handleRemoveColumn}
        {...other}
      />
    );
  }
}

export class TableRemoveRowIcon extends React.Component {
  handleRemoveRow = (event) => {
    let {change, onChange} = this.props;

    onChange(
      EditTable.changes.removeRow(
        // attn: the whole block must be selected for successful deletion !!!
        change.extendToStartOfBlock().extendToEndOfBlock()
      )
    );
  }

  render() {
    const {change, ...other} = this.props;

    if (!isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="table-remove-row"
        commandName="Remove Row"
        isDisplayed={true}
        onClick={this.handleRemoveRow}
        {...other}
      />
    );
  }
}

const renderTable = () => {
  return ({attributes, children}) => {
    return (
      <table className={styles.Table}>
        <tbody {...attributes}>
          {children}
        </tbody>
      </table>
    );
  };
}

const renderTableRow = () => {
  return ({attributes, children}) => {
    return (
      <tr {...attributes}>
        {children}
      </tr>
    );
  };
}

const renderTableCell = () => {
  return ({attributes, children}) => {
    return (
      <td {...attributes}>
        {children}
      </td>
    );
  };
}

const TablePlugin = (opts = {}) => {
  // const options = Object.assign(defaultTableOptions, opts);
  return {
    renderNode: (props) => {
      if (props.node.type === blockTypes.TABLE)
        return renderTable()(props);

      else if (props.node.type === blockTypes.TABLE_ROW)
        return renderTableRow()(props);

      else if (props.node.type === blockTypes.TABLE_CELL)
        return renderTableCell()(props);
    }
  }
};

export default TablePlugin;
