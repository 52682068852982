// -------------------------------------------------------------------------------------------------
//  PricingPageContainer.js
//  - - - - - - - - - -
//  Контейнер сторінки статичних сторінок.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import AccountStore from 'stores/AccountStore';
import Loader from 'utils/ComponentLoader';

const PricingPage = Loader.create(() => import('./PricingPage'));
const isVerbose = DEBUG && true;
const prefix = '- - - PricingPageContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class PricingPageContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccount = AccountStore.getMyAccount();
    return {isLoggedIn, myAccount};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = !is(this.state.myAccount, nextState.myAccount);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  render() {
    const {isLoggedIn, myAccount} = this.state;
    trace(`render`);
    return (
      <PricingPage
        isLoggedIn={isLoggedIn}
        myAccount={myAccount}
      />
    );
  }
}

export default Container.create(PricingPageContainer, {withProps: true, pure: false});
