// -------------------------------------------------------------------------------------------------
//  CreateCollectionContainer.js
//  - - - - - - - - - - - -
//
//  Attn: a)
//  - - - - -
//  - тип створюваної колекцій, визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import {fetchMyCollections} from 'actions/CollectionActions';
import {fetchSystemSubjects} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const CreateCollection = Loader.create(() => import('./CreateCollection'));
const isVerbose = DEBUG && true;
const prefix = '- - - CreateCollectionContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CreateCollectionContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const myAccount = AccountStore.getMyAccount();
    const subjects = SystemStore.getSubjectListOrderedByName();
    return {collectionType, myAccount, subjects};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.collectionType !== nextState.collectionType
      || !is(this.state.myAccount, nextState.myAccount)
      || this.state.subjects.size !== nextState.subjects.size
      || !is(this.state.subjects, nextState.subjects);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!SystemStore.areSubjectsLoaded()) {
      fetchSystemSubjects();
    }
    if (!CollectionStore.areMyCollectionsLoaded()) {
      fetchMyCollections();
    }
  }

  render() {
    trace(`render`);
    const {myAccount, collectionType, subjects} = this.state;
    const {accountType:myAccountType, slug:mySlug, languageId = ENGLISH_LANG} = myAccount || {};
    return (
      <CreateCollection
        collectionType={collectionType}
        myAccountType={myAccountType}
        mySlug={mySlug}
        languageId={languageId}
        subjects={subjects}
      />
    );
  }
}

export default Container.create(CreateCollectionContainer, {withProps: true, pure: false});
