// -------------------------------------------------------------------------------------------------
//  commonHeading.js
//  - - - - - - - - - -
//  Heading type node helpers.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import isHotkey from 'is-hotkey';
import {blockTypes} from 'core/richTypes';
import {renderNode} from './commonNode';
import {haveBlocks} from './utils';

export const toggleHeading = (change, type) => {
  const isActive = haveBlocks(change, type);

  return change.setBlocks(isActive ? blockTypes.PARAGRAPH : type);
};

export function headingPlugin({type, tagName, hotkey}) {
  return {
    onKeyDown: (event, change) => {
      if (event.key === 'Enter') {
        const {value} = change;
        const {blocks} = value;
        const getCurrentblock = blocks.get(0);

        if (getCurrentblock.type === type) {
          return change
            .splitBlock()
            .setBlocks(blockTypes.PARAGRAPH);
        }
      } else if (isHotkey(hotkey, event)) {
        change.call(toggleHeading, type);
        return false;
      }
    },
    renderNode: (props) => {
      if (props.node.type === type)
        return renderNode(tagName)(props);
    },
  }
}
