// -------------------------------------------------------------------------------------------------
//  mdastTypes.js
//  - - - - - - - - - -
//  MDAST (Markdown Abstract Syntax Tree) enumerated types.
// -------------------------------------------------------------------------------------------------

export const mdastTypes = {
  ROOT:                 'root',
  TEXT:                 'text',
  YAML:                 'yaml',
  HTML:                 'html',
  PARAGRAPH:            'paragraph',
  BLOCKQUOTE:           'blockquote',
  CODEBLOCK:            'code',
  INLINE_CODE:          'inlineCode',
  BOLD:                 'strong',
  ITALIC:               'emphasis',
  STRIKE:               'delete',
  HEADING:              'heading',
  BREAK:                'break',
  HR:                   'thematicBreak',
  TABLE:                'table',
  TABLE_ROW:            'tableRow',
  TABLE_CELL:           'tableCell',
  LIST:                 'list',
  LIST_ITEM:            'listItem',
  LINK:                 'link',
  LINK_REF:             'linkReference',
  IMAGE:                'image',
  IMAGE_REF:            'imageReference',
  FOOTNOTE:             'footnote',
  FOOTNOTE_REF:         'footnoteReference',
  FOOTNOTE_DEFINITION:  'footnoteDefinition',
  DEFINITION:           'definition',
};
