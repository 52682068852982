// -------------------------------------------------------------------------------------------------
//  PlusButton.js
//  - - - - - - - - - -
//  Глобальна кнопка для створення нових обʼєктів.
//
//  Attn:
//  - кнопку PlusButton розміщуємо між елементами ScrollWrapper щоб вона була розміщена
//    ближче до кореня в dom-дереві, аніж класи що візуально повинні її перекривати;
//    це тому, що в мобільних браузерах порядок приорітетніше аніж z-index;
//      <ScrollWrapper>...</ScrollWrapper>
//      <PlusButton />
//      <ScrollWrapper>...</ScrollWrapper>
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import Icon from 'components/UI/icons/Icon';
import styles from './PlusButton.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function setPlusButtonAction(onClick) {
  pubsub.publish(onClick);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class PlusButton extends React.Component {
  state = {
    onClick: null,                        // колбек на натискання кнопки (якщо null, то кнопка не показуємо)
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(onClick => {
      this.setState({
        onClick: onClick,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const {onClick} = this.state;
    if (!onClick) {
      return null;
    }
    return (
      <div className={styles.PlusButton} title={t`Add`} onClick={onClick}>
        <div className={styles.container}>
          <Icon symbolName="plus" className={styles.icon} />
        </div>
      </div>
    );
  }
}
