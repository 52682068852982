// -------------------------------------------------------------------------------------------------
//  PhoneField.js
//  - - - - - - - - - -
//  Поле для вводу номеру телефону.
//
//  Attn:
//  - при введенні коротких номерів телефону (без міжнародного коду країни) виконується
//    перетворення у повний формат (через розпізнавання у формат e164 з подальшим форматуванням);
//  - перетворення типу телефону encodePhoneType(pnType) --> const робимо відразу в момент
//    визначення і в подальшому зберігаємо в базі даних та в системі у скороченому вигляді;
// -------------------------------------------------------------------------------------------------
// FixMe: при виборі території шукає по CallingCode, а не по CountryCode --> видає неправильний прапор !!!

import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import memoize from 'lodash.memoize';
import reduce from 'lodash.reduce';
import startsWith from 'lodash.startswith';
import PhoneNumber from 'awesome-phonenumber';
import {t} from 'ttag';
import './utils/prototypes'
import Icon from 'components/UI/icons/Icon';
import CountryData from './utils/CountryData';
import styles from './PhoneField.scss';

export const FIXED_LINE_PHONE             = 'f';    // ('fixed-line')
export const FIXED_LINE_OR_MOBILE_PHONE   = 'g';    // ('fixed-line-or-mobile')
export const MOBILE_PHONE                 = 'm';    // ('mobile')
export const PAGER_PHONE                  = 'p';    // ('pager')
export const PERSONAL_NUMBER_PHONE        = '1';    // ('personal-number')
export const PREMIUM_RATE_PHONE           = '2';    // ('premium-rate')
export const SHARED_COST_PHONE            = '3';    // ('shared-cost')
export const TOLL_FREE_PHONE              = '4';    // ('toll-free')
export const UAN_PHONE                    = '5';    // ('uan')
export const VOIP_PHONE                   = 'v';    // ('voip')
export const UNKNOWN_PHONE                = '';     // ('unknown')

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function encodePhoneType(pnType) {
  let encodedType;
  switch (pnType) {
    case 'fixed-line':                    encodedType = FIXED_LINE_PHONE; break;
    case 'fixed-line-or-mobile':          encodedType = FIXED_LINE_OR_MOBILE_PHONE; break;
    case 'mobile':                        encodedType = MOBILE_PHONE; break;
    case 'pager':                         encodedType = PAGER_PHONE; break;
    case 'personal-number':               encodedType = PERSONAL_NUMBER_PHONE; break;
    case 'premium-rate':                  encodedType = PREMIUM_RATE_PHONE; break;
    case 'shared-cost':                   encodedType = SHARED_COST_PHONE; break;
    case 'toll-free':                     encodedType = TOLL_FREE_PHONE; break;
    case 'uan':                           encodedType = UAN_PHONE; break;
    case 'voip':                          encodedType = VOIP_PHONE; break;
    case 'unknown':                       encodedType = UNKNOWN_PHONE; break;
    default:                              encodedType = UNKNOWN_PHONE; break;
  }
  return encodedType;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function decodePhoneType(pnType) {
  let decodedType;
  switch (pnType) {
    case FIXED_LINE_PHONE:                decodedType = t`fixed-line`; break;
    case FIXED_LINE_OR_MOBILE_PHONE:      decodedType = t`fixed-line-or-mobile`; break;
    case MOBILE_PHONE:                    decodedType = t`mobile`; break;
    case PAGER_PHONE:                     decodedType = t`pager`; break;
    case PERSONAL_NUMBER_PHONE:           decodedType = t`personal-number`; break;
    case PREMIUM_RATE_PHONE:              decodedType = t`premium-rate`; break;
    case SHARED_COST_PHONE:               decodedType = t`shared-cost`; break;
    case TOLL_FREE_PHONE:                 decodedType = t`toll-free`; break;
    case UAN_PHONE:                       decodedType = t`uan`; break;
    case VOIP_PHONE:                      decodedType = t`voip`; break;
    case UNKNOWN_PHONE:                   decodedType = ''; break;
    default:                              decodedType = ''; break;
  }
  return decodedType;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class PhoneField extends React.Component {
  static propTypes = {
    country: Types.oneOfType([Types.string, Types.number]), // країна по-замовчуванню (для відображення тел.коду країни)
    label: Types.string,                  // label text (is shown above the field)
    value: Types.string,                  // current value
    error: Types.string,                  // повідомлення про помилку; якщо вказане, то відмальовуємо поле червоним
    placeholder: Types.string,            // placeholder (e.g.: '1 (702) 123-4567')
    isDisabled: Types.bool,               // is field disabled?
    isRequired: Types.bool,               // is field required?
    isDropdownVisible: Types.bool,        // is dropdown visible on startup?
    hasDropdown: Types.bool,              // is country dropdown available in the component?
    hasSearchBox: Types.bool,             // has search box?
    onChange: Types.func,                 // -spec: onChange(formattedNumber, countryData: {name, dialCode, countryCode, format}, event)
    onAddButton: Types.func,              // -spec: onAddButton({pnNumber, pnFormattedNumber, pnCountryCode, pnType})
    // ...
    preferredCountries: Types.arrayOf(Types.string), // список країн, що показуються в голові списку
    excludeCountries: Types.arrayOf(Types.string), // список країн, яких видалити зі списку країн
    onlyCountries: Types.arrayOf(Types.string), // список країн, якими обмежується введення номерів
    // ...
    enableTerritories: Types.oneOfType([Types.bool, Types.arrayOf(Types.string)]), // чи показувати території в списку вибору?
    enableAreaCodes: Types.oneOfType([Types.bool, Types.arrayOf(Types.string)]), // ...?
    regions: Types.oneOfType([Types.string, Types.arrayOf(Types.string)]), // ...?
    preserveOrder: Types.arrayOf(Types.string), // ...?
    masks: Types.object,                  // ...?
    priority: Types.object,               // ...?
    areaCodes: Types.object,              // ...?
    localization: Types.object,           // ...?
    prefix: Types.string,                 // phone prefix (e.g.: '+')
    defaultMask: Types.string,            // ...?
    alwaysDefaultMask: Types.bool,        // ...?
    // ...
    searchPlaceholder: Types.string,      // ...?
    searchNotFound: Types.string,         // ...?
    autoFormat: Types.bool,               // ...?
    disableCountryCode: Types.bool,       // ...?
    countryCodeEditable: Types.bool,      // ...?
    disableCountryGuess: Types.bool,      // ...?
    disableInitialCountryGuess: Types.bool, // ...?
    jumpCursorToEnd: Types.bool,          // ...?
    enableAreaCodeStretch: Types.bool,    // ...?
    enableClickOutside: Types.bool,       // ...?
    enableLongNumbers: Types.oneOfType([Types.bool, Types.number]), // ...?
    // ...
    onFocus: Types.func,                  // ...?
    onBlur: Types.func,                   // ...?
    onClick: Types.func,                  // ...?
    onKeyDown: Types.func,                // ...?
  }

  static defaultProps = {
    country: '',
    label: '',
    value: '',
    error: '',
    placeholder: '1 (702) 123-4567',
    isDisabled: false,
    isRequired: false,
    hasDropdown: false,
    hasSearchBox: false,
    // ...
    preferredCountries: [],               // список країн, що показуються в голові списку
    excludeCountries: [],                 // список країн, яких видалити зі списку введення
    onlyCountries: [],                    // список країн, якими обмежується введення номерів
    // ...
    enableTerritories: false,             // чи показувати території в списку вибору?
    enableAreaCodes: false,
    regions: '',
    preserveOrder: [],
    masks: null,
    priority: null,
    areaCodes: null,
    localization: {},
    prefix: '+',
    defaultMask: '... ... ... ... ..',    // prefix + dialCode + ' ' + defaultMask
    alwaysDefaultMask: false,
    // ...
    searchPlaceholder: t`search...`,
    searchNotFound: t`No entries to show`,
    autoFormat: true,
    disableCountryCode: false,
    enableLongNumbers: false,
    countryCodeEditable: true,
    disableInitialCountryGuess: false,
    disableCountryGuess: false,
    jumpCursorToEnd: true,
    enableAreaCodeStretch: false,
    enableClickOutside: true,
    isDropdownVisible: false,
    // ...
    keys: {
      UP: 38, DOWN: 40, RIGHT: 39, LEFT: 37, ENTER: 13,
      ESC: 27, PLUS: 43, A: 65, Z: 90, SPACE: 32, TAB: 9,
    }
  }

  constructor(props) {
    super(props);
    const {onlyCountries, preferredCountries, hiddenAreaCodes} =
      new CountryData(
        props.enableAreaCodes,
        props.enableTerritories,
        props.regions,
        props.preferredCountries,
        props.excludeCountries,
        props.onlyCountries,
        props.preserveOrder,
        props.masks,
        props.priority,
        props.areaCodes,
        props.localization,
        props.prefix,
        props.defaultMask,
        props.alwaysDefaultMask,
      );
    const inputNumber = props.value ? props.value.replace(/\D/g, '') : '';
    let countryGuess;
    if (props.disableInitialCountryGuess) {
      countryGuess = 0;
    } else if (inputNumber.length > 1) {
      // Country detect by phone
      countryGuess = this.guessSelectedCountry(inputNumber.substring(0, 6), props.country, onlyCountries, hiddenAreaCodes) || 0;
    } else if (props.country) {
      // Default country
      countryGuess = onlyCountries.find(o => o.iso2 === props.country) || 0;
    } else {
      // Empty params
      countryGuess = 0;
    }
    const dialCode = (inputNumber.length < 2
      && countryGuess
      && !startsWith(inputNumber, countryGuess.dialCode)) ? countryGuess.dialCode : '';
    let formattedNumber = (inputNumber === '' && countryGuess === 0) ? '' :
      this.formatNumber(
        (props.disableCountryCode ? '' : dialCode) + inputNumber,
        countryGuess.name ? countryGuess : undefined
      );
    const highlightCountryIndex = onlyCountries.findIndex(o => o === countryGuess);
    this.state = {
      formattedNumber: formattedNumber,   // поточний введений номер у форматованому вигляді
      selectedCountry: countryGuess,      // поточна вибрана/детектована країна
      onlyCountries,                      // список країн, якими обмежується введення номерів
      preferredCountries,                 // список країн, що показуються в голові списку
      highlightCountryIndex,              // ...?
      hiddenAreaCodes,                    // ...?
      queryString: '',                    // ...?
      searchValue: '',                    // ...?
      isFormattedNumberValid: false,      // чи є введений номер повністю набраним та коректним?
      isSelectionFreezed: false,          // ...?
      isDropdownVisible: props.isDropdownVisible, // ...?
      debouncedQueryStingSearcher: debounce(this.searchCountry, 250), // ...?
    };
  }

  componentDidMount() {
    if (document.addEventListener && this.props.enableClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (document.removeEventListener && this.props.enableClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.country !== this.props.country) {
      this.updateCountry(this.props.country);
    }
    else if (prevProps.value !== this.props.value) {
      this.updateFormattedNumber(this.props.value);
    }
  }

  getProbableCandidate = memoize((queryString) => {
    if (!queryString || queryString.length === 0) {
      return null;
    }
    // don't include the preferred countries in search
    const probableCountries = this.state.onlyCountries.filter((country) => {
      return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
    }, this);
    return probableCountries[0];
  });

  guessSelectedCountry = memoize((inputNumber, country, onlyCountries, hiddenAreaCodes) => {
    // if enableAreaCodes === false, try to search in hidden area codes to detect area code correctly
    // then search and insert main country which has this area code
    // https://github.com/bl00mber/react-phone-input-2/issues/201
    if (this.props.enableAreaCodes === false) {
      let mainCode;
      hiddenAreaCodes.some(country => {
        if (startsWith(inputNumber, country.dialCode)) {
          onlyCountries.some(o => {
            if (country.iso2 === o.iso2 && o.mainCode) {
              mainCode = o;
              return true;
            }
          })
          return true;
        }
      })
      if (mainCode) return mainCode;
    }
    const secondBestGuess = onlyCountries.find(o => o.iso2 === country);
    if (inputNumber.trim() === '') return secondBestGuess;
    const bestGuess = onlyCountries.reduce((selectedCountry, country) => {
      if (startsWith(inputNumber, country.dialCode)) {
        if (country.dialCode.length > selectedCountry.dialCode.length) {
          return country;
        }
        if (country.dialCode.length === selectedCountry.dialCode.length
          && country.priority < selectedCountry.priority) {
          return country;
        }
      }
      return selectedCountry;
    }, {dialCode: '', priority: 10001}, this);
    if (!bestGuess.name) return secondBestGuess;
    return bestGuess;
  });

  // hooks for updated props
  updateCountry = (country) => {
    const {onlyCountries} = this.state
    let newSelectedCountry;
    if (country.indexOf(0) >= '0' && country.indexOf(0) <= '9') { // digit
      newSelectedCountry = onlyCountries.find(o => o.dialCode === +country);
    } else {
      newSelectedCountry = onlyCountries.find(o => o.iso2 === country);
    }
    if (newSelectedCountry && newSelectedCountry.dialCode) {
      this.setState({
        selectedCountry: newSelectedCountry,
        formattedNumber: this.props.disableCountryCode ? '' : this.formatNumber(newSelectedCountry.dialCode, newSelectedCountry),
      });
    }
  }

  updateFormattedNumber(value) {
    if (value === null) return this.setState({selectedCountry: 0, formattedNumber: ''});
    const {onlyCountries, selectedCountry, hiddenAreaCodes} = this.state;
    const {country, prefix} = this.props;
    if (value === '') return this.setState({selectedCountry, formattedNumber: ''});
    let inputNumber = value.replace(/\D/g, '');
    let newSelectedCountry, formattedNumber;
    // if new value start with selectedCountry.dialCode, format number, otherwise find newSelectedCountry
    if (selectedCountry && startsWith(value, prefix + selectedCountry.dialCode)) {
      formattedNumber = this.formatNumber(inputNumber, selectedCountry);
      this.setState({formattedNumber});
    }
    else {
      if (this.props.disableCountryGuess) {newSelectedCountry = selectedCountry;}
      else {
        newSelectedCountry = this.guessSelectedCountry(inputNumber.substring(0, 6), country, onlyCountries, hiddenAreaCodes) || selectedCountry;
      }
      const dialCode = newSelectedCountry && startsWith(inputNumber, prefix + newSelectedCountry.dialCode) ? newSelectedCountry.dialCode : '';
      formattedNumber = this.formatNumber(
        (this.props.disableCountryCode ? '' : dialCode) + inputNumber,
        newSelectedCountry ? (newSelectedCountry) : undefined
      );
      this.setState({selectedCountry: newSelectedCountry, formattedNumber});
    }
  }

  // display methods
  scrollTo = (country, middle) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;
    const element = country;
    const elementOffset = element.getBoundingClientRect();
    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;
    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = (containerHeight / 2) - (elementHeight / 2);
    if (this.props.hasSearchBox ? elementTop < containerTop + 32 : elementTop < containerTop) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  }

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  }

  formatNumber = (text, country) => {
    if (!country) {
      return text;
    }
    const {format} = country;
    const {disableCountryCode, enableAreaCodeStretch, enableLongNumbers, autoFormat} = this.props;
    let pattern;
    if (disableCountryCode) {
      pattern = format.split(' ');
      pattern.shift();
      pattern = pattern.join(' ');
    } else {
      if (enableAreaCodeStretch && country.isAreaCode) {
        pattern = format.split(' ');
        pattern[1] = pattern[1].replace(/\.+/, ''.padEnd(country.areaCodeLength, '.'))
        pattern = pattern.join(' ');
      } else {
        pattern = format;
      }
    }
    if (!text || text.length === 0) {
      return disableCountryCode ? '' : this.props.prefix;
    }
    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      return disableCountryCode ? text : this.props.prefix + text;
    }
    const formattedObject = reduce(pattern, (acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }
      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText
        };
      }
      const [ head, ...tail ] = acc.remainingText;
      return {
        formattedText: acc.formattedText + head,
        remainingText: tail
      };
    }, {
      formattedText: '',
      remainingText: text.split('')
    });
    let formattedNumber = enableLongNumbers ?
      formattedObject.formattedText + formattedObject.remainingText.join('') :
      formattedObject.formattedText;
    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
    return formattedNumber;
  }

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.inputRef;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len-1)=== ')') len = len-1;
    input.setSelectionRange(len, len);
  }

  getElement = (index) => {
    return this[`flag_no_${index}`];
  }

  getCountryData = () => {
    if (!this.state.selectedCountry) {
      return {}
    }
    const {name = '', dialCode = '', iso2 = '', format = ''} = this.state.selectedCountry;
    return {name, dialCode, countryCode:iso2, format}
  }

  handleFlagDropdownClick = (e) => {
    e.preventDefault();
    if (!this.state.isDropdownVisible && this.props.isDisabled) return;
    const {preferredCountries, selectedCountry} = this.state
    const allCountries = preferredCountries.concat(this.state.onlyCountries)
    const highlightCountryIndex = allCountries.findIndex(o => o.dialCode === selectedCountry.dialCode && o.iso2 === selectedCountry.iso2);
    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
      highlightCountryIndex,
    }, () => {
      if (this.state.isDropdownVisible) {
        this.scrollTo(this.getElement(this.state.highlightCountryIndex));
      }
    });
  }

  handleChange = (e) => {
    const {value} = e.target;
    const {prefix, onChange} = this.props;
    let formattedNumber = this.props.disableCountryCode ? '' : prefix;
    let newSelectedCountry = this.state.selectedCountry;
    let isSelectionFreezed = this.state.isSelectionFreezed;
    if (!this.props.countryCodeEditable) {
      const mainCode = newSelectedCountry.hasAreaCodes ?
        this.state.onlyCountries.find(o => o.iso2 === newSelectedCountry.iso2 && o.mainCode).dialCode :
        newSelectedCountry.dialCode;
      const updatedInput = prefix + mainCode;
      if (value.slice(0, updatedInput.length) !== updatedInput) return;
    }
    if (value === prefix) {
      // we should handle change when we delete the last digit
      onChange && onChange('', this.getCountryData(), e);
      return this.setState({
        formattedNumber: this.props.disableCountryCode ? '' : prefix
      });
    }
    // is default 15 digit number limit exceeded?
    if (value.replace(/\D/g, '').length > 15) {
      if (this.props.enableLongNumbers === false) return;
      if (typeof this.props.enableLongNumbers === 'number') {
        if (value.replace(/\D/g, '').length > this.props.enableLongNumbers) return;
      }
    }
    // if the input is the same as before, must be some special key like enter etc.
    if (value === this.state.formattedNumber) return;
    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }
    const {country} = this.props
    const {onlyCountries, selectedCountry, hiddenAreaCodes} = this.state
    if (onChange) e.persist();
    if (value.length > 0) {
      // before entering the number in new format, lets check if the dial code now matches some other country
      const inputNumber = value.replace(/\D/g, '');
      // we don't need to send the whole number to guess the country... only the first 6 characters
      // are enough the guess country function can then use memoization much more effectively
      // since the set of input it gets has drastically reduced
      if (!this.state.isSelectionFreezed || selectedCountry.dialCode.length > inputNumber.length) {
        if (this.props.disableCountryGuess) {
          newSelectedCountry = selectedCountry;
        } else {
          newSelectedCountry = this.guessSelectedCountry(inputNumber.substring(0, 6), country, onlyCountries, hiddenAreaCodes) || selectedCountry;
        }
        isSelectionFreezed = false;
      }
      formattedNumber = this.formatNumber(inputNumber, newSelectedCountry);
      newSelectedCountry = newSelectedCountry.dialCode ? newSelectedCountry : selectedCountry;
    }
    let caretPosition = e.target.selectionStart;
    const oldFormattedText = this.state.formattedNumber;
    const diff = formattedNumber.length - oldFormattedText.length;
    // *) визначення правильності введеного номера:
    const {dialCode = '', iso2:pnCountryCode = ''} = newSelectedCountry;
    const pn = PhoneNumber(formattedNumber.replace(/[^0-9]+/g,''), pnCountryCode);
    const isFormattedNumberValid = pn.isValid() && pn.isPossible();
    this.setState({
      formattedNumber,
      isFormattedNumberValid,
      selectedCountry: newSelectedCountry,
      isSelectionFreezed,
    }, () => {
      if (diff > 0) {
        caretPosition = caretPosition - diff;
      }
      const lastChar = formattedNumber.charAt(formattedNumber.length - 1);
      if (lastChar === ')') {
        this.inputRef.setSelectionRange(formattedNumber.length - 1, formattedNumber.length - 1);
      } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
        this.inputRef.setSelectionRange(caretPosition, caretPosition);
      }
      onChange && onChange(formattedNumber, this.getCountryData(), e);
    });
  }

  handleInputClick = (e) => {
    this.setState({isDropdownVisible: false});
    if (this.props.onClick) this.props.onClick(e, this.getCountryData());
  }

  handleDoubleClick = (e) => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  }

  handleFlagItemClick = (country, e) => {
    const currentSelectedCountry = this.state.selectedCountry;
    const newSelectedCountry = this.state.onlyCountries.find(o => o === country);
    if (!newSelectedCountry) return;
    const unformattedNumber = this.state.formattedNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
    const newNumber = unformattedNumber.length > 1 ? unformattedNumber.replace(currentSelectedCountry.dialCode, newSelectedCountry.dialCode) : newSelectedCountry.dialCode;
    const formattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), newSelectedCountry);
    this.setState({
      formattedNumber,
      selectedCountry: newSelectedCountry,
      isSelectionFreezed: true,
      isDropdownVisible: false,
    }, () => {
      this.cursorToEnd();
      if (this.props.onChange) this.props.onChange(formattedNumber, this.getCountryData(), e);
    });
  }

  handleInputFocus = (e) => {
    // if the input is blank, insert dial code of the selected country
    if (this.inputRef) {
      if (this.inputRef.value === this.props.prefix && this.state.selectedCountry && !this.props.disableCountryCode) {
        this.setState({
          formattedNumber: this.props.prefix + this.state.selectedCountry.dialCode
        }, () => {this.props.jumpCursorToEnd && setTimeout(this.cursorToEnd, 0)});
      }
    }
    this.setState({placeholder: ''});
    this.props.onFocus && this.props.onFocus(e, this.getCountryData());
    this.props.jumpCursorToEnd && setTimeout(this.cursorToEnd, 0);
  }

  handleInputBlur = (e) => {
    if (!e.target.value) this.setState({placeholder: this.props.placeholder});
    this.props.onBlur && this.props.onBlur(e, this.getCountryData());
  }

  getHighlightCountryIndex = (direction) => {
    // had to write own function because underscore does not have findIndex. lodash has it
    const highlightCountryIndex = this.state.highlightCountryIndex + direction;
    if (highlightCountryIndex < 0 || highlightCountryIndex >= (this.state.onlyCountries.length + this.state.preferredCountries.length)) {
      return highlightCountryIndex - direction;
    }
    if (this.props.hasSearchBox && highlightCountryIndex > this.getSearchFilteredCountries().length) return 0; // select first country
    return highlightCountryIndex;
  }

  searchCountry = () => {
    const probableCandidate = this.getProbableCandidate(this.state.queryString) || this.state.onlyCountries[0];
    const probableCandidateIndex = this.state.onlyCountries.findIndex(o => o === probableCandidate) + this.state.preferredCountries.length;
    this.scrollTo(this.getElement(probableCandidateIndex), true);
    this.setState({queryString: '', highlightCountryIndex: probableCandidateIndex});
  }

  handleKeydown = (e) => {
    const {keys} = this.props;
    const {target: {className}} = e;
    if (className.includes(styles.dropdownButton) && e.which === keys.ENTER && !this.state.isDropdownVisible) return this.handleFlagDropdownClick(e);
    if (className.includes(styles.inputControl) && (e.which === keys.ENTER || e.which === keys.ESC)) return e.target.blur();
    if (!this.state.isDropdownVisible || this.props.isDisabled) return;
    if (className.includes(styles.searchBox)) {
      if (e.which !== keys.UP && e.which !== keys.DOWN && e.which !== keys.ENTER) {
        if (e.which === keys.ESC && e.target.value === '') {
          // do nothing // if search field is empty, pass event (close dropdown)
        } else {
          return; // don't process other events coming from the search field
        }
      }
    }
    // ie hack
    if (e.preventDefault) {e.preventDefault(); }
    else {e.returnValue = false;}
    const moveHighlight = (direction) => {
      this.setState({
        highlightCountryIndex: this.getHighlightCountryIndex(direction)
      }, () => {
        this.scrollTo(this.getElement(this.state.highlightCountryIndex), true);
      });
    }
    switch (e.which) {
      case keys.DOWN:
        moveHighlight(1);
        break;
      case keys.UP:
        moveHighlight(-1);
        break;
      case keys.ENTER:
        if (this.props.hasSearchBox) {
          this.handleFlagItemClick(this.getSearchFilteredCountries()[this.state.highlightCountryIndex] || this.getSearchFilteredCountries()[0], e);
        } else {
          this.handleFlagItemClick([...this.state.preferredCountries, ...this.state.onlyCountries][this.state.highlightCountryIndex], e);
        }
        break;
      case keys.ESC:
      case keys.TAB:
        this.setState({
          isDropdownVisible: false
        }, this.cursorToEnd);
        break;
      default:
        if ((e.which >= keys.A && e.which <= keys.Z) || e.which === keys.SPACE) {
          this.setState({
            queryString: this.state.queryString + String.fromCharCode(e.which)
          }, this.state.debouncedQueryStingSearcher);
        }
    }
  }

  handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.state.isDropdownVisible && this.setState({isDropdownVisible: false});
    }
  }

  handleSearchChange = (e) => {
    const {currentTarget: {value: searchValue}} = e;
    const {preferredCountries, selectedCountry} = this.state
    let highlightCountryIndex = 0;
    if (searchValue === '' && selectedCountry) {
      const {onlyCountries} = this.state
      highlightCountryIndex = preferredCountries.concat(onlyCountries).findIndex(o => o === selectedCountry);
      // wait asynchronous search results re-render, then scroll
      setTimeout(() => this.scrollTo(this.getElement(highlightCountryIndex)), 100)
    }
    this.setState({searchValue, highlightCountryIndex});
  }

  processFormattedNumber = () => {
    const {formattedNumber = ''} = this.state;
    const country = this.getCountryData();
    const {dialCode = '', countryCode:pnCountryCode = ''} = country;
    const pn = PhoneNumber(formattedNumber.replace(/[^0-9]+/g,''), pnCountryCode);
    const pnNumber = pn.getNumber('e164').replace(/[^0-9]+/g,''); // беремо повний формат номера (юзер міг ввести короткий внутрішній формат)
    const pnFormattedNumber = this.formatNumber(pnNumber, country); // форматуємо цей повний номер (додаємо дужки)
    const pnType = encodePhoneType(pn.getType());
    // стираємо поточний номер (готовність для наступного номера)
    this.setState({
      formattedNumber: this.props.prefix + dialCode,
      isFormattedNumberValid: false
    });
    setTimeout(() => document.getElementById("pn-input").focus(), 300); // FixMe: поки що так (чомусь не виходить повернути фокус через this.inputRef) !!!
    return {pnNumber, pnFormattedNumber, pnCountryCode, pnType};
  }

  handleInputKeyDown = (e) => {
    const {keys, onAddButton, onKeyDown} = this.props;
    if (e.which === keys.ENTER) {
      this.state.isFormattedNumberValid && onAddButton && onAddButton(this.processFormattedNumber());
    }
    if (onKeyDown) onKeyDown(e);
  }

  handleAddPhone = (e) => {
    const {onAddButton} = this.props;
    this.state.isFormattedNumberValid && onAddButton && onAddButton(this.processFormattedNumber());
  }

  getDropdownCountryName = (country) => {
    return country.localName || country.name;
  }

  getSearchFilteredCountries = () => {
    const {preferredCountries, onlyCountries, searchValue} = this.state
    const {hasSearchBox} = this.props
    const allCountries = preferredCountries.concat(onlyCountries);
    const sanitizedSearchValue = searchValue.trim().toLowerCase();
    if (hasSearchBox && sanitizedSearchValue) {
      // [...new Set()] to get rid of duplicates
      // firstly search by iso2 code
      if (/^\d+$/.test(sanitizedSearchValue)) { // contains digits only
        // values wrapped in ${} to prevent undefined
        return allCountries.filter(({dialCode}) =>
          [`${dialCode}`].some(field => field.toLowerCase().includes(sanitizedSearchValue)))
      } else {
        const iso2countries = allCountries.filter(({iso2}) =>
          [`${iso2}`].some(field => field.toLowerCase().includes(sanitizedSearchValue)))
        // || '' - is a fix to prevent search of 'undefined' strings
        // Since all the other values shouldn't be undefined, this fix was accepte
        // but the structure do not looks very good
        const searchedCountries = allCountries.filter(({name, localName, iso2}) =>
          [`${name}`, `${localName || ''}`].some(field => field.toLowerCase().includes(sanitizedSearchValue)))
        this.scrollToTop()
        return [...new Set([].concat(iso2countries, searchedCountries))]
      }
    } else {
      return allCountries
    }
  }

  getDropdownList = () => {
    const {prefix, hasDropdown, hasSearchBox, searchNotFound, searchPlaceholder} = this.props;
    const {searchValue, preferredCountries, highlightCountryIndex, isDropdownVisible} = this.state;
    const searchedCountries = this.getSearchFilteredCountries();
    let countryDropdownList = searchedCountries.map((country, index) => {
      const highlight = highlightCountryIndex === index;
      const itemClasses = classnames({
        [styles.countryItem]: true,
        [styles.preferred]: country.iso2 === 'us' || country.iso2 === 'gb',
        [styles.active]: country.iso2 === 'us',
        [styles.highlight]: highlight
      });
      return (
        <li className={itemClasses}
            ref={elem => this[`flag_no_${index}`] = elem}
            key={`flag_no_${index}`}
            data-flag-key={`flag_no_${index}`}
            data-dial-code='1'
            tabIndex={!hasDropdown ? '-1' : '0'}
            data-country-code={country.iso2}
            onClick={(e) => this.handleFlagItemClick(country, e)}
            role='option'
            {... highlight ? {"aria-selected": true} : {}}
        >
          <div className={classnames(styles.flag, {[country.iso2]: true})}/>
          <span className={styles.countryName}>{this.getDropdownCountryName(country)}</span>
          <span className={styles.dialCode}>{country.format ? this.formatNumber(country.dialCode, country) : (prefix + country.dialCode)}</span>
        </li>
      );
    });
    const dashedLi = (<li key={'dashes'} className={styles.dividerItem} />);
    // let's insert a dashed line in between preferred countries and the rest
    (preferredCountries.length > 0) && (!hasSearchBox || hasSearchBox && !searchValue.trim()) &&
    countryDropdownList.splice(preferredCountries.length, 0, dashedLi);
    return (
      <ul className={classnames(styles.countryList, {[styles.hide]: !isDropdownVisible})}
          ref={elem => {
            !hasSearchBox && elem && elem.focus();
            return (this.dropdownRef = elem);
          }}
          role='listbox'
          tabIndex='0'
      >
        {hasSearchBox && (
          <li className={styles.search}>
            <input
              className={styles.searchBox}
              type='search'
              placeholder={searchPlaceholder}
              autoFocus={true}
              autoComplete={'off'}
              value={searchValue}
              onChange={this.handleSearchChange}
            />
          </li>
        )}
        {countryDropdownList.length > 0
          ? countryDropdownList
          : (
            <li className={styles.noEntriesMessage}>
              <span>{searchNotFound}</span>
            </li>
          )}
      </ul>
    );
  }

  render() {
    const {label, error, placeholder, isDisabled, isRequired, hasDropdown, onAddButton} = this.props;
    const {formattedNumber, selectedCountry, isFormattedNumberValid, isDropdownVisible} = this.state;
    const isAddButtonActive = isFormattedNumberValid && !!onAddButton;
    return (
      <div className={classnames(styles.PhoneField, {[styles.hasError]: error})}
           onKeyDown={this.handleKeydown}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <div className={styles.inputWrapper}>
          <input
            id="pn-input"
            className={classnames(styles.inputControl, {[styles.open]: isDropdownVisible})}
            ref={elem => this.inputRef = elem}
            value={formattedNumber}
            placeholder={isDisabled ? '' : placeholder}
            type='tel'
            autoComplete='off'
            autoCorrect='off'
            onChange={this.handleChange}
            onClick={this.handleInputClick}
            onDoubleClick={this.handleDoubleClick}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            onKeyDown={this.handleInputKeyDown}
            disabled={isDisabled}
          />
          <div className={classnames(styles.countryDropdown, {[styles.open]: isDropdownVisible})}
               ref={elem => this.dropdownRef = elem}>
            <div
              className={classnames(styles.dropdownButton, {[styles.open]: isDropdownVisible})}
              title={selectedCountry ? `${selectedCountry.name}: +${selectedCountry.dialCode}` : ''}
              tabIndex={!hasDropdown ? '-1' : '0'}
              onClick={!hasDropdown ? undefined : this.handleFlagDropdownClick}
              role='button'
              aria-haspopup={'listbox'}
              aria-expanded={isDropdownVisible ? true : undefined}>
              <div className={classnames(styles.flag, {[selectedCountry.iso2]: !!selectedCountry})}></div>
              {hasDropdown &&
                <div className={classnames(styles.arrow, {[styles.up]: isDropdownVisible})}></div>
              }
            </div>
            {isDropdownVisible &&
              this.getDropdownList()
            }
          </div>
          <div className={classnames(styles.addButton, {[styles.isActive]: isAddButtonActive})}
               onClick={isAddButtonActive ? this.handleAddPhone : null}>
            <Icon symbolName="plus" className={styles.icon} />
          </div>
        </div>
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
