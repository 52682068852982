// -------------------------------------------------------------------------------------------------
//  BitmaskField.js
//  - - - - - - - - - -
//  Компонента редагування бітів поля colorBits.
//  Вибіркове відображення бітів що редагуються задається в visiMask (по-замовчуванню 6шт).
//  максимальна к-сть тегів - 15шт.
//  Імплементовано два режими роботи: "filter" та "editor".
//  В режимі редактора зміна бітів - це просте інвертування.
//  В режимі фільтру зміна бітів виконується за логікою, яка описана у функції зміни.
//
//  Manual about bit operations:
//  https://medium.com/@parkerjmed/practical-bit-manipulation-in-javascript-bfd9ef6d6c30
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {
  BIT01, BIT02, BIT03, BIT04, BIT05,
  BIT06, BIT07, BIT08, BIT09, BIT10,
  BIT11, BIT12, BIT13, BIT14, BIT15, NON01} from 'core/bitmaskTypes';
import styles from './BitmaskField.scss';

const symbolPrefix = 'common-icon';       // same prefix must be set in 'reset.scss' in SVG/Icons section !!!

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const BitItem = ({symbolName, className, onClick}) => (
  <svg className={classnames(`${symbolPrefix} ${symbolPrefix}-${symbolName}`, styles.bitItem, className)}
       onClick={onClick}>
    <use xlinkHref={`#${symbolPrefix}-${symbolName}`} />
  </svg>
);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class BitmaskField extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    mode: Types.string,                   // mode = ["filter", "editor"]
    value: Types.number,                  // current bitmask value
    visiMask: Types.number,               // visibility mask to display items
    getSymbolName1: Types.func,           // callback which returns symbolName for Bit01
    getSymbolNameX: Types.func,           // callback which returns symbolName for Bit02..15
    getTitle1: Types.func,                // callback which returns title for Bit01
    labelText: Types.string,              // текст напису (зазвичай к-сть)
    onChange: Types.func,                 // callback on any change
  }

  static defaultProps = {
    mode: "filter",
    visiMask: BIT01 | BIT02 | BIT03 | BIT04 | BIT05 | BIT06 // визначаємо які біти показувати
  }

  curryBit = (f) => {
    return function(carriedBit) {
      return function() {
        return f(carriedBit);
      };
    };
  }

  //  Attn:
  // - - - - -
  // Режим "filter":
  //  1) якщо змінюємо перший біт --> скидаємо решту бітів в 0;
  //  2) якщо змінюємо біти 2..15 --> скидаємо перший біт в 0 (операція;
  //  3) якщо змінили останній біт 2..15 в 0 --> то перший виставляємо в 1 (фільтр: тільки активні);
  // - - - - -
  // Режим "editor":
  //  4) інветуємо відповідний біт.
  // - - - - -
  changeBitmask = (carriedBit) => {
    const {value, mode, onChange} = this.props;
    let nextBitmask;
    if (mode === "filter") {
      nextBitmask = (carriedBit === BIT01) ? (value & BIT01) ^ BIT01 : // attn: 1)
        ((value & carriedBit) > 1 ? value & (value ^ carriedBit) : value | carriedBit) & NON01; // attn: 2)
      nextBitmask = (carriedBit !== BIT01) && nextBitmask === 0 ? BIT01 : nextBitmask; // attn: 3)
    } else {
      nextBitmask = (value & carriedBit) > 0 ? value & (value ^ carriedBit) : value | carriedBit; // attn: 4)
    }
    onChange && onChange(nextBitmask);
  }

  render() {
    const {className, value, visiMask, getSymbolName1, getSymbolNameX, getTitle1, labelText} = this.props;
    return (
      <div className={classnames(styles.BitmaskField, className)}>
        <span>{labelText}</span>
        {(visiMask & BIT01) > 0 &&
          <div title={getTitle1(value & BIT01)}>
            <BitItem symbolName={getSymbolName1((value & BIT01) > 0)} className={styles.bit01}
                     onClick={this.curryBit(this.changeBitmask)(BIT01)} />
          </div>
        }
        <section>
          {(visiMask & BIT02) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT02) > 0)} className={styles.bit02}
                     onClick={this.curryBit(this.changeBitmask)(BIT02)} />
          }
          {(visiMask & BIT03) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT03) > 0)} className={styles.bit03}
                     onClick={this.curryBit(this.changeBitmask)(BIT03)} />
          }
          {(visiMask & BIT04) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT04) > 0)} className={styles.bit04}
                     onClick={this.curryBit(this.changeBitmask)(BIT04)} />
          }
          {(visiMask & BIT05) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT05) > 0)} className={styles.bit05}
                     onClick={this.curryBit(this.changeBitmask)(BIT05)} />
          }
          {(visiMask & BIT06) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT06) > 0)} className={styles.bit06}
                     onClick={this.curryBit(this.changeBitmask)(BIT06)} />
          }
          {(visiMask & BIT07) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT07) > 0)} className={styles.bit07}
                     onClick={this.curryBit(this.changeBitmask)(BIT07)} />
          }
          {(visiMask & BIT08) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT08) > 0)} className={styles.bit08}
                     onClick={this.curryBit(this.changeBitmask)(BIT08)} />
          }
          {(visiMask & BIT09) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT09) > 0)} className={styles.bit09}
                     onClick={this.curryBit(this.changeBitmask)(BIT09)} />
          }
          {(visiMask & BIT10) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT10) > 0)} className={styles.bit10}
                     onClick={this.curryBit(this.changeBitmask)(BIT10)} />
          }
          {(visiMask & BIT11) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT11) > 0)} className={styles.bit11}
                     onClick={this.curryBit(this.changeBitmask)(BIT11)} />
          }
          {(visiMask & BIT12) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT12) > 0)} className={styles.bit12}
                     onClick={this.curryBit(this.changeBitmask)(BIT12)} />
          }
          {(visiMask & BIT13) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT13) > 0)} className={styles.bit13}
                     onClick={this.curryBit(this.changeBitmask)(BIT13)} />
          }
          {(visiMask & BIT14) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT14) > 0)} className={styles.bit14}
                     onClick={this.curryBit(this.changeBitmask)(BIT14)} />
          }
          {(visiMask & BIT15) > 0 &&
            <BitItem symbolName={getSymbolNameX((value & BIT15) > 0)} className={styles.bit15}
                     onClick={this.curryBit(this.changeBitmask)(BIT15)} />
          }
        </section>
      </div>
    );
  }
}
