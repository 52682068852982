// -------------------------------------------------------------------------------------------------
//  settingsTools.js
//  - - - - - - - - - -
//  Інструменти для роботи з налаштуваннями.
// -------------------------------------------------------------------------------------------------
import {LS_LOCALE} from 'core/commonTypes';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import {videos as settings} from 'settings/local.yaml';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getSettingsVideoUrl(videoSlug) {
  const locale = localStorage.getItem(LS_LOCALE) || ENGLISH_LANG;
  const {[videoSlug]:videoBySlug} = settings;
  return videoBySlug[locale] || '';
}

//  --> /10/sa/fl/pk/ev/p/1OSAFlPKev01pmAYOFqmePBLK-UHxmYjeASB-a-m.jpeg
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function composeAvatarUrl(xhash2, size, ext) {
  return (CDN_ROOT +
    xhash2.slice(0,2) + '/' +
    xhash2.slice(2,4) + '/' +
    xhash2.slice(4,6) + '/' +
    xhash2.slice(6,8) + '/' +
    xhash2.slice(8,10) + '/' +
    xhash2.slice(12,13) + '/').toLowerCase() + xhash2 + '-a-' + size + '.' + ext;
}

//  Attn:
//  '-p-*0.' <-- суфікс оригіналу зображення
//  '-p-*b.' <-- суфікс заблуреного зображення
//
//  --> /10/sa/fl/pk/ev/p/1OSAFlPKev01pmAYOFqmePBLK-UHxmYjeASB-p-x0.jpeg
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function composePictureUrl(xhash2, sizeAndType, ext) {
  return (CDN_ROOT +
    xhash2.slice(0,2) + '/' +
    xhash2.slice(2,4) + '/' +
    xhash2.slice(4,6) + '/' +
    xhash2.slice(6,8) + '/' +
    xhash2.slice(8,10) + '/' +
    xhash2.slice(12,13) + '/').toLowerCase() + xhash2 + '-p-' + sizeAndType + '.' + ext;
}

//  Attn:
//  '-d-0.' <-- суфікс оригіналу документу
//  '-d-b.' <-- суфікс семплу документу (шматок тексту чи заблурений текст для ознайомлення)
//
//  --> /10/sa/fl/pk/ev/p/1OSAFlPKev01pmAYOFqmePBLK-UHxmYjeASB-d-0.pdf
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function composeDocumentUrl(xhash2, ext) {
  return (CDN_ROOT +
    xhash2.slice(0,2) + '/' +
    xhash2.slice(2,4) + '/' +
    xhash2.slice(4,6) + '/' +
    xhash2.slice(6,8) + '/' +
    xhash2.slice(8,10) + '/' +
    xhash2.slice(12,13) + '/').toLowerCase() + xhash2 + '-d-0' + '.' + ext;
}
