// -------------------------------------------------------------------------------------------------
//  HttpAPI.js
//  - - - - - - - - - -
//  https://learn.javascript.ru/fetch
//  https://learn.javascript.ru/xmlhttprequest
//
//  Attn:
//  - - - - -
//  - HTTPS_API_URL задається в webpack.DefinePlugin;
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на http*;
// -------------------------------------------------------------------------------------------------
import urlJoin from 'url-join';
import Dispatcher from 'dispatcher/Dispatcher';
import {LOGGED_OUT_ACTION} from 'core/actionTypes';
import {resetServerTime} from 'utils/systemTools';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
async function parseResponse(response) {
  try {
    return await response.json();
  } catch(error) {
    console.log(error);
    return null;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function httpFetchXHR(path, options) {
  const xhr = new XMLHttpRequest();
  const url = urlJoin(HTTPS_API_URL, path);
  xhr.open((options.method || 'GET').toUpperCase(), url, true);
  const token = localStorage.getItem('authToken');
  if (token) {
    xhr.setRequestHeader('x-auth-token', token);
  }
  Object.keys(options.headers || {}).forEach(header =>
    xhr.setRequestHeader(header, options.headers[header])
  );
  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = (event) => {
      if (xhr.readyState === 4) {
        try {
          const json = JSON.parse(xhr.responseText);
          resolve(json);
        } catch(error) {
          resolve(xhr.responseText);
        }
      }
    };
    xhr.onerror = (error) => {
      reject(error);
    };
    if (options.onUploadProgress) {
      xhr.upload.onprogress = options.onUploadProgress;
    }
    xhr.send(options.body);
  });
}

//  HTTP-запит до бекенду
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FixMe: '403 Error: disallowed_useragent' --> options.headers['User-Agent'] = 'Mozilla/5.0+ (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2228.0 Safari/537.36';
//
export async function httpFetch(path, options = {}) {
  if (options.onUploadProgress) {
    return httpFetchXHR(path, options);
  }
  const url = urlJoin(HTTPS_API_URL, path);
  const token = localStorage.getItem('authToken');
  if (token) {
    options.headers = options.headers || {};
    options.headers['x-auth-token'] = token;
  }
  const response = await window.fetch(url, options).catch (error => {
    console.error(error.message);
    throw error;
  });
  const data = await parseResponse(response);
  if (response.headers && response.headers.get('Date')) {
    resetServerTime(response.headers.get('Date'));
  }
  if (response.status === 401) {
    Dispatcher.dispatch({
      type: LOGGED_OUT_ACTION
    });
  }
  if (!response.ok) {
    throw data || response;
  }
  return data;
}

//  HTTP-запит до бекенду з повтором
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function httpFetchRetry(path, delay, limit, fetchOptions = {}) {
  return new Promise((resolve, reject) => {
    function success(response) {
      resolve(response);
    }
    function failure(error) {
      limit--;
      if (limit){
        setTimeout(fetchUrl, delay)
      } else {
        // - - - stop retrying
        reject(error);
      }
    }
    function finalHandler(finalError) {
      throw finalError;
    }
    function fetchUrl() {
      return httpFetch(path, fetchOptions)
        .then(success)
        .catch(failure)
        .catch(finalHandler);
    }
    fetchUrl();
  });
}

//  HTTP-запит до зовнішнього ресурсу
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function httpRequest(path, options = {}) {
  const response = await window.fetch(path, options).catch (error => {
    console.error(error.message);
    throw error;
  });
  const data = await parseResponse(response);
  if (!response.ok) {
    throw data || response;
  }
  return data;
}
