// -------------------------------------------------------------------------------------------------
//  RootNavbar.js
//  - - - - - - - - - -
//  Для компоненту існують два набори props: "initialProps" та "extraProps".
//  Параметри "extraProps" задаються через компоненту <PageNavbar .../> та мають приорітет
//  над початковими параметрами. При заданні props через <PageNavbar .../> нові параметри додаємо
//  до списку propsMap, а при видаленні відновлюємо попередній набір параметрів.
// -------------------------------------------------------------------------------------------------
// FixMe: переробити RootNavbar/upperPanel, бо скролиться на Safari !!!

import React from 'react';
import Types from 'prop-types';
import {OrderedMap} from 'immutable';
import PubSub from 'utils/PubSub';
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';

const isVerbose = DEBUG && true;
const prefix = '- - - RootNavbar';

const propsMap = new OrderedMap().asMutable();
const pubsub = new PubSub();

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

function setProps(key, nextProps) {
  propsMap.set(key, nextProps);
  pubsub.publish(propsMap.last());
}

function removeProps(key) {
  propsMap.remove(key);
  pubsub.publish(propsMap.last() || {});
}

export default class RootNavbar extends React.Component {
  static setProps = setProps;
  static removeProps = removeProps;

  static propTypes = {
    myAccount: Types.object,              // акаунт користувача
    isLoggedIn: Types.bool,               // is current user logged in?
    isMobile: Types.bool,                 // is an app running in mobile layout?
    isSidebarOpen: Types.bool,            // is sidebar open?
    fsrCount: Types.string,               // к-сть отриманих запитів дружби
    topicUnreadCount: Types.string,       // к-сть непрочитаних повідомлень для тематичних чатів
    communityUnreadCount: Types.string,   // к-сть непрочитаних повідомлень для прямих чатів
    onToggleSidebar: Types.func,          // callback called when the overlay is clicked
  }

  static defaultProps = {
    isLoggedIn: false,
    onToggleSidebar: () => {},
  }

  state = {
    extraProps: {},                       // додаткові props, щр задаються через PageNavbar
  }

  // ToDo: !!!
  // shouldComponentUpdate(nextProps, nextState) {
  //   const result = this.state.sidebarWidth !== nextState.sidebarWidth
  //     || this.props.isOpen !== nextProps.isOpen
  //     || this.props.isDocked !== nextProps.isDocked
  //     || this.props.isMobile !== nextProps.isMobile
  //     || this.props.location.pathname !== nextProps.location.pathname
  //     || !is(this.state.extraProps, nextState.extraProps);
  //   trace(`shouldComponentUpdate: ${result}`);
  //   return result;
  // }

  componentDidMount() {
    pubsub.subscribe((extraProps) => this.setState({extraProps}));
  }

  render() {
    const {
      myAccount,
      isLoggedIn,
      isMobile,
      isSidebarOpen,
      fsrCount,
      topicUnreadCount,
      communityUnreadCount,
      onToggleSidebar} = this.props;
    const {extraProps} = this.state;
    const NavbarToRender = isMobile ? MobileNavbar : DesktopNavbar;
    // attn: {...extraProps} should be last to reload props of the component !!!
    trace(`render`);
    return (
      <NavbarToRender
        myAccount={myAccount}
        isLoggedIn={isLoggedIn}
        isSidebarOpen={isSidebarOpen}
        fsrCount={fsrCount}
        topicUnreadCount={topicUnreadCount}
        communityUnreadCount={communityUnreadCount}
        onToggleSidebar={onToggleSidebar}
        {...extraProps}
      />
    );
  }
}
