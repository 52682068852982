// -------------------------------------------------------------------------------------------------
//  systemLimits.js
//  - - - - - - - - - -
//  Системні обмеження, що встановленні в проекті.
// -------------------------------------------------------------------------------------------------

// advert limits:
export const MAX_ADVERT_NAME_LEN                    = 96;       // максимальна довжина назви оголошення
export const MAX_ADVERT_BUSINESSHOURS_LEN           = 140;      // максимальна довжина опису годин роботи в оголошенні
export const MAX_ADVERT_PRICERANGE_LEN              = 140;      // максимальна довжина опису цін оголошення
export const MAX_ADVERT_SUBJECTS_QTY                = 3;        // максимальна к-сть предметів в оголошенні

// advert feedback limits:
export const MAX_FEEDBACK_NAME_LEN                  = 48;       // максимальна довжина назви форми запиту
export const MAX_FEEDBACK_PHONE_LEN                 = 32;       // максимальна довжина телефону у формі запиту
export const MAX_FEEDBACK_DESCRIPTION_LEN           = 140;      // максимальна довжина тексту запиту

// collection limits:
export const MAX_COLLECTION_SUBJECTS_QTY            = 3;        // максимальна к-сть предметів в колекції
export const MAX_COLLECTION_CATEGORIES_QTY          = 3;        // максимальна к-сть категорій в колекції

// ai limits:
export const MAX_PROMPT_LEN                         = 512;      // максимальна допустима довжина тексту промпту
