// -------------------------------------------------------------------------------------------------
//  CreateAdvertContainer.js
//  - - - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import AccountStore from 'stores/AccountStore';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const CreateAdvert = Loader.create(() => import('./CreateAdvert'));
const isVerbose = DEBUG && true;
const prefix = '- - - CreateAdvertContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CreateAdvertContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const subjects = SystemStore.getSubjectList();
    return {isLoggedIn, myAccountType, myId, subjects};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.subjects.size !== nextState.subjects.size
      || !is(this.state.subjects, nextState.subjects);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  render() {
    trace(`render`);
    const {myAccountType, myId, subjects} = this.state;
    return (
      <CreateAdvert
        myAccountType={myAccountType}
        myId={myId}
        subjects={subjects}
      />
    );
  }
}

export default Container.create(CreateAdvertContainer, {pure: false});
