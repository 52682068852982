// -------------------------------------------------------------------------------------------------
//  DiscoverCollectionsContainer.js
//  - - - - - - - - - - - - - -
//  Контейнер ознайомчої стрічки курс-колекцій (URL: '/discover').
//
//  Attn:
//  - - - - -
//  - функція getDiscoverCollectionsFeedByType віддає колекції тільки ДО курсора, щоб не було "пропусків" в фіді;
//
//  Attn: a)
//  - - - - -
//  - колекції різних типів не показуються ОДНОЧАСНО;
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається feedType та яке API буде застосовано;
//  - для даного фіду тип колекції DISCOVER_COLLECTIONS_FEED не підтримується, тому === undefined;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR, DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {SUBJECT_TAB, DISCOVER_COURSES_FEED} from 'core/uiTypes';
import {fetchDiscoverCollectionsAsGuest, fetchDiscoverCollections} from 'actions/CollectionActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import {fetchSystemSubjectsAsGuest, fetchSystemSubjects} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const DiscoverCollections = Loader.create(() => import('./DiscoverCollections'));
const isVerbose = DEBUG && true;
const prefix = '- - - DiscoverCollectionsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class DiscoverCollectionsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, SystemStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const feedType = collectionType === COURSE_COLLECTION ? DISCOVER_COURSES_FEED : undefined; // attn: a) !!!
    const {[feedType]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const {collections, collectionCursor, areCollectionsLoaded} = CollectionStore.getDiscoverCollectionsFeedByType(collectionType);
    const owners = UserStore.getSomeUsersMap(collections.reduce((a, c) => a.add(c.ownerId), Set().add(myId)));
    const allSubjects = SystemStore.getSubjectList();
    const areSubjectsLoaded = SystemStore.areSubjectsLoaded();
    return {isLoggedIn, collectionType, myAccountType, myId, owners, collections, allSubjects,
      feedType, viewMode, collectionCursor, areCollectionsLoaded, areSubjectsLoaded};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.viewMode !== nextState.viewMode
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.collectionCursor !== nextState.collectionCursor
      || this.state.areCollectionsLoaded !== nextState.areCollectionsLoaded
      || this.state.areSubjectsLoaded !== nextState.areSubjectsLoaded
      || this.state.collectionType !== nextState.collectionType
      || this.state.feedType !== nextState.feedType
      || this.state.collections.size !== nextState.collections.size
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || this.state.owners.size !== nextState.owners.size
      || !is(this.state.collections, nextState.collections)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn} = this.state;
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        fetchSystemSubjects() :
        fetchSystemSubjectsAsGuest();
    }
    // CTX:
    if (isLoggedIn) {
      substituteSidebarTabMode(SUBJECT_TAB);
      substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const p1 = nextProps.history.location.pathname;
    const p2 = nextProps.location.pathname;
    if (p1 && p1 === p2) {
      const {isLoggedIn} = this.state;
      // CTX:
      if (isLoggedIn) {
        substituteSidebarTabMode(SUBJECT_TAB);
        substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      }
    }
  }

  isFetchingDiscoverCollections = false;

  fetchDiscoverCollectionPage = async () => {
    trace(`fetchDiscoverCollectionPage(1): isFetching=${this.isFetchingDiscoverCollections}`);
    if (!this.isFetchingDiscoverCollections) {
      const {isLoggedIn, collectionType, collectionCursor = DEFAULT_TSN12_CURSOR} = this.state;
      trace(`fetchDiscoverCollectionPage(2): cursor='${collectionCursor}'`);
      this.isFetchingDiscoverCollections = true;
      isLoggedIn ?
        await fetchDiscoverCollections({type: collectionType, cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT}) :
        await fetchDiscoverCollectionsAsGuest({type: collectionType, cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT});
      this.isFetchingDiscoverCollections = false;
    }
  }

  render() {
    const {
      isLoggedIn,
      myAccountType,
      myId,
      owners,
      collections,
      allSubjects,
      feedType,
      viewMode,
      areCollectionsLoaded} = this.state;
    if (!feedType) {
      return null;
    }
    trace(`render`);
    return (
      <DiscoverCollections
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        collections={collections}
        allSubjects={allSubjects}
        feedType={feedType}
        viewMode={viewMode}
        areCollectionsLoaded={areCollectionsLoaded}
        onPageTrigger={this.fetchDiscoverCollectionPage}
      />
    );
  }
}

export default Container.create(DiscoverCollectionsContainer, {withProps: true, pure: false});
