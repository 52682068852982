// -------------------------------------------------------------------------------------------------
//  UserActions.js
//  - - - - - - - - - -
//  Other users actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' / catch;
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  USERS_WERE_FETCHED_ACTION,
  USER_PROFILES_WERE_FETCHED_ACTION,
  MY_FRIENDS_WERE_FETCHED_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_TO_CONTACTS_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION,
  FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
  FRIENDSHIP_WAS_CANCELED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  EMAILS_FLD,
  FRIENDS_FLD,
  FRIENDSHIP_REQUESTS_IN_FLD,
  FRIENDSHIP_REQUESTS_OUT_FLD,
  DIRECT_USERS_FLD,
  CONTACT_IDS_FLD,
  USER_FLD,
  USERS_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchUsers,
  apiFetchUsersAsGuest,
  apiFetchUsersBySlugs,
  apiFetchUsersBySlugsAsGuest,
  apiFetchUserProfiles,
  apiFetchUserProfilesAsGuest,
  apiFetchUserProfilesBySlugs,
  apiFetchUserProfilesBySlugsAsGuest,
  apiFetchMyFriends,
  apiSendFriendshipRequest,
  apiSendFriendshipRequestByEmail,
  apiSendFriendshipRequestAgain,
  apiSendFriendshipRequestToContacts,
  apiAcceptFriendshipRequest,
  apiRejectFriendshipRequest,
  apiTerminateFriendshipRequest,
  apiCancelFriendship} from 'api/UserAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - UserActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let areMyFriendsFetched = false;          // чи викликали завантаження списку друзів (в т.ч. чатів з ними)?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUsers(ids) {
  trace(`fetchUsers: ids=${ids}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUsers(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUsers: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USERS_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUsers: ids=${ids}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUsersAsGuest(ids) {
  trace(`fetchUsersAsGuest: ids=${ids}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUsersAsGuest(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUsersAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USERS_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUsersAsGuest: ids=${ids}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUsersBySlugs(slugs) {
  trace(`fetchUsersBySlugs: slugs=${slugs}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUsersBySlugs(slugs);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUsersBySlugs: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USERS_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUsersBySlugs: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUsersBySlugsAsGuest(slugs) {
  trace(`fetchUsersBySlugsAsGuest: slugs=${slugs}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUsersBySlugsAsGuest(slugs);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUsersBySlugsAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USERS_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUsersBySlugsAsGuest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserProfiles(ids) {
  trace(`fetchUserProfiles: ids=${ids}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUserProfiles(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUserProfiles: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USER_PROFILES_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUserProfiles: ids=${ids}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserProfilesAsGuest(ids) {
  trace(`fetchUserProfilesAsGuest: ids=${ids}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUserProfilesAsGuest(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUserProfilesAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USER_PROFILES_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUserProfilesAsGuest: ids=${ids}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserProfilesBySlugs(slugs) {
  trace(`fetchUserProfilesBySlugs: slugs=${slugs}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUserProfilesBySlugs(slugs);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUserProfilesBySlugs: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USER_PROFILES_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUserProfilesBySlugs: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserProfilesBySlugsAsGuest(slugs) {
  trace(`fetchUserProfilesBySlugsAsGuest: slugs=${slugs}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUserProfilesBySlugsAsGuest(slugs);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchUserProfilesBySlugsAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[USERS_FLD]:users} = payload;
        if (users && users.length > 0) {
          Dispatcher.dispatch({
            type: USER_PROFILES_WERE_FETCHED_ACTION,
            payload: {
              users: users,
            }
          });
        }
        break;
      default:
        traceError(`fetchUserProfilesBySlugsAsGuest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// 1) Attn: Запит контактів fetchMyContacts() обовʼязково робити ДО запиту юзерів fetchMyFriends() !!!
//    - - - - -
//    Причина: Якщо першим виконується запит юзерів, то в процесі обробки інформація про
//    привʼязаних юзерів заноситься тільки в ІСНУЮЧІ контакти - нові контакти НЕ створюються,
//    немає куди записати і інформація про контакт втрачається (friendshipStatus, ...).
//    Тоді ж як при обробці списку контактів в сторі UserStore створюються юзери для кожного
//    отриманого контакту і заповнюються поля про контакт (contactId, contactName, ...).
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyFriends(isForced = false) {
  trace(`fetchMyFriends`);
  let status;
  let payload;
  let errors = {};

  const fetcher = async () => {
    let resp;
    if (!areMyFriendsFetched || isForced) {
      try {
        areMyFriendsFetched = true;
        resp = await apiFetchMyFriends();
      } catch(error) {
        areMyFriendsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
      switch(status) {
        case 'ok':
          trace(`fetchMyFriends: OK, payload=${JSON.stringify(payload)}`);
          const {
            [FRIENDS_FLD]:friends,
            [FRIENDSHIP_REQUESTS_IN_FLD]:receivedRequests,
            [FRIENDSHIP_REQUESTS_OUT_FLD]:sentRequests,
            [DIRECT_USERS_FLD]:directUsers} = payload;
          Dispatcher.dispatch({
            type: MY_FRIENDS_WERE_FETCHED_ACTION,
            payload: {
              friends,
              sentRequests,
              receivedRequests,
              directUsers,
            }
          });
          break;
        default:
          traceError(`fetchMyFriends: status=${status}`);
          errors.formError = `server_error`;
          break;
      }
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendFriendshipRequest(id) {
  trace(`sendFriendshipRequest: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiSendFriendshipRequest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendFriendshipRequest: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_SENT_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_REQUESTED,
          label: `by-profile`
        });
        break;
      // повторне виконання запиту
      case 'already_requested':
        break;
      default:
        traceError(`sendFriendshipRequest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendFriendshipRequestByEmail(email, description, languageId) {
  trace(`sendFriendshipRequestByEmail: email=${email}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiSendFriendshipRequestByEmail(email, description, languageId);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendFriendshipRequestByEmail: status=${status}, id=${email}`);
        const {[USER_FLD]:user} = payload;
        // attn: якщо юзер зареєстрований в системі, то user !== undefined;
        if (user) {
          Dispatcher.dispatch({
            type: FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
            payload: {
              user: user,
            }
          });
        }
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_REQUESTED,
          label: `by-email`
        });
        break;
      // attn: не показуємо користувачу, чи існує клієнт в системі
      case 'not_found':
      case 'already_accepted':
      case 'already_requested':
      case 'request_on_request':
      case 'own_email_passed_error':
        trace(`sendFriendshipRequestByEmail: status=${status}, id=${email}`);
        break;
      default:
        traceError(`sendFriendshipRequestByEmail: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendFriendshipRequestAgain(id) {
  trace(`sendFriendshipRequestAgain: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiSendFriendshipRequestAgain(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendFriendshipRequestAgain: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_SENT_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_REQUESTED,
          label: `send-again`
        });
        break;
      // повторне виконання запиту
      case 'already_requested':
        break;
      default:
        traceError(`sendFriendshipRequestAgain: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function sendFriendshipRequestToContacts(contactIds, languageId) {
  trace(`sendFriendshipRequestToContacts`);
  let status;
  let payload;
  let errors = {};
  let sentContactsQty = 0;
  try {
    const response = await apiSendFriendshipRequestToContacts(contactIds, languageId);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`sendFriendshipRequestToContacts: OK, payload=${JSON.stringify(payload)}`);
        const {[CONTACT_IDS_FLD]:contactIdsSent} = payload;
        sentContactsQty = (contactIdsSent && contactIdsSent.length) || 0;
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_SENT_TO_CONTACTS_ACTION,
          payload: {
            contactIds: contactIdsSent,
          }
        });
        break;
      default:
        traceError(`sendFriendshipRequestToContacts: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors, sentContactsQty};
  }
  return {status, errors, sentContactsQty};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function acceptFriendshipRequest(id) {
  trace(`acceptFriendshipRequest: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiAcceptFriendshipRequest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`acceptFriendshipRequest: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_ACCEPTED
        });
        break;
      default:
        traceError(`acceptFriendshipRequest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function rejectFriendshipRequest(id) {
  trace(`rejectFriendshipRequest: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiRejectFriendshipRequest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`rejectFriendshipRequest: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_REJECTED
        });
        break;
      default:
        traceError(`rejectFriendshipRequest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function terminateFriendshipRequest(id) {
  trace(`terminateFriendshipRequest: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiTerminateFriendshipRequest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`terminateFriendshipRequest: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_TERMINATED
        });
        break;
      default:
        traceError(`terminateFriendshipRequest: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function cancelFriendship(id) {
  trace(`cancelFriendship: id=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCancelFriendship(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`cancelFriendship: OK, id=${id}`);
        Dispatcher.dispatch({
          type: FRIENDSHIP_WAS_CANCELED_ACTION,
          payload: {
            userId: id,
          }
        });
        ReactGA.event({
          category: gaCategories.FRIENDS,
          action: gaActions.FRIENDSHIP_CANCELED,
          label: id
        });
        break;
      default:
        traceError(`cancelFriendship: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
