// -------------------------------------------------------------------------------------------------
//  AdvertAPI.js
//  - - - - - - - - - -
//  Advert APIs.
//
//  Attn:
//  - - - - -
//  - для subjects потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - параметр cursor або повинен містити значення дати-часу, або бути відсутнім в запиті;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
// -------------------------------------------------------------------------------------------------
import {
  ID_FLD,
  LANGUAGE_ID_FLD,
  OWNER_ID_FLD,
  USER_ID_FLD,
  ALIAS_FLD,
  TYPE_FLD,
  NAME_FLD,
  EMAIL_FLD,
  PHONE_FLD,
  DESCRIPTION_FLD,
  BUSINESS_HOURS_FLD,
  PRICE_RANGE_FLD,
  CREATED_AT_FLD,
  VISITS_COUNT_FLD,
  ADVERT_FLD,
  ADVERT_ID_FLD,
  ADVERT_IDS_FLD,
  SUBJECT_ID_FLD,
  SUBJECTS_FLD,
  CURSOR_FLD,
  LIMIT_FLD,
  ORDER_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_DISCOVER_ADVERTS_AS_GUEST_CMD,
  FETCH_DISCOVER_ADVERTS_CMD,
  FETCH_SUBJECT_ADVERTS_CMD,
  FETCH_USER_ADVERTS_CMD,
  FETCH_ADVERT_AS_GUEST_CMD,
  FETCH_ADVERT_CMD,
  CREATE_ADVERT_CMD,
  UPDATE_ADVERT_CMD,
  DELETE_ADVERT_CMD,
  SEND_ADVERT_FEEDBACK_EMAIL_CMD} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const ADVERTS_PER_REQUEST_LIMIT = 12;     // типова к-сть оголошень за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - AdvertAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverAdvertsAsGuest(opts = {}) {
  trace(`apiFetchDiscoverAdvertsAsGuest`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_DISCOVER_ADVERTS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverAdverts(opts = {}) {
  trace(`apiFetchDiscoverAdverts`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_DISCOVER_ADVERTS_CMD,
    [PAYLOAD]: cursor ? {
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSubjectAdverts(opts = {}) {
  trace(`apiFetchSubjectAdverts`);
  const {
    subjectId,                            // код предмету
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_SUBJECT_ADVERTS_CMD,
    [PAYLOAD]: cursor ? {
      [SUBJECT_ID_FLD]: subjectId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [SUBJECT_ID_FLD]: subjectId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserAdverts(opts = {}) {
  trace(`apiFetchUserAdverts`);
  const {
    userId,                               // код юзера
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = ADVERTS_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_USER_ADVERTS_CMD,
    [PAYLOAD]: cursor ? {
      [USER_ID_FLD]: userId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [USER_ID_FLD]: userId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchAdvertAsGuest(id) {
  trace(`apiFetchAdvertAsGuest`);
  return await httpFetch(FETCH_ADVERT_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [ADVERT_ID_FLD]: id,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchAdvert(id) {
  trace(`apiFetchAdvert: id=${id}`);
  return await wsockFetch({
    [CMD]: FETCH_ADVERT_CMD,
    [PAYLOAD]: {
      [ADVERT_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateAdvert(advert) {
  trace(`apiCreateAdvert`);
  const {
    name = '',
    description = '',
    businessHours = '',
    priceRange = '',
    subjects,
  } = advert;
  return await wsockFetch({
    [CMD]: CREATE_ADVERT_CMD,
    [PAYLOAD]: {
      [ADVERT_FLD]: {
        [NAME_FLD]: name,
        [DESCRIPTION_FLD]: description,
        [BUSINESS_HOURS_FLD]: businessHours,
        [PRICE_RANGE_FLD]: priceRange,
        [SUBJECTS_FLD]: subjects,
      },
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateAdvert(advert, patchObject) {
  trace(`apiUpdateAdvert`);
  const {id} = advert;
  const {
    name = '',
    description = '',
    businessHours = '',
    priceRange = '',
    subjects,
  } = patchObject;
  return await wsockFetch({
    [CMD]: UPDATE_ADVERT_CMD,
    [PAYLOAD]: {
      [ADVERT_FLD]: {
        [ID_FLD]: id,
        [NAME_FLD]: name,
        [DESCRIPTION_FLD]: description,
        [BUSINESS_HOURS_FLD]: businessHours,
        [PRICE_RANGE_FLD]: priceRange,
        [SUBJECTS_FLD]: subjects,
      }
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteAdvert(id) {
  trace(`apiDeleteAdvert: id=${id}`);
  return await wsockFetch({
    [CMD]: DELETE_ADVERT_CMD,
    [PAYLOAD]: {
      [ADVERT_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendAdvertFeedbackByEmail(advertId, ownerId, name, phone, description) {
  trace(`apiSendAdvertFeedbackByEmail: phone=${phone}`);
  return await httpFetch(SEND_ADVERT_FEEDBACK_EMAIL_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [ADVERT_ID_FLD]: advertId,
      [OWNER_ID_FLD]: ownerId,
      [NAME_FLD]: name,
      [PHONE_FLD]: phone,
      [DESCRIPTION_FLD]: description || ''
    }),
  });
}
