// -------------------------------------------------------------------------------------------------
//  MediaFilePicker.js
//  - - - - - - - - - -
//  Кнопка вибору файлу/запису медіа (фото, відео).
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import UAParser from 'ua-parser-js';
import classnames from 'classnames';
import {t} from 'ttag';
import {confirmAction} from 'components/UI/ConfirmAction';
import {selectAction} from 'components/UI/SelectAction';
import {isAndroid, isIOS, isMediaCaptureSupported} from 'utils/systemTools';
import styles from './MediaFilePicker.scss';

export const IOS_CAMERA_ROLL_MODE         = 'c';
export const IOS_PHOTO_MODE               = 'p';
export const IOS_VIDEO_MODE               = 'v';

export const ANDROID_CAMERA_ROLL_MODE     = 'camera_roll';
export const ANDROID_PHOTO_MODE           = 'photo';
export const ANDROID_VIDEO_MODE           = 'video';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class MediaFilePicker extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    isDisabled: Types.bool,               // is component disabled?
    onFileSelected: Types.func,           // callback on media file is picked
  }

  openMobilePickerOptions = () => {
    selectAction([
      {title: t`Camera Roll`, symbolName: "folder-special", onClick: () => this.openMobilePicker(IOS_CAMERA_ROLL_MODE)},
      {title: t`Photo`, symbolName: "camera", onClick: () => this.openMobilePicker(IOS_PHOTO_MODE)}
    ]);
  }

  openMobilePicker = (mode) => {
    if(isIOS()) {
      switch(mode) {
        case IOS_CAMERA_ROLL_MODE:
          this.pickerRef.accept = 'image/*'; // 'image/*, video/*'
          this.pickerRef.removeAttribute('capture');
          break;
        case IOS_PHOTO_MODE:
          this.pickerRef.accept = 'image/*';
          this.pickerRef.capture = true;
          break;
        case IOS_VIDEO_MODE:
          this.pickerRef.accept = 'video/*';
          this.pickerRef.capture = true;
          break;
      }
      this.pickerRef.click();
    }
    if(isAndroid()) {
      switch(mode) {
        case ANDROID_CAMERA_ROLL_MODE:
          this.pickerRef.accept = 'image/*'; // 'image/*, video/*'
          this.pickerRef.removeAttribute('capture');
          break;
        case ANDROID_PHOTO_MODE:
          this.pickerRef.accept = 'image/*';
          this.pickerRef.capture = 'camera';
          break;
        case ANDROID_VIDEO_MODE:
          this.pickerRef.accept = 'video/*';
          this.pickerRef.capture = 'camcorder';
          break;
      }
      this.pickerRef.click();
    }
  }

  openDesktopPicker = () => {
    this.pickerRef.accept = 'image/jpeg, image/png'; // video/mp4, video/quicktime
    this.pickerRef.removeAttribute('capture');
    this.pickerRef.click();
  }

  handlePickerPressed = () => {
    const platform = (new UAParser()).getResult();
    // - - - протидія вбудованим браузерам від Instagram/Snapchat
    if(platform.browser.name.toLowerCase() === 'chrome webview') {
      confirmAction({
        title: t`Posting Requires Google Chrome for Android`,
        description: (
          <F>
            {t`The Instagram/Snapchat web browser does not allow us to access your camera.`}
            <br/><br/>
            {t`Please use Google Chrome for Android to post content.`}
          </F>
        ),
      });
      return;
    }
    (isIOS() || isAndroid()) && isMediaCaptureSupported() ?
      this.openMobilePickerOptions() :
      this.openDesktopPicker();
  }

  handleFileSelected = async (event) => {
    const {onFileSelected} = this.props;
    const file = event.target.files[0];
    onFileSelected && onFileSelected(file);
  }

  render() {
    const {className, isDisabled, children} = this.props;
    if (isDisabled) {
      return null;
    }
    return (
      <div className={classnames(styles.MediaFilePicker, className)} onClick={this.handlePickerPressed}>
        <input type="file" value="" hidden
               ref={elem => this.pickerRef = elem}
               onChange={this.handleFileSelected} />
        {children}
      </div>
    /*
      <div className={classnames(styles.MediaFilePicker, className, {[styles.isDisabled]: isDisabled})}
           onClick={isDisabled ? null : this.handlePickerPressed}>
        <input type="file" value="" hidden
               ref={elem => this.pickerRef = elem}
               onChange={this.handleFileSelected} />
        {children}
      </div>
    */
    );
  }
}
