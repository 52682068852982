// -------------------------------------------------------------------------------------------------
//  rich-inlinecode.js
//  - - - - - - - - - -
//  Inline code node: plugin.
//
//  Attn:
//  - - - - -
//  - INLINE_CODE заборонений в контексті CODEBLOCK, TABLE;
//  - всередині INLINE_CODE заборонені інші типи, окрім тексту;
// -------------------------------------------------------------------------------------------------
// ToDo: додати редагування останньої літери зсередини inline-блоку (зараз ззовні) !!!

import React from 'react';
import {inlineTypes, htmlTagTypes} from 'core/richTypes';

export const defaultInlineCodeOptions = {
  type: inlineTypes.INLINE_CODE,
  tagName: htmlTagTypes.INLINE_CODE,
};

const renderInlineCodeNode = (Tag) => {
  const InlineCode = ({attributes, children}) => {
    return (
      <Tag spellCheck={false} {...attributes}>
        {children}
      </Tag>
    );
  };

  InlineCode.displayName = `${Tag}-node`;
  return InlineCode;
}

const InlineCodePlugin = (opts = {}) => {
  const options = Object.assign(defaultInlineCodeOptions, opts);

  return {
    renderNode: (props) => {
      if (props.node.type === options.type) {
        return renderInlineCodeNode(options.tagName)(props);
      }
    },
  }
};

export default InlineCodePlugin;
