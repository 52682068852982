// -------------------------------------------------------------------------------------------------
//  EditStapleContainer.js
//  - - - - - - - - - -
//  Контейнер форми редагування стейплу.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchMyCollections} from 'actions/CollectionActions';
import {fetchStaple} from 'actions/StapleActions';
import {substituteSidebarContext, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import Loader from 'utils/ComponentLoader';

const EditStaple = Loader.create(() => import('./EditStaple'));
const isVerbose = DEBUG && true;
const prefix = '- - - EditStapleContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class EditStapleContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, StapleStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myId = StapleStore.getMyId();
    const staple = StapleStore.getStaple(props.match.params.stapleId);
    const myAccount = AccountStore.getMyAccount();
    const myCollections = CollectionStore.getMyCollections();
    return {myId, staple, myAccount, myCollections};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || !is(this.state.staple, nextState.staple)
      || !is(this.state.myAccount, nextState.myAccount)
      || !is(this.state.myCollections, nextState.myCollections);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {staple} = this.state;
    const {id:stapleId, ownerId} = staple || {};
    if (!CollectionStore.areMyCollectionsLoaded()) {
      fetchMyCollections();
    }
    if (!StapleStore.isStapleLoaded(this.props.match.params.stapleId)) {
      fetchStaple(this.props.match.params.stapleId);
    }
    if (ownerId) {
      substituteSidebarContext({ctxId: stapleId, ctxType: STAPLE_CTX, ctxOwnerId: ownerId});
      if (!StapleStore.hasSelectedStaples()) {
        substituteSidebarTabMode(COLLECTION_TAB);
      }
    }
  }

  componentWillUnmount() {
    trace(`componentWillUnmount`);
    substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
  }

  render() {
    const {myId, staple, myAccount, myCollections} = this.state;
    const {accountType:myAccountType, slug:mySlug} = myAccount || {};
    if (!staple || staple.ownerId !== myId) {
      return null;
    }
    trace(`render`);
    return (
      <EditStaple
        myId={myId}
        staple={staple}
        mySlug={mySlug}
        myAccountType={myAccountType}
        myCollections={myCollections}
      />
    );
  }
}

export default Container.create(EditStapleContainer, {withProps: true, pure: false});
