// -------------------------------------------------------------------------------------------------
//  ContactAPI.js
//  - - - - - - - - - -
//  API для роботи з користувачами (MyContact).
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - параметр cursor або повинен містити значення дати-часу, або бути відсутнім в запиті;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
// -------------------------------------------------------------------------------------------------
import {DEFAULT_TAGS_PLUS} from 'components/UI/fields/SelectField';
import {
  ADDRESSES_FLD,
  ANCESTOR_ID_FLD,
  AVATAR_ID_FLD,
  BODY_FLD,
  STAPLE_IDS_FLD,
  COLLECTIONS_FLD,
  COLLECTION_ID_FLD,
  COMMENTS_FLD,
  CONTACT_FLD,
  CONTACT_IDS_FLD,
  CONTACT_ID_FLD,
  DESCRIPTION_FLD,
  DETAILS_FLD,
  EMAILS_FLD,
  URLS_FLD,
  GROUPS_FLD,
  ID_FLD,
  LIMIT_FLD,
  FIRST_NAME_FLD,
  MIDDLE_NAME_FLD,
  LAST_NAME_FLD,
  ORDER_FLD,
  ORIGIN_URL_FLD,
  PICTURE_ID_FLD,
  PICTURE_SOURCE_URL_FLD,
  PHONES_FLD,
  CURSOR_FLD,
  POSITION_FLD,
  SOURCE_FLD,
  USER_ID_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_MY_CONTACTS_CMD,
  CREATE_CONTACT_CMD,
  UPDATE_CONTACT_CMD,
  DELETE_CONTACT_CMD} from 'core/apiTypes';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - ContactAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyContacts() {
  trace(`apiFetchMyContacts`);
  return await wsockFetch({
    [CMD]: FETCH_MY_CONTACTS_CMD,
    [PAYLOAD]: {}
  });
}

// FixMe: помилки валідації даних (порожні значення) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateContact(contact) {
  trace(`apiCreateContact`);
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    comments = '',
    addresses = DEFAULT_TAGS_PLUS,
    details = DEFAULT_TAGS_PLUS,
    phones = DEFAULT_TAGS_PLUS,
    emails = DEFAULT_TAGS_PLUS,
    urls = DEFAULT_TAGS_PLUS,
    groups,
    avatarId = null,
    userId = null} = contact;
  return await wsockFetch({
    [CMD]: CREATE_CONTACT_CMD,
    [PAYLOAD]: {
      [CONTACT_FLD]: {
        [FIRST_NAME_FLD]: firstName,
        [MIDDLE_NAME_FLD]: middleName,
        [LAST_NAME_FLD]: lastName,
        [COMMENTS_FLD]: comments,
        [ADDRESSES_FLD]: addresses,
        [DETAILS_FLD]: details,
        [PHONES_FLD]: phones,
        [EMAILS_FLD]: emails,
        [URLS_FLD]: urls,
        [GROUPS_FLD]: groups,
        [AVATAR_ID_FLD]: avatarId,
        [USER_ID_FLD]: userId,
      }
    }
  });
}

// FixMe: BE розвертає groups в зворотньому порядку !!!
// FixMe: помилки валідації даних (порожні значення) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateContact(contact, patchObject) {
  trace(`apiUpdateContact`);
  const {id} = contact;
  const {
    firstName,
    middleName,
    lastName,
    comments,
    addresses,
    details,
    phones,
    emails,
    urls,
    groups,
    avatarId = null} = patchObject; // attn: якщо null, то НЕ оновлюємо аватар !!!
  return await wsockFetch({
    [CMD]: UPDATE_CONTACT_CMD,
    [PAYLOAD]: {
      [CONTACT_FLD]: {
        [ID_FLD]: id,
        [FIRST_NAME_FLD]: firstName,
        [MIDDLE_NAME_FLD]: middleName,
        [LAST_NAME_FLD]: lastName,
        [COMMENTS_FLD]: comments,
        [ADDRESSES_FLD]: addresses,
        [DETAILS_FLD]: details,
        [PHONES_FLD]: phones,
        [EMAILS_FLD]: emails,
        [URLS_FLD]: urls,
        [GROUPS_FLD]: groups,
        [AVATAR_ID_FLD]: avatarId
      }
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteContact(id) {
  trace(`apiDeleteContact: id=${id}`);
  return await wsockFetch({
    [CMD]: DELETE_CONTACT_CMD,
    [PAYLOAD]: {
      [CONTACT_ID_FLD]: id
    }
  });
}
