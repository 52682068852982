// -------------------------------------------------------------------------------------------------
//  MiscAPI.js
//  - - - - - - - - - -
//  Different types of APIs for different types of objects.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
// -------------------------------------------------------------------------------------------------
import {
  COLLECTION_FIELDS_FLD,
  CONTACT_FIELDS_FLD,
  GROUP_FIELDS_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  CREATE_STAPLE_GRADE_CMD,
  UPDATE_PENDING_FIELDS_CMD} from 'core/apiTypes';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - MiscAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdatePendingFields(contactPendingFields, groupPendingFields, collectionPendingFields) {
  trace(`apiUpdatePendingFields`);
  return await wsockFetch({
    [CMD]: UPDATE_PENDING_FIELDS_CMD,
    [PAYLOAD]: {
      [CONTACT_FIELDS_FLD]: contactPendingFields,
      [GROUP_FIELDS_FLD]: groupPendingFields,
      [COLLECTION_FIELDS_FLD]:collectionPendingFields,
    }
  });
}
