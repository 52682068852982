// -------------------------------------------------------------------------------------------------
//  richTypes.js
//  - - - - - - - - - -
//  Slate/RichEditor enumerated types.
// -------------------------------------------------------------------------------------------------

export const hotkeyTypes = {
  BOLD:               'cmd+b',
  ITALIC:             'cmd+i',
  STRIKE:             'cmd+k',
  INLINE_CODE:        'cmd+j',
  BLOCKQUOTE:         'cmd+\'',
  HEADING_1:          'cmd+opt+1',
  HEADING_2:          'cmd+opt+2',
  HEADING_3:          'cmd+opt+3',
  HEADING_4:          'cmd+opt+4',
  HEADING_5:          'cmd+opt+5',
  HEADING_6:          'cmd+opt+6',
  HR:                 'ctrl+opt+h',
};

export const htmlTagTypes = {
  BOLD:               'strong',
  ITALIC:             'em',
  STRIKE:             'strike',
  INLINE_CODE:        'samp',
  BLOCKQUOTE:         'blockquote',
  HEADING_1:          'h1',
  HEADING_2:          'h2',
  HEADING_3:          'h3',
  HEADING_4:          'h4',
  HEADING_5:          'h5',
  HEADING_6:          'h6',
  HR:                 'div',              // sic!: this tag must have children
};

export const markTypes = {
  BOLD:               'bold',
  ITALIC:             'italic',
  STRIKE:             'strike',
};

export const documentTypes = {
  DOCUMENT:           'document',
};

export const blockTypes = {
  PARAGRAPH:          'paragraph',
  BLOCKQUOTE:         'blockquote',
  FOOTNOTE:           'footnote',
  CODEBLOCK:          'code',
  CODEBLOCK_LINE:     'code_line',
  HEADING_1:          'h1',
  HEADING_2:          'h2',
  HEADING_3:          'h3',
  HEADING_4:          'h4',
  HEADING_5:          'h5',
  HEADING_6:          'h6',
  HR:                 'hr',
  TABLE:              'table',
  TABLE_ROW:          'table_row',
  TABLE_CELL:         'table_cell',
  TODO_LIST:          'todo',
  OL_LIST:            'ol',
  UL_LIST:            'ul',
  LIST_ITEM:          'li',
};

export const inlineTypes = {
  INLINE_CODE:        'inline_code',
  LINK:               'link',
  MATH:               'math',
  EMOJI:              'emoji',
  IMAGE:              'image',
  VIDEO:              'video',
  HTML:               'html',
  BREAK:              'break',
  TEMPLATE:           'template',
  FOOTNOTE_REF:       'footnote_ref',
};

export const slateTypes = Object.assign(
  documentTypes,
  blockTypes,
  inlineTypes,
  markTypes,
);
