// -------------------------------------------------------------------------------------------------
//  DiscoverStaplesContainer.js
//  - - - - - - - - - - - - - -
//  Контейнер ознайомчої стрічки стейплів (URL: '/discover').
//
//  Attn:
//  - - - - -
//  - функція getDiscoverStaples віддає стейпли тільки ДО курсора, щоб не було "пропусків" в фіді;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {SUBJECT_TAB, DISCOVER_STAPLES_FEED} from 'core/uiTypes';
import {fetchDiscoverStaplesAsGuest, fetchDiscoverStaples} from 'actions/StapleActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const DiscoverStaples = Loader.create(() => import('./DiscoverStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - DiscoverStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class DiscoverStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {[DISCOVER_STAPLES_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const {staples, stapleCursor, areStaplesLoaded} = StapleStore.getDiscoverStaplesFeed();
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    return {isLoggedIn, myAccountType, myId, owners, staples, viewMode, stapleCursor, areStaplesLoaded};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.viewMode !== nextState.viewMode
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn} = this.state;
    // CTX:
    if (isLoggedIn) {
      substituteSidebarTabMode(SUBJECT_TAB);
      substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const p1 = nextProps.history.location.pathname;
    const p2 = nextProps.location.pathname;
    if (p1 && p1 === p2) {
      const {isLoggedIn} = this.state;
      // CTX:
      if (isLoggedIn) {
        substituteSidebarTabMode(SUBJECT_TAB);
        substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
      }
    }
  }

  isFetchingDiscoverStaples = false;

  fetchDiscoverStaplesPage = async () => {
    trace(`fetchDiscoverStaplesPage(1): isFetching=${this.isFetchingDiscoverStaples}`);
    if (!this.isFetchingDiscoverStaples) {
      const {isLoggedIn, stapleCursor = DEFAULT_TSN12_CURSOR} = this.state;
      trace(`fetchDiscoverStaplesPage(2): cursor='${stapleCursor}'`);
      this.isFetchingDiscoverStaples = true;
      isLoggedIn ?
        await fetchDiscoverStaples({cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT}) :
        await fetchDiscoverStaplesAsGuest({cursor: stapleCursor, limit: GUEST_FEED_FETCH_LIMIT});
      this.isFetchingDiscoverStaples = false;
    }
  }

  render() {
    const {isLoggedIn, myAccountType, myId, owners, staples, viewMode, areStaplesLoaded} = this.state;
    trace(`render`);
    return (
      <DiscoverStaples
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        staples={staples}
        viewMode={viewMode}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchDiscoverStaplesPage}
      />
    );
  }
}

export default Container.create(DiscoverStaplesContainer, {withProps: true, pure: false});
