// -------------------------------------------------------------------------------------------------
//  Index.js
//  - - - - - - - - - -
//
//  Attn: 1) https://babeljs.io/docs/en/babel-polyfill#usage-in-node-browserify-webpack
// -------------------------------------------------------------------------------------------------
import "core-js/stable";                  // more.info: (1)
import "regenerator-runtime/runtime";     // more.info: (1)
import 'raf/polyfill';
import 'whatwg-fetch';                    // polyfills that implements a subset of the standard Fetch specification
import 'intersection-observer';           // polyfills the native IntersectionObserver API
import React from 'react';
import {render} from 'react-dom';
import {addLocale, useLocale} from 'ttag';
import {LS_LOCALE} from 'core/commonTypes';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import App from 'application/App';

// - - - - - i18n:
const translationsDe = require(`./locales/de.po.json`);
const translationsEs = require(`./locales/es.po.json`);
const translationsFi = require(`./locales/fi.po.json`);
const translationsFr = require(`./locales/fr.po.json`);
const translationsUk = require(`./locales/uk.po.json`);
const translationsEn = require(`./locales/en.po.json`);
const locale = localStorage.getItem(LS_LOCALE) || ENGLISH_LANG;

switch(locale) {
  case 'de':  addLocale(locale, translationsDe); break;
  case 'es':  addLocale(locale, translationsEs); break;
  case 'fi':  addLocale(locale, translationsFi); break;
  case 'fr':  addLocale(locale, translationsFr); break;
  case 'uk':  addLocale(locale, translationsUk); break;
  default:    addLocale(locale, translationsEn);
}
useLocale(locale);
// - - - - -

render(<App />, document.querySelector('#app'));
