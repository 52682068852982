// -------------------------------------------------------------------------------------------------
//  StapleActions.js
//  - - - - - - - - - -
//  Staple & MyStaple actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' та робимо обробку catch-помилок;
//  - інформація про помилку міститься в status та errors обʼєкту response;
//  - в полі errors.formError повертаємо додаткову інформацію про помилки;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
//
//  Attn: a)
//  - - - - -
//  -   підписка CSubscription є надмножиною над Collection;
//  -   в базі даних унікальність пари [collection_id, user_id] гарантується унікальним індексом;
//  -   на фронтенді клас Collection просто розширений полями підписки (див. формат {{ CSUBSCRIPTION }});
//  -   тому вся робота з підписками здійснюється НЕ через csubscriptionId, а через collectionId !!!
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  DISCOVER_STAPLES_WERE_FETCHED_ACTION,
  FEATURED_STAPLES_WERE_FETCHED_ACTION,
  FOLLOWING_STAPLES_WERE_FETCHED_ACTION,
  CSUBSCRIPTION_STAPLES_WERE_FETCHED_ACTION,
  COLLECTION_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
  COLLECTION_STAPLES_WERE_FETCHED_ACTION,
  USER_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
  USER_STAPLES_WERE_FETCHED_ACTION,
  STAPLE_WAS_FETCHED_AS_GUEST_ACTION,
  STAPLE_WAS_FETCHED_ACTION,
  STAPLE_WAS_CREATED_ACTION,
  STAPLE_WAS_CLONED_ACTION,
  STAPLE_WAS_UPDATED_ACTION,
  STAPLE_WAS_BANNED_ACTION,
  STAPLES_WERE_DELETED_ACTION,
  STAPLE_WAS_SELECTED_ACTION,
  STAPLE_WAS_UNSELECTED_ACTION,
  STAPLES_WERE_UNSELECTED_ACTION,
  STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION,
  STAPLES_OF_COLLECTION_WERE_UNSELECTED_ACTION,
  CHAT_MESSAGE_WAS_CREATED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  STAPLE_FLD,
  STAPLE_ID_FLD,
  STAPLE_IDS_FLD,
  STAPLES_FLD,
  STAPLEDATAS_FLD,
  COLLECTIONS_FLD,
  CSUBSCRIPTION_ID_FLD,
  USER_ID_FLD,
  USERS_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  BLOB_FLD,
  BLOBS_FLD,
  CHAT_FLD,
  MESSAGE_FLD,
  POSITION_FLD,
  POSITION2_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR, TOPMOST_TSN12_CURSOR, DEFAULT_POS10_CURSOR, TOPMOST_POS10_CURSOR} from 'core/commonTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {fetchMyCollections} from 'actions/CollectionActions';
import {substitutePresetCollections} from 'actions/LayoutActions';
import {
  apiFetchDiscoverStaplesAsGuest,
  apiFetchDiscoverStaples,
  apiFetchFeaturedStaplesAsGuest,
  apiFetchFeaturedStaples,
  apiFetchFollowingStaples,
  // apiFetchSearchStaples,
  apiFetchCSubscriptionStaples,
  apiFetchCollectionStaplesAsGuest,
  apiFetchCollectionStaples,
  apiFetchUserStaplesAsGuest,
  apiFetchUserStaples,
  apiFetchStapleAsGuest,
  apiFetchStaple,
  apiCreateStaple,
  apiCloneStaple,
  apiUpdateStaple,
  apiBanStaple,
  apiDeleteStaples,
  apiCreateStapleGrade} from 'api/StapleAPI';

const STAPLES_PER_REQUEST_LIMIT = 16;     // типова к-сть стейплів за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - StapleActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverStaplesAsGuest(opts = {}) {
  trace(`fetchDiscoverStaplesAsGuest`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchDiscoverStaplesAsGuest({cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples, [USERS_FLD]:users} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: DISCOVER_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            users: users,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverStaplesAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchDiscoverStaples(opts = {}) {
  trace(`fetchDiscoverStaples`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchDiscoverStaples({cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: DISCOVER_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchDiscoverStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchFeaturedStaplesAsGuest(opts = {}) {
  trace(`fetchFeaturedStaplesAsGuest`);
  const {
    languageId,                           // мова, для якої відображаємо матеріали у фіді
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchFeaturedStaplesAsGuest({cursor, order, limit, languageId});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples, [USERS_FLD]:users} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: FEATURED_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            users: users,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchFeaturedStaplesAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchFeaturedStaples(opts = {}) {
  trace(`fetchFeaturedStaples`);
  const {
    languageId,                           // мова, для якої відображаємо матеріали у фіді
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchFeaturedStaples({cursor, order, limit, languageId});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: FEATURED_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchFeaturedStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchFollowingStaples(opts = {}) {
  trace(`fetchFollowingStaples`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchFollowingStaples({cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: FOLLOWING_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchFollowingStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function fetchSearchStaples(opts = {}) {
//   trace(`fetchSearchStaples`);
//   const {
//     cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
//     order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
//     limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
//   } = opts;
//   try {
//     const response = await apiFetchSearchStaples({cursor, order, limit});
//     const {[STATUS]:status, [PAYLOAD]:payload} = response;
//     if (status === 'ok') {
//       const {[STAPLES_FLD]:staples} = payload;
//       if (staples) {
//         const areLoaded = staples.length < limit;
//         const nextCursor = staples.length > 0 ?
//           staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
//           cursor;
//         Dispatcher.dispatch({
//           type: SEARCHED_STAPLES_WERE_FETCHED_ACTION,
//           payload: {
//             staples: staples,
//             stapleCursor: nextCursor,
//             areStaplesLoaded: areLoaded,
//           }
//         });
//       }
//     } else {
//       traceError(`fetchSearchStaples: status=${status}, payload=${JSON.stringify(payload)}`);
//     }
//   } catch(error) {
//     console.error(`API error`, error);
//   }
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCSubscriptionStaples(opts = {}) {
  trace(`fetchCollectionStaples`);
  const {
    collectionId,                         // код колекції <--- attn: a) !!!
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації стейплів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchCSubscriptionStaples({collectionId, cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[POSITION2_FLD] ? acc : elem[POSITION2_FLD], cursor || TOPMOST_POS10_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: CSUBSCRIPTION_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            collectionId: collectionId,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCollectionStaplesAsGuest(opts = {}) {
  trace(`fetchCollectionStaplesAsGuest`);
  const {
    collectionId,                         // код колекції, по якій запитуємо стейпли
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchCollectionStaplesAsGuest({collectionId, cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[POSITION2_FLD] ? acc : elem[POSITION2_FLD], cursor || TOPMOST_POS10_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: COLLECTION_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
          payload: {
            collectionId: collectionId,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchCollectionStaplesAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchCollectionStaples(opts = {}) {
  trace(`fetchCollectionStaples`);
  const {
    collectionId,                         // код колекції, по якій запитуємо стейпли
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації стейплів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchCollectionStaples({collectionId, cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[POSITION2_FLD] ? acc : elem[POSITION2_FLD], cursor || TOPMOST_POS10_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: COLLECTION_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            collectionId: collectionId,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserStaplesAsGuest(opts = {}) {
  trace(`fetchUserStaplesAsGuest`);
  const {
    userId,                               // код юзера, по якому запитуємо стейпли
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchUserStaplesAsGuest({userId, cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: USER_STAPLES_WERE_FETCHED_AS_GUEST_ACTION,
          payload: {
            userId: userId,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserStaplesAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserStaples(opts = {}) {
  trace(`fetchUserStaples`);
  const {
    userId,                               // код юзера, по якому запитуємо стейпли
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  try {
    const response = await apiFetchUserStaples({userId, cursor, order, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STAPLES_FLD]:staples} = payload;
      if (staples) {
        const areLoaded = staples.length < limit;
        const nextCursor = staples.length > 0 ?
          staples.reduce((acc, elem) => acc < elem[ID_FLD] ? acc : elem[ID_FLD], cursor || TOPMOST_TSN12_CURSOR) :
          cursor;
        Dispatcher.dispatch({
          type: USER_STAPLES_WERE_FETCHED_ACTION,
          payload: {
            userId: userId,
            staples: staples,
            stapleCursor: nextCursor,
            areStaplesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserStaples: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchStaple(id) {
  trace(`fetchStaple: stapleId=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchStaple(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchStaple: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_FLD]:staple} = payload;
        if (staple) {
          Dispatcher.dispatch({
            type: STAPLE_WAS_FETCHED_ACTION,
            payload: {
              staple: staple,
            }
          });
        }
        return {status, errors, staple}; // sic!: повертаємо staple заради ownerId в розрахунку sidebarContext !!!
        break;
      default:
        traceError(`fetchStaple: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchStapleAsGuest(id) {
  trace(`fetchStapleAsGuest: stapleId=${id}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchStapleAsGuest(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchStapleAsGuest: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_FLD]:staple} = payload;
        if (staple) {
          Dispatcher.dispatch({
            type: STAPLE_WAS_FETCHED_AS_GUEST_ACTION,
            payload: {
              staple: staple,
            }
          });
        }
        break;
      default:
        traceError(`fetchStapleAsGuest: id=${id}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// Attn: 1) превьюшки генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createStaple(staple, pictureBlobUrls) {
  trace(`createStaple`);
  const {[COLLECTIONS_FLD]:tgxCollections} = staple;
  let response;
  let status;
  let payload;
  let errors = {};
  try {
    response = await apiCreateStaple(staple);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createStaple: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_FLD]:createdStaple, [STAPLEDATAS_FLD]:createdStapleDatas} = payload;
        if (createdStaple) {
          if (pictureBlobUrls) {
            Object.assign(createdStaple, {[BLOBS_FLD]: pictureBlobUrls}); // attn: 1)
          }
          // ...встановлюємо дефолтні колекції
          const stapleCollections = createdStaple[COLLECTIONS_FLD];
          if (stapleCollections) {
            await substitutePresetCollections(Object.keys(JSON.parse(stapleCollections)));
          }
          Dispatcher.dispatch({
            type: STAPLE_WAS_CREATED_ACTION,
            payload: {
              staple: createdStaple,
              stapleDatas: createdStapleDatas,
              tgxCollections: tgxCollections,
            }
          });
          // counts: оновлення списку колекцій, якщо юзер додав НОВІ колекції
          if (Object.keys(tgxCollections || {}).find(elem => elem.startsWith('+'))) {
            fetchMyCollections(true);
            ReactGA.event({
              category: gaCategories.KNOWLEDGE,
              action: gaActions.COLLECTION_CREATED,
              label: 'ui:select2'
            });
          }
          ReactGA.event({
            category: gaCategories.KNOWLEDGE,
            action: gaActions.STAPLE_CREATED
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`createStaple: status=${status}`);
        break
      case 'collections_processing_error':
        traceError(`createStaple: status=${status}`);
        errors.formError = status;
        break;
      default:
        traceError(`createStaple: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function cloneStaple(ancestorId, staple) {
  trace(`cloneStaple`);
  const {[COLLECTIONS_FLD]:tgxCollections} = staple;
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCloneStaple(ancestorId, staple);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`cloneStaple: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_FLD]:clonedStaple, [STAPLEDATAS_FLD]:clonedStapleDatas} = payload;
        if (clonedStaple) {
          Dispatcher.dispatch({
            type: STAPLE_WAS_CLONED_ACTION,
            payload: {
              staple: clonedStaple,
              stapleDatas: clonedStapleDatas,
              tgxCollections: tgxCollections,
            }
          });
          // counts: оновлення списку колекцій, якщо юзер додав НОВІ колекції
          if (Object.keys(tgxCollections).find(elem => elem.startsWith('+'))) {
            fetchMyCollections(true);
            ReactGA.event({
              category: gaCategories.KNOWLEDGE,
              action: gaActions.COLLECTION_CREATED,
              label: 'ui:select2'
            });
          }
          ReactGA.event({
            category: gaCategories.KNOWLEDGE,
            action: gaActions.STAPLE_CLONED
          });
        }
        break;
      // ToDo: чому тут БЕЗ присвоювання errors.formError ???
      case 'invalid_formdata':
        traceError(`cloneStaple: status=${status}`);
        break
      default:
        traceError(`cloneStaple: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// Attn: 1) превьюшки генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateStaple(staple, patchObject, pictureBlobUrls) {
  trace(`updateStaple`);
  const {[COLLECTIONS_FLD]:tgxCollections} = patchObject;
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateStaple(staple, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateStaple: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_FLD]:updatedStaple, [STAPLEDATAS_FLD]:addedStapleDatas} = payload;
        const {lstCollections:prevStapleCollections = []} = staple || {};
        const {[COLLECTIONS_FLD]:tgxUpdatedCollections = {}} = updatedStaple || {};
        const nextStapleCollectionIds = Object.keys(JSON.parse(tgxUpdatedCollections)) || [];
        // ...1) формуємо список колекцій, які були видалені із попереднього стейплу
        const removedCollectionIds = prevStapleCollections.reduce((acc, currColl) => {
          const {i:collectionId} = currColl;
          const isCollectionDeleted = nextStapleCollectionIds.findIndex(id => id === collectionId) < 0;
          return isCollectionDeleted ? acc.concat(collectionId) : acc;
        }, []);
        // ...2) формуємо список колекцій, які були додані до попереднього стейплу
        const addedCollectionIds = nextStapleCollectionIds.reduce((acc, collectionId) => {
          const isCollectionAdded = prevStapleCollections.findIndex(coll => coll.i === collectionId) < 0;
          return isCollectionAdded ? acc.concat(collectionId) : acc;
        }, []);
        if (updatedStaple) {
          if (pictureBlobUrls) {
            Object.assign(updatedStaple, {[BLOBS_FLD]: pictureBlobUrls}); // attn: 1)
          }
          Dispatcher.dispatch({
            type: STAPLE_WAS_UPDATED_ACTION,
            payload: {
              staple: updatedStaple,
              stapleDatas: addedStapleDatas,
              addedCollectionIds: addedCollectionIds,
              removedCollectionIds: removedCollectionIds,
            }
          });
          // counts: оновлення списку колекцій (юзер міг змінити набір колекцій у стейплі)
          if (Object.keys(tgxCollections || {}).find(elem => elem.startsWith('+'))) {
            fetchMyCollections(true);
          }
        }
        break;
      case 'invalid_formdata':
        traceError(`updateStaple: status=${status}`);
        break
      default:
        traceError(`updateStaple: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function banStaple(id) {
  trace(`banStaple`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiBanStaple(id);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`banStaple: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_ID_FLD]:stapleId} = payload || [];
        if (stapleId) {
          Dispatcher.dispatch({
            type: STAPLE_WAS_BANNED_ACTION,
            payload: {
              stapleId: stapleId,
            }
          });
        }
        break;
      default:
        traceError(`banStaple: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

//  Attn:
//  1)  в параметрах передаємо список СТЕЙПЛІВ (а не ids), бо потрібна інфа про змінені колекції;
//  2)  якщо ДЕКІЛЬКА стейплів видаляється із тієї ж само колекції, то ідентифікатор цієї колекції
//      треба додавати ДЕКІЛЬКА РАЗІВ щоб коректно зменшити лічильники к-ті стейплів в колекції;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteStaples(staples) {
  trace(`deleteStaples`);
  let status;
  let payload;
  let errors = {};
  const ids = staples.reduce((acc, currStaple) => {
    const {id} = currStaple;
    return id ? acc.concat(id) : acc;
  }, []);
  try {
    const response = await apiDeleteStaples(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    // - - - - -
    // FixMe: 'forbidden' при видаленні вже видаленого стейплу !!!
    // видає 'forbidden' при спробі видалити стейпл, який був видалений в іншій вкладці;
    // потрібно обробити ситуацію - напр. видалити цей стейпл із списку ???
    // - - - - -
    switch(status) {
      case 'ok':
        trace(`deleteStaples: OK, payload=${JSON.stringify(payload)}`);
        const {[STAPLE_IDS_FLD]:deletedIds} = payload || [];
        // ...формуємо список ідентифікаторів колекцій, яких зачепило видалення стейплів;
        const affectedCollectionIds = staples.reduce((acc, currStaple) => {
          const {id:stapleId, lstCollections:stapleCollections = []} = currStaple || {};
          const isStapleDeleted = deletedIds.findIndex(id => id === stapleId) >= 0;
          return isStapleDeleted ?
            stapleCollections.reduce((localAcc, currColl) => {
              const {i:collectionId} = currColl;
              return collectionId ? localAcc.concat(collectionId) : localAcc; // sic!: 2)
            }, acc) :
            acc;
        }, []);
        if (deletedIds && deletedIds.length > 0) {
          Dispatcher.dispatch({
            type: STAPLES_WERE_DELETED_ACTION,
            payload: {
              deletedIds: deletedIds,
              affectedCollectionIds: affectedCollectionIds,
            }
          });
          ReactGA.event({
            category: gaCategories.KNOWLEDGE,
            action: gaActions.STAPLE_DELETED
          });
        }
        break;
      default:
        traceError(`deleteStaples: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setStapleSelected(stapleId) {
  trace(`setStapleSelected`);
  Dispatcher.dispatch({
    type: STAPLE_WAS_SELECTED_ACTION,
    payload: {
      stapleId: stapleId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setStapleUnselected(stapleId) {
  trace(`setStapleUnselected`);
  Dispatcher.dispatch({
    type: STAPLE_WAS_UNSELECTED_ACTION,
    payload: {
      stapleId: stapleId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setAllStaplesUnselected() {
  trace(`setAllStaplesUnselected`);
  Dispatcher.dispatch({
    type: STAPLES_WERE_UNSELECTED_ACTION,
    payload: {},
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setStaplesOfCollectionSelected(collectionId) {
  trace(`setStaplesOfCollectionSelected`);
  Dispatcher.dispatch({
    type: STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION,
    payload: {
      collectionId: collectionId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setStaplesOfCollectionUnselected(collectionId) {
  trace(`setStaplesOfCollectionUnselected`);
  Dispatcher.dispatch({
    type: STAPLES_OF_COLLECTION_WERE_UNSELECTED_ACTION,
    payload: {
      collectionId: collectionId
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createStapleGrade(opts) {
  trace(`createStapleGrade`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateStapleGrade(opts);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createStapleGrade: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_FLD]:chat, [MESSAGE_FLD]:message} = payload;
        Dispatcher.dispatch({
          type: CHAT_MESSAGE_WAS_CREATED_ACTION,
          payload: {
            chat: chat,
            message: message,
          }
        });
        break;
      case 'account_type_error':
      case 'forbidden':
      case 'grade_already_exists':
      case 'invalid_formdata':
        traceWarn(`createStapleGrade: status=${status}`);
        break;
      default:
        traceError(`createStapleGrade: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
