// -------------------------------------------------------------------------------------------------
//  AccountAPI.js
//  - - - - - - - - - -
//  Current user account APIs.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
// -------------------------------------------------------------------------------------------------
import {
  ACCOUNT_FLD,
  AVATAR_ID_FLD,
  DESCRIPTION_FLD,
  DETAILS_FLD,
  PHONES_FLD,
  EMAILS_FLD,
  URLS_FLD,
  SUBJECTS_FLD,
  LANGUAGE_ID_FLD,
  COUNTRY_ID_FLD,
  ZIP_CODE_FLD,
  IS_HELP_MODE_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  GET_MY_ACCOUNT_CMD, UPDATE_MY_ACCOUNT_CMD} from 'core/apiTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - AccountAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// Attn: тут обовʼязково потрібен http, бо не завжди браузер встановлює socket-зʼєднання !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyAccount() {
  trace(`apiFetchMyAccount`);
  return await httpFetch(GET_MY_ACCOUNT_CMD, {
    method: 'GET',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateMyAccount(account) {
  trace(`apiUpdateMyAccount`);
  const {
    description,
    details,
    phones,
    emails,
    urls,
    languageId,
    countryId = 'us',
    zipCode = '10000',
    avatarId = null,
    isHelpMode} = account;
  return await wsockFetch({
    [CMD]: UPDATE_MY_ACCOUNT_CMD,
    [PAYLOAD]: {
      [ACCOUNT_FLD]: {
        [DESCRIPTION_FLD]: description,
        [DETAILS_FLD]:details,
        [PHONES_FLD]:phones,
        [EMAILS_FLD]:emails,
        [URLS_FLD]:urls,
        [LANGUAGE_ID_FLD]: languageId,
        [COUNTRY_ID_FLD]: countryId,
        [ZIP_CODE_FLD]: zipCode,
        [AVATAR_ID_FLD]: avatarId,
        [IS_HELP_MODE_FLD]:isHelpMode,
      }
    }
  });
}
