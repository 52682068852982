// -------------------------------------------------------------------------------------------------
//  TextingPanel.js
//  - - - - - - - - - -
//  Панель для введення тексту з подальшою передачею цього тексту в колбек.
//  Введений текст не повинен бути порожнім та не повинен перевищувати {MAX_PROMPT_LEN} знаків.
//
//  Attn:
//  - - - - -
//  1) error-system: clear error message on any change;
//  2) rich-поля редагуємо у форматі RichEditor, a в колбек передаємо у форматі Conmark;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {MAX_PROMPT_LEN} from 'core/systemLimits';
import PictButton from 'components/UI/buttons/PictButton';
import RichField, {toRich, isRichEmpty, toConmark, cleanifyConmark, isConmarkEmpty} from 'components/UI/fields/RichField';
import styles from './TextingPanel.scss';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - TextingPanel';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function setTextingPanelAction(onClick) {
  pubsub.publish(onClick);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class TextingPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      richText: toRich(''),               // текст в форматі Conmark
      richTextError: '',                  // помилки поля richText
      isProcessing: false,                // флаг обробки даних форми
      onClick: null,                      // колбек на натискання кнопки (якщо null, то кнопка не показуємо)
    }
    this.editorRef = React.createRef();   // посилання на редактор повідомлення
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(onClick => {
      this.setState({
        onClick: onClick,
      });
    });
    this.handleSetFocus();
    setTimeout(this.handleSetFocus, 1000);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkText = (value) => {
    let error = '';
    if (isRichEmpty(value)) {
      error = t`The text can't be empty.`;
    } else if (toConmark(value).length > MAX_PROMPT_LEN) {
      error = t`The text can't be larger than ${MAX_PROMPT_LEN} symbols.`;
    }
    return error;
  }

  isFormValid = () => {
    trace(`isFormValid`);
    const {richText} = this.state;
    const richTextError = this.checkText(richText);
    this.setState({
      richTextError: richTextError,
    });
    return richTextError === '';
  }

  handleKeyDown = (event, change, editor) => {
    if (event.key === 'Enter'
      && event.altKey === false
      && event.ctrlKey === false
      && event.metaKey === false
      && event.shiftKey === false)
    {
      trace(`handleKeyDown: ENTER`);
      setTimeout(this.handleSubmit, 100); // sic!: чомусь викликати через setTimeout, бо інакше не спрацьовує setState в handleSubmit !!!???
    }
  }

  handleSetFocus = () => {
    trace(`handleSetFocus`);
    this.editorRef
    && this.editorRef.current
    && this.editorRef.current.focus
    && this.editorRef.current.focus();
  }

  handleTextChange = ({value}) => {
    trace(`handleTextChange`);
    this.setState({
      richText: value,
      richTextError: '', // sic!: 1)
    });
  }

  handleSubmit = async () => {
    trace(`handleSubmit`);
    this.setState({isProcessing: true});
    if (this.isFormValid()) {
      const {richText, onClick} = this.state;
      this.setState({
        richText: toRich(''),
        richTextError: '',
        isProcessing: false
      });
      onClick && onClick(toConmark(richText));
    } else {
      this.setState({isProcessing: false});
    }
  }

  render() {
    const {richText, richTextError, isProcessing, onClick} = this.state;
    if (!onClick) {
      return null;
    }
    return (
      <div className={styles.TextingPanel} title={t`Add`}>
        <div className={styles.textingWrapper}>
          <RichField
            className={styles.editorWrapper}
            editorClassName={styles.editor}
            errorClassName={styles.error}
            inputRef={this.editorRef}
            value={richText}
            error={richTextError}
            placeholder={t`Enter text...`}
            autoFocus={true}
            hasToolbar={false}
            isDisabled={isProcessing}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleTextChange}
          />
          <PictButton
            className={classnames(styles.actionButton, {
              [styles.isProcessing]: isProcessing,
              [styles.hasError]: !!richTextError,
            })}
            iconClassName={styles.icon}
            symbolName="send-message"
            title="ENTER"
            isDisabled={isProcessing}
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}
