// -------------------------------------------------------------------------------------------------
//  AccountActions.js
//  - - - - - - - - - -
//  Current user account actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' / catch;
// -------------------------------------------------------------------------------------------------
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  MY_ACCOUNT_WAS_UPDATED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ACCOUNT_FLD,
  ID_FLD,
  SLUG_FLD,
  LANGUAGE_ID_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {apiFetchMyAccount, apiUpdateMyAccount} from 'api/AccountAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - AccountActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function removeAuthToken() {
  const token = localStorage.getItem('authToken');
  if (token) {
    localStorage.removeItem('authToken');
    window.location.replace('/');
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyAccount() {
  trace(`fetchMyAccount`);
  try {
    const response = await apiFetchMyAccount();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[ACCOUNT_FLD]:account} = payload;
      if (account) {
        const {[ID_FLD]:myId, [SLUG_FLD]:mySlug, [LANGUAGE_ID_FLD]:languageId} = account;
        Dispatcher.dispatch({
          type: MY_ACCOUNT_WAS_FETCHED_ACTION,
          payload: {account}
        });
        return {myId, mySlug, languageId};
      }
    } else {
      traceError(`fetchMyAccount: status=${status}, payload=${JSON.stringify(payload)}`);
      removeAuthToken();
      Dispatcher.dispatch({
        type: LOGGED_OUT_ACTION
      });
    }
  } catch(error) {
    console.error(`API error`, error);
    removeAuthToken();
  }
  return {};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateMyAccount(account) {
  trace(`updateMyAccount`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateMyAccount(account);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateMyAccount: OK, payload=${JSON.stringify(payload)}`);
        const {[ACCOUNT_FLD]:updatedAccount} = payload;
        if (updatedAccount) {
          Dispatcher.dispatch({
            type: MY_ACCOUNT_WAS_UPDATED_ACTION,
            payload: {
              account: updatedAccount,
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`updateMyAccount: status=${status}`);
        break
      default:
        traceError(`updateMyAccount: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}
