// -------------------------------------------------------------------------------------------------
//  MyGroupContactsContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки контактів вказаної групи.
//
//  Attn:
//  - - - - -
//  - якщо групу не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в групу контекст сайдбару встановлюємо на закладку чатів (CHAT_TAB) ???;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {List, is} from 'immutable';
import {fetchMyGroups} from 'actions/GroupActions';
import {fetchMyContacts} from 'actions/ContactActions';
import {GROUP_TAB, MY_GROUP_CONTACTS_FEED} from 'core/uiTypes';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode, substitutePresetGroups} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const MyGroupContacts = Loader.create(() => import('./MyGroupContacts'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyGroupContactsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyGroupContactsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {groupId} = props.match.params;
    const {[MY_GROUP_CONTACTS_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const myUser= myId ? UserStore.getUser(myId) : null;
    const group = groupId ? CrmStore.getMyGroup(groupId) : null;
    const contacts = group ? CrmStore.getMyGroupContacts(group.id) : List();
    const unreadCountsByUser = isLoggedIn ? ChatStore.getUnreadCountsByUser() : {};
    const areContactsLoaded = group ? CrmStore.areMyContactsLoaded() : false;
    return {myAccountType, myId, myUser, group, contacts, viewMode, unreadCountsByUser, areContactsLoaded};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.unreadCountsByUser.size !== nextState.unreadCountsByUser.size
      || this.state.viewMode !== nextState.viewMode
      || this.state.areContactsLoaded !== nextState.areContactsLoaded
      || this.state.contacts.size !== nextState.contacts.size
      || !is(this.state.group, nextState.group)
      || !is(this.state.contacts, nextState.contacts)
      || !is(this.state.myUser, nextState.myUser)
      || !is(this.state.unreadCountsByUser, nextState.unreadCountsByUser);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {groupId} = this.props.match.params;
    if (groupId) {
      if (!CrmStore.areMyGroupsLoaded()) {
        fetchMyGroups();
      }
      if (!CrmStore.areMyContactsLoaded()) {
        fetchMyContacts();
      }
      await substitutePresetGroups([groupId]);
      // CTX:
      substituteSidebarTabMode(GROUP_TAB);
    }
  }

  async componentDidUpdate() {
    trace(`componentDidUpdate`);
    const {groupId} = this.props.match.params;
    if (groupId) {
      await substitutePresetGroups([groupId]);
    }
  }

  render() {
    const {
      myAccountType,
      myId,
      myUser,
      group,
      contacts,
      viewMode,
      unreadCountsByUser,
      areContactsLoaded} = this.state;
    if (areContactsLoaded && !group) {
      return (
        <NotFoundPage />
      );
    }
    trace(`render`);
    return (
      <MyGroupContacts
        myAccountType={myAccountType}
        myId={myId}
        myUser={myUser}
        group={group}
        contacts={contacts}
        viewMode={viewMode}
        unreadCountsByUser={unreadCountsByUser}
        areContactsLoaded={areContactsLoaded}
      />
    );
  }
}

export default Container.create(MyGroupContactsContainer, {withProps: true, pure: false});
