// -------------------------------------------------------------------------------------------------
//  rich-hr.js
//  - - - - - - - - - -
//  <hr> node: plugin & toolbar icon.
//
//  Attn:
//  - - - - -
//  - HR заборонений в контексті CODEBLOCK, TABLE;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import RichIcon from '../RichIcon';
import {blockTypes, hotkeyTypes, htmlTagTypes} from 'core/richTypes';
import {isInTableContext, haveMarks} from './utils';
import styles from './rich-hr.scss';

const defaultOptions = {
  type: blockTypes.HR,
  tagName: htmlTagTypes.HR,
  hotkey: hotkeyTypes.HR,
};

export class HorizontalIcon extends React.Component {
  onClick = (event) => {
    let {change, onChange} = this.props;

    event.preventDefault();
    onChange(
      change.insertBlock({
        type: defaultOptions.type,
        isVoid: true
      }).collapseToStartOfNextBlock()
    );
  }

  render() {
    const {change, ...other} = this.props;
    const onClick = event => this.onClick(event);

    if (isInTableContext(change)) {
      return null;
    }

    return (
      <RichIcon
        symbolName="markup-hr"
        commandName={defaultOptions.type}
        isActive={haveMarks(change, defaultOptions.type)}
        onClick={onClick}
        {...other}
      />
    );
  }
}

const HorizontalPlugin = (opts) => {
  const options = Object.assign(defaultOptions, opts);

  return {
    renderNode: (props) => {
      if (props.node.type === options.type) {
        const {attributes, children} = props;
        return (
          <div
            {...attributes}
            className={styles.Horizontal}
            data-slate-type="hr"
          >
            {children}
          </div>
        );
      }
    }
  }
};

export default HorizontalPlugin;
