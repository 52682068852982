// -------------------------------------------------------------------------------------------------
//  CollectionAPI.js
//  - - - - - - - - - -
//  Collection & MyCollection APIs.
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//
//  Attn: a)
//  - - - - -
//  -   підписка CSubscription є надмножиною над Collection;
//  -   в базі даних унікальність пари [collection_id, user_id] гарантується унікальним індексом;
//  -   на фронтенді клас Collection просто розширений полями підписки (див. формат {{ CSUBSCRIPTION }});
//  -   тому вся робота з підписками здійснюється НЕ через csubscriptionId, а через collectionId !!!
// -------------------------------------------------------------------------------------------------
import {
  ID_FLD,
  TYPE_FLD,
  LANGUAGE_ID_FLD,
  COLLECTION_FLD,
  COLLECTION_ID_FLD,
  STAPLE_IDS_FLD,
  STAPLEDATAS_FLD,
  SUBJECT_ID_FLD,
  USER_ID_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  COMMENTS_FLD,
  ATTACHMENTS_FLD,
  SUBJECTS_FLD,
  INTERESTS_FLD,
  PICTURES_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  ACCESS_COST_FLD,
  INITIAL_DROP_SIZE_FLD,
  RECURRING_DROP_SIZE_FLD,
  NEXT_DROP_DELAY_FLD,
  IS_PUBLIC_FLD,
  IS_PUBLISHED_FLD,
  CURSOR_FLD,
  LIMIT_FLD,
  ORDER_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_DISCOVER_COLLECTIONS_AS_GUEST_CMD,
  FETCH_DISCOVER_COLLECTIONS_CMD,
  FETCH_FEATURED_COLLECTIONS_AS_GUEST_CMD,
  FETCH_FEATURED_COLLECTIONS_CMD,
  FETCH_SEARCHED_COLLECTIONS_AS_GUEST_CMD,
  FETCH_SEARCHED_COLLECTIONS_CMD,
  FETCH_SUBJECT_COLLECTIONS_CMD,
  FETCH_USER_COLLECTIONS_AS_GUEST_CMD,
  FETCH_USER_COLLECTIONS_CMD,
  FETCH_MY_CSUBSCRIPTIONS_CMD,
  FETCH_MY_COLLECTIONS_CMD,
  FETCH_COLLECTION_AS_GUEST_CMD,
  FETCH_COLLECTION_CMD,
  CREATE_COLLECTION_CMD,
  UPDATE_COLLECTION_CMD,
  BAN_COLLECTION_CMD,
  DELETE_COLLECTION_CMD,
  ADD_STAPLES_TO_COLLECTION_CMD,
  REMOVE_STAPLES_FROM_COLLECTION_CMD,
  UPDATE_COLLECTION_STAPLEDATAS_CMD,
  FOLLOW_COLLECTION_CMD,
  UNFOLLOW_COLLECTION_CMD,
  FETCH_FOLLOWED_COLLECTION_IDS_CMD,
  FETCH_CSUBSCRIBED_COLLECTION_IDS_CMD,
  FETCH_CSUBSCRIPTION_CMD,
  CREATE_CSUBSCRIPTION_CMD,
  CANCEL_CSUBSCRIPTION_CMD} from 'core/apiTypes';
import {
  DEFAULT_TSN12_CURSOR,
  DEFAULT_COLLECTION,
  COURSE_COLLECTION,
  SEQUENCE_COLLECTION,
  RANDOM_COLLECTION} from 'core/commonTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const COLLS_PER_REQUEST_LIMIT = 12;       // типова к-сть колекцій за один запит до бекенду
const DEFAULT_SUBJECTS = [];              // значення предметів по-замовчуванню

const isVerbose = DEBUG && true;
const prefix = '- - - CollectionAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverCollectionsAsGuest(opts = {}) {
  trace(`apiFetchDiscoverCollectionsAsGuest`);
  const {
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_DISCOVER_COLLECTIONS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [TYPE_FLD]: type,
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [TYPE_FLD]: type,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverCollections(opts = {}) {
  trace(`apiFetchDiscoverCollections`);
  const {
    type,                                 // тип колекції (default, course, ...)
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_DISCOVER_COLLECTIONS_CMD,
    [PAYLOAD]: cursor ? {
      [TYPE_FLD]: type,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [TYPE_FLD]: type,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchSubjectCollections(opts = {}) {
  trace(`apiFetchSubjectCollections`);
  const {
    type,                                 // тип колекції (default, course, ...)
    subjectId,                            // код предмету
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації колекцій
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_SUBJECT_COLLECTIONS_CMD,
    [PAYLOAD]: cursor ? {
      [SUBJECT_ID_FLD]: subjectId,
      [TYPE_FLD]: type,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [SUBJECT_ID_FLD]: subjectId,
      [TYPE_FLD]: type,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserCollectionsAsGuest(opts = {}) {
  trace(`apiFetchUserCollectionsAsGuest`);
  const {
    type,                                 // тип колекції (default, course, ...)
    userId,                               // код юзера
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_USER_COLLECTIONS_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [USER_ID_FLD]: userId,
        [TYPE_FLD]: type,
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [USER_ID_FLD]: userId,
        [TYPE_FLD]: type,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserCollections(opts = {}) {
  trace(`apiFetchUserCollections`);
  const {
    type,                                 // тип колекції (default, course, ...)
    userId,                               // код юзера
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_USER_COLLECTIONS_CMD,
    [PAYLOAD]: cursor ? {
      [USER_ID_FLD]: userId,
      [TYPE_FLD]: type,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [USER_ID_FLD]: userId,
      [TYPE_FLD]: type,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyCSubscriptions(opts = {}) {
  trace(`apiFetchMyCSubscriptions`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = COLLS_PER_REQUEST_LIMIT,      // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_MY_CSUBSCRIPTIONS_CMD,
    [PAYLOAD]: cursor ? {
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyCollections() {
  trace(`apiFetchMyCollections`);
  return await wsockFetch({
    [CMD]: FETCH_MY_COLLECTIONS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCollectionAsGuest(id) {
  trace(`apiFetchCollectionAsGuest`);
  return await httpFetch(FETCH_COLLECTION_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': 'en',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [COLLECTION_ID_FLD]: id,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCollection(id) {
  trace(`apiFetchCollection: id=${id}`);
  return await wsockFetch({
    [CMD]: FETCH_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateCollection(collection) {
  trace(`apiCreateCollection`);
  const {
    type,
    languageId,
    name,
    description,
    comments,
    attachments,
    subjects = DEFAULT_SUBJECTS,          // sic!: для DEFAULT_COLLECTION значенням буде [] !!!
    accessCost,
    isPublic = false} = collection;
  return await wsockFetch({
    [CMD]: CREATE_COLLECTION_CMD,
    [PAYLOAD]: attachments ? {
      [TYPE_FLD]: type,
      [LANGUAGE_ID_FLD]: languageId,
      [NAME_FLD]: name,
      [DESCRIPTION_FLD]: description,
      [COMMENTS_FLD]: comments,
      [ATTACHMENTS_FLD]: attachments,     // sic!: attachments has been defined !!!
      [SUBJECTS_FLD]: subjects,
      [ACCESS_COST_FLD]: accessCost,
      [IS_PUBLIC_FLD]: isPublic
    } : {
      [TYPE_FLD]: type,
      [LANGUAGE_ID_FLD]: languageId,
      [NAME_FLD]: name,
      [DESCRIPTION_FLD]: description,
      [COMMENTS_FLD]: comments,
      [SUBJECTS_FLD]: subjects,
      [ACCESS_COST_FLD]: accessCost,
      [IS_PUBLIC_FLD]: isPublic
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateCollection(collection, patchObject) {
  trace(`apiUpdateCollection`);
  const {id} = collection;
  const {
    languageId,
    name,
    description,
    comments,
    attachments,
    subjects = DEFAULT_SUBJECTS,            // sic!: для DEFAULT_COLLECTION значенням буде [] !!!
    accessCost,
    isPublic,
    isPublished} = patchObject;
  return await wsockFetch({
    [CMD]: UPDATE_COLLECTION_CMD,
    [PAYLOAD]: attachments ? {
      [COLLECTION_FLD]: {
        [ID_FLD]: id,
        [LANGUAGE_ID_FLD]: languageId,
        [NAME_FLD]: name,
        [DESCRIPTION_FLD]: description,
        [COMMENTS_FLD]: comments,
        [ATTACHMENTS_FLD]: attachments,     // sic!: attachments has been defined !!!
        [SUBJECTS_FLD]: subjects,
        [ACCESS_COST_FLD]: accessCost,
        [IS_PUBLIC_FLD]: isPublic,
        [IS_PUBLISHED_FLD]: isPublished
      }} : {
      [COLLECTION_FLD]: {
        [ID_FLD]: id,
        [LANGUAGE_ID_FLD]: languageId,
        [NAME_FLD]: name,
        [DESCRIPTION_FLD]: description,
        [COMMENTS_FLD]: comments,
        [SUBJECTS_FLD]: subjects,
        [ACCESS_COST_FLD]: accessCost,
        [IS_PUBLIC_FLD]: isPublic,
        [IS_PUBLISHED_FLD]: isPublished
    }}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiBanCollection(id) {
  trace(`apiBanCollection: id=${id}`);
  return await wsockFetch({
    [CMD]: BAN_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteCollection(id) {
  trace(`apiDeleteCollection: id=${id}`);
  return await wsockFetch({
    [CMD]: DELETE_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiAddStaplesToCollection(collId, stapleIds) {
  trace(`apiAddStaplesToCollection: collId=${collId}, stapleIds=${stapleIds}`);
  return await wsockFetch({
    [CMD]: ADD_STAPLES_TO_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: collId,
      [STAPLE_IDS_FLD]: stapleIds
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiRemoveStaplesFromCollection(collId, stapleIds) {
  trace(`apiRemoveStaplesFromCollection: collId=${collId}, stapleIds=${stapleIds}`);
  return await wsockFetch({
    [CMD]: REMOVE_STAPLES_FROM_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: collId,
      [STAPLE_IDS_FLD]: stapleIds
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateCollectionStapleDatas(collId, stapleDatas) {
  trace(`apiUpdateCollectionStapleDatas: collId=${collId}, stapleDatas=${stapleDatas}`);
  return await wsockFetch({
    [CMD]: UPDATE_COLLECTION_STAPLEDATAS_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: collId,
      [STAPLEDATAS_FLD]: stapleDatas
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFollowCollection(id) {
  trace(`apiFollowCollection: id=${id}`);
  return await wsockFetch({
    [CMD]: FOLLOW_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUnfollowCollection(id) {
  trace(`apiUnfollowCollection: id=${id}`);
  return await wsockFetch({
    [CMD]: UNFOLLOW_COLLECTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchFollowedCollectionIds() {
  trace(`apiFetchFollowedCollectionIds`);
  return await wsockFetch({
    [CMD]: FETCH_FOLLOWED_COLLECTION_IDS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCSubscribedCollectionIds() {
  trace(`apiFetchCSubscribedCollectionIds`);
  return await wsockFetch({
    [CMD]: FETCH_CSUBSCRIBED_COLLECTION_IDS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCSubscription(collectionId) {
  trace(`apiFetchCSubscription: collectionId=${collectionId}`);
  return await wsockFetch({
    [CMD]: FETCH_CSUBSCRIPTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: collectionId // attn: a) !!!
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateCSubscription(id) {
  trace(`apiCreateCSubscription: id=${id}`);
  return await wsockFetch({
    [CMD]: CREATE_CSUBSCRIPTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCancelCSubscription(id) {
  trace(`apiCancelCSubscription: id=${id}`);
  return await wsockFetch({
    [CMD]: CANCEL_CSUBSCRIPTION_CMD,
    [PAYLOAD]: {
      [COLLECTION_ID_FLD]: id
    }
  });
}
