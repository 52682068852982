// -------------------------------------------------------------------------------------------------
//  paragraph.js
//  - - - - - - - - - -
//  Логіка поведінки при натисканні ENTER.
// -------------------------------------------------------------------------------------------------
import EditListPlugin from 'slate-edit-list';
import EditBlockquotePlugin from 'slate-edit-blockquote';
import {defaultListOptions} from 'components/RichEditor/core/rich-list'
import {defaultBlockquoteOptions} from 'components/RichEditor/core/rich-blockquote'
import {blockTypes} from 'core/richTypes';

export default function autoreplaceParagraph(change) {
  const {value} = change;
  const {blocks, texts, selection} = value;
  const currentBlock = blocks.get(0);
  const currentNode = texts.get(0);
  const currentLineText = currentNode.text;
  const {isSelectionInList} = EditListPlugin(defaultListOptions).utils;
  const {isSelectionInBlockquote} = EditBlockquotePlugin(defaultBlockquoteOptions).utils;

  if (
    currentBlock.type === blockTypes.CODEBLOCK
    || currentBlock.type === blockTypes.CODEBLOCK_LINE
    || isSelectionInList(value)
    || isSelectionInBlockquote(value)
    || currentLineText.length > selection.focusOffset
  ) {
    return;
  }

  return change.insertBlock(blockTypes.PARAGRAPH);
}
