// -------------------------------------------------------------------------------------------------
//  SelectObjects.js
//  - - - - - - - - - -
//  Вибір одного (чи декількох) обʼєктів із переданого списку.
//  Повертає вибраний обʼєкт як параметр колбека onSuccess.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import Icon from 'components/UI/icons/Icon';
import SelectField from 'components/UI/fields/SelectField';
import styles from './SelectObjects.scss';

const pubsub = new PubSub();

const isVerbose = DEBUG && true;
const prefix = '- - - SelectObjects';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function selectObjects({title, description, symbolName, choices, isMulti, onSuccess}) {
  pubsub.publish({
    title: title,
    description: description,
    symbolName: symbolName,
    choices: choices,
    isMulti: isMulti,
    onSuccess: onSuccess,
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class SelectObjects extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    title: null,
    description: null,
    symbolName: "",
    choices: null,
    selObjects: null,
    isMulti: 1,
    isProcessing: false,
    isDisplayed: false,
    onSuccess: null
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({title, description, symbolName, choices, isMulti, onSuccess}) => {
      this.setState({
        title,
        description,
        symbolName,
        choices,
        isMulti,
        isProcessing: false,
        isDisplayed: true,
        onSuccess: onSuccess
      });
      PreventBodyScroll();
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = true;
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  createClickHandler(handler) {
    const {selObjects} = this.state;
    return () => {
      this.setState({isProcessing: true});
      handler && handler(selObjects);
      this.handleClose();
    };
  }

  handleClose = () => {
    this.setState({selObjects: null, isDisplayed: false});
    RestoreBodyScroll();
  }

  handleObjectsChange = (objects) => {
    trace(`handleObjectChange`);
    this.setState({
      selObjects: objects,
    });
  }

  render() {
    const {
      title,
      description,
      symbolName,
      choices,
      selObjects,
      isMulti,
      isProcessing,
      isDisplayed,
      onSuccess} = this.state;
    if (!isDisplayed) {
      return null;
    }
    return (
      <div className={styles.backgroundWrapper} onClick={this.handleClose}>
        <div className={styles.SelectObjects} onClick={e => e.stopPropagation()}>
          <h1><Icon symbolName={symbolName} className={styles.icon} />{title}</h1>
          <p>{description}:</p>
          <div className={styles.selectHolder}>
            <SelectField
              label={''}
              value={selObjects}
              error={''}
              placeholder={t`Select something...`}
              choices={choices}
              isAutoFocused={true}
              isClearable={false}
              isInsertable={false}
              isMenuOpenOnFocus={false}
              isMenuCloseOnSelect={true}
              isMulti={isMulti}
              isRequired={false}
              isDisabled={isProcessing}
              onChange={this.handleObjectsChange}
            />
          </div>
          <ul>
            <button
              key="1"
              className={classnames({[styles.isDisabled]: isProcessing})}
              disabled={isProcessing}
              onClick={this.createClickHandler(null)}>
              {t`Cancel`}
            </button>
            <button
              key="2"
              className={classnames(styles.isAccented, {[styles.isDisabled]: isProcessing || !selObjects})}
              disabled={isProcessing || !selObjects}
              onClick={(isProcessing || !selObjects) ? null : this.createClickHandler(onSuccess)}>
              {t`Proceed`}
            </button>
          </ul>
        </div>
        <NoTabbar />
      </div>
    );
  }
}
