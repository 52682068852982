// -------------------------------------------------------------------------------------------------
//  core-rules.js
//  - - - - - - - - - - - - - -
//  Базові правила для перетворень між форматами Mdast <- - -> Slate.
//
//  API:
//  - - - - -
//  https://github.com/syntax-tree/mdast - MDAST / Markdown Abstract Syntax Tree format
// -------------------------------------------------------------------------------------------------
import {slateTypes} from 'core/richTypes';
import {mdastTypes} from 'core/mdastTypes';

// - - - - -
// Attn:
// - - - - -
// - підчас stringify перевід стрічки кодуємо новим параграфом (а не '\n');
// - такі порожні параграфи при перетворенні в MDAST позначаємо як mdastTypes.BREAK;
//   для комірок таблиці це правило не застосовується (бо помилки з парсінгом conmark);
//
export const paragraphRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.PARAGRAPH,
  toMdast: (object, index, parent, {visitChildren}) => {

    // a) якщо параграф порожній (або лише пробіли) і це не таблиця, то це розрив стрічки (break)
    if (parent.type !== slateTypes.TABLE_CELL
      && object.nodes.length === 1
      && object.nodes[0].leaves.length === 1
      && object.nodes[0].leaves[0].text.trim() === '') {
      return ({
        type: mdastTypes.BREAK,
      });
    }

    // b) інакше це звичайний параграф
    return ({
      type: mdastTypes.PARAGRAPH,
      children: visitChildren(object),
    });
  },
  matchMdast: node => node.type === mdastTypes.PARAGRAPH,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.PARAGRAPH,
      nodes: visitChildren(node)
    });
  }
}

export const breakRule = {
  matchMdast: node => node.type === mdastTypes.BREAK,
  toSlate: () => {
    return ({
      object: 'text',
      leaves: [{ object: 'leaf', text: '', marks: []}],
    })
  }
}

export const blockquoteRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.BLOCKQUOTE,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.BLOCKQUOTE,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.BLOCKQUOTE,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.BLOCKQUOTE,
      nodes: visitChildren(node)
    });
  }
}

// - - - - -
// Attn:
// - - - - -
// - для отримання value ігноруємо slate-розмітку і робимо серіалізацію (склейку тексту);
// - при серіалізації закладємось на те, що Slate має жорстко задану структуру (та глибину) дерева;
//
// Note: old way of stringifying/parsing: a) JSON.stringify(object.nodes), b) JSON.parse(node.value)
//
export const codeblockRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.CODEBLOCK,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.CODEBLOCK,
      lang: object.data ? object.data.syntax : null,
      value: object.nodes.map(
        (codeline) => codeline.nodes.map(
          (textline) => textline.leaves.map(
            (leaf) => {
              return leaf.text ? leaf.text : '';
            }
          )
        )
      ).reduce((result, arrElem) => { return arrElem ? result + arrElem + '\n' : result }, ''),
    });
  },
  matchMdast: node => node.type === mdastTypes.CODEBLOCK,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.CODEBLOCK,
      data: {
        syntax: node.lang ? node.lang : 'txt'
      },
      nodes: [{
        object: 'block',
        type: slateTypes.CODEBLOCK_LINE,
        nodes: [{
          object: 'text',
          leaves: [{
            object: 'leaf',
            text: node.value,
            marks: []
          }]
        }],
      }]
    });
  }
}

// - - - - -
// Attn:
// - - - - -
// - для отримання value ігноруємо slate-розмітку і робимо серіалізацію (склейку тексту);
// - при серіалізації закладємось на те, що Slate має жорстко задану структуру (та глибину) дерева;
// - slateTypes.INLINE_CODE може містити лише текст + заборона autoreplace плагінів всередині;
//
export const inlineCodeRule = {
  matchSlate: node => node.object === 'inline' && node.type === slateTypes.INLINE_CODE,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.INLINE_CODE,
      value: object.nodes[0].leaves.reduce((result, elem) => { return elem.text ? result + elem.text : result; }, '')
    });
  },
  matchMdast: node => node.type === mdastTypes.INLINE_CODE,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'inline',
      type: slateTypes.INLINE_CODE,
      nodes: [{
        object: 'text',
        leaves: [{
          object: 'leaf',
          text: node.value,
          marks: []
        }]
      }],
    });
  }
}

export const headingOneRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_1,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 1,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 1,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_1,
      nodes: visitChildren(node)
    });
  }
}

export const headingTwoRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_2,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 2,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 2,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_2,
      nodes: visitChildren(node)
    });
  }
}

export const headingThreeRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_3,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 3,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 3,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_3,
      nodes: visitChildren(node)
    });
  }
}

export const headingFourRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_4,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 4,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 4,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_4,
      nodes: visitChildren(node)
    })
  }
}

export const headingFiveRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_5,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 5,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 5,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_5,
      nodes: visitChildren(node)
    });
  }
}

export const headingSixRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HEADING_6,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HEADING,
      depth: 6,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HEADING && node.depth === 6,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HEADING_6,
      nodes: visitChildren(node)
    });
  }
}

export const horizontalRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.HR,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.HR,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.HR,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.HR,
      nodes: visitChildren(node)
    })
  }
}

export const tableRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.TABLE,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.TABLE,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.TABLE,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.TABLE,
      nodes: visitChildren(node)
    });
  }
}

export const tableRowRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.TABLE_ROW,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.TABLE_ROW,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.TABLE_ROW,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.TABLE_ROW,
      nodes: visitChildren(node)
    });
  }
}

// ToDo: робити перетворення не в JSON.stringify, а в звичайний текст !!!
export const tableCellRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.TABLE_CELL,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.TABLE_CELL,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.TABLE_CELL,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.TABLE_CELL,
      nodes: visitChildren(node)
    });
  }
}

export const listRule = {
  matchSlate: node => node.object === 'block' && (node.type === slateTypes.UL_LIST || node.type === slateTypes.OL_LIST),
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.LIST,
      ordered: object.type === slateTypes.OL_LIST,
      start: object.type === slateTypes.OL_LIST ? 1 : null,
      loose: false,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.LIST,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: node.ordered ? slateTypes.OL_LIST : slateTypes.UL_LIST,
      nodes: visitChildren(node)
    });
  }
}

export const listItemRule = {
  matchSlate: node => node.object === 'block' && node.type === slateTypes.LIST_ITEM,
  toMdast: (object, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.LIST_ITEM,
      loose: false,
      checked: (object.checked !== undefined && object.checked !== null) ? object.checked : null,
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.LIST_ITEM,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'block',
      type: slateTypes.LIST_ITEM,
      checked: node.checked,
      nodes: visitChildren(node)
    });
  }
}

export const linkRule = {
  matchSlate: node => node.object === 'inline' && node.type === slateTypes.LINK,
  toMdast: (object, index, parent, {visitChildren}) => {
    // href: "https://imart.ua "bravo""
    const parts = object.data.href.split(' ');

    return ({
      type: mdastTypes.LINK,
      title: parts && parts.length > 1 ? parts[1].replace(/"/g, '') : null,
      url: parts[0],
      children: visitChildren(object)
    });
  },
  matchMdast: node => node.type === mdastTypes.LINK,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'inline',
      type: slateTypes.LINK,
      data: {
        href: `${node.url}${node.title ? ` "${node.title}"` : ''}`
      },
      nodes: visitChildren(node)
    });
  }
}

export const boldRule = {
  matchSlate: node => node.object === 'mark' && node.type === slateTypes.BOLD,
  toMdast: (mark, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.BOLD,
      children: visitChildren(mark)
    });
  },
  matchMdast: node => node.type === mdastTypes.BOLD,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'mark',
      type: slateTypes.BOLD,
      nodes: visitChildren(node)
    });
  }
}

export const italicRule = {
  matchSlate: node => node.object === 'mark' && node.type === slateTypes.ITALIC,
  toMdast: (mark, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.ITALIC,
      children: visitChildren(mark)
    });
  },
  matchMdast: node => node.type === mdastTypes.ITALIC,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'mark',
      type: slateTypes.ITALIC,
      nodes: visitChildren(node)
    });
  }
}

export const strikeRule = {
  matchSlate: node => node.object === 'mark' && node.type === slateTypes.STRIKE,
  toMdast: (mark, index, parent, {visitChildren}) => {
    return ({
      type: mdastTypes.STRIKE,
      children: visitChildren(mark)
    });
  },
  matchMdast: node => node.type === mdastTypes.STRIKE,
  toSlate: (node, index, parent, {visitChildren}) => {
    return ({
      object: 'mark',
      type: slateTypes.STRIKE,
      nodes: visitChildren(node)
    });
  }
}
