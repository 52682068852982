// -------------------------------------------------------------------------------------------------
//  MessageCommands.js
//  - - - - - - - - - -
//  Випадаючий список команд для Message/MyMessage.
//
//  Attn:
//  - - - - -
//  - onDelete викликати з setTimeout, щоб дати час на закриття суб-компоненти PopupMenu;
//    причому затримка повинна бути більшою, аніж setTimeout компоненти PopupMenu (~50):
//    onClick={() => setTimeout(onDelete, 100)
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import {t} from 'ttag';
import Icon from 'components/UI/icons/Icon';
import PopupMenu from 'components/UI/PopupMenu';
import styles from './MessageCommands.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MessageCommands = ({prefix = '', className, messageId, onDelete, onTogglePopup}) => (
  <div className={classnames(styles.MessageCommands, className)}>
    <button className={styles.moreButton} title={t`More`} onClick={onTogglePopup}>
      <Icon symbolName="more-vertical" className={styles.icon} />
    </button>
    <PopupMenu id={prefix + messageId} className={styles.popupMenu}>
      <ul>
        {onDelete &&
          <li title={t`Delete`}>
            <p onClick={() => setTimeout(onDelete, 100)}>
              <Icon symbolName="delete" className={styles.icon} />
            </p>
          </li>
        }
      </ul>
    </PopupMenu>
  </div>
);

MessageCommands.propTypes = {
  prefix: Types.string,                   // префікс для побудови унікального ідентифікатора
  className: Types.string,                // custom class name
  messageId: Types.string,                // код меседжу
  onDelete: Types.func,                   // функція видалення меседжу
  onTogglePopup: Types.func,              // функція перемикання стану попапу
};

export default MessageCommands;
