// -------------------------------------------------------------------------------------------------
//  MyCSubscriptionsContainer.js
//  - - - - - - - - - - - - - -
//  Контейнер підписок користувача на курс-колекції (URL: '/my/subscriptions').
//
//  Attn:
//  - - - - -
//  - функція getMyCSubscriptionsFeed віддає колекції тільки ДО курсора, щоб не було "пропусків" в фіді;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR, DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {SUBJECT_TAB, MY_CSUBSCRIPTIONS_FEED} from 'core/uiTypes';
import {fetchMyCSubscriptions} from 'actions/CollectionActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const MyCSubscriptions = Loader.create(() => import('./MyCSubscriptions'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyCSubscriptionsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyCSubscriptionsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {[MY_CSUBSCRIPTIONS_FEED]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const {collections, collectionCursor, areCollectionsLoaded} = CollectionStore.getMyCSubscriptionsFeed();
    const owners = UserStore.getSomeUsersMap(collections.reduce((a, c) => a.add(c.ownerId), Set().add(myId)));
    return {isLoggedIn, myAccountType, myId, owners, collections, viewMode, collectionCursor, areCollectionsLoaded};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.viewMode !== nextState.viewMode
      || this.state.myId !== nextState.myId
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.collectionCursor !== nextState.collectionCursor
      || this.state.areCollectionsLoaded !== nextState.areCollectionsLoaded
      || this.state.collections.size !== nextState.collections.size
      || this.state.owners.size !== nextState.owners.size
      || !is(this.state.collections, nextState.collections)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    // CTX:
    substituteSidebarTabMode(SUBJECT_TAB);
    substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
  }

  isFetchingMyCSubscriptions = false;

  fetchMyCSubscriptionPage = async () => {
    trace(`fetchMyCSubscriptionPage(1): isFetching=${this.isFetchingMyCSubscriptions}`);
    if (!this.isFetchingMyCSubscriptions) {
      const {collectionCursor = DEFAULT_TSN12_CURSOR} = this.state;
      trace(`fetchMyCSubscriptionPage(2): cursor='${collectionCursor}'`);
      this.isFetchingMyCSubscriptions = true;
      await fetchMyCSubscriptions({cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT});
      this.isFetchingMyCSubscriptions = false;
    }
  }

  render() {
    const {isLoggedIn, myAccountType, myId, owners, collections, viewMode, areCollectionsLoaded} = this.state;
    if (!isLoggedIn) {
      return null;
    }
    trace(`render`);
    return (
      <MyCSubscriptions
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        collections={collections}
        viewMode={viewMode}
        areCollectionsLoaded={areCollectionsLoaded}
        onPageTrigger={this.fetchMyCSubscriptionPage}
      />
    );
  }
}

export default Container.create(MyCSubscriptionsContainer, {pure: false});
