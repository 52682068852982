// -------------------------------------------------------------------------------------------------
//  commonNode.js
//  - - - - - - - - - -
//  Хелпери для різних типів нод.
// -------------------------------------------------------------------------------------------------
import React from 'react';

export function renderNode(Tag) {
  const NodeComponent = ({attributes, children, node}) => {
    return (
      <Tag
        {...attributes}
        data-slate-type={Tag}
      >
        {children}
      </Tag>
    );
  };
  NodeComponent.displayName = `${Tag}-node`;
  return NodeComponent;
}
