// -------------------------------------------------------------------------------------------------
//  rich-list.js (*)
//  - - - - - - - - - -
//  List node: plugin & default options.
//
//  Attn:
//  - - - - -
//  - UL_LIST, OL_LIST заборонені в контексті CODEBLOCK, TABLE;
// -------------------------------------------------------------------------------------------------
// ToDo: ListIcon !!!

import React from 'react';
import {renderNode} from './commonNode';
import {blockTypes} from 'core/richTypes';

export const defaultListOptions = {
  types: [blockTypes.OL_LIST, blockTypes.UL_LIST], // the possibles types for list containers
  typeItem: blockTypes.LIST_ITEM,         // the type of list items
  typeDefault: blockTypes.PARAGRAPH,      // the type of default block in items
  ordered: false                          // default type of list
};

const ListPlugin = (opts = {}) => {
  // const options = Object.assign(defaultListOptions, opts);

  return {
    renderNode: (props) => {
      if (props.node.type === blockTypes.UL_LIST
        || props.node.type === blockTypes.OL_LIST
        || props.node.type === blockTypes.LIST_ITEM) {
        return renderNode(props.node.type)(props);
      }
    }
  }
};

export default ListPlugin;
