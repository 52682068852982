// -------------------------------------------------------------------------------------------------
//  ConfirmAction.js
//  - - - - - - - - - -
//
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import PubSub from 'utils/PubSub';
import {NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import styles from './ConfirmAction.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function confirmAction({title, description}, buttons) {
  pubsub.publish({
    title: title,
    description: description,
    buttons: (buttons && buttons.length) ? buttons : ['OK'],
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class ConfirmAction extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    title: null,
    description: null,
    buttons: [],
    isProcessing: false,
    isDisplayed: false,
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({title, description, buttons}) => {
      this.setState({
        title,
        description,
        buttons,
        isProcessing: false,
        isDisplayed: true,
      });
      PreventBodyScroll();
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  createClickHandler(handler) {
    return () => {
      this.setState({isProcessing: true});
      handler && handler();
      this.handleClose();
    };
  }

  handleClose = () => {
    this.setState({isDisplayed: false});
    RestoreBodyScroll();
  }

  render() {
    const {title, description, buttons, isProcessing, isDisplayed} = this.state;
    if (!isDisplayed) {
      return null;
    }
    return (
      <div className={styles.backgroundWrapper} onClick={this.handleClose}>
        <div className={styles.ConfirmAction} onClick={e => e.stopPropagation()}>
          <h1>{title}</h1>
          <p>{description}</p>
          <ul>
            {buttons.map((button, index) =>
              <button
                key={index}
                className={classnames({[styles.isAccented]: button.isAccented, [styles.isDisabled]: isProcessing,})}
                onClick={this.createClickHandler(button.onClick)}>
                {typeof button === 'string' ? button : button.title}
              </button>
            )}
          </ul>
        </div>
        <NoTabbar />
      </div>
    );
  }
}
