// -------------------------------------------------------------------------------------------------
//  Informer.js
//  - - - - - - - - - -
//  Показує нотифікації.
//
//  Docs:
//  - - - - -
//  https://www.npmjs.com/package/react-hot-toast
//  https://react-hot-toast.com/docs/styling
//
//  icon: '❌',
//  icon: '✅',
//
//  showInfo('Text');
//  showSuccess('Success');
//  showWarning('Warning');
//  showError('Error');
// -------------------------------------------------------------------------------------------------
// ToDo: додати код повідомлення (msgRef), щоб НЕ показувати декілька однакових повідомлень !!!

import React from 'react';
import toast, {Toaster} from 'react-hot-toast';
import styles from './Informer.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function showInfo(text, duration = 4000) {
  toast(text, {
    className: styles.showInfo,
    duration: duration,
    position: 'bottom-center',
    icon: '',
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function showSuccess(text, duration = 4000) {
  toast(text, {
    className: styles.showSuccess,
    duration: duration,
    position: 'bottom-center',
    icon: '✅',
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function showWarning(text, duration = 4000) {
  toast(text, {
    className: styles.showWarning,
    duration: duration,
    position: 'bottom-center',
    icon: '⚠',
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function showError(text, duration = 4000) {
  toast(text, {
    className: styles.showError,
    duration: duration,
    position: 'bottom-center',
    icon: '🚫',
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Informer extends React.Component {
  render() {
    return (
      <Toaster
        containerClassName={styles.Informer}
      />
    );
  }
}
