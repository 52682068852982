// -------------------------------------------------------------------------------------------------
//  HelpScreen.js
//  - - - - - - - - - -
//  Показуємо підказку по роботі з системою в модальному вікні.
//
//  <iframe>:
//  - - - - -
//  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import classnames from 'classnames';
import {t} from 'ttag';
import PubSub from 'utils/PubSub';
import {NoTabbar, PreventBodyScroll, RestoreBodyScroll} from 'application/Body';
import {cleanifyMeta} from 'utils/converters';
import {getSettingsVideoUrl} from 'utils/settingsTools';
import styles from './HelpScreen.scss';

const pubsub = new PubSub();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function showHelpScreen({title, subtitle, videoSlug}, buttons) {
  pubsub.publish({
    title: title,
    subtitle: subtitle,
    videoUrl: getSettingsVideoUrl(videoSlug || 'defaultHelpVideo'),
    buttons: (buttons && buttons.length) ? buttons : ['OK'],
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class HelpScreen extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    title: null,
    subtitle: null,
    videoUrl: null,
    buttons: [],
    isProcessing: false,
    isDisplayed: false,
  }

  componentDidMount() {
    this.unsubscribe = pubsub.subscribe(({title, subtitle, videoUrl, buttons}) => {
      this.setState({
        title,
        subtitle,
        videoUrl,
        buttons,
        isProcessing: false,
        isDisplayed: true,
      });
      PreventBodyScroll();
    });
    this.context.router.history.listen(this.handleClose); // close on location changes
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  createClickHandler(handler) {
    return () => {
      this.setState({isProcessing: true});
      handler && handler();
      this.handleClose();
    };
  }

  handleClose = () => {
    this.setState({isDisplayed: false});
    RestoreBodyScroll();
  }

  render() {
    const {title = '', subtitle = '', videoUrl, buttons, isProcessing, isDisplayed} = this.state;
    if (!isDisplayed) {
      return null;
    }
    return (
      <F>
        <Helmet>
          <title>{title + ` – Contentle`}</title>
          <meta property="og:type" content="article" />
          <meta property="og:image" content='' />
          <meta property="og:title" content={title + ` – Contentle`} />
          <meta property="og:description" content={cleanifyMeta(subtitle)} />
          <meta name="description" content={cleanifyMeta(subtitle)} />
          <meta name="keywords" content={title + ' ' + subtitle} />
        </Helmet>
        <div className={styles.backgroundWrapper} onClick={this.handleClose}>
          <div className={styles.HelpScreen} onClick={e => e.stopPropagation()}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <div className={styles.videoHolder}>
              <iframe
                src={videoUrl}
                allow="autoplay; fullscreen" // "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture; web-share"
                sandbox="allow-same-origin allow-scripts allow-presentation"
                frameBorder="0"
              ></iframe>
            </div>
            <Link to="/settings" rel="nofollow noopener noreferrer" className={styles.settingsButton}>
              {t`Click here and turn off appropriate option to hide all help videos.`}
            </Link>
            <ul>
              {buttons.map((button, index) =>
                <button
                  key={index}
                  className={classnames({[styles.isAccented]: button.isAccented, [styles.isDisabled]: isProcessing,})}
                  onClick={this.createClickHandler(button.onClick)}>
                  {typeof button === 'string' ? button : button.title}
                </button>
              )}
            </ul>
          </div>
          <NoTabbar />
        </div>
      </F>
    );
  }
}
