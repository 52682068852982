// -------------------------------------------------------------------------------------------------
//  actionTypes.js
//  - - - - - - - - - -
//  Загальний перелік типів екшенів (спільний для усіх сторів).
//  Назви екшенів повинні виражати подію яка відбулася.
// -------------------------------------------------------------------------------------------------

export const MY_ACCOUNT_WAS_FETCHED_ACTION                      = 'MYACFT';
export const MY_ACCOUNT_WAS_UPDATED_ACTION                      = 'MYACUP';

export const MY_FRIENDS_WERE_FETCHED_ACTION                     = 'MYFRFT';
export const FRIENDSHIP_REQUEST_WAS_SENT_ACTION                 = 'FSRWS0';
export const FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION        = 'FSRWS@';
export const FRIENDSHIP_REQUEST_WAS_SENT_TO_CONTACTS_ACTION     = 'FSRSKX';
export const FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION     = 'FSRWAU';
export const FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION       = 'FSRWAM';
export const FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION             = 'FSRRJT';
export const FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION             = 'FSRRCV';
export const FRIENDSHIP_WAS_CANCELED_ACTION                     = 'FS0RMD';

export const MY_CONTACTS_WERE_FETCHED_ACTION                    = 'MYKXFT';
export const CONTACT_WAS_CREATED_ACTION                         = 'KNTADD';
export const CONTACT_WAS_UPDATED_ACTION                         = 'KNTUPD';
export const CONTACT_WAS_DELETED_ACTION                         = 'KNTDEL';
export const CONTACT_WAS_SELECTED_ACTION                        = 'KNTSEL';
export const CONTACT_WAS_UNSELECTED_ACTION                      = 'KNTUNS';
export const CONTACTS_WERE_UNSELECTED_ACTION                    = 'KNXUNS';
export const CONTACTS_OF_GROUP_WERE_SELECTED_ACTION             = 'KXGRSL';
export const CONTACTS_OF_GROUP_WERE_UNSELECTED_ACTION           = 'KXGRUN';
export const CONTACT_WAS_CONNECTED_TO_USER_ACTION               = 'KNT2UU';

export const CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION            = 'KNNTRC';

export const MY_GROUPS_WERE_FETCHED_ACTION                      = 'MYGRFT';
export const GROUP_WAS_FETCHED_ACTION                           = 'GRPFTT';
export const GROUP_WAS_CREATED_ACTION                           = 'GRPADD';
export const GROUP_WAS_UPDATED_ACTION                           = 'GRPUPD';
export const GROUP_WAS_DELETED_ACTION                           = 'GRPDEL';

export const DISCOVER_ADVERTS_WERE_FETCHED_ACTION               = 'DIADFT';
export const SUBJECT_ADVERTS_WERE_FETCHED_ACTION                = 'SJADFT';
export const USER_ADVERTS_WERE_FETCHED_ACTION                   = 'UUADFT';

export const ADVERT_WAS_FETCHED_AS_GUEST_ACTION                 = 'ADPFGU';
export const ADVERT_WAS_FETCHED_ACTION                          = 'ADPSFT';
export const ADVERT_WAS_CREATED_ACTION                          = 'ADPADD';
export const ADVERT_WAS_UPDATED_ACTION                          = 'ADPUPD';
export const ADVERT_WAS_DELETED_ACTION                          = 'ADPDEL';

export const DISCOVER_STAPLES_WERE_FETCHED_ACTION               = 'DISTFT';
export const FEATURED_STAPLES_WERE_FETCHED_ACTION               = 'FESTFT';
export const FOLLOWING_STAPLES_WERE_FETCHED_ACTION              = 'WASTFT';
export const SEARCHED_STAPLES_WERE_FETCHED_ACTION               = 'SESTFT';
export const CSUBSCRIPTION_STAPLES_WERE_FETCHED_ACTION          = 'SSSTGU';
export const COLLECTION_STAPLES_WERE_FETCHED_AS_GUEST_ACTION    = 'COSTGU';
export const COLLECTION_STAPLES_WERE_FETCHED_ACTION             = 'COSTFT';
export const MY_STAPLES_WERE_FETCHED_ACTION                     = 'MYSTFT';
export const USER_STAPLES_WERE_FETCHED_AS_GUEST_ACTION          = 'UUSTGU';
export const USER_STAPLES_WERE_FETCHED_ACTION                   = 'UUSTFT';

export const STAPLE_WAS_FETCHED_AS_GUEST_ACTION                 = 'STPFGU';
export const STAPLE_WAS_FETCHED_ACTION                          = 'STPSFT';
export const STAPLE_WAS_CREATED_ACTION                          = 'STPADD';
export const STAPLE_WAS_CLONED_ACTION                           = 'STPCLO';
export const STAPLE_WAS_UPDATED_ACTION                          = 'STPUPD';
export const STAPLE_WAS_BANNED_ACTION                           = 'STPBAN';
export const STAPLES_WERE_DELETED_ACTION                        = 'STPDEL';
export const STAPLE_WAS_SELECTED_ACTION                         = 'STPSEL';
export const STAPLE_WAS_UNSELECTED_ACTION                       = 'STPUNS';
export const STAPLES_WERE_UNSELECTED_ACTION                     = 'STXUNS';
export const STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION         = 'STCXSL';
export const STAPLES_OF_COLLECTION_WERE_UNSELECTED_ACTION       = 'STCXUN';

export const STAPLE_UPDATED_NTF_WAS_RECEIVED_ACTION             = 'STNTRC';

export const DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION           = 'DCXWFT';
export const FEATURED_COLLECTIONS_WERE_FETCHED_ACTION           = 'FCXWFT';
export const SEARCHED_COLLECTIONS_WERE_FETCHED_ACTION           = 'SCXWFT';
export const SUBJECT_COLLECTIONS_WERE_FETCHED_ACTION            = 'JCXWFT';
export const USER_COLLECTIONS_WERE_FETCHED_AS_GUEST_ACTION      = 'UUCOGU';
export const USER_COLLECTIONS_WERE_FETCHED_ACTION               = 'UUCOFT';
export const MY_CSUBSCRIPTIONS_WERE_FETCHED_ACTION              = 'MYSSFT';
export const MY_COLLECTIONS_WERE_FETCHED_ACTION                 = 'MYCOFT';

export const COLLECTION_WAS_FETCHED_AS_GUEST_ACTION             = 'COLFGU';
export const COLLECTION_WAS_FETCHED_ACTION                      = 'COLFTT';
export const COLLECTION_WAS_CREATED_ACTION                      = 'COLADD';
export const COLLECTION_WAS_UPDATED_ACTION                      = 'COLUPD';
export const COLLECTION_WAS_BANNED_ACTION                       = 'COLBAN';
export const COLLECTION_WAS_DELETED_ACTION                      = 'COLDEL';
export const COLLECTION_STAPLEDATAS_WERE_UPDATED_ACTION         = 'CSDXUP';
export const COLLECTION_WAS_FOLLOWED_ACTION                     = 'COLFOL';
export const COLLECTION_WAS_UNFOLLOWED_ACTION                   = 'COLUNF';
export const FOLLOWED_COLLECTION_IDS_WERE_FETCHED_ACTION        = 'FCIDSF';
export const CSUBSCRIBED_COLLECTION_IDS_WERE_FETCHED_ACTION     = 'CSSIFT';
export const CSUBSCRIPTION_WAS_FETCHED_ACTION                   = 'CSSFET';
export const CSUBSCRIPTION_WAS_CREATED_ACTION                   = 'CSSCRE';
export const CSUBSCRIPTION_WAS_CANCELED_ACTION                  = 'CSSDEL';

export const COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION      = 'CPFWPU';
export const COLLECTION_PENDING_FIELDS_WERE_SENT_ACTION         = 'CPFWST';
export const COLLECTION_PENDING_FIELDS_WERE_PROCESSED_ACTION    = 'CPFWPR';
export const CONTACT_PENDING_FIELDS_WERE_CHANGED_ACTION         = 'KPFWPU';
export const CONTACT_PENDING_FIELDS_WERE_SENT_ACTION            = 'KPFWST';
export const CONTACT_PENDING_FIELDS_WERE_PROCESSED_ACTION       = 'KPFWPR';
export const GROUP_PENDING_FIELDS_WERE_CHANGED_ACTION           = 'GPFWPU';
export const GROUP_PENDING_FIELDS_WERE_SENT_ACTION              = 'GPFWST';
export const GROUP_PENDING_FIELDS_WERE_PROCESSED_ACTION         = 'GPFWPR';

export const MY_CONTEXTS_WERE_FETCHED_ACTION                    = 'MYCTXX';
export const USER_CONTEXTS_WERE_FETCHED_ACTION                  = 'USCTXX';

// export const MY_SCTX_CHATS_WERE_FETCHED_ACTION               = 'MYSHFT';
// export const USER_SCTX_CHATS_WERE_FETCHED_ACTION             = 'USHXFT';
// export const USER_CCTX_CHATS_WERE_FETCHED_ACTION             = 'UCHXFT';
export const STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION              = 'SXHXFT';
export const COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION          = 'CXHXFT'; // ToDo: COLLECTION_CTX !!!
export const USER_UCTX_CHATS_WERE_FETCHED_ACTION                = 'UXHXFT';
export const CHATS_WERE_FETCHED_ACTION                          = 'HXFT00';
export const CHAT_WAS_CREATED_ACTION                            = 'CH0CRE';
export const SCTX_CHATS_WERE_CREATED_ACTION                     = 'SHXCRE';
export const CHAT_WAS_UPDATED_ACTION                            = 'CH0UPD';
export const CHAT_WAS_CLOSED_ACTION                             = 'CH0CLS';
export const CHAT_WAS_DELETED_ACTION                            = 'CH0DEL';

export const CHAT_MESSAGES_WERE_FETCHED_ACTION                  = 'CHMSFT';
export const CHAT_MESSAGE_WAS_CREATED_ACTION                    = 'CHMSAD';
export const CHAT_MESSAGE_WAS_RECEIVED_ACTION                   = 'CHMSRC';
export const CHAT_MESSAGE_WAS_UPDATED_ACTION                    = 'CHMSUP';
export const CHAT_MESSAGE_WAS_READ_ACTION                       = 'CHMSRD';
export const CHAT_MESSAGE_WAS_DELETED_ACTION                    = 'CHMSDE';
export const CHAT_CONTEXT_WAS_UPDATED_ACTION                    = 'CHCTXU';

export const UNREAD_MESSAGES_WERE_FETCHED_ACTION                = 'UNMSFT';
export const UNREAD_MESSAGES_WERE_READ_ACTION                   = 'UNMSRD';
export const UNREAD_MESSAGE_WAS_OBSERVED_ACTION                 = 'UNMSOB';

export const PRESENCE_NTF_WAS_RECEIVED_ACTION                   = 'PRNTRC';

export const USERS_WERE_FETCHED_ACTION                          = 'UXFT00';
export const USER_PROFILES_WERE_FETCHED_ACTION                  = 'UPLXFI';

export const VIEWMODE_WAS_TOGGLED_ACTION                        = 'VMTOGL';
export const SIDEBAR_WAS_TOGGLED_ACTION                         = 'SBTOGL';
export const SIDEBAR_CONTEXT_WAS_SUBSTITUTED_ACTION             = 'SBCTXS';
export const SIDEBAR_CHAT_WAS_SUBSTITUTED_ACTION                = 'SBCHSU';
export const SIDEBAR_TABMODE_WAS_SUBSTITUTED_ACTION             = 'SBTMSU';

export const CALL_STATE_WAS_SUBSTITUTED_ACTION                  = 'CLSTSU';

export const SYSTEM_LANGUAGES_WERE_FETCHED_ACTION               = 'SYLNWF';
export const SYSTEM_COUNTRIES_WERE_FETCHED_ACTION               = 'SYYXWF';
export const SYSTEM_CATEGORIES_WERE_FETCHED_ACTION              = 'SYCGWF';
export const SYSTEM_SUBJECTS_WERE_FETCHED_ACTION                = 'SYSJWF';
export const SYSTEM_STATICPAGES_WERE_FETCHED_ACTION             = 'SYSPWF';

export const LOGGED_IN_ACTION                                   = 'LIN000';
export const LOGGED_OUT_ACTION                                  = 'LOUT00';
