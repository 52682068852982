// -------------------------------------------------------------------------------------------------
//  CreateStapleContainer.js
//  - - - - - - - - - - - -
//
//  Attn:
//  - - - - -
//  - список колекцій по-замовчуванню беремо із localStorage напряму (PRESET_COLLECTION_IDS);
// -------------------------------------------------------------------------------------------------
import React from 'react';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchMyCollections} from 'actions/CollectionActions';
import AccountStore from 'stores/AccountStore';
import CollectionStore from 'stores/CollectionStore';
import Loader from 'utils/ComponentLoader';

const CreateStaple = Loader.create(() => import('./CreateStaple'));
const isVerbose = DEBUG && true;
const prefix = '- - - CreateStapleContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CreateStapleContainer extends React.Component {
  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myAccount = AccountStore.getMyAccount();
    const myCollections = CollectionStore.getMyCollections();
    return {myAccount, myCollections};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.myCollections.size !== nextState.myCollections.size
      || !is(this.state.myAccount, nextState.myAccount)
      || !is(this.state.myCollections, nextState.myCollections);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    trace(`componentDidMount`);
    if (!CollectionStore.areMyCollectionsLoaded()) {
      fetchMyCollections();
    }
  }

  render() {
    trace(`render`);
    const {myAccount, myCollections} = this.state;
    const {accountType:myAccountType} = myAccount || {};
    return (
      <CreateStaple
        myAccountType={myAccountType}
        myCollections={myCollections}
      />
    );
  }
}

export default Container.create(CreateStapleContainer, {withProps: true, pure: false});
