// -------------------------------------------------------------------------------------------------
//  StaticPageContainer.js
//  - - - - - - - - - -
//  Контейнер сторінки статичних сторінок.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {fetchSystemStaticpages} from 'actions/SystemActions';
import SystemStore from 'stores/SystemStore';
import Loader from 'utils/ComponentLoader';

const StaticPage = Loader.create(() => import('./StaticPage'));
const isVerbose = DEBUG && true;
const prefix = '- - - StaticPageContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class StaticPageContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [SystemStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {pathname} = props.history.location;
    const slug = pathname ? pathname.toLowerCase().replace('/', '') : '';
    const staticpage = SystemStore.getStaticpage(slug);
    return {slug, staticpage};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.slug !== nextState.slug
    || !is(this.state.staticpage, nextState.staticpage);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    if (!SystemStore.areStaticpagesLoaded()) {
      await fetchSystemStaticpages();
    }
  }

  render() {
    const {slug, staticpage} = this.state;
    trace(`render`);
    return (
      <StaticPage
        slug={slug}
        staticpage={staticpage}
      />
    );
  }
}

export default Container.create(StaticPageContainer, {withProps: true, pure: false});
