// -------------------------------------------------------------------------------------------------
//  Spinner.js
//  - - - - - - - - - -
//  Індикатор процесу виконання (напр: завантаження компоненти).
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import styles from './Spinner.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - Spinner';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Spinner extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    onShow: Types.func,                   // callback called when component becomes visible
  }

  observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && this.props.onShow) {
      trace(`onShow`);
      this.props.onShow();
    }
  });

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.onShow !== nextProps.onShow;
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    this.observer.observe(this.elementRef);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const {className} = this.props;
    trace(`render`);
    return (
      <div className={classnames(styles.Spinner, className)} ref={elem => this.elementRef = elem}>
        <div />
        <div />
        <div />
      </div>
    );
  }
}
