// -------------------------------------------------------------------------------------------------
//  link.js
//  - - - - - - - - - -
//  [example](http://example.com "Optional title")[SPACE]
//
//  Notes:
//  - видаляємо пробіли ДО лінку, якщо не початок стрічки чи попередній пробіл відсутній;
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import {inlineTypes} from 'core/richTypes';

export default function autoreplaceLink(node, matched, change) {
  const matchedLength = matched[0].length;

  change.deleteAtRange(
    Range.create({
      anchorKey: node.key,
      focusKey: node.key,
      anchorOffset: matched.index,
      focusOffset: matched.index + matchedLength
    })
  );

  // eliminate preceding SPACE for some cases by expanding selection
  if (matched.index === 0 || matched.input[matched.index - 1] === ' ') {
    change.extend(-1);
  }

  change.insertText(matched[1]);
  change.extend(0 - matched[1].length);
  change.wrapInline({
      type: inlineTypes.LINK,
      data: {
        href: matched[2]
      }
    });
  change.collapseToEnd();

  return change;
}
