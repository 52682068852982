// -------------------------------------------------------------------------------------------------
//  rich-mark-italic.js
//  - - - - - - - - - -
//  Italic mark: plugin & toolbar icon.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import RichIcon from '../RichIcon';
import {markTypes, hotkeyTypes, htmlTagTypes} from 'core/richTypes';
import {markPlugin} from './commonMark';
import {haveMarks} from './utils';

const defaultOptions = {
  type: markTypes.ITALIC,
  tagName: htmlTagTypes.ITALIC,
  hotkey: hotkeyTypes.ITALIC,
};

export class ItalicIcon extends React.Component {
  onClick = (event) => {
    let {change, onChange} = this.props;

    event.preventDefault();
    onChange(change.toggleMark(defaultOptions.type));
  }

  render() {
    const {change, ...other} = this.props;
    const onClick = event => this.onClick(event);

    return (
      <RichIcon
        symbolName="markup-italic"
        commandName={defaultOptions.type}
        isActive={haveMarks(change, defaultOptions.type)}
        onClick={onClick}
        {...other}
      />
    );
  }
}

const ItalicPlugin = (opts) => {
  const options = Object.assign(defaultOptions, opts);
  return markPlugin(options, options.hotkey);
};

export default ItalicPlugin;
