// -------------------------------------------------------------------------------------------------
//  ChatActions.js
//  - - - - - - - - - -
//  Chat & messaging actions.
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' / catch;
//  - запити для фідів повинні бути або з явно вказаним курсором, або зі значенням DEFAULT_TSN12_CURSOR;
//  - з бекенду списки обʼєктів можуть приходити НЕСОРТОВАНІ, тому потрібно визначати курсор
//    через обхід усіх елементів;
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import {t} from 'ttag';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_CONTEXTS_WERE_FETCHED_ACTION,
  USER_CONTEXTS_WERE_FETCHED_ACTION,
  // MY_SCTX_CHATS_WERE_FETCHED_ACTION,
  // USER_SCTX_CHATS_WERE_FETCHED_ACTION,
  STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION,
  USER_UCTX_CHATS_WERE_FETCHED_ACTION,
  CHATS_WERE_FETCHED_ACTION,
  CHAT_WAS_CREATED_ACTION,
  SCTX_CHATS_WERE_CREATED_ACTION,
  CHAT_WAS_UPDATED_ACTION,
  CHAT_WAS_CLOSED_ACTION,
  CHAT_WAS_DELETED_ACTION,
  CHAT_MESSAGES_WERE_FETCHED_ACTION,
  CHAT_MESSAGE_WAS_CREATED_ACTION,
  CHAT_MESSAGE_WAS_UPDATED_ACTION,
  CHAT_MESSAGE_WAS_DELETED_ACTION,
  UNREAD_MESSAGES_WERE_FETCHED_ACTION,
  UNREAD_MESSAGES_WERE_READ_ACTION,
  UNREAD_MESSAGE_WAS_OBSERVED_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  BODY_FLD,
  BLOB_FLD,
  CHAT_FLD,
  CHAT_ID_FLD,
  CHATS_FLD,
  CONTEXT_ID_FLD,
  CONTEXT_TYPE_FLD,
  CONTEXTDATAS_FLD,
  CONTEXTS_FLD,
  MESSAGE_FLD,
  MESSAGE_ID_FLD,
  MESSAGES_FLD,
  UNREAD_MESSAGES_FLD,
  CONTACT_IDS_FLD,
  USERS_FLD,
  STAPLES_FLD,
  STAPLE_ID_FLD,
  STAPLE_IDS_FLD,
  COLLECTIONS_FLD,
  PROCESSED_IDS_FLD,
  POSITION_FLD,
  CURSOR_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR, TOPMOST_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX, UCTX_CHAT, SCTX_CHAT} from 'core/communicationTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {
  apiFetchMyContexts,
  apiFetchUserContexts,
  // apiFetchMySctxChats,
  // apiFetchUserSctxChats,
  apiFetchStapleSctxChats,
  // apiFetchCollectionCctxChats, // ToDo: COLLECTION_CTX !!!
  apiFetchUserUctxChats,
  apiFetchChats,
  apiCreateChat,
  apiCreateSctxChats,
  apiUpdateChat,
  apiCloseChat,
  apiDeleteChat,
  apiFetchChatMessages,
  apiCreateChatMessage,
  apiUpdateChatMessage,
  apiDeleteChatMessage,
  apiFetchUnreadMessages,
  apiSetUnreadMessagesAsRead} from 'api/ChatAPI';
import {showSuccess, showError} from 'components/UI/Informer';

const CHATS_PER_REQUEST_LIMIT = 24;       // типова к-сть чатів за один запит до бекенду
const MESSAGES_PER_REQUEST_LIMIT = 48;    // типова к-сть меседжів за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - ChatActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchMyContexts({
  ctxType = STAPLE_CTX,                   // тип контексту чатів, які запитуємо
  cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
  limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
}) {
  trace(`fetchMyContexts`);
  try {
    const response = await apiFetchMyContexts({ctxType, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[CONTEXTDATAS_FLD]:contextDatas, [CONTEXTS_FLD]:staples} = payload;
      if (contextDatas) {
        const areLoaded = contextDatas.length < limit;
        const nextCursor = contextDatas.length > 0 ?
          contextDatas.reduce((acc, elem) => acc < elem[CHAT_ID_FLD] ? acc : elem[CHAT_ID_FLD], cursor || TOPMOST_TSN12_CURSOR) : // attn: для пагінації використовується CHAT_ID_FLD !!!
          cursor;
        Dispatcher.dispatch({
          type: MY_CONTEXTS_WERE_FETCHED_ACTION,
          payload: {
            staples: staples,
            contextType: ctxType,
            contextDatas: contextDatas,
            contextCursor: nextCursor,
            areContextsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchMyContexts: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`API Error:`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserContexts({
  userId,                                 // код юзера, по якого запитуємо чати
  ctxType = STAPLE_CTX,                   // тип контексту чатів, які запитуємо
  cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
  limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
}) {
  trace(`fetchUserContexts`);
  try {
    const response = await apiFetchUserContexts({userId, ctxType, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[CONTEXTDATAS_FLD]:contextDatas, [CONTEXTS_FLD]:staples} = payload;
      if (contextDatas) {
        const areLoaded = contextDatas.length < limit;
        const nextCursor = contextDatas.length > 0 ?
          contextDatas.reduce((acc, elem) => acc < elem[CHAT_ID_FLD] ? acc : elem[CHAT_ID_FLD], cursor || TOPMOST_TSN12_CURSOR) : // attn: для пагінації використовується CHAT_ID_FLD !!!
          cursor;
        Dispatcher.dispatch({
          type: USER_CONTEXTS_WERE_FETCHED_ACTION,
          payload: {
            userId: userId,
            staples: staples,
            contextType: ctxType,
            contextDatas: contextDatas,
            contextCursor: nextCursor,
            areContextsLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchUserContexts: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`API Error:`, error);
  }
}

// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function fetchMySctxChats({
//   cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
//   limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
// }) {
//   trace(`fetchMySctxChats`);
//   try {
//     const response = await apiFetchMySctxChats({cursor, limit});
//     const {[STATUS]:status, [PAYLOAD]:payload} = response;
//     if (status === 'ok') {
//       const {[CHATS_FLD]:chats, [STAPLES_FLD]:staples} = payload;
//       if (chats) {
//         const areLoaded = chats.length < limit;
//         const nextCursor = chats.length > 0 ?
//           chats.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) : // sic!: 'i' = external, 'id' = inner !!!
//           cursor;
//         Dispatcher.dispatch({
//           type: MY_SCTX_CHATS_WERE_FETCHED_ACTION,
//           payload: {
//             chats: chats,
//             staples: staples,             // attn: список стейплів, що є контекстами до chats
//             mySctxChatsCursor: nextCursor,
//             areMySctxChatsLoaded: areLoaded,
//           }
//         });
//       }
//     } else {
//       traceError(`fetchMySctxChats: status=${status}, payload=${JSON.stringify(payload)}`);
//     }
//   } catch(error) {
//     traceError(`API Error:`, error);
//   }
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function fetchUserSctxChats({
//   userId,                                 // код юзера, спільні чати з яким запитуємо
//   cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
//   limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
// }) {
//   trace(`fetchUserSctxChats`);
//   try {
//     const response = await apiFetchUserSctxChats({userId, cursor, limit});
//     const {[STATUS]:status, [PAYLOAD]:payload} = response;
//     if (status === 'ok') {
//       const {[CHATS_FLD]:chats} = payload;
//       if (chats) {
//         const areLoaded = chats.length < limit;
//         const nextCursor = chats.length > 0 ?
//           chats.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
//           cursor; // sic!: 'i' = external, 'id' = inner !!!
//         Dispatcher.dispatch({
//           type: USER_SCTX_CHATS_WERE_FETCHED_ACTION,
//           payload: {
//             userId: userId,
//             chats: chats,
//             chatsCursor: nextCursor,
//             areChatsLoaded: areLoaded,
//           }
//         });
//       }
//     } else {
//       traceError(`fetchUserSctxChats: status=${status}, payload=${JSON.stringify(payload)}`);
//     }
//   } catch(error) {
//     traceError(`API Error:`, error);
//   }
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchStapleSctxChats({
  stapleId,                               // код стейплу, по якому запитуємо чати
  cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
  limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
}) {
  trace(`fetchStapleSctxChats`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchStapleSctxChats({stapleId, cursor, limit});
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    if (status === 'ok') {
      const {[CHATS_FLD]:chats} = payload;
      if (chats) {
        const areLoaded = chats.length < limit;
        const nextCursor = chats.length > 0 ?
          chats.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION,
          payload: {
            stapleId: stapleId,
            chats: chats,
            areStapleChatsLoaded: areLoaded,
          }
        });
        return {status, chats};
      }
    } else {
      traceError(`fetchStapleSctxChats: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`fetchStapleSctxChats`, error);
    errors.formError = JSON.stringify(error);
  }
  return {status, errors};
}

// ToDo: COLLECTION_CTX !!!
// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function fetchCollectionCctxChats({
//   collectionId,                           // код колекції, по якої запитуємо чати
//   cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
//   limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
// }) {
//   trace(`fetchCollectionCctxChats`);
//   let status;
//   let payload;
//   let errors = {};
//   try {
//     const response = await apiFetchCollectionCctxChats({collectionId, cursor, limit});
//     ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
//     if (status === 'ok') {
//       const {[CHATS_FLD]:chats} = payload;
//       if (chats) {
//         const areLoaded = chats.length < limit;
//         const nextCursor = chats.length > 0 ?
//           chats.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
//           cursor; // sic!: 'i' = external, 'id' = inner !!!
//         Dispatcher.dispatch({
//           type: COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION,
//           payload: {
//             collectionId: collectionId,
//             chats: chats,
//             areCollectionChatsLoaded: areLoaded,
//           }
//         });
//         return {status, chats};
//       }
//     } else {
//       traceError(`fetchCollectionCctxChats: status=${status}, payload=${JSON.stringify(payload)}`);
//     }
//   } catch(error) {
//     traceError(`fetchCollectionCctxChats`, error);
//     errors.formError = JSON.stringify(error);
//   }
//   return {status, errors};
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUserUctxChats({
  userId,                                 // код юзера, по якого запитуємо чати
  cursor = DEFAULT_TSN12_CURSOR,          // значення курсора для фільтрації обʼєктів
  limit = CHATS_PER_REQUEST_LIMIT,        // к-сть записів, що повертаються
}) {
  trace(`fetchUserUctxChats`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchUserUctxChats({userId, cursor, limit});
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    if (status === 'ok') {
      const {[CHATS_FLD]:chats} = payload;
      if (chats) {
        const areLoaded = chats.length < limit;
        const nextCursor = chats.length > 0 ?
          chats.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: USER_UCTX_CHATS_WERE_FETCHED_ACTION,
          payload: {
            userId: userId,
            chats: chats,
            areCollectionChatsLoaded: areLoaded,
          }
        });
        return {status, chats};
      }
    } else {
      traceError(`fetchUserUctxChats: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`fetchUserUctxChats`, error);
    errors.formError = JSON.stringify(error);
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchChats(ids) {
  trace(`fetchChats: ids=${ids}`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiFetchChats(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`fetchChats: OK, payload=${JSON.stringify(payload)}`);
        const {[CHATS_FLD]:chats} = payload;
        if (chats && chats.length > 0) {
          Dispatcher.dispatch({
            type: CHATS_WERE_FETCHED_ACTION,
            payload: {
              chats: chats,
            }
          });
        }
        break;
      default:
        traceError(`fetchChats: ids=${ids}, status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createChat(opts, eventLabel = '?') {
  trace(`createChat`);
  let status;
  let payload;
  let errors = {};
  let chatId = '';
  try {
    const response = await apiCreateChat(opts);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createChat: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_FLD]:chat, [MESSAGES_FLD]:messages} = payload;
        ({[ID_FLD]:chatId} = chat || {});
        Dispatcher.dispatch({
          type: CHAT_WAS_CREATED_ACTION,
          payload: {
            chat: chat,
            messages: messages,
          }
        });
        ReactGA.event({
          category: gaCategories.CHATS,
          action: gaActions.CHAT_CREATED,
          label: eventLabel
        });
        break;
      case 'chat_already_exists':
        traceWarn(`createChat: status=${status}`);
        break;
      default:
        traceError(`createChat: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors, chatId}; // attn: chatId повертаємо з метою переходу на новостворений чат
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createDirectChat(userId, eventLabel = '') {
  trace(`createDirectChat: userId=${userId}`);
  const {chatId = ''} = await createChat({ctxType: USER_CTX, ctxId: userId, memberIds: [userId]}, eventLabel);
  return chatId;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createTopicChat(userId, ctxId, ctxType, eventLabel = '') {
  trace(`createTopicChat: userId=${userId}, ctxId=${ctxId}, ctxType=${ctxType}`);
  const {chatId = ''} = await createChat({ctxType: ctxType, ctxId: ctxId, memberIds: [userId]}, eventLabel);
  return chatId;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createSctxChats(contactIds, stapleIds) {
  trace(`createSctxChats`);
  let status;
  let payload;
  let errors = {};
  let sentContactsQty = 0;
  let sentStaplesQty = 0;
  try {
    const response = await apiCreateSctxChats(contactIds, stapleIds);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createSctxChats: OK, payload=${JSON.stringify(payload)}`);
        const {
          [CONTACT_IDS_FLD]:contactIdsSent,
          [STAPLE_IDS_FLD]:stapleIdsSent,
          [CHATS_FLD]:chatsCreated} = payload;
        sentContactsQty = (contactIdsSent && contactIdsSent.length) || 0;
        sentStaplesQty = (stapleIdsSent && stapleIdsSent.length) || 0;
        Dispatcher.dispatch({
          type: SCTX_CHATS_WERE_CREATED_ACTION,
          payload: {
            contactIds: contactIdsSent,
            stapleIds: stapleIdsSent,
            chats: chatsCreated,
          }
        });
        break;
      default:
        traceError(`createSctxChats: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    console.error(`API error`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors, sentContactsQty, sentStaplesQty};
  }
  return {status, errors, sentContactsQty, sentStaplesQty};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateChat(chat, patchObject) {
  trace(`updateChat`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiUpdateChat(chat, patchObject);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateChat: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_FLD]:updatedChat, [MESSAGE_FLD]:statusMessage} = payload;
        if (updatedChat) {
          Dispatcher.dispatch({
            type: CHAT_WAS_UPDATED_ACTION,
            payload: {
              chat: updatedChat,
              message: statusMessage
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`updateChat: status=${status}`);
        break
      default:
        traceError(`updateChat: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function closeChat(chat) {
  trace(`closeChat`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCloseChat(chat);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`closeChat: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_FLD]:closeChat, [MESSAGE_FLD]:statusMessage} = payload;
        if (closeChat) {
          Dispatcher.dispatch({
            type: CHAT_WAS_CLOSED_ACTION,
            payload: {
              chat: closeChat,
              message: statusMessage
            }
          });
        }
        break;
      case 'invalid_formdata':
        traceError(`closeChat: status=${status}`);
        break
      default:
        traceError(`closeChat: status=${status}`);
        errors.formError = status;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteChat(chat) {
  trace(`deleteChat`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiDeleteChat(chat);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`deleteChat: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_ID_FLD]:chatId, [CONTEXT_ID_FLD]:ctxId, [CONTEXT_TYPE_FLD]:ctxType} = payload || [];
        if (chatId) {
          Dispatcher.dispatch({
            type: CHAT_WAS_DELETED_ACTION,
            payload: {chatId: chatId, ctxId: ctxId, ctxType: ctxType}
          });
        }
        showSuccess(t`Chat was deleted successfully.`, 3000);
        break;
      default:
        traceError(`deleteChat: status=${status}`);
        errors.formError = `server_error`;
        showError(t`You can't delete this chat.`, 3000);
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    showError(t`Server Error`, 3000);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchChatMessages(opts) {
  trace(`fetchChatMessages`);
  const {
    chatId,                               // код чату, по якому запитуємо меседжі
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    limit = MESSAGES_PER_REQUEST_LIMIT,   // к-сть записів, що повертаються
  } = opts;
  try {
    const response = await apiFetchChatMessages({chatId, cursor, limit});
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[MESSAGES_FLD]:messages} = payload;
      if (messages) {
        const areLoaded = messages.length < limit;
        const nextCursor = messages.length > 0 ?
          messages.reduce((acc, elem) => acc < (elem.i || elem.id) ? acc : elem.i || elem.id, cursor || TOPMOST_TSN12_CURSOR) :
          cursor; // sic!: 'i' = external, 'id' = inner !!!
        Dispatcher.dispatch({
          type: CHAT_MESSAGES_WERE_FETCHED_ACTION,
          payload: {
            chatId: chatId,
            messages: messages,
            chatMessageCursor: nextCursor,
            areChatMessagesLoaded: areLoaded,
          }
        });
      }
    } else {
      traceError(`fetchChatMessages: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`API Error:`, error);
  }
}

// Attn: 1) превьюшки генеруються з затримкою --> відображаємо зображення з канвасу (blob)!!!
// Attn: 2) параметр chat присутній лише для меседжів зміни статусу чату !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function createChatMessage(opts, tempMediaUrl) {
  trace(`createChatMessage`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiCreateChatMessage(opts);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`createChatMessage: OK, payload=${JSON.stringify(payload)}`);
        const {[CHAT_FLD]:chat, [MESSAGE_FLD]:message} = payload;
        if (tempMediaUrl) {
          Object.assign(message, {[BLOB_FLD]: tempMediaUrl}); // attn: 1)
        }
        Dispatcher.dispatch({
          type: CHAT_MESSAGE_WAS_CREATED_ACTION,
          payload: {
            chat: chat, // attn: 2)
            message: message,
          }
        });
        break;
      case 'invalid_formdata':
        const {[BODY_FLD]:body} = errors;
        errors.formError = body === 'too_long' ? 'message_is_too_long' : 'server_error';
        break;
      default:
        traceError(`createChatMessage: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function updateChatMessage(patchObject) {
  trace(`updateChatMessage`);
  let status;
  let errors = {};
  try {
    const response = await apiUpdateChatMessage(patchObject);
    ({[STATUS]:status = 'unknown_error', [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`updateChatMessage: OK`);
        const {[MESSAGE_FLD]:message} = patchObject;
        // ToDo: чи треба (бо може буде розсилатись нотифікейшеном) ???
        Dispatcher.dispatch({
          type: CHAT_MESSAGE_WAS_UPDATED_ACTION,
          payload: {
            message: message,
          }
        });
        break;
      default:
        traceError(`updateChatMessage: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function deleteChatMessage({chatId, messageId}) {
  trace(`deleteChatMessage`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiDeleteChatMessage(chatId, messageId);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        const {[MESSAGE_FLD]:message} = payload || {};
        if (message) {
          trace(`updateChatMessage: OK`);
          Dispatcher.dispatch({
            type: CHAT_MESSAGE_WAS_UPDATED_ACTION, // sic!: параметри повинні співпадати з NTF:ch.msg~
            payload: {
              [MESSAGE_FLD]:message,
            }
          });
        } else {
          trace(`deleteChatMessage: OK`);
          Dispatcher.dispatch({
            type: CHAT_MESSAGE_WAS_DELETED_ACTION, // sic!: параметри повинні співпадати з NTF:ch.msg-
            payload: {
              [CHAT_ID_FLD]:chatId,
              [MESSAGE_ID_FLD]:messageId,
            }
          });
        }
        break;
      // case 'invalid_formdata':
      // case 'forbidden':
      // case 'not_found':
      // case 'type_error':
      // case 'server_error':
      default:
        traceError(`deleteChatMessage: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchUnreadMessages() {
  trace(`fetchUnreadMessages`);
  try {
    const response = await apiFetchUnreadMessages();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[UNREAD_MESSAGES_FLD]:unreadMessages, [CHATS_FLD]:relatedChats, [STAPLES_FLD]:relatedStaples} = payload;
      if (unreadMessages) {
        Dispatcher.dispatch({
          type: UNREAD_MESSAGES_WERE_FETCHED_ACTION,
          payload: {
            unreadMessages: unreadMessages,
            chats: relatedChats,
            staples: relatedStaples,
          }
        });
      }
    } else {
      traceError(`fetchUnreadMessages: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    traceError(`API Error:`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function setUnreadMessagesAsRead(ids) {
  trace(`setUnreadMessagesAsRead`);
  let status;
  let payload;
  let errors = {};
  try {
    const response = await apiSetUnreadMessagesAsRead(ids);
    ({[STATUS]:status = 'unknown_error', [PAYLOAD]:payload = {}, [ERRORS]:errors = {}} = response);
    switch(status) {
      case 'ok':
        trace(`setUnreadMessagesAsRead: OK, payload=${JSON.stringify(payload)}`);
        const {[PROCESSED_IDS_FLD]:readMessageIds} = payload || [];
        if (readMessageIds && readMessageIds.length > 0) {
          Dispatcher.dispatch({
            type: UNREAD_MESSAGES_WERE_READ_ACTION,
            payload: {
              readMessageIds:readMessageIds,
            }
          });
        }
        break;
      default:
        traceError(`setUnreadMessagesAsRead: status=${status}`);
        errors.formError = `server_error`;
        break;
    }
  } catch(error) {
    traceError(`API Error:`, error);
    status = 'unknown_error';
    errors.formError = JSON.stringify(error);
    return {status, errors};
  }
  return {status, errors};
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function setUnreadMessageAsObserved(id) {
  trace(`setUnreadMessageAsObserved: messageId=${id}`);
  Dispatcher.dispatch({
    type: UNREAD_MESSAGE_WAS_OBSERVED_ACTION,
    payload: {
      [MESSAGE_ID_FLD]:id,
    }
  });
}
