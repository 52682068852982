// -------------------------------------------------------------------------------------------------
//  OneChat.js
//  - - - - - - - - - -
//  Вбудований у сайдбар чат.
//
//  https://learn.javascript.ru/metrics
//
//  Notes:
//  - - - - -
//  scrollTop     - висота проскроленої невидимої частини зверху контейнеру
//  scrollHeight  - повний розмір елементу (включаючи все, що виходить за рамки контейнера)
//  clientHeight  - розмір елементу всередині контейнера (без border-top, border-bottom)
//  clientTop     - розмір рамки зверху (border-top)
//  offsetHeight  - повний розмір елементу з рамками (border-top + height + border-bottom)
//
//   +-------+   A.
//   |       |
//  +---------+  B.   AB = scrollTop
//  |         |       AD = scrollHeight
//  |         |       BC = clientHeight
//  +---------+  C.   CD = delta (my metrics)
//   |       |
//   +-------+   D.
//
//  Attn:
//  - - - - -
//  - параметри скролювання зберігаємо для кожного чату окремо в savedPositions (handleScroll);
//  - на період оновлення стору потрібно відключати скролювання враперу (overflowY = 'hidden');
//  - відновлення позиції ігнорується під час виконання запитів до бекенду (isFrozen-режим);
//  - режим isFrozen встановлюється saveChatScrollPosition, знімається restoreChatScrollPosition;
// -------------------------------------------------------------------------------------------------
// ToDo: створювати тимчасові меседжі і відправляти коли буде інтернет (відкладене створення) !!!

import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {is} from 'immutable';
import {t} from 'ttag';
import classnames from 'classnames';
import {STATUS_FLD} from 'core/apiFields';
import {
  UCTX_CHAT, SCTX_CHAT,
  INITIAL_CHAT_STATUS,
  ACTIVE_CHAT_STATUS,
  ASSIGNED_CHAT_STATUS,
  SUBMITTED_CHAT_STATUS,
  REVISION_CHAT_STATUS,
  GRADED_CHAT_STATUS,
  DISPUTE_CHAT_STATUS,
  CLOSED_CHAT_STATUS,
  INITIAL_MESSAGE,
  ACTIVATION_MESSAGE,
  ASSIGNED_MESSAGE,
  SUBMISSION_MESSAGE,
  REVISION_MESSAGE,
  DISPUTE_MESSAGE,
  TEXT_MESSAGE,
  MEDIA_MESSAGE,
  GRADE_MESSAGE,
  REPLY_MESSAGE,
  WIPED_MESSAGE,
  SYSTEM_MESSAGE,
  MISSED_CALL_MESSAGE,
  REJECTED_CALL_MESSAGE,
  FINISHED_CALL_MESSAGE,
  CLOSED_MESSAGE,
  chatStatusName, isStatusMessage} from 'core/communicationTypes';
import {updateChat, closeChat, createChatMessage} from 'actions/ChatActions'; // , deleteChat
import {createGradeMessage} from 'components/Chats/CreateGradeMessage';
import {createMediaMessage} from 'components/Chats/CreateMediaMessage';
import {togglePopupMenu} from 'components/UI/PopupMenu';
import {confirmAction} from 'components/UI/ConfirmAction';
import {getErrorTitle, getErrorDescription} from 'utils/errors';
import Icon from 'components/UI/icons/Icon';
import PictButton from 'components/UI/buttons/PictButton';
import RichField, {toRich, isRichEmpty, toConmark, cleanifyConmark, isConmarkEmpty} from 'components/UI/fields/RichField';
import Spinner from 'components/UI/Spinner';
import StatusMessage from 'components/Chats/Messages/StatusMessage';
import Message from 'components/Chats/Messages/Message';
import MyMessage from 'components/Chats/Messages/MyMessage';
import styles from './OneChat.scss';

const MIN_SCROLL_SHIFT = 200;               // min scroll shift to change chat state

const isVerbose = DEBUG && true;
const prefix = '- - - OneChat';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let scrollWrapperRef = React.createRef();   // посилання на скрол-контейнер
let savedPositions = {};                    // параметри скролювання для кожного чату

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isChatScrolled(chatId) {
  const {isScrolled = false} = savedPositions[chatId || ''] || {};
  trace(`isChatScrolled: chatId=${chatId}, result=${isScrolled}`);
  return isScrolled;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function scrollChatToTop(chatId) {
  const {isFrozen} = savedPositions[chatId || ''] || {};
  if (!isFrozen) {
    const {scrollHeight = 0, clientHeight = 0} = scrollWrapperRef.current;
    trace(`scrollChatToTop: chatId=${chatId}`);
    scrollWrapperRef.current.scrollTop = 0;
    savedPositions[chatId] = {
      delta: scrollHeight - clientHeight,
      isScrolled: scrollHeight - clientHeight > MIN_SCROLL_SHIFT,
      isFrozen: false,
    }
  }
  scrollWrapperRef.current.scrollTop = 0;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function scrollChatToBottom(chatId) {
  const {isFrozen} = savedPositions[chatId || ''] || {};
  if (!isFrozen) {
    const {scrollHeight = 0, clientHeight = 0} = scrollWrapperRef.current;
    trace(`scrollChatToBottom: chatId=${chatId}`);
    scrollWrapperRef.current.scrollTop = scrollHeight - clientHeight;
    savedPositions[chatId] = {
      delta: 0,
      isScrolled: false,
      isFrozen: false,
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function saveChatScrollPosition(chatId) {
  if (chatId) {
    trace(`saveChatScrollPosition: chatId=${chatId}, isFrozen=${true}, savedPositions=`, savedPositions);
    // ...заборона скролювання
    scrollWrapperRef.current.style.overflowY = 'hidden';
    // ...перехід у режим 'заморозки'
    const {delta = 0, isScrolled = false} = savedPositions[chatId] || {};
    savedPositions[chatId] = {
      delta: delta,
      isScrolled: isScrolled,
      isFrozen: true, // sic!
    };
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function restoreChatScrollPosition(chatId) {
  if (chatId) {
    const {delta:prevDelta, isScrolled:prevIsScrolled} = savedPositions[chatId] || {};
    const {scrollHeight, clientHeight} = scrollWrapperRef.current;
    if (prevIsScrolled) {
      trace(`restoreChatScrollPosition(1): chatId=${chatId}, toPosition: ${prevDelta}`);
      scrollWrapperRef.current.scrollTop = scrollHeight - clientHeight - prevDelta;
    } else {
      trace(`restoreChatScrollPosition(2): chatId=${chatId}, toBottom`);
      scrollWrapperRef.current.scrollTop = scrollHeight - clientHeight;
    }
    // ...відновлення скролювання
    scrollWrapperRef.current.style.overflowY = '';
    // ...зняття режиму 'заморозки'
    const {delta = 0, isScrolled = false} = savedPositions[chatId] || {};
    savedPositions[chatId] = {
      delta: delta,
      isScrolled: isScrolled,
      isFrozen: false, // sic!
    };
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class OneChat extends React.Component {
  static propTypes = {
    className: Types.string,              // custom class name
    myAccountType: Types.string,          // тип акаунту користувача
    myId: Types.string,                   // код користувача
    chat: Types.object,                   // chat
    allUsers: Types.object,               // усі юзери (активні + усі юзери з чатів + користувач)
    unreadIds: Types.object,              // список ідентифікаторів непрочитаних повідомлень
    isCalling: Types.bool,                // is component called in call?
    isVisible: Types.bool,                // is component visible?
    onClose: Types.func,                  // close handler (close button action)
    onPageTrigger: Types.func,            // chat message page loading handler
  }

  static contextTypes = {
    router: Types.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      richBody: toRich(''),               // текст повідомлення в форматі Conmark
      isProcessing: false,
    }
    this.editorRef = React.createRef();   // посилання на редактор повідомлення
  }

  // ToDo: PERF: робити перемальовку тільки у isVisible стані !!!
  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.isVisible !== nextProps.isVisible
      || this.state.isProcessing !== nextState.isProcessing
      || this.props.isCalling !== nextProps.isCalling
      || this.props.myId !== nextProps.myId
      || this.props.myAccountType !== nextProps.myAccountType
      || this.props.allUsers.size !== nextProps.allUsers.size
      || !is(this.props.chat, nextProps.chat)
      || !is(this.props.allUsers, nextProps.allUsers)
      || !is(this.props.unreadIds, nextProps.unreadIds)
      || !is(this.state.richBody, nextState.richBody);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidUpdate(prevProps, prevState) {
    const {chat} = this.props;
    const {id:chatId} = chat || {};
    if (chatId) {
      const {isFrozen = false} = savedPositions[chatId] || {};
      if (!isFrozen) {
        trace(`componentDidUpdate: chatId=${chatId}`);
        restoreChatScrollPosition(chatId);
      }
    }
  }

  handleScroll = () => {
    const {chat} = this.props;
    const {id:chatId} = chat || {};
    if (chatId) {
      const {isFrozen = false} = savedPositions[chatId] || {};
      if (!isFrozen) {
        const {scrollTop = 20000, scrollHeight = 0, clientHeight = 0} = scrollWrapperRef.current;
        const delta = scrollHeight - scrollTop - clientHeight;
        const isScrolled = delta > MIN_SCROLL_SHIFT;
        savedPositions[chatId] = {
          delta: delta,
          isScrolled: isScrolled,
          isFrozen: isFrozen, // sic!: set as before
        };
        // trace(`handleScroll: chatId=${chatId}, delta=${delta}, isScrolled=${isScrolled}, scrollTop='${scrollTop}', scrollHeight='${scrollHeight}', clientHeight='${clientHeight}'`, savedPositions);
      }
    }
  }

  handleTogglePopup = (e) => {
    e.preventDefault();
    const {chat} = this.props;
    const {id:chatId} = chat || {};
    togglePopupMenu(prefix + chatId);
  }

  handleKeyDown = (event, change, editor) => {
    if (event.key === 'Enter'
      && event.altKey === false
      && event.ctrlKey === false
      && event.metaKey === false
      && event.shiftKey === false)
    {
      trace(`handleKeyDown: ENTER`);
      this.handleSendMessage();
    }
  }

  // ToDo: встановлення фокусу робити в залежності від ЛОКАЛЬНИХ налаштувань (на iPad відкриває клавіатуру, а це не зручно) !!!
  handleSetFocus = () => {
    this.editorRef
    && this.editorRef.current
    && this.editorRef.current.focus
    && this.editorRef.current.focus();
  }

  handleBodyChange = ({value}) => {
    trace(`handleBodyChange`);
    this.setState({richBody: value});
  }

  handleSendMessage = async () => {
    trace(`handleSendMessage`);
    const {id:chatId, type:chatType} = this.props.chat || {};
    if (chatId) {
      const conmarkBody = cleanifyConmark(toConmark(this.state.richBody));
      if (chatId && conmarkBody && !isConmarkEmpty(conmarkBody)) {
        try {
          this.setState({isProcessing: true});
          const {status, errors} = await createChatMessage({
            chatId: chatId,
            chatType: chatType,
            body: conmarkBody,
            attachment: {},
          });
          switch(status) {
            case 'ok':
              this.setState({ // clear previous value
                richBody: toRich(''),
                isProcessing: false,
              });
              break;
            default:
              const {formError = status} = errors;
              switch(formError) {
                case 'message_is_too_long':
                  confirmAction({
                    title: getErrorTitle(formError),
                    description: t`The message can't be larger than 512 symbols.`,
                  });
                  break;
                case 'invalid_formdata':
                  confirmAction({
                    title: getErrorTitle(formError),
                    description: t`An unexpected error has occurred. Please try to reload webpage or inform us via Feedback`,
                  });
                  break;
                default:
                  traceError(`handleSendMessage: ERROR=${JSON.stringify(errors)}`);
                  confirmAction({
                    title: getErrorTitle(formError),
                    description: t`An unexpected error has occurred. Please try to reload webpage or inform us via Feedback`,
                  });
              }
              this.setState({isProcessing: false});
          }
        } catch(error) {
          traceError(`handleSendMessage: ERROR`, ERROR);
          this.setState({isProcessing: false});
        }
      }
      this.handleSetFocus();
    }
  }

  handleMediaProgress = (percent) => {
    this.setState({mediaPercent: percent, isUploading: percent < 100});
  }

  handleMediaMessage = () => {
    trace(`handleMediaMessage`);
    const {id:chatId, type:chatType} = this.props.chat || {};
    const {richBody} = this.state;
    const handleSetFocus = this.handleSetFocus;
    if (chatId) {
      createMediaMessage({chatId, chatType, richBody, handleSetFocus});
      this.setState({
        richBody: toRich(''),
      });
    }
  }

  handleAssignStaple = async () => {
    trace(`handleAssignStaple`);
    const {chat} = this.props;
    await updateChat(chat, {[STATUS_FLD]: ASSIGNED_CHAT_STATUS});
  }

  handleConfirmAssignStaple = () => {
    trace(`handleConfirmAssignStaple`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Assign Staple`,
        description: t`Are you sure you want to assign the staple to the user?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleAssignStaple() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  handleSubmitStaple = async () => {
    trace(`handleSubmitStaple`);
    const {chat} = this.props;
    await updateChat(chat, {[STATUS_FLD]: SUBMITTED_CHAT_STATUS});
  }

  handleConfirmSubmitStaple = () => {
    trace(`handleConfirmSubmitStaple`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Submit Staple`,
        description: t`Are you sure you want to submit the staple?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleSubmitStaple() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  handleRequestRevision = async () => {
    trace(`handleRequestRevision`);
    const {chat} = this.props;
    await updateChat(chat, {[STATUS_FLD]: REVISION_CHAT_STATUS});
  }

  handleConfirmRequestRevision = () => {
    trace(`handleConfirmRequestRevision`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Request Staple Revision`,
        description: t`Are you sure you want to request the user to revise the staple?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleRequestRevision() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  handleCreateStapleGrade = () => {
    trace(`handleCreateStapleGrade`);
    const {myId, chat} = this.props;
    const {richBody} = this.state;
    const handleSetFocus = this.handleSetFocus;
    if (chat) {
      const {id:chatId, type:chatType, memberIds, contexts} = chat;
      const userId = memberIds.first();
      const {ctxId:stapleId} = contexts.first();
      createGradeMessage({myId, userId, chatId, chatType, stapleId, richBody, handleSetFocus});
      this.setState({
        richBody: toRich(''),
      });
    }
  }

  handleDisputeGrade = async() => {
    trace(`handleDisputeGrade`);
    const {chat} = this.props;
    await updateChat(chat, {[STATUS_FLD]: DISPUTE_CHAT_STATUS});
  }

  handleConfirmDisputeGrade = () => {
    trace(`handleConfirmDisputeGrade`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Dispute Staple Grade`,
        description: t`Are you sure you want to dispute the grade assigned to the staple?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleDisputeGrade() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  handleCloseChat = async () => {
    trace(`handleCloseChat`);
    const {chat} = this.props;
    await closeChat(chat);
  }

  handleConfirmCloseChat = () => {
    trace(`handleConfirmCloseChat`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Close Chat`,
        description: t`Are you sure you want to close the current chat?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleCloseChat() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  handleActivateChat = async () => {
    trace(`handleActivateChat`);
    const {chat} = this.props;
    await updateChat(chat, {[STATUS_FLD]: ACTIVE_CHAT_STATUS});
  }

  handleConfirmActivateChat = () => {
    trace(`handleConfirmActivateChat`);
    const {chat} = this.props;
    if (chat) {
      confirmAction({
        title: t`Activate Chat`,
        description: t`Are you sure you want to activate this chat?`
      }, [
        {title: t`Yes`, isAccented: false, onClick: () => { this.handleActivateChat() }},
        {title: t`No`, isAccented: true}
      ]);
    }
  }

  // handleConfirmDeleteChat = () => {
  //   trace(`handleConfirmDeleteChat`);
  //   const {chat, onClose} = this.props;
  //   if (chat) {
  //     confirmAction({
  //       title: t`Delete Chat`,
  //       description: t`Are you sure you want to permanently delete the current chat?`
  //     }, [
  //       {title: t`Yes`, isAccented: false, onClick: () => { deleteChat(chat); onClose && onClose(); }},
  //       {title: t`No`, isAccented: true}
  //     ]);
  //   }
  // }

  render() {
    const {className, myId, chat, allUsers, unreadIds, isCalling, isVisible, onClose, onPageTrigger} = this.props;
    const {richBody, isProcessing} = this.state;
    const {
      id:chatId,
      type:chatType,
      [STATUS_FLD]:chatStatus,
      ownerId,
      messages = {},
      areMessagesLoaded} = chat || {};
    const isChatClosed = chatStatus === CLOSED_CHAT_STATUS;
    const messageQty = messages.size || 0;
    trace(`render: chatId=${chatId} areMessagesLoaded=${areMessagesLoaded} isProcessing=${isProcessing}`);
    return (
      <div className={classnames(styles.OneChat, className, {[styles.isCalling]: isCalling})} style={{display: !isVisible ? 'none' : ''}}>
        <div className={styles.scrollWrapper} ref={scrollWrapperRef} onScroll={this.handleScroll}>
          <div className={styles.messageList}>
            {!areMessagesLoaded && chatId &&
              <Spinner
                key={messageQty}
                className={styles.spinner}
                onShow={onPageTrigger}
              />
            }
            {areMessagesLoaded && messageQty === 0 &&
              <div className={styles.noResult}>{t`There are no messages in this chat yet.`}</div>
            }
            {messageQty > 0 && messages.map(msg =>
              <F key={msg.id}>
                {isStatusMessage(msg.type) &&
                  <StatusMessage
                    message={msg}
                    createdAt={msg.createdAt}
                    isMy={msg.authorId === myId}
                    isUnread={unreadIds.contains(msg.id)}
                  />
                }
                {!isStatusMessage(msg.type) && msg.authorId === myId &&
                  <MyMessage
                    message={msg}
                    chatType={chatType}
                    createdAt={msg.createdAt}
                    handleSetFocus={this.handleSetFocus}
                  />
                }
                {!isStatusMessage(msg.type) && msg.authorId !== myId &&
                  <Message
                    message={msg}
                    chatType={chatType}
                    author={allUsers.find(user => user.id === msg.authorId)}
                    createdAt={msg.createdAt}
                    isUnread={unreadIds.contains(msg.id)}
                    handleSetFocus={this.handleSetFocus}
                  />
                }
              </F>
            )}
          </div>
        </div>
        {chatType === UCTX_CHAT &&
          <div className={styles.upperPanel}>{t`Direct Chat`}</div>
        }
      {/*
        {chatType === SCTX_CHAT &&
          <div className={styles.upperPanel}>{t`Topic Chat`}<span>{'[ ' + chatStatusName(chatStatus, true) + ' ]'}</span></div>
        }
      */}
        {chatType === SCTX_CHAT &&
          <div className={styles.upperPanel}>{chatStatusName(chatStatus, true)}</div>
        }
        <div className={styles.lowerPanel}>
        {/*
          <PictButton
            className={styles.startCallButton}
            iconClassName={styles.icon}
            symbolName="phone"
            isDisabled={isProcessing}
            // onClick={this.handleMediaMessage}
          />
        */}
          {!isChatClosed &&
            <F>
              <PictButton
                className={styles.mediaMessageButton}
                iconClassName={styles.icon}
                symbolName="attach"
                isDisabled={isProcessing || !chatId || isChatClosed}
                onClick={this.handleMediaMessage}
              />
              <RichField
                className={styles.editorWrapper}
                editorClassName={styles.editor}
                inputRef={this.editorRef}
                value={richBody}
                error={''}
                placeholder={t`Enter text...`}
                autoFocus={true}
                hasToolbar={false}
                isDisabled={isProcessing || !chatId || isChatClosed}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleBodyChange}
              />
              <PictButton
                className={styles.sendMessageButton}
                iconClassName={styles.icon}
                symbolName="send-message"
                title="ENTER"
                isDisabled={isProcessing || !chatId || isChatClosed}
                onClick={this.handleSendMessage}
              />
            </F>
          }
          {isChatClosed &&
            <div className={styles.chatStatus}>{t`Chat is closed.`}</div>
          }
        </div>
        {myId && myId === ownerId && chatType === SCTX_CHAT &&
          <div className={styles.statusCommands}>
            <button className={styles.closeButton} title={t`Close`} onClick={onClose}>
              <Icon symbolName="cross" className={styles.icon} />
            </button>
            {chatStatus === CLOSED_CHAT_STATUS &&
              <PictButton
                className={styles.statusButton}
                iconClassName={styles.icon}
                title={t`Activate Chat`}
                symbolName="backup-restore"
                isDisabled={isProcessing}
                onClick={this.handleConfirmActivateChat}
              />
            }
            {chatStatus !== CLOSED_CHAT_STATUS &&
              <PictButton
                className={styles.statusButton}
                iconClassName={styles.icon}
                title={t`Close Chat`}
                symbolName="eye-off"
                isDisabled={isProcessing}
                onClick={this.handleConfirmCloseChat}
              />
            }
            {(chatStatus === INITIAL_CHAT_STATUS || chatStatus === ACTIVE_CHAT_STATUS) &&
              <button className={styles.myCommand} onClick={this.handleConfirmAssignStaple}>
                <Icon symbolName="local-library" className={styles.icon} />{t`Assign Task`}
              </button>
            }
            {(chatStatus === SUBMITTED_CHAT_STATUS || chatStatus === GRADED_CHAT_STATUS || chatStatus === DISPUTE_CHAT_STATUS) &&
              <button className={styles.myCommand} onClick={this.handleConfirmRequestRevision}>
                <Icon symbolName="arrow-u-left-top-bold" className={styles.icon} />{t`Request Staple Revision`}
              </button>
            }
            {chatStatus !== CLOSED_CHAT_STATUS &&
              <PictButton
                className={styles.statusButton}
                iconClassName={styles.coinIcon}
                title={t`Create Staple Grade`}
                symbolName="coin"
                isDisabled={isProcessing}
                onClick={this.handleCreateStapleGrade}
              />
            }
          </div>
        }
        {myId && myId !== ownerId && chatType === SCTX_CHAT &&
          <F>
            <button className={styles.closeButton} title={t`Close`} onClick={onClose}>
              <Icon symbolName="cross" className={styles.icon} />
            </button>
            {(chatStatus === INITIAL_CHAT_STATUS
            || chatStatus === ACTIVE_CHAT_STATUS
            || chatStatus === ASSIGNED_CHAT_STATUS
            || chatStatus === REVISION_CHAT_STATUS
            || chatStatus === DISPUTE_CHAT_STATUS) &&
              <button className={styles.userCommand} onClick={this.handleConfirmSubmitStaple}>
                <Icon symbolName="book-openpage-outline" className={styles.icon} />{t`Submit Task`}
              </button>
            }
            {chatStatus === GRADED_CHAT_STATUS &&
              <button className={styles.userCommand} onClick={this.handleConfirmDisputeGrade}>
                <Icon symbolName="timeline-question" className={styles.disputeIcon} />{t`Dispute Grade`}
              </button>
            }
          </F>
        }
      </div>
    );
  }
}
