// -------------------------------------------------------------------------------------------------
//  friendshipTypes.js
//  - - - - - - - - - -
//  Типи, що відносяться до взаємодії юзерів один з одним (чати, дружба, ...).
//
//  Attn:
//  - - - - -
//  - значенння статусів використовуються для сортування списку чатів: "?", ">", "+", "#";
// -------------------------------------------------------------------------------------------------

export const FS_NONE_STATUS                         = '.';      // користувач не має цього юзера в списку друзів
export const FS_FRIEND_STATUS                       = 'f';      // користувач має цього юзера в списку друзів
export const FS_REQUEST_SENT_STATUS                 = '>';      // користувач надіслав юзеру запит на дружбу
export const FS_REQUEST_RECEIVED_STATUS             = '?';      // користувач отримав від юзера запит на дружбу
export const FS_REQUEST_ACCEPTED_STATUS             = '+';      // користувач прийняв запит на дружбу
export const FS_REQUEST_REJECTED_STATUS             = '!';      // користувач відмовився від запиту на дружбу
export const FS_CANCELED_STATUS                     = '-';      // юзер видалив користувача із списку друзів
