// -------------------------------------------------------------------------------------------------
//  accountTypes.js
//  - - - - - - - - - -
//  Account types.
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import {t} from 'ttag';
import {confirmAction} from 'components/UI/ConfirmAction';

export const BASIC_ACCOUNT                = '.';      // basic account type
export const PRO_ACCOUNT                  = '1';      // pro account type
export const PREMIUM_ACCOUNT              = '2';      // premium account type
export const ENTERPRISE_ACCOUNT           = '8';      // enterprise account type
export const ADMIN_ACCOUNT                = 'a';      // admin account type

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function accountTypeName(accountType, isBasicDisplayed) {
  let accountTypeName;
  switch(accountType) {
    case BASIC_ACCOUNT:                   accountTypeName = isBasicDisplayed ? 'Free' : '';   break;
    case PRO_ACCOUNT:                     accountTypeName = 'Pro';                            break;
    case PREMIUM_ACCOUNT:                 accountTypeName = 'Premium';                        break;
    case ENTERPRISE_ACCOUNT:              accountTypeName = 'Enterprise';                     break;
    case ADMIN_ACCOUNT:                   accountTypeName = 'Admin';                          break;
    default:                              accountTypeName = '';
  }
  return accountTypeName;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function eventTypeName(eventType) {
  let eventTypeName;
  switch(eventType) {
    case 'subscription.activated':        eventTypeName = t`subscription.activated`;          break;
    case 'subscription.trialing':         eventTypeName = t`subscription.trialing`;           break;
    case 'subscription.paused':           eventTypeName = t`subscription.paused`;             break;
    case 'subscription.resumed':          eventTypeName = t`subscription.resumed`;            break;
    case 'subscription.past_due':         eventTypeName = t`subscription.past_due`;           break;
    case 'subscription.canceled':         eventTypeName = t`subscription.canceled`;           break;
    case 'subscription.imported':         eventTypeName = t`subscription.imported`;           break;
    case 'subscription.created':          eventTypeName = t`subscription.created`;            break;
    case 'subscription.updated':          eventTypeName = t`subscription.updated`;            break;
    default:                              eventTypeName = '';
  }
  return eventTypeName;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function applyAccountTypeChanges(eventType, nextAccountType) {
  const eventTypeStr = eventTypeName(eventType).toUpperCase();
  const accountTypeStr = accountTypeName(nextAccountType, true).toUpperCase();
  confirmAction({
    title: `${eventTypeStr}`,
    description: (
      <F>
        {t`Your account type has been updated to `}<b>{accountTypeStr}</b>
        <br/><br/>
        {t`Please reload the webpage for the changes to take full effect by clicking the button below.`}
      </F>)
    }, [
      {title: t`Reload`, isAccented: true, onClick: () => window.location.reload()} // sic!: reload webpage !!!
    ]
  );
}
