// -------------------------------------------------------------------------------------------------
//  MyCSubscriptionStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки стейплів підписки користувача на курс-колекцію.
//
//  Attn:
//  - - - - -
//  - якщо підписку не знайдено в сторі і на сервері --> сторінка /404;
//  - при вході в підписку контекст сайдбару встановлюємо на чат до підписки (CHAT_TAB);
//  - функція getCSubscriptionStaplesFeed віддає стейпли тільки ДО курсора, щоб не було "пропусків" в фіді;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {List, Map, Set, OrderedSet, is} from 'immutable';
import {DEFAULT_POS10_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {SUBJECT_TAB, MY_CSUBSCRIPTION_STAPLES_FEED} from 'core/uiTypes';
import {fetchCSubscription} from 'actions/CollectionActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import {fetchCSubscriptionStaples} from 'actions/StapleActions';
import {fetchSystemSubjects, fetchSystemSubjectsAsGuest} from 'actions/SystemActions';
import {fetchUsers} from 'actions/UserActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const MyCSubscriptionStaples = Loader.create(() => import('./MyCSubscriptionStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyCSubscriptionStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyCSubscriptionStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про стейпл?
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CollectionStore, StapleStore, SystemStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionId} = props.match.params;
    const {[MY_CSUBSCRIPTION_STAPLES_FEED]:viewMode, isSidebarOpen} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const csubscription = collectionId ? CollectionStore.getCSubscription(collectionId) : null;
    const collectionOwnerId = csubscription ? csubscription.ownerId : myId;
    const {staples, stapleCursor, areStaplesLoaded} = StapleStore.getCSubscriptionStaplesFeed(collectionId);
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId).add(collectionOwnerId)));
    const unreadCountsByStaple = isLoggedIn ? ChatStore.getUnreadCountsByCtxType(STAPLE_CTX) : Map();
    const allSubjects = SystemStore.getSubjectList();
    return {
      isLoggedIn, myAccountType, myId, owners, staples, csubscription, allSubjects,
      unreadCountsByStaple, viewMode, isSidebarOpen, stapleCursor, areStaplesLoaded
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.isSidebarOpen !== nextState.isSidebarOpen
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.csubscription, nextState.csubscription)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {collectionId} = this.props.match.params;
    const {isLoggedIn} = this.state;
    if (collectionId && !CollectionStore.isCSubscriptionLoaded(collectionId)) {
      const {ownerId} = await fetchCSubscription(collectionId);
      if (ownerId) {
        if (!UserStore.isUserLoaded(ownerId)) {
            await fetchUsers([ownerId]);
        }
      }
    }
    this.setState({isServerRequested: true});
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
    // CTX:
    substituteSidebarTabMode(SUBJECT_TAB);
    substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {collectionId} = nextProps.match.params;
    this.setState({isServerRequested: false});
    if (collectionId && !CollectionStore.isCSubscriptionLoaded(collectionId)) {
      const {ownerId} = await fetchCSubscription(collectionId);
      if (ownerId) {
        if (!UserStore.isUserLoaded(ownerId)) {
          await fetchUsers([ownerId]);
        }
      }
    }
  }

  isFetchingCSubscriptionStaples = false;

  fetchCSubscriptionStaplesPage = async () => {
    trace(`fetchCSubscriptionStaplesPage(1): isFetching=${this.isFetchingCSubscriptionStaples}`);
    const {csubscription, stapleCursor = DEFAULT_POS10_CURSOR} = this.state;
    const {id:collectionId} = csubscription || {};
    if (collectionId && !this.isFetchingCSubscriptionStaples) {
      trace(`fetchCSubscriptionStaplesPage(2): collectionId=${collectionId}, cursor='${stapleCursor}'`);
      this.isFetchingCSubscriptionStaples = true;
      await fetchCSubscriptionStaples({collectionId: collectionId, cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT});
      this.isFetchingCSubscriptionStaples = false;
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const {
      isServerRequested,
      isLoggedIn,
      myAccountType,
      myId,
      owners,
      staples,
      csubscription,
      unreadCountsByStaple,
      allSubjects,
      viewMode,
      isSidebarOpen,
      areStaplesLoaded} = this.state;
    if (isServerRequested && !csubscription) {
      return (
        <NotFoundPage />
      );
    }
    trace(`render`);
    return (
      <MyCSubscriptionStaples
        isLoggedIn={isLoggedIn}
        myAccountType={myAccountType}
        myId={myId}
        owners={owners}
        staples={staples}
        csubscription={csubscription}
        unreadCountsByStaple={unreadCountsByStaple}
        allSubjects={allSubjects}
        pathname={pathname}
        viewMode={viewMode}
        isSidebarOpen={isSidebarOpen}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchCSubscriptionStaplesPage}
      />
    );
  }
}

export default Container.create(MyCSubscriptionStaplesContainer, {withProps: true, pure: false});
