// -------------------------------------------------------------------------------------------------
//  Message.js
//  - - - - - - - - - -
//  Повідомлення в чат від інших юзерів.
//
//  Attn:
//  - - - - -
//  - для непрочитаних повідомлень встановлюємо observer;
// -------------------------------------------------------------------------------------------------
import React, {Fragment as F} from 'react';
import Types from 'prop-types';
import {is} from 'immutable';
import dateFormat from 'dateformat';
import imagesLoaded from 'imagesloaded';
import classnames from 'classnames';
import {t} from 'ttag';
import {MARKDOWN_CSS, SELECTABLE_CSS} from 'core/commonTypes';
import {
  UCTX_CHAT, SCTX_CHAT,
  TEXT_MESSAGE,
  MEDIA_MESSAGE,
  GRADE_MESSAGE,
  REPLY_MESSAGE,
  WIPED_MESSAGE,
  SYSTEM_MESSAGE,
  MISSED_CALL_MESSAGE,
  REJECTED_CALL_MESSAGE,
  FINISHED_CALL_MESSAGE,
  CLOSED_MESSAGE} from 'core/communicationTypes';
import {setUnreadMessageAsObserved} from 'actions/ChatActions';
import {togglePopupMenu} from 'components/UI/PopupMenu';
import {humanDateShort} from 'utils/humanFormats';
import Avatar from 'components/UI/Avatar';
import Icon from 'components/UI/icons/Icon';
import Grade from 'components/UI/Grade';
import Spinner from 'components/UI/Spinner';
import {mediaViewer} from 'components/MediaViewer';
import MessageCommands from 'components/Chats/MessageCommands';
import styles from './Message.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - Message';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Message extends React.Component {
  static propTypes = {
    message: Types.object,                // повідомлення
    chatType: Types.string,               // тип чату
    author: Types.object,                 // автор повідомлення
    createdAt: Types.string,              // час створення повідомлення
    isUnread: Types.bool,                 // чи є це повідомлення в списку непрочитаних?
    handleSetFocus: Types.func,           // функція установки фокуса компоненти OneChat
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,                    // is the image of the message loaded?
    }
    this.observer = new IntersectionObserver(this.handleObservation, {threshold: 0.001});
  };

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.createdAt !== nextProps.createdAt
      || !is(this.props.message, nextProps.message)
      || !is(this.props.author, nextProps.author)
      || this.state.isLoaded !== nextState.isLoaded;
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentDidMount() {
    // 1) інакше НЕ відображає картинки після показу контенту, відмінного від картинок !!!
    const ref = this.pictureRef;
    if (ref) {
      imagesLoaded(ref, {background: true}, () => {
        this.setState({isLoaded: true});
      });
    }
  }

  componentDidUpdate() {
    // 2) інакше НЕ відображає картинки після показу контенту, відмінного від картинок !!!
    // 3) без перевірки isLoaded впадає в нескінчений цикл, бо компонента змінюється внаслідок imagesLoaded !!!
    const ref = this.pictureRef;
    const isLoaded = this.state.isLoaded;
    if (ref && !isLoaded) { // sic!: 3)
      imagesLoaded(ref, {background: true}, () => {
        this.setState({isLoaded: true});
      });
    }
  }

  componentWillUnmount() {
    trace(`componentWillUnmount`);
    this.observer.disconnect();
  }

  handleObservation = ([entry]) => {
    if (entry && entry.isIntersecting) {
      const {message} = this.props;
      const {id:messageId} = message;
      if (messageId) {
        trace(`handleObservation: messageId=${messageId}`);
        setUnreadMessageAsObserved(messageId);
        this.observer.unobserve(entry.target);
      }
    }
  }

  initiateObserver = (elem) => {
    if(elem) {
      trace(`initiateObserver: messageId=${this.props.message.id}`);
      this.observer.observe(elem);
    }
  }

  handleTogglePopup = (e) => {
    e.preventDefault();
    const {message} = this.props;
    const {id:messageId} = message || {};
    togglePopupMenu(prefix + messageId);
  }

  handleViewMedia = () => {
    trace(`handleViewMedia`);
    const {message, handleSetFocus} = this.props;
    const {type, previewXlUrl} = message || {};
    mediaViewer({
      type:type,
      previewUrl:previewXlUrl,
      handleSetFocus:handleSetFocus,
    });
  }

  render() {
    const {chatType, message, author, createdAt, isUnread} = this.props;
    const {isLoaded} = this.state;
    const {id:messageId, type, extraText, bodyHtml, previewMdUrl} = message || {};
    const {title:userTitle, userAvatarSsUrl, contactAvatarSsUrl} = author || {};
    trace(`render`);
    return (
      <div
        className={classnames(styles.Message, {
          [styles.isUctxChat]: chatType === UCTX_CHAT && type !== WIPED_MESSAGE,
          [styles.isSctxChat]: chatType === SCTX_CHAT && type !== WIPED_MESSAGE,
          [styles.gradeType]: type === GRADE_MESSAGE,
          [styles.wipedType]: type === WIPED_MESSAGE,
          [styles.systemType]: type === SYSTEM_MESSAGE,
          [styles.missedCallType]: type === MISSED_CALL_MESSAGE || type === REJECTED_CALL_MESSAGE,
          [styles.finishedCallType]: type === FINISHED_CALL_MESSAGE
        })}
        title={dateFormat(createdAt, t`mmm d, yyyy HH:MM`)}
        ref={isUnread ? this.initiateObserver : null}
      >
        <Avatar
          className={styles.avatarIcon}
          title={userTitle}
          largePreviewUrl={contactAvatarSsUrl || userAvatarSsUrl}
          smallPreviewUrl={userAvatarSsUrl}
        />
        {type === GRADE_MESSAGE && extraText &&
          <Grade className={styles.grade} iconClassName={styles.icon} gradeId={extraText} />
        }
        {type === WIPED_MESSAGE &&
          <F>
            <Icon symbolName="delete-2" className={styles.wipedIcon} />
            <div className={styles.wipedText}>{t`Message was deleted`}</div>
          </F>
        }
        {previewMdUrl &&
          <div className={classnames(styles.pictureHolder, {[styles.hasBody]: bodyHtml && type !== MISSED_CALL_MESSAGE})}>
            {!isLoaded &&
              <F>
                <Icon symbolName="image" className={styles.typeIcon} />
                <Spinner key={type} className={styles.spinner} />
              </F>
            }
            <img src={previewMdUrl} ref={e => this.pictureRef = e} onClick={this.handleViewMedia} />
          </div>
        }
        {bodyHtml && type !== MISSED_CALL_MESSAGE &&
          <div className={classnames(MARKDOWN_CSS, styles.bodyText)}>{bodyHtml}</div>
        }
        {type === MISSED_CALL_MESSAGE &&
          <Icon symbolName="phone-missed" className={styles.missedCallIcon} />
        }
        <div className={styles.dateTime}>{humanDateShort(createdAt)}</div>
        {false && (type === TEXT_MESSAGE || type === MEDIA_MESSAGE || type === REPLY_MESSAGE || type === GRADE_MESSAGE) &&
          <MessageCommands
            prefix={prefix}
            className={styles.messageCommandsButton}
            messageId={messageId}
            onTogglePopup={this.handleTogglePopup}
          />
        }
      </div>
    );
  }
}
