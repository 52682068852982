// -------------------------------------------------------------------------------------------------
//  CallAPI.js
//  - - - - - - - - - -
//  (WebRTC: Part 2/4)
//
//  Attn:
//  - - - - -
//  -
// -------------------------------------------------------------------------------------------------
import {
  CONTEXT_ID_FLD,
  CONTEXT_TYPE_FLD,
  CALL_ID_FLD,
  SIGNALS_FLD,
  MEMBER_IDS_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_MY_CALLS_CMD,
  REQUEST_CALL_CMD,
  JOIN_CALL_CMD,
  LEAVE_CALL_CMD,
  ADD_CALL_MEMBER_CMD,
  SEND_SIGNAL_CMD} from 'core/apiTypes';
import {wsockFetch} from 'api/WebSocketAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - CallAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchMyCalls() {
  trace(`apiFetchMyCalls`);
  return await wsockFetch({
    [CMD]: FETCH_MY_CALLS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiRequestCall(opts = {}) {
  trace(`apiRequestCall`);
  const {
    ctxId,                                // код контексту
    ctxType,                              // тип контексту = {'s' || 'c' || 'u' || 'r'}
    memberIds = [],                       // список ідентифікаторів учасників дзвінку
  } = opts;
  return await wsockFetch({
    [CMD]: REQUEST_CALL_CMD,
    [PAYLOAD]: {
      [CONTEXT_ID_FLD]: ctxId,
      [CONTEXT_TYPE_FLD]: ctxType,
      [MEMBER_IDS_FLD]: memberIds,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiJoinCall(callId) {
  trace(`apiJoinCall`);
  return await wsockFetch({
    [CMD]: JOIN_CALL_CMD,
    [PAYLOAD]: {
      [CALL_ID_FLD]: callId,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiLeaveCall(callId) {
  trace(`apiLeaveCall`);
  return await wsockFetch({
    [CMD]: LEAVE_CALL_CMD,
    [PAYLOAD]: {
      [CALL_ID_FLD]: callId,
    }
  });
}

// ToDo: !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiAddCallMember(opts = {}) {
  trace(`apiAddCallMember`);
  const {
    callId,                               // ідентифікатор дзвінка
    memberIds = [],                       // список ідентифікаторів учасників дзвінку, що додаються
  } = opts;
  return await wsockFetch({
    [CMD]: ADD_CALL_MEMBER_CMD,
    [PAYLOAD]: {
      [CALL_ID_FLD]: callId,
      [MEMBER_IDS_FLD]: memberIds,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiSendSignal(opts = {}) {
  trace(`apiSendSignal`);
  const {
    callId,                               // ідентифікатор дзвінка
    signals = [],                         // список сигналів виду {'member_id': ID, 'data': {...}}
  } = opts;
  return await wsockFetch({
    [CMD]: SEND_SIGNAL_CMD,
    [PAYLOAD]: {
      [CALL_ID_FLD]: callId,
      [SIGNALS_FLD]: signals,
    }
  });
}
