// -------------------------------------------------------------------------------------------------
//  UserStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки стейплів юзера.
//
//  Attn: 1)
//  - - - - -
//  Для ініціалізації на сайдбарі прямого чату з юзером і таби CHAT_TAB враховувати/робити таке:
//  - після перезавантаження сторінки на момент виконання componentDidMount() значення поля
//    user.directChatId встановлюється НЕ ВІДРАЗУ (стор не встигає обробити API getMyFriends());
//  - тому доводиться викликати setSidebar() із componentDidUpdate;
//  - isSetSidebarRepaired потрібен щоб викликати setSidebar() із componentDidUpdate лише ОДИН раз;
//  - щоб обійти помилку "Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch"
//    доводиться робити затримку через setTimeout ~50ms;
//
//  Attn:
//  - - - - -
//  - якщо юзера не знайдено в сторі і на сервері --> сторінка /404;
//  - форма має ДВА варіанти параметрів виклику: з параметром userId та userSlug;
//  - форма може викликатись для залогіненого (користувач) або незалогіненого (гість) юзерів;
//  - для залогіненого юзера використовуємо wsock-набір API;
//  - для незалогіненого юзера використовуємо http-набір API;
//  - ми не робимо перевірку даних в render, щоб показати процес завантаження (Loader/Spinner);
//  - в guest-режимі стейпли можуть також приходити з DiscoverStaplesFeed,
//    тому шукаємо останній курсор в сторі серед стейплів заданого юзера !!!
//  - форма викликається на декількох url-адрес, тому shouldComponentUpdate перевіряє зміну pathname;
//  - функція getUserStaples віддає стейпли тільки ДО курсора, щоб не було "пропусків" в фіді;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {Map, Set, is} from 'immutable';
import {DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, ACTION_TAB, CHAT_TAB, SELECTION_TAB, USER_STAPLES_FEED} from 'core/uiTypes';
import {setAllContactsUnselected} from 'actions/ContactActions';
import {fetchUserStaples, fetchUserStaplesAsGuest, setAllStaplesUnselected} from 'actions/StapleActions';
import {fetchUsers, fetchUsersAsGuest, fetchUsersBySlugs, fetchUsersBySlugsAsGuest} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import NotFoundPage from 'components/Pages/NotFoundPage';
import Loader from 'utils/ComponentLoader';

const UserStaples = Loader.create(() => import('./UserStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - UserStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let isSetSidebarRepaired = false;         // чи викликали функцію setSidebar?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class UserStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, StapleStore, UserStore];
  }

  state = {
    isServerRequested: false,             // чи звертались до сервера за інформацією про юзера?
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {userId, userSlug} = props.match.params;
    const {[USER_STAPLES_FEED]:viewMode, isSidebarOpen} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myId = AccountStore.getMyId();
    const user = userId ? UserStore.getUser(userId) : userSlug ? UserStore.getUserBySlug(userSlug) : null;
    const staples = StapleStore.getUserStaples(user ? user.id : '');
    const owners = UserStore.getUsersMap(); // const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    const fsrUsers = UserStore.getFSRequestUsers();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const unreadCountsByStaple = isLoggedIn && user ? ChatStore.getUnreadCountsByCtxType(STAPLE_CTX) : Map();
    const stapleCursor = user ? StapleStore.getUserStapleCursor(user.id) : DEFAULT_TSN12_CURSOR;
    const areStaplesLoaded = user ? StapleStore.areUserStaplesLoaded(user.id) : false;
    const hasAnySelected = myId && user && user.id === myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, user, owners, staples, viewMode, isSidebarOpen,
      fsrUsers, fsrCount, groupsQty, contactsQty, unreadCountsByStaple,
      stapleCursor, areStaplesLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.isSidebarOpen !== nextState.isSidebarOpen
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.isServerRequested !== nextState.isServerRequested
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || this.props.location.pathname !== nextProps.location.pathname
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners)
      || !is(this.state.fsrUsers, nextState.fsrUsers)
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {userId, userSlug} = this.props.match.params;
    const {isLoggedIn, myId, user} = this.state;
    if (userId) {
      if (!UserStore.isUserLoaded(userId)) {
        isLoggedIn ?
          await fetchUsers([userId]) :
          await fetchUsersAsGuest([userId]);
      }
    } else if (userSlug) {
      if (!UserStore.isUserLoadedBySlug(userSlug)) {
        isLoggedIn ?
          await fetchUsersBySlugs([userSlug]) :
          await fetchUsersBySlugsAsGuest([userSlug]);
      }
    }
    this.setState({isServerRequested: true});
    this.setSidebar(); // attn: 1)
  }

  // CTX: Attn: 1)
  async componentDidUpdate(prevProps, prevState) {
    const {user:prevUser} = prevState;
    const {isLoggedIn, user} = this.state;
    if (isLoggedIn) {
      const {directChatId:prevDirectChatId} = prevUser || {};
      const {directChatId} = user || {};
      if (prevDirectChatId === '' && directChatId) {
        trace(`componentDidUpdate: directChatId =`, directChatId);
        if (!isSetSidebarRepaired) {
          setTimeout(this.setSidebar, 50);
          isSetSidebarRepaired = true;
        }
      }
    }
  }

  // CTX: Attn: 1)
  setSidebar = async () => {
    trace(`setSidebar`);
    const {isLoggedIn, myId, user, hasAnySelected} = this.state;
    if (isLoggedIn) {
      if (user && user.id !== myId) {
        await substituteSidebarContext({ctxId: user.id, ctxType: USER_CTX, ctxOwnerId: user.id});
        if (user.directChatId) {
          await substituteSidebarTabMode(CHAT_TAB, true);
          await substituteSidebarChat({chatId: user.directChatId});
        } else {
          await substituteSidebarTabMode(ACTION_TAB);
          await substituteSidebarChat({chatId: ''});
        }
      }
      if (user && user.id === myId) {
        await substituteSidebarTabMode(!hasAnySelected ? COLLECTION_TAB : SELECTION_TAB);
        await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
        await substituteSidebarChat({chatId: ''});
      }
    }
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  isFetchingUserStaples = false;

  fetchUserStaplesPage = async () => {
    trace(`fetchUserStaplesPage(1): isFetching=${this.isFetchingUserStaples}`);
    const {isLoggedIn, user, stapleCursor = DEFAULT_TSN12_CURSOR} = this.state;
    const {id:userId} = user || {};
    if (userId && !this.isFetchingUserStaples) {
      trace(`fetchUserStaplesPage(2): userId=${userId}, cursor='${stapleCursor}'`);
      this.isFetchingUserStaples = true;
      isLoggedIn ?
        await fetchUserStaples({userId: userId, cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT}) :
        await fetchUserStaplesAsGuest({userId: userId, cursor: stapleCursor, limit: STAPLES_FEED_FETCH_LIMIT});
      this.isFetchingUserStaples = false;
    }
  }

  render() {
    const {
      isServerRequested,
      isLoggedIn,
      myId,
      user,
      owners,
      staples,
      viewMode,
      fsrUsers,
      fsrCount,
      groupsQty,
      contactsQty,
      unreadCountsByStaple,
      isSidebarOpen,
      areStaplesLoaded,
      hasAnySelected} = this.state;
    if (isServerRequested && !user) {
      return (
        <NotFoundPage />
      );
    }
    trace(`render`);
    return (
      <UserStaples
        isLoggedIn={isLoggedIn}
        myId={myId}
        user={user}
        owners={owners}
        staples={staples}
        viewMode={viewMode}
        fsrUsers={fsrUsers}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        unreadCountsByStaple={unreadCountsByStaple}
        isSidebarOpen={isSidebarOpen}
        areStaplesLoaded={areStaplesLoaded}
        onPageTrigger={this.fetchUserStaplesPage}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
      />
    );
  }
}

export default Container.create(UserStaplesContainer, {withProps: true, pure: false});
