// -------------------------------------------------------------------------------------------------
//  LayoutActions.js
//  - - - - - - - - - -
//  UI layout actions.
// -------------------------------------------------------------------------------------------------
import Dispatcher from 'dispatcher/Dispatcher';
import {
  VIEWMODE_WAS_TOGGLED_ACTION,
  SIDEBAR_WAS_TOGGLED_ACTION,
  SIDEBAR_CONTEXT_WAS_SUBSTITUTED_ACTION,
  SIDEBAR_CHAT_WAS_SUBSTITUTED_ACTION,
  SIDEBAR_TABMODE_WAS_SUBSTITUTED_ACTION,
  CALL_STATE_WAS_SUBSTITUTED_ACTION} from 'core/actionTypes';
import {LS_PRESET_COLLECTION_IDS, LS_PRESET_GROUP_IDS} from 'core/commonTypes';
import {
  BRICK_VIEW, MASONRY_VIEW, SNIPPET_VIEW, STRIPE_VIEW,
  COLLECTION_TAB, GROUP_TAB, SUBJECT_TAB, COMMUNITY_TAB, ACTION_TAB, CHAT_TAB, RTC_TAB, SELECTION_TAB} from 'core/uiTypes';
import {addBodyClass, removeBodyClass} from 'application/Body';
// import ChatStore from 'stores/ChatStore'; // ToDo: OBSOLETE ???
import UserStore from 'stores/UserStore';

const isVerbose = DEBUG && true;
const prefix = '- - - LayoutActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function toggleViewMode(feedType, viewMode) {
  (viewMode === BRICK_VIEW || viewMode === MASONRY_VIEW) ? // layout: 2) on/off bricks view
    removeBodyClass('no-bricks') :
    addBodyClass('no-bricks');
  Dispatcher.dispatch({
    type: VIEWMODE_WAS_TOGGLED_ACTION,
    payload: {
      feedType: feedType,
      viewMode: viewMode,
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function toggleSidebar(isSidebarOpen) {
  const prevSidebarOpen = !document.body.classList.contains('no-sidebar');
  // layout: 2) on/off sidebar
  isSidebarOpen ? removeBodyClass('no-sidebar') : addBodyClass('no-sidebar');
  Dispatcher.dispatch({
    type: SIDEBAR_WAS_TOGGLED_ACTION,
    payload: {isSidebarOpen},
  });
  return prevSidebarOpen;
}

//  Зміна контексту для сайдбару
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Params:
//  opts = {ctxId = '', ctxType = '', ctxOwnerId = ''}
//
export async function substituteSidebarContext(opts = {}) {
  trace(`substituteSidebarContext: opts =`, opts);
  Dispatcher.dispatch({
    type: SIDEBAR_CONTEXT_WAS_SUBSTITUTED_ACTION,
    payload: opts,
  });
}

//  Зміна поточного чату сайдбару
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Params:
//  opts = {chatId = '', userId = ''}
//
//  Attn:
//  - якщо вказаний userId, то контекст чату задається "глобально" в контексті юзера;
//
export async function substituteSidebarChat(opts = {}) {
  trace(`substituteSidebarChat: opts =`, opts);
  Dispatcher.dispatch({
    type: SIDEBAR_CHAT_WAS_SUBSTITUTED_ACTION,
    payload: opts,
  });
}

//  Зміна поточної вкладки сайдбару
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Attn:
//  - якщо користувач знаходиться в режимі дзвінка, то АВТОМАТИЧНА зміна контексту не відбувається;
//    зміни контексту відбуваються лиша за прямою командою користувача;
//
export async function substituteSidebarTabMode(nextTabMode, isForced = false) {
  trace(`substituteSidebarTabMode: nextTabMode =`, nextTabMode, isForced);
  if (!isForced) {
    const {callState} = UserStore.getCallOptions();
    if (callState) {
      return;
    }
    // ToDo: OBSOLETE ???
    // FixMe: костиль для запобігання переключення на табу, якщо активний режим формування відправки !!!
    // const currMode = ChatStore.getSideTabMode();
    // if (currMode === SELECTION_TAB && (nextTabMode === COLLECTION_TAB || nextTabMode === GROUP_TAB)) {
    //   return;
    // }
  }
  Dispatcher.dispatch({
    type: SIDEBAR_TABMODE_WAS_SUBSTITUTED_ACTION,
    payload: {
      tabMode: nextTabMode,
    },
  });
}

//  Зміна стану дзвінка
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function substituteCallState(nextCallState) {
  trace(`substituteCallState`);
  Dispatcher.dispatch({
    type: CALL_STATE_WAS_SUBSTITUTED_ACTION,
    payload: {
      callState: nextCallState,
    },
  });
}

//  Зміна ідентифікаторів колекцій по-замовчуванню
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function substitutePresetCollections(ids) {
  trace(`substitutePresetCollections`);
  localStorage.setItem(LS_PRESET_COLLECTION_IDS, JSON.stringify(ids));
}

//  Зміна ідентифікаторів груп по-замовчуванню
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function substitutePresetGroups(ids) {
  trace(`substitutePresetGroups`);
  localStorage.setItem(LS_PRESET_GROUP_IDS, JSON.stringify(ids));
}
