// -------------------------------------------------------------------------------------------------
//  SystemActions.js
//  - - - - - - - - - -
//  System actions (Languages/ISO 639-1, Countries, Categories, Subjects, ..., StaticPages)
//
//  Attn:
//  - - - - -
//  - в екшенах проводимо обробку помилок: status !=='ok' / catch;
//  - для спрощення параметрів параметр мови (локаль) додаємо в api-функціях (а не в actions);
// -------------------------------------------------------------------------------------------------
import Dispatcher from 'dispatcher/Dispatcher';
import {
  SYSTEM_LANGUAGES_WERE_FETCHED_ACTION,
  SYSTEM_COUNTRIES_WERE_FETCHED_ACTION,
  SYSTEM_CATEGORIES_WERE_FETCHED_ACTION,
  SYSTEM_SUBJECTS_WERE_FETCHED_ACTION,
  SYSTEM_STATICPAGES_WERE_FETCHED_ACTION} from 'core/actionTypes';
import {
  LANGUAGES_FLD,
  COUNTRIES_FLD,
  CATEGORIES_FLD,
  SUBJECTS_FLD,
  STATICPAGES_FLD} from 'core/apiFields';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS} from 'core/apiTypes';
import {
  apiFetchSystemLanguages,
  apiFetchSystemCountries,
  apiFetchSystemCategoriesAsGuest,
  apiFetchSystemCategories,
  apiFetchSystemSubjectsAsGuest,
  apiFetchSystemSubjects,
  apiFetchSystemStaticpages} from 'api/SystemAPI';

const isVerbose = DEBUG && true;
const prefix = '- - - AccountActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let areSystemCategoriesFetched = false;   // чи викликали завантаження списку категорій?
let areSystemSubjectsFetched = false;     // чи викликали завантаження списку предметів?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemLanguages() {
  trace(`fetchSystemLanguages`);
  try {
    const response = await apiFetchSystemLanguages();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[LANGUAGES_FLD]:languages} = payload;
      if (languages) {
        Dispatcher.dispatch({
          type: SYSTEM_LANGUAGES_WERE_FETCHED_ACTION,
          payload: {
            languages: languages,
          }
        });
      }
    } else {
      traceError(`fetchSystemLanguages: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemCountries() {
  trace(`fetchSystemCountries`);
  try {
    const response = await apiFetchSystemCountries();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[COUNTRIES_FLD]:countries} = payload;
      if (countries) {
        Dispatcher.dispatch({
          type: SYSTEM_COUNTRIES_WERE_FETCHED_ACTION,
          payload: {
            countries: countries,
          }
        });
      }
    } else {
      traceError(`fetchSystemCountries: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemCategoriesAsGuest(isForced = false) {
  trace(`fetchSystemCategoriesAsGuest`);

  const fetcher = async () => {
    let resp;
    if (!areSystemCategoriesFetched || isForced) {
      try {
        areSystemCategoriesFetched = true;
        resp = await apiFetchSystemCategoriesAsGuest();
      } catch(error) {
        areSystemCategoriesFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[CATEGORIES_FLD]:categories} = payload;
        if (categories) {
          Dispatcher.dispatch({
            type: SYSTEM_CATEGORIES_WERE_FETCHED_ACTION,
            payload: {
              categories: categories,
            }
          });
        }
      } else {
        traceError(`fetchSystemCategoriesAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemCategories(isForced = false) {
  trace(`fetchSystemCategories`);

  const fetcher = async () => {
    let resp;
    if (!areSystemCategoriesFetched || isForced) {
      try {
        areSystemCategoriesFetched = true;
        resp = await apiFetchSystemCategories();
      } catch(error) {
        areSystemCategoriesFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[CATEGORIES_FLD]:categories} = payload;
        if (categories) {
          Dispatcher.dispatch({
            type: SYSTEM_CATEGORIES_WERE_FETCHED_ACTION,
            payload: {
              categories: categories,
            }
          });
        }
      } else {
        traceError(`fetchSystemCategories: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemSubjectsAsGuest(isForced = false) {
  trace(`fetchSystemSubjectsAsGuest`);

  const fetcher = async () => {
    let resp;
    if (!areSystemSubjectsFetched || isForced) {
      try {
        areSystemSubjectsFetched = true;
        resp = await apiFetchSystemSubjectsAsGuest();
      } catch(error) {
        areSystemSubjectsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[SUBJECTS_FLD]:subjects} = payload;
        if (subjects) {
          Dispatcher.dispatch({
            type: SYSTEM_SUBJECTS_WERE_FETCHED_ACTION,
            payload: {
              subjects: subjects,
            }
          });
        }
      } else {
        traceError(`fetchSystemSubjectsAsGuest: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemSubjects(isForced = false) {
  trace(`fetchSystemSubjects`);

  const fetcher = async () => {
    let resp;
    if (!areSystemSubjectsFetched || isForced) {
      try {
        areSystemSubjectsFetched = true;
        resp = await apiFetchSystemSubjects();
      } catch(error) {
        areSystemSubjectsFetched = false;
        console.error(`API error`, error);
      }
    }
    return resp;
  }

  try {
    const response = await fetcher();
    if (response) {
      const {[STATUS]:status, [PAYLOAD]:payload} = response;
      if (status === 'ok') {
        const {[SUBJECTS_FLD]:subjects} = payload;
        if (subjects) {
          Dispatcher.dispatch({
            type: SYSTEM_SUBJECTS_WERE_FETCHED_ACTION,
            payload: {
              subjects: subjects,
            }
          });
        }
      } else {
        traceError(`fetchSystemSubjects: status=${status}, payload=${JSON.stringify(payload)}`);
      }
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchSystemStaticpages() {
  trace(`fetchSystemStaticpages`);
  try {
    const response = await apiFetchSystemStaticpages();
    const {[STATUS]:status, [PAYLOAD]:payload} = response;
    if (status === 'ok') {
      const {[STATICPAGES_FLD]:staticpages} = payload;
      if (staticpages) {
        Dispatcher.dispatch({
          type: SYSTEM_STATICPAGES_WERE_FETCHED_ACTION,
          payload: {
            staticpages: staticpages,
          }
        });
      }
    } else {
      traceError(`fetchSystemStaticpages: status=${status}, payload=${JSON.stringify(payload)}`);
    }
  } catch(error) {
    console.error(`API error`, error);
  }
}
