// -------------------------------------------------------------------------------------------------
//  Body.js
//  - - - - - - - - - -
//  Функції та компоненти для модифікації body.
//
//  Usage:
//  - - - - -
//  <NoTabbar />          = hides application Tabbars
// -------------------------------------------------------------------------------------------------
import React from 'react';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export class BodyClass extends React.Component {
  wasAppliedBefore = false;

  componentDidMount() {
    this.wasAppliedBefore = document.body.classList.contains(this.props.className);
    if (!this.wasAppliedBefore) {
      document.body.classList.add(this.props.className);
    }
  }

  componentWillUnmount() {
    if (!this.wasAppliedBefore) {
      document.body.classList.remove(this.props.className);
    }
  }

  render() {
    return null;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function addBodyClass(className) {
  if (!document.body.classList.contains(className)) {
    document.body.classList.add(className);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function removeBodyClass(className) {
  if (document.body.classList.contains(className)) {
    document.body.classList.remove(className);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function NoTabbar() {
  return <BodyClass className="no-tabbar" />;
}

export function PreventBodyScroll() {
  document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden; touch-action: none');
  document.ontouchmove = (e) => {e.preventDefault()};
  // document.body.style.position = 'fixed';
  // document.body.style.width = '100%';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function RestoreBodyScroll() {
  document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: ""; touch-action: auto');
  document.ontouchmove = () => { return true };
  // document.body.style.position = '';
  // document.body.style.width = '';
}
