// -------------------------------------------------------------------------------------------------
//  MediaAPI.js
//  - - - - - - - - - -
//
//  MIME Types:
//  https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
//
//  Attn:
//  - - - - -
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
// -------------------------------------------------------------------------------------------------
import loadImage from 'blueimp-load-image';
import {UPLOAD_AVATAR_CMD, UPLOAD_PICTURE_CMD} from 'core/apiTypes';
import {
  ADVERT_ELEMENT,
  BOOKMARK_ELEMENT,
  EMBED_ELEMENT,
  IMAGE_ELEMENT,
  TEXTNOTE_ELEMENT,
  DOCUMENT_ELEMENT,
  COLLECTION_ELEMENT,
  USER_ELEMENT} from 'core/elementTypes';
import {httpFetch, httpRequest} from 'api/HttpAPI';
import {media as settings} from 'settings/local.yaml';

const isVerbose = DEBUG && true;
const prefix = '- - - MediaAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

const SUPPORTED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png'
];

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isValidImageType(type = '') {
  return SUPPORTED_IMAGE_MIME_TYPES.includes(type);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function identifyImageType(type = '', defaultType = 'image/jpeg') {
  return SUPPORTED_IMAGE_MIME_TYPES.includes(type) ? type : defaultType;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function previewSettings(elemType, fileType) {
  let pvType;
  let pvQuality;
  let pvMaxWidth;
  let pvMaxHeight;
  switch(elemType) {
    case BOOKMARK_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.bookmark.type;
      pvQuality = settings.bookmark.quality;
      pvMaxWidth = settings.bookmark.maxWidth;
      pvMaxHeight = settings.bookmark.maxHeight;
      break;
    case EMBED_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.embed.type;
      pvQuality = settings.embed.quality;
      pvMaxWidth = settings.embed.maxWidth;
      pvMaxHeight = settings.embed.maxHeight;
      break;
    case IMAGE_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.image.type;
      pvQuality = settings.image.quality;
      pvMaxWidth = settings.image.maxWidth;
      pvMaxHeight = settings.image.maxHeight;
      break;
    case DOCUMENT_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.document.type;
      pvQuality = settings.document.quality;
      pvMaxWidth = settings.document.maxWidth;
      pvMaxHeight = settings.document.maxHeight;
      break;
    case COLLECTION_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.collection.type;
      pvQuality = settings.document.quality;
      pvMaxWidth = settings.document.maxWidth;
      pvMaxHeight = settings.document.maxHeight;
      break;
    case USER_ELEMENT:
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.avatar.type;
      pvQuality = settings.avatar.quality;
      pvMaxWidth = settings.avatar.maxWidth;
      pvMaxHeight = settings.avatar.maxHeight;
      break;
    case 'illustration':
      pvType = SUPPORTED_IMAGE_MIME_TYPES.includes(fileType) ? fileType : settings.illustration.type;
      pvQuality = settings.illustration.quality;
      pvMaxWidth = settings.illustration.maxWidth;
      pvMaxHeight = settings.illustration.maxHeight;
      break;
    default:
      pvType = settings.default.type;
      pvQuality = settings.default.quality;
      pvMaxWidth = settings.default.maxWidth;
      pvMaxHeight = settings.default.maxHeight;
  }
  return {type:pvType, quality:pvQuality, maxWidth:pvMaxWidth, maxHeight:pvMaxHeight};
}

// https://stackoverflow.com/questions/40258028/canvas-toblob-is-not-recognized-as-a-function-in-safari
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function(callback, type, quality) {
      let binStr = atob(this.toDataURL(type, quality).split(',')[1]),
        len = binStr.length,
        arr = new Uint8Array(len);
      for (let i = 0; i < len; i++ ) {
        arr[i] = binStr.charCodeAt(i);
      }
      callback(new Blob([arr], {type: type || 'image/png'}));
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  1. зменшує розмір зображення по максимальному виміру до maxSize
//  2. виконує перетворення формату до вказаного типу
// - - - - - - - - - - - - - - - - - - -
//  https://github.com/blueimp/JavaScript-Load-Image#options
//
//  maxWidth                Defines the maximum width of the img/canvas element.
//  maxHeight               Defines the maximum height of the img/canvas element.
//  minWidth                Defines the minimum width of the img/canvas element.
//  minHeight               Defines the minimum height of the img/canvas element.
//  sourceWidth             The width of the sub-rectangle of the source image to draw into the destination canvas.
//                          Defaults to the source image width and requires canvas: true.
//  sourceHeight            The height of the sub-rectangle of the source image to draw into the destination canvas.
//                          Defaults to the source image height and requires canvas: true.
//  top                     The top margin of the sub-rectangle of the source image.
//                          Defaults to 0 and requires canvas: true.
//  right                   The right margin of the sub-rectangle of the source image.
//                          Defaults to 0 and requires canvas: true.
//  bottom                  The bottom margin of the sub-rectangle of the source image.
//                          Defaults to 0 and requires canvas: true.
//  left                    The left margin of the sub-rectangle of the source image.
//                          Defaults to 0 and requires canvas: true.
//  contain                 Scales the image up/down to contain it in the max dimensions if set to true.
//                          This emulates the CSS feature background-image: contain.
//  cover                   Scales the image up/down to cover the max dimensions with the image dimensions if set to true.
//                          This emulates the CSS feature background-image: cover.
//  aspectRatio             Crops the image to the given aspect ratio (e.g. 16/9).
//                          Setting the aspectRatio also enables the crop option.
//  pixelRatio              Defines the ratio of the canvas pixels to the physical image pixels on the screen.
//                          Should be set to window.devicePixelRatio unless the scaled image is not rendered on screen.
//                          Defaults to 1 and requires canvas: true.
//  downsamplingRatio       Defines the ratio in which the image is downsampled (scaled down in steps).
//                          By default, images are downsampled in one step.
//                          With a ratio of 0.5, each step scales the image to half the size,
//                          before reaching the target dimensions.
//                          Requires canvas: true.
//  imageSmoothingEnabled   If set to false, disables image smoothing.
//                          Defaults to true and requires canvas: true.
//  imageSmoothingQuality   Sets the quality of image smoothing.
//                          Possible values: 'low', 'medium', 'high'
//                          Defaults to 'low' and requires canvas: true.
//  crop                    Crops the image to the maxWidth/maxHeight constraints if set to true.
//                          Enabling the crop option also enables the canvas option.
//  orientation             Transform the canvas according to the specified Exif orientation,
//                          which can be an integer in the range of 1 to 8 or the boolean value true.
//                          When set to true, it will set the orientation value based on the Exif data
//                          of the image, which will be parsed automatically if the Exif extension
//                          is available.
//  meta                    Automatically parses the image metadata if set to true.
//                          If metadata has been found, the data object passed as second argument to
//                          the callback function has additional properties (see metadata parsing).
//                          If the file is given as URL and the browser supports the fetch API or the XHR responseType blob, fetches the file as Blob to be able to parse the metadata.
//  canvas                  Returns the image as canvas element if set to true.
//  crossOrigin             Sets the crossOrigin property on the img element for loading CORS
//                          enabled images.
//  noRevoke                By default, the created object URL is revoked after the image has been
//                          loaded, except when this option is set to true.
//
export function optimizeImage(file, options = {}) {
  const {type = 'image/jpeg', quality = 0.9, maxSize = 1024, maxWidth = 1024, maxHeight = 1024} = options;
  return new Promise((resolve) => {
    loadImage(file, (canvas) => {
      if (canvas && canvas.toBlob) {
        // ...HTMLCanvasElement
        canvas.toBlob((blob) => {
          blob.name = file.name;
          resolve(blob);
        }, type, quality);
      } else {
        // ...other types (e.g. HTMLImageElement)
        resolve(file);
      }
    }, {
      canvas: true, // 'true' required by many loadImage API's (see above)
      orientation: true,
      maxWidth: maxWidth || maxSize,
      maxHeight: maxHeight || maxSize
    });
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUploadAvatar(file, onProgress) {
  trace(`apiUploadAvatar`);
  const buffer = await readFileAsArrayBuffer(file);
  return await httpFetch(UPLOAD_AVATAR_CMD, {
    method: 'POST',
    body: buffer,
    onUploadProgress: ({loaded, total}) => {
      if(onProgress) {
        const percent = Math.floor(loaded / total * 100);
        onProgress(percent);
      }
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUploadPicture(file, onProgress) {
  trace(`apiUploadPicture`);
  const buffer = await readFileAsArrayBuffer(file);
  return await httpFetch(UPLOAD_PICTURE_CMD, {
    method: 'POST',
    body: buffer,
    onUploadProgress: ({loaded, total}) => {
      if(onProgress) {
        const progress = Math.floor(loaded / total * 100);
        onProgress(progress);
      }
    },
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  A Facebook avatar image for a User.
//
//  Doc:
//  https://developers.facebook.com/docs/graph-api/reference/user/picture/
//
//  Response:
//  {
//    "data": {
//      "url": "...",
//      "width": 320
//      "height": 320,
//      "is_silhouette": false,
//    }
// }
// - - - - - - - - - - - - - - - - - - -
export async function apiGetFacebookPictureUrl(id) {
  trace(`apiGetFacebookPictureUrl`);
  try {
    const result = await httpRequest(
      `https://graph.facebook.com/v3.2/${id}/picture?width=320&height=320&redirect=false`, {
        method: 'GET',
        headers: {},
      }
    );
    const {data} = result || {};
    const {url = ''} = data || {};
    return url;
  } catch(error) {
    console.error(`HTTP Request Error`, error);
  }
  return undefined;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function getVideoDuration(video) {
  return new Promise((resolve, reject) => {
    const element = document.createElement('video');
    element.src = URL.createObjectURL(video);
    element.onloadedmetadata = (event) => {
      URL.revokeObjectURL(element.src);
      resolve(element.duration);
    };
    element.onerror = reject;
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function fetchImageUrlAsBlob(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      return null;
    }
    return await response.blob();
  } catch (error) {
    console.error('Error fetching image as Blob:', error);
    return null;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function saveImageBlobAsTempFile(imageBlob) {
  try {
    const {type = 'image/jpeg'} = imageBlob;
    const pictureExt = type.split('/')[1];
    const timestamp = Date.now(); // current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 1000000); // random number
    const tempFileName = `file-${timestamp}-${randomNum}.${pictureExt}`;
    return new File([imageBlob], tempFileName, {type: imageBlob.type});
  } catch (error) {
    console.error('Error saving imageBlob as temporary file:', error);
    return null;
  }
}
