// -------------------------------------------------------------------------------------------------
//  image.js (*)
//  - - - - - - - - - -
//  ![example](http://example.com "Optional title")
// -------------------------------------------------------------------------------------------------
import {Range} from 'slate';
import {inlineTypes} from 'core/richTypes';

export default function autoreplaceImage(node, matched, change) {
  const matchedLength = matched[0].length;

  return change
    .deleteAtRange(
      Range.create({
        anchorKey: node.key,
        focusKey: node.key,
        anchorOffset: matched.index,
        focusOffset: matched.index + matchedLength
      })
    )
    .insertInline({
      type: inlineTypes.IMAGE,
      isVoid: true,
      data: {
        src: matched[2]
      }
    })
    .collapseToStartOfNextText();
}
