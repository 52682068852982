// -------------------------------------------------------------------------------------------------
//  StatusMessage.js
//  - - - - - - - - - -
//  Початкове повідомлення в чаті.
//
//  Attn:
//  - - - - -
//  - для непрочитаних повідомлень встановлюємо observer;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import dateFormat from 'dateformat';
import classnames from 'classnames';
import {t} from 'ttag';
import {
  INITIAL_MESSAGE,
  ACTIVATION_MESSAGE,
  ASSIGNED_MESSAGE,
  SUBMISSION_MESSAGE,
  REVISION_MESSAGE,
  DISPUTE_MESSAGE,
  CLOSED_MESSAGE,
  messageTypeText} from 'core/communicationTypes';
import {setUnreadMessageAsObserved} from 'actions/ChatActions';
import Icon from 'components/UI/icons/Icon';
import {humanDate} from 'utils/humanFormats';
import styles from './StatusMessage.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - StatusMessage';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class StatusMessage extends React.Component {
  static propTypes = {
    message: Types.object,                // message
    createdAt: Types.string,              // час створення повідомлення
    isMy: Types.bool,                     // чи є користувач автором повідомлення?
    isUnread: Types.bool,                 // чи є це повідомлення в списку непрочитаних?
  }

  constructor(props) {
    super(props);
    this.observer = new IntersectionObserver(this.handleObservation, {threshold: 0.001});
  };

  // FixMe: обновлювати коли прочитане повідомлення, але проблема dispatch in the middle of dispatch !!!
  shouldComponentUpdate(nextProps, nextState) {
    const result = this.props.createdAt !== nextProps.createdAt;
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  componentWillUnmount() {
    trace(`componentWillUnmount`);
    this.observer.disconnect();
  }

  handleObservation = ([entry]) => {
    if (entry && entry.isIntersecting) {
      const {message} = this.props;
      const {id:messageId} = message;
      if (messageId) {
        trace(`handleObservation: messageId=${messageId}`);
        setUnreadMessageAsObserved(messageId);
        this.observer.unobserve(entry.target);
      }
    }
  }

  initiateObserver = (elem) => {
    if(elem) {
      trace(`initiateObserver: messageId=${this.props.message.id}`);
      this.observer.observe(elem);
    }
  }

  render() {
    const {message, createdAt, isMy, isUnread} = this.props;
    const {type:msgType = '', isRead} = message || {};
    trace(`render`);
    return (
      <div className={styles.StatusMessage}
           title={dateFormat(createdAt, t`dddd, mmmm d, yyyy HH:MM`)}
           ref={isUnread && !isMy ? this.initiateObserver : null}>
        <header>
          <span className={classnames({[styles.revisionBold]: msgType === REVISION_MESSAGE})}>{messageTypeText(msgType)}</span>
          <div className={styles.dateTime}>{humanDate(createdAt)}</div>
            <Icon symbolName={isRead ? "done-all" : "done"} className={styles.readIcon} />
        </header>
      </div>
    );
  }
}
