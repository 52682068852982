// -------------------------------------------------------------------------------------------------
//  AdaptiveWrapper.js
//  - - - - - - - - - -
//  Враппер задає змінну ширину елементу в задежності від ширини вікна.
//  Ширина враппера кратна ширині цілого числа стейплів.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import styles from './AdaptiveWrapper.scss';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class AdaptiveWrapper extends React.Component {
  render() {
    const {id, children} = this.props;
    return (
      <div id={id} className={styles.AdaptiveWrapper}>
        {children}
      </div>
    );
  }
}
